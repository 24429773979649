export default class ElementSizeObserver {
  private resizeObserver: any = null;
  observingElement: null | HTMLElement = null;

  install(elem: HTMLElement, callback?: () => void, callOnInstall?: boolean) {
    this.uninstall();

    const ResizeObserver = window.ResizeObserver;
    if(ResizeObserver) {
      this.resizeObserver = new ResizeObserver((entries: any) => {
        callback?.();
      });

      // start observe
      this.resizeObserver.observe(elem);

      this.observingElement = elem;

      if(callOnInstall) callback?.()
    }
  }
  uninstall() {
    if(this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
    this.observingElement = null;
  }
}
