
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {ContentItemAudio, ContentItemType, DraggingObjectType, TimelineContent} from "@/Booker/lib/BookerTypes";
import {PT} from "@/assets/lib/dom/DomTypes";
import DraggingItemModel from "@/Booker/lib/model/DraggingItemModel";
import ElementDragHandler from "@/assets/lib/dom/ElementDragHandler";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

@Component({name: "AudioTimelineItem", components: {}})
export default class AudioTimelineItem extends Vue {

  @Prop() timelineContent!: TimelineContent
  @Prop() elementDragHandler!: ElementDragHandler
  @Prop() timelineSize!: number
  @Prop() audioItemIndex!: number
  @Prop() isPreview!: boolean

  @Ref() refCanvas!: HTMLCanvasElement;
  @Ref() refDragCanvas!: HTMLCanvasElement;
  @Ref() refDragView!: HTMLElement;

  audioCaptionStatus = false
  BookerUtility = BookerUtility

  isResizing = false
  startWidth = 0
  startX = 0
  mouseRelativeCoordinatePT: PT = {x: 0, y: 0}
  deleteStatus = true

  get nowWidth() {
    return (((this.timelineContent.end! - this.timelineContent.start) / 1000) * this.timelineSize).toFixed(2);
  }

  get nowLeft() {
    return (((this.timelineContent.start * this.timelineSize / 1000)).toFixed(2));
  }

  mounted() {
    addEventListener('keydown', this.deleteKeyInput)
    this.createAudioWave()
  }

  beforeDestroy() {
    removeEventListener('keydown', this.deleteKeyInput)
  }

  /**
   * audio wave
   */
  async createAudioWave() {
    const audioContext = new AudioContext();
    const audioSrc = BookerUtility.getFile((this.timelineContent.content as ContentItemAudio).source);
    const response = await fetch(audioSrc);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const canvasCtx = this.refCanvas.getContext('2d') as CanvasRenderingContext2D;
    const canvasCtx2 = this.refDragCanvas.getContext('2d') as CanvasRenderingContext2D;
    const samples = audioBuffer.getChannelData(0);
    const bufferLength = samples.length;
    const canvasWidth = this.refCanvas.width;
    const canvasHeight = this.refCanvas.height;
    let sliceWidth = (canvasWidth / bufferLength);
    let x = 0;
    let y = 0;

    canvasCtx.clearRect(0, 0, canvasWidth, canvasHeight);
    canvasCtx.lineWidth = 4;
    canvasCtx.strokeStyle = '#ffffff44';
    canvasCtx.beginPath();

    if (bufferLength > 1000000) {
      let sliceWidth = (canvasWidth / bufferLength * 100);

      for (let i = 0; i < bufferLength / 5; i = i + 5) {
        let sample = 0;
        samples.slice(i, i + 5).forEach((s) => sample += s);
        y = (sample / 5 + 1) * canvasHeight / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;

      }
    } else {
      for (let i = 0; i < bufferLength; i++) {
        const sample = samples[i];
        y = (sample + 1) * canvasHeight / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;

      }
    }

    canvasCtx.stroke();
    canvasCtx2.drawImage(this.refCanvas, 0, 0)

  }

  deleteKeyInput(event: KeyboardEvent) {
    if (event.code === 'Delete' && this.$el.classList.contains('focus')) {
      if (confirm('삭제하시겠습니까?')) this.delTimelineItem();
    }
  }


  /**
   * vue 이벤트
   */

  /**
   * PreviewSeekBar 상태 변경
   */
  @Emit('setPreSeekBar')
  setPreSeekBar(isPreSeekBar: boolean) {
    return isPreSeekBar;
  }

  /**
   * timelineContent 삭제
   */
  @Emit('delTimelineItem')
  delTimelineItem() {
    return this.timelineContent;
  }

  /**
   * timelineContent 시간 변경
   */
  @Emit('timelineContentTimeChange')
  timelineContentTimeChange(contentItemType: ContentItemType, itemIndex: number, endTime: number) {
    return {contentItemType, itemIndex, endTime};
  }


  /**
   * return time duration in korean
   */
  getDurationKorean(duration: number): string {
    const durationSec = Math.floor(duration / 1000);
    const durationMin = Math.floor(durationSec / 60);
    const durationHour = Math.floor(durationMin / 60);
    const durationDay = Math.floor(durationHour / 24);

    if (durationDay > 0) {
      return `${durationDay}일 ${durationHour % 24}시간 ${durationMin % 60}분 ${durationSec % 60}초`
    } else if (durationHour > 0) {
      return `${durationHour}시간 ${durationMin % 60}분 ${durationSec % 60}초`
    } else if (durationMin > 0) {
      return `${durationMin}분 ${durationSec % 60}초`
    } else {
      return `${durationSec}초`
    }
  }

  // 미디어 클릭시
  mediaAddFocus(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.resizeMediaUp();
    this.repositionMouseUp();
    if (!this.$el.classList.contains('focus')) this.$el.classList.add('focus')
    else this.$el.classList.remove('focus')
  }

  mediaEnter(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isResizing) return;
    this.audioCaptionStatus = true
  }

  mediaLeave(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isResizing) return;
    this.audioCaptionStatus = false
  }


  resizeMediaDown(event: MouseEvent) {
    if (!(event.target as HTMLElement).classList.contains('side-bar')) return

    this.isResizing = true;
    this.deleteStatus = false;

    this.startX = event.clientX;
    this.startWidth = this.$el.clientWidth
    this.audioCaptionStatus = false

    this.setPreSeekBar(false);
    window.addEventListener('mousemove', this.resizeMediaMove);
    window.addEventListener('mouseup', this.resizeMediaUp);
  }

  resizeMediaMove(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.isResizing) {
      const deltaX = event.clientX - this.startX
      if (this.timelineContent.end) {
        let endTime = (1000 / this.timelineSize * (this.startWidth + deltaX)) + this.timelineContent.start
        const minTime = this.timelineContent.start + 1000
        const maxTime = this.timelineContent.start + (this.timelineContent.content as ContentItemAudio).length;
        if (endTime < minTime) endTime = minTime
        if (endTime > maxTime) endTime = maxTime

        this.timelineContentTimeChange(this.timelineContent.type, this.audioItemIndex, endTime);
        this.setElStyle();
      }
    }
  }


  resizeMediaUp() {
    this.isResizing = false;
    this.deleteStatus = true;
    this.setPreSeekBar(true);
    window.removeEventListener('mousemove', this.resizeMediaMove);
    window.removeEventListener('mouseup', this.resizeMediaUp);
  }

  dragTimelineItem(offset: null | PT, pt?: PT) {
    if (!offset) {
      this.repositionMouseUp()
      return;
    }

    if (offset.x === 0 && offset.y === 0) {
      this.repositionMouseDown();
      return;
    }

    this.repositionMouseMove()
    //if (pt) this.repositionMouseMove(offset, pt);
  }

  startDrag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.elementDragHandler.mouseDown(event, this.dragTimelineItem)

    console.log('left', this.timelineContent.start * this.timelineSize / 1000)
  }

  @Emit('endRepositionTimeline')
  repositionMouseUp() {
    this.$el.classList.remove('drag');
    this.setElStyle();
    //this.setElDragStyle();
    //return {timelineContent: this.timelineContent, timelineIndex: this.audioItemIndex}
  }

  repositionMouseDown() {
    DraggingItemModel.setItem(DraggingObjectType.TimelineContent, this.timelineContent);
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');
  }

  repositionMouseMove() {
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');
  }

  // repositionMouseMove(offset: null | PT, pt: PT) {
  //   if (!offset) return;
  //   if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');
  //
  //   const rectPt = {x: this.$el.getBoundingClientRect().x, y: this.$el.getBoundingClientRect().y}
  //   const mouseRelativeCoordinatePtX = pt.x - this.elementDragHandler.usePtConverter(rectPt).x + this.mouseRelativeCoordinatePT.x
  //
  //   if (Math.abs(offset.x) > 1 || Math.abs(offset.y) > 1) {
  //     this.setElDragStyle(mouseRelativeCoordinatePtX, offset.y);
  //     return;
  //   }
  //   this.setElStyle();
  // }

  setElStyle() {
    this.$el.setAttribute('style',
      `width : ${this.nowWidth}px; left : ${this.nowLeft}px;`
    );

  }

  // setElDragStyle(x = 0, y = 0) {
  //   this.refDragView.setAttribute('style',
  //     `width : ${parseInt(this.nowWidth)}px; position : absolute; left: ${x}px; height: 50px; top:${y - 12}px;`
  //   );
  // }

}
