
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import TtsAudioType from "@/Booker/views/components/tts/TtsAudioType.vue"
import CommonInput from "@/Booker/views/components/common/CommonInput.vue"
import CommonButton from "@/Booker/views/components/common/CommonButton.vue"
import ContentItemTextToSpeechViewModel from "@/Booker/lib/viewModel/classContent/ContentItemTextToSpeechViewModel";
import {
  ContentItemTextToSpeech,
  ContentLibraryType,
  ContentToolStateType,
  TextToSpeechVoiceType
} from "@/Booker/lib/BookerTypes";

@Component({name: "", components: {TtsAudioType, CommonInput, CommonButton}})
export default class EditTextToSpeech extends Vue {
  @Prop() model!: ClassContentMediaListViewModel
  @Prop() libraryKey!: string
  textToSpeechViewModel: ContentItemTextToSpeechViewModel | null = null;



  @Emit('setToolState')
  setToolState(state: ContentToolStateType) {
    return state;
  }

  init() {
    console.log('libraryKey', this.libraryKey)
    const textToSpeech
      = this.model.getContentLibrary(ContentLibraryType.TextToSpeech).find(content => content.key === this.libraryKey) as ContentItemTextToSpeech
    this.textToSpeechViewModel = new ContentItemTextToSpeechViewModel(textToSpeech, this.model)

    console.log('textToSpeech', textToSpeech)
    if(!textToSpeech) {
      alert('알수없는 데이터 입니다.');
      this.$router.back();
    }
    this.textToSpeechViewModel = new ContentItemTextToSpeechViewModel(JSON.parse(JSON.stringify(textToSpeech)), this.model)
  }

  created() {
    this.init();
  }

  mounted() {
    if (!this.textToSpeechViewModel) this.init()
  }


  onInputTitle(e: Event) {
    this.textToSpeechViewModel!.textToSpeech.title = (e.target as HTMLInputElement).value;
    this.checkSave();
  }

  onInputMessage(e: Event) {
    //this.textToSpeechViewModel!.textToSpeech.message = (e.target as HTMLTextAreaElement).value;
    this.textToSpeechViewModel!.setMessage((e.target as HTMLTextAreaElement).value)
    this.checkSave();
  }

  onInputVoiceType(voiceType: TextToSpeechVoiceType) {
    this.textToSpeechViewModel!.setVoiceType(voiceType);
    this.checkSave();
  }


  /**
   * TTS 저장
   */
  isSaveAble = false

  checkSave() {
    //this.isSaveAble = true
    this.isSaveTextToSpeech()
  }

  /**
   * 저장 가능상태인지 확인
   */
  isSaveTextToSpeech() {
    if (!this.textToSpeechViewModel?.textToSpeech.title) return false;
    if (this.textToSpeechViewModel?.textToSpeech.title.length < 2 || this.textToSpeechViewModel?.textToSpeech.message.length < 2) return false;
    return true;
  }


  saveTextToSpeechState() {
    if (this.model.addTextToSpeechLibrary(this.textToSpeechViewModel!.textToSpeech)) {
      this.model.save();
      this.textToSpeechViewModel?.saveTextToSpeech()
      this.setToolState(ContentToolStateType.Default);
      return;
    }
    alert('저장에 실패하였습니다. 상태를 확인해주세요.');
  }

  @Watch('libraryKey')
  libraryKeyWatch() {
    this.init();
  }

}
