import axios from "axios";
import Api from "@/assets/lib/api/Api";
import {
  ContentAddRequest,
  ContentDelRequest,
  ContentRequest,
  EmptyRequest,
  EvaluationCriteriaRequest,
  ReleaseClassInfoRequest,
  UserClassAddRequest,
  UserClassUpdateRequest,
  UserContentAddRequest,
  UserContentUpdateRequest
} from "@/assets/lib/type/ApiRequestTypes";
import {
  classInfoResponse,
  EmptyResponse,
  EvaluationCriteriaResponseData,
  ResponseData,
  UserClassInfoResponse,
  UserContentInfoResponse
} from "@/assets/lib/type/ApiResponseTypes";
import {AxiosError} from "axios/index";
import Config from "@/config";
import App from "@/assets/lib/controller/App";

export default class ContentApi extends Api {
  private static classAxios = axios.create({
    baseURL: Config.CONFIG.API_HOST + '/api/v1/class'
  })

  private static contentAxios = axios.create({
    baseURL: Config.CONFIG.API_HOST + '/api/v1/content'
  })

  private static get headerWithToken() {
    return {headers: {Authorization: `Bearer ${App.controller.auth.token}`}}
  }


  /**
   * get class list all
   * @param data
   * @param onSuc
   * @param onErr
   */
  static classList<Q = EmptyRequest, R = ResponseData<classInfoResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.classAxios, '', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * get class by key
   * @param contentKey
   * @param data
   * @param onSuc
   * @param onErr
   */
  static class<Q = ContentRequest, R = ResponseData<classInfoResponse>>(contentKey: string, data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.classAxios, `${contentKey}`, data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * get release class list all
   * @param data
   * @param onSuc
   * @param onErr
   */
  static releaseClassList<Q = EmptyRequest, R = ResponseData<classInfoResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.classAxios, '/release', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * add Class
   * @param data
   * @param onSuc
   * @param onErr
   */
  static addClass<Q = ContentAddRequest, R = ResponseData<EmptyResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.classAxios, '/', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * update Class
   * @param data
   * @param onSuc
   * @param onErr
   */
  static updateClass<Q = ContentAddRequest, R = ResponseData<EmptyResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.classAxios, '/update', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * release Class
   * @param data
   * @param onSuc
   * @param onErr
   */
  static releaseClass<Q = ReleaseClassInfoRequest, R = ResponseData<EmptyResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.classAxios, '/release', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * delete Class
   * @param data
   * @param onSuc
   * @param onErr
   */
  static delClass<Q = ContentDelRequest, R = ResponseData<EmptyResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.classAxios, '/delete', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * get user class info
   * @param data
   * @param onSuc
   * @param onErr
   */
  static userClass<Q = EmptyRequest, R = ResponseData<UserClassInfoResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.classAxios, '/user', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * user class info add
   * @param data
   * @param onSuc
   * @param onErr
   */
  static userClassAdd<Q = UserClassAddRequest, R = ResponseData<EmptyResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.classAxios, '/user/add', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * user class info update
   * @param data
   * @param onSuc
   * @param onErr
   */
  static userClassUpdate<Q = UserClassUpdateRequest, R = ResponseData<EmptyResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.classAxios, '/user/update', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * evaluation criteria content
   * @param data
   * @param onSuc
   * @param onErr
   */
  static evaluationCriteria<Q = EvaluationCriteriaRequest, R = ResponseData<EvaluationCriteriaResponseData>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.classAxios, '/evaluation', data, this.headerWithToken, onSuc, onErr);
  }


  /**
   * get user content info
   * @param data
   * @param onSuc
   * @param onErr
   */
  static userContent<Q = EmptyRequest, R = ResponseData<UserContentInfoResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.contentAxios, '/user', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * user content info add
   * @param data
   * @param onSuc
   * @param onErr
   */
  static userContentAdd<Q = UserContentAddRequest, R = ResponseData<EmptyResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.contentAxios, '/user/add', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * user content info update
   * @param data
   * @param onSuc
   * @param onErr
   */
  static userContentUpdate<Q = UserContentUpdateRequest, R = ResponseData<EmptyResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.contentAxios, '/user/update', data, this.headerWithToken, onSuc, onErr);
  }
}
