
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import CommonModal from "@/Booker/views/components/common/CommonModal.vue";

@Component({name: "BookMarkToolHeader", components: {CommonModal, CommonInput}})
export default class BookMarkToolHeader extends Vue {

  @Prop() model!: ClassContentMediaListViewModel;
  //@Prop() titleEdit!: string
  @Ref() refTitleInput!: HTMLInputElement


  modalStatus = false
  titleStatus = false
  titleEdit = ''


  // 메인으로 이동
  goBack() {
    this.$router.push('/bookmark/main')
  }

  // 미리보기 수업으로 이동
  goPreview() {
    this.$router.push('/class/' + this.model.content.key)
  }


  // 제목 수정
  renameTitle() {
    this.titleStatus = !this.titleStatus
    //this.model. = this.titleEdit
    this.disabledChange()
    this.$nextTick(() => {
      if (this.refTitleInput) {
        this.refTitleInput.focus()
      }
    })
  }

  // 제목 저장
  titleSave(e: Event) {
    this.model!.content.title = (e.target as HTMLInputElement).value
  }

  // 제목 텍스트 변경시 header disabled false로 변경
  disabledChange() {
    const btnTool = document.querySelectorAll('.btn-tool');
    if (this.titleEdit.length >= 0) {
      btnTool.forEach((btn) => {
        btn.removeAttribute('disabled')
      })
    } else {
      btnTool.forEach((btn) => {
        btn.setAttribute('disabled', 'disabled')
      })
    }
  }

  saveTemporaryContent() {
    this.titleSave
    this.model.save(true);
  }

  async releaseContent(isRelease: boolean, isOverWirte = false) {
    if (!await this.model.setRelease(isRelease, isOverWirte)) {
      this.modalStatus = true;
    }
  }

  receiveResult(result: boolean) {
    this.modalStatus = false;
    if (!result) return;
    this.releaseContent(true, true);
  }

}
