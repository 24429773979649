
import {Component, Prop, Vue} from "vue-property-decorator";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";
import {QuizItem} from "@/Booker/lib/BookerTypes";

@Component({name: "QuizScore", components: {CommonInput}})
export default class QuizScore extends Vue {

  @Prop() model!: ContentItemQuizEditorViewModel;
  @Prop() questionIndex!: number;
  @Prop() difficulty!: number;
  question: QuizItem | null = null;
  max = 100;
  min = 0;

  get scores() {
    return this.question?.score
  }

  get totalScore() {
    return this.scores?.reduce((acc, cur) => acc + cur.value, 0)
  }

  mounted() {
    const quizIdx = this.model.findDifficultyIndexInAllList(this.questionIndex, this.difficulty);
    this.question = this.model.getQuiz().quizItems[quizIdx]
  }

  onInputScore(e: Event) {
    const el = (e.target as HTMLInputElement);
    let score = Number(el.value)
    const scoreIdx = Number(el.dataset.index)
    this.changeScore(score, scoreIdx)
  }

  changeScore(score: number, scoreIdx: number) {
    if (score > this.max) score = this.max;
    if (score < this.min) score = this.min;
    const scoreData = {
      questionIndex: this.questionIndex,
      difficulty: this.difficulty,
      scoreIdx: scoreIdx,
      score: score
    }
    this.model.setScore(scoreData)
  }
  
}
