import ClassContentModel from "@/Booker/lib/model/ClassContentModel";
import ContentCacheManager from "@/Booker/lib/util/ContentCacheManager";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import {
  ClassContent,
  ContentItemAudio,
  ContentItemBase,
  ContentItemBook,
  ContentItemFigure,
  ContentItemImage,
  ContentItemPronunciation,
  ContentItemQuiz,
  ContentItemRelaxation,
  ContentItemScenario,
  ContentItemScenarioType,
  ContentItemText,
  ContentItemTextToSpeech,
  ContentItemType,
  ContentItemVideo,
  MediaContentItemBase,
  TextToSpeechVoiceType,
  TimelineContent,
  UserClassContent,
  VideoContentSourceType,
  YoutubeItem
} from "@/Booker/lib/BookerTypes";
import FileApi from "@/assets/lib/api/FileApi";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import DocumentApi from "@/assets/lib/api/DocumentApi";
import {PrepareRequest} from "@/assets/lib/type/ApiRequestTypes";
import {PrepareResponse} from "@/assets/lib/type/ApiResponseTypes";
import DeviceUtil from "@/assets/lib/utility/DeviceUtil";

export default class ClassContentController {
  model = new ClassContentModel()
  cache = new ContentCacheManager()
  device = new DeviceUtil()

  /**
   * 미디어 추가 (동영상, 이미지, 오디오)
   * @param content
   * @param file
   */
  async addMediaLibrary(content: ClassContent, file: File): Promise<ContentItemBase> {
    const isAudio = file.type.startsWith('audio/');
    const isVideo = file.type.startsWith('video/');
    const isImage = file.type.startsWith('image/');

    const fileApi = await FileApi.uploadFile(file)
    const fileId = fileApi?.file_list[0].file_id

    if (!fileId) {
      throw new Error('file type is not supported: ' + file.type)
    }

    let item: ContentItemBase

    if (isAudio) {
      item = await this.createAudioContentItem(file, fileId)
      content.contentLibrary.push(item as ContentItemAudio)
    } else if (isVideo) {
      await FileApi.convertHls({file_id: fileId});
      const getVideoFile = await this.createVideoContentItem(file, fileId);
      if (getVideoFile === undefined) {
        throw new Error('file type is not supported: ' + file.type)
      }
      item = getVideoFile
      content.contentLibrary.push(item as ContentItemVideo)
    } else if (isImage) {
      item = await this.createImageContentItem(file, fileId)
      content.contentLibrary.push(item as ContentItemImage)
    } else {
      throw new Error('file type is not supported: ' + file.type)
    }
    ClassContentManager.controller.model.saveContent(content)
    return item;
  }


  /**
   * 빈페이지 라이브러리 추가
   * @param content
   * @param contentImg
   */
  addEmptyPageLibrary(content: ClassContent, contentImg: ContentItemImage): ContentItemImage {
    content.contentLibrary.push(contentImg)
    return contentImg
  }

  /**
   * 유튜브 미디어 추가
   * @param content
   * @param youtubeItem
   */
  async addYoutubeMediaLibrary(content: ClassContent, youtubeItem: YoutubeItem): Promise<ContentItemBase> {
    const item = await this.createVideoContentItemByYoutube(youtubeItem)
    content.contentLibrary.push(item)
    return item
  }

  /**
   * 교재 추가
   */
  async addBookLibrary(content: ClassContent, file: File) {

    const fileFormat = file.name.toLowerCase().split('.').pop()
    if (fileFormat) {
      const prepareData: PrepareRequest = {
        contentId: content.key,
        fileName: file.name,
        fileFormat: 'jpg'
      }

      const prepareResult = await DocumentApi.prepare(content.key, prepareData)

      if (prepareResult && prepareResult.code === 200) {
        const item = await this.createBookContentItem(file.name, prepareResult.data)
        return item
      } else {
        alert('지원하지 않는 문서입니다.');
      }

      return undefined

    } else {
      throw new Error('file type is not supported: ' + file.type)
    }
  }

  /**
   * 오디오 컨텐츠 아이템 생성
   * @param file
   * @param fileId
   */
  async createAudioContentItem(file: File, fileId: string): Promise<ContentItemAudio> {
    const audioLengthInSeconds = await BookerUtility.getAudioLengthInSeconds(file)
    const item: ContentItemAudio = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Audio,
      createTime: Date.now(),
      title: file.name,
      source: fileId,
      length: audioLengthInSeconds * 1000,
      file: file
    }
    return item
  }

  /**
   * 동영상 컨텐츠 아이템 생성
   * @param file
   * @param fileId
   */
  async createVideoContentItem(file: File, fileId: string): Promise<ContentItemVideo | undefined> {
    const videoLengthInSeconds = await BookerUtility.getVideoLengthInSeconds(file)
    if (videoLengthInSeconds === Infinity) {
      alert('지원하지 않는 동영상입니다.')
      return undefined;
    }
    const item: ContentItemVideo = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Video,
      createTime: Date.now(),
      title: file.name,
      sourceType: VideoContentSourceType.Upload,
      source: fileId,
      isLoading: true,
      length: videoLengthInSeconds * 1000,
      file: file
    }
    return item
  }

  /**
   * 유튜브 컨텐츠 아이템 생성
   * @param youtubeItem
   */
  async createVideoContentItemByYoutube(youtubeItem: YoutubeItem): Promise<ContentItemVideo> {
    console.log('youtubeItem.duration', youtubeItem.duration)
    return {
      key: BookerUtility.createKey(),
      type: ContentItemType.Video,
      createTime: Date.now(),
      title: youtubeItem.title,
      sourceType: VideoContentSourceType.Youtube,
      source: youtubeItem.url,
      length: youtubeItem.duration * 1000,
    }
  }

  /**
   * 이미지 컨텐츠 아이템 생성
   * @param file
   * @param fileId
   */
  async createImageContentItem(file: File, fileId: string): Promise<ContentItemImage> {
    const imageSize = await BookerUtility.getImageDimensions(file)
    const item: ContentItemImage = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Image,
      createTime: Date.now(),
      title: file.name,
      source: fileId,
      width: imageSize.width,
      height: imageSize.height,

      file: file
    }
    return item
  }

  /**
   * 이미지 컨텐츠 아이템 생성
   * @param fileId
   * @param width
   * @param height
   */
  async createImageContentItemByUrl(fileId: string): Promise<ContentItemImage | null> {
    const item: ContentItemImage = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Image,
      createTime: Date.now(),
      title: BookerUtility.createKey(),
      source: fileId,
      width: 0,
      height: 0,
    }
    return item
  }

  /**
   * 빈페이지 이미지 아이템 생성
   */
  createEmptyPageImageContentItem(title: string, color: string): ContentItemImage {
    const item: ContentItemImage = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Image,
      createTime: Date.now(),
      bound: {
        background: color
      },
      title: title,
      source: '',
      width: 1280,
      height: 720,

    }
    return item
  }

  /**
   * 교재 컨텐츠 아이템 생성
   * @param name
   * @param prepareData
   */
  async createBookContentItem(name: string, prepareData: PrepareResponse): Promise<ContentItemBook> {

    const item: ContentItemBook = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Book,
      createTime: Date.now(),
      title: name,
      fileName: name,
      apiInfo: prepareData,
      uploadState: {totalPage: 0, donePage: 0},
      pages: [],
    }
    return item
  }

  /**
   * contentKey 값에 해당되는 컨텐츠 라이브러리 삭제
   * 삭제된 컨텐츠의 source 값은 사용 불가
   * @param content
   * @param contentKey
   * @return 삭제된 컨텐츠
   */
  deleteContentLibrary(content: ClassContent, contentKey: string): null | ContentItemBase {
    const itemIndex = content.contentLibrary.findIndex(cl => cl.key === contentKey)
    let deletedItem = null
    if (itemIndex >= 0) {
      const items = content.contentLibrary.splice(itemIndex, 1)
      if (items && items.length > 0) deletedItem = items[0]
    }

    if (deletedItem && (deletedItem as MediaContentItemBase).file && (deletedItem as MediaContentItemBase).source) {
      try {
        URL.revokeObjectURL((deletedItem as MediaContentItemBase).source);
        (deletedItem as MediaContentItemBase).source = ''
      } catch (e) {
        // ignored
      }
    }

    return deletedItem
  }

  /**
   * 퀴즈 템플릿 생성
   */
  createQuizTemplate(): ContentItemQuiz {
    const quiz: ContentItemQuiz = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Quiz,
      createTime: Date.now(),
      title: '',
      quizItems: [],
      quizType: 2,
      useRepeat: false,
      repeatMinScore: 50,
      repeatSection: 0
    }
    return quiz
  }

  /**
   * 퀴즈 라이브러리 추가
   * key 값이 동일한 퀴즈가 있는경우 업데이트
   * @param content
   * @param quiz
   */
  addQuizLibrary(content: ClassContent, quiz: ContentItemQuiz): ContentItemQuiz {
    // update if exists
    const quizIndex = content.contentLibrary.findIndex(cl => cl.key === quiz.key)
    if (quizIndex >= 0) {
      content.contentLibrary.splice(quizIndex, 1, quiz)
      return quiz
    } else {
      content.contentLibrary.push(quiz)
      return quiz
    }
  }

  /**
   * 발음연습 템플릿 추가
   */
  createPronunciationTemplate(): ContentItemPronunciation {
    const item: ContentItemPronunciation = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Pronunciation,
      createTime: Date.now(),
      sentences: [
        {
          sentence: '',
          duration: 10000 // 10초
        }
      ]
    }
    return item
  }

  /**
   * 발음 연습 라이브러리 추가
   * @param content
   * @param item
   */
  addPronunciationLibrary(content: ClassContent, item: ContentItemPronunciation): ContentItemPronunciation {
    // update if exists
    const quizIndex = content.contentLibrary.findIndex(cl => cl.key === item.key)
    if (quizIndex >= 0) {
      content.contentLibrary.splice(quizIndex, 1, item)
      return item
    } else {
      content.contentLibrary.push(item)
      return item
    }
    return item
  }

  /**
   * 텍스트 오버레이 템플릿 추가
   */
  createTextOverlayTemplate(): ContentItemText {
    const item: ContentItemText = {
      type: ContentItemType.Text,
      key: BookerUtility.createKey(),
      createTime: Date.now(),
      title: '제목을 입력해주세요.',
      text: '',
      bound: {
        width: '400px',
        height: '100px'
      },
      font: '',
      size: 40,
      color: '',
      background: '',
      align: '',
      angle: 0,
      isSubtitle: false
    }
    return item
  }

  /**
   * 텍스트 오버레이 라이브러리 추가
   * @param content
   * @param item
   */
  addTextOverlayLibrary(content: ClassContent, item: ContentItemText): ContentItemText {
    // update if exists
    const textOverlayIndex = content.contentLibrary.findIndex(cl => cl.key === item.key)
    if (textOverlayIndex >= 0) {
      content.contentLibrary.splice(textOverlayIndex, 1, item)
      return item
    } else {
      content.contentLibrary.push(item)
      return item
    }
    return item
  }

  /**
   * 휴식 템플릿 추가
   */
  createRelaxationTemplate(): ContentItemRelaxation {
    const item: ContentItemRelaxation = {
      type: ContentItemType.Relaxation,
      key: BookerUtility.createKey(),
      length: 59833,
      sourceType: VideoContentSourceType.Upload,
      source: "/videos/4bd247e1cf1f5d3aa2b9c07a7b5993ad166a9e2893f997878cd7965996a95ee4/4bd247e1cf1f5d3aa2b9c07a7b5993ad166a9e2893f997878cd7965996a95ee4.m3u8",
      createTime: Date.now(),
      title: '휴식',
    }
    return item;
  }


// <iframe width="560" height="315" src="https://www.youtube.com/embed/X7nTyBHI2Pw?start=290" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

  /**
   * 휴식 라이브러리 추가
   * @param content
   * @param files
   */
  addRelaxationLibrary(content: ClassContent): ContentItemBase {
    const item = this.createRelaxationTemplate()

    content.contentLibrary.push(item);
    return item;
  }


  /**
   * 시나리오 템플릿 생성
   */
  createScenarioTemplate(): ContentItemScenario {
    const scenario: ContentItemScenario = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Scenario,
      scenarioType: ContentItemScenarioType.OpenContentItem,
      createTime: Date.now(),
      title: '',
      message: ''
    }
    return scenario
  }

  /**
   * 시나리오 라이브러리 추가
   * key 값이 동일한 시나리오가 있는경우 업데이트
   * @param content
   * @param quiz
   */
  addScenarioLibrary(content: ClassContent, scenario: ContentItemScenario): ContentItemBase {
    // update if exists
    const scenarioIndex = content.contentLibrary.findIndex(cl => cl.key === scenario.key)
    if (scenarioIndex >= 0) {
      content.contentLibrary.splice(scenarioIndex, 1, scenario)
      return scenario
    } else {
      content.contentLibrary.push(scenario)
      return scenario
    }
  }

  /**
   * TTS 미디어 컨텐츠 라이브러리 추가
   * @param content
   * @param contentItemTextToSpeech
   */
  addTextToSpeechLibrary(content: ClassContent, contentItemTextToSpeech: ContentItemTextToSpeech): ContentItemBase {
    // update if exists
    console.log('contentItemTextToSpeech', contentItemTextToSpeech)
    const scenarioIndex = content.contentLibrary.findIndex(cl => cl.key === contentItemTextToSpeech.key)
    if (scenarioIndex >= 0) {
      content.contentLibrary.splice(scenarioIndex, 1, contentItemTextToSpeech)
      return contentItemTextToSpeech
    } else {
      content.contentLibrary.push(contentItemTextToSpeech)
      return contentItemTextToSpeech
    }
  }

  /**
   * TTS 미디어 컨텐츠 라이브러리 추가
   * @param content
   * @param contentItemFigure
   */
  addFigureLibrary(content: ClassContent, contentItemFigure: ContentItemFigure): ContentItemBase {
    // update if exists
    const scenarioIndex = content.contentLibrary.findIndex(cl => cl.key === contentItemFigure.key)
    if (scenarioIndex >= 0) {
      content.contentLibrary.splice(scenarioIndex, 1, contentItemFigure)
      return contentItemFigure
    } else {
      content.contentLibrary.push(contentItemFigure)
      return contentItemFigure
    }
  }

  /**
   * TTS 템플릿 추가
   */
  createTextToSpeechTemplate(): ContentItemTextToSpeech {
    const ttsItem: ContentItemTextToSpeech = {
      type: ContentItemType.TextToSpeech,
      voiceType: TextToSpeechVoiceType.Man,
      length: 0,
      title: '',
      message: '',
      source: '',
      key: BookerUtility.createKey(),
      createTime: Date.now()
    }
    return ttsItem;
  }


  setAvatar(classContent: ClassContent, isAvatar: boolean) {
    classContent.isAvatar = isAvatar
  }

  /**
   * content release
   * @param classContent
   * @param isRelease
   * @param isOverWrite
   */
  async setRelease(classContent: ClassContent, isRelease: boolean, isOverWrite: boolean) {
    return await this.model.releaseContent(classContent, isRelease, isOverWrite)
  }

  async userClassAdd(contentKey: string) {
    const content = this.model.getAllContent(contentKey);
    if (!content) return;
    if (this.model.userClassList.findIndex((c) => c.classStep === content.classStep) === -1) {
      await this.model.userClassAdd(contentKey)
    }
  }

  userClassUpdate() {
    return this.model.userClassUpdate(null)
  }

  setUserClassUpdate(contentKey: string) {
    const userContent = this.model.getUserContent(contentKey);
    if (userContent) {
      this.model.changeUserClass(userContent)
    }
  }

  changeTimelineContent(contentKey: string, timelineItem: TimelineContent) {
    const userContent = this.model.getUserContent(contentKey);
    if (userContent) {
      const timelineIdx = BookerUtility.getTimelineTypeFromContentItemType(timelineItem.type);
      const findIdx = userContent.timeline[timelineIdx].contents.findIndex((c) => c.key === timelineItem.key)
      userContent.timeline[timelineIdx].contents.splice(findIdx, 1, timelineItem);
      this.model.changeUserClass(userContent)
    }
  }

  setActiveUserContent(userContent: UserClassContent) {
    this.model.activeUserContent = userContent
  }


}
