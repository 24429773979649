
import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator';
import BookerUtility from "@/Booker/lib/util/BookerUtility";

@Component({
  name: 'Card'
})
export default class Card extends Vue {
  @Prop() index !: number
  @Prop() isOn !: number
  @Prop() progressValue !: number
  @Prop() title !: string
  @Prop() contentKey !: string
  @Prop() img !: string
  @Ref() refProgressBar !: HTMLDivElement

  BookerUtility = BookerUtility

  value = 0
  speed = 10

  @Emit('setSelectIdx')
  sendSelectIdx() {
    return {selectIdx: this.index, title: this.title};
  }

  mounted() {

    let progress = setInterval(() => {
      this.value++;

      if (!this.refProgressBar) {
        return;
      }
      const percentage = (this.value / this.progressValue) * 100;
      const remainingPercentage = 100 - percentage;

      this.refProgressBar.style.background = `conic-gradient(#7277E2 ${remainingPercentage}%, rgba(114, 119, 226, 0.2) 0 100%)`;
      requestAnimationFrame(() => {
        this.refProgressBar.style.background = `conic-gradient(#7277E2 ${this.value}%, rgba(114, 119, 226, 0.2) 0 100%)`;
      })


      if (this.value === this.progressValue) {
        clearInterval(progress);
      }
    }, this.speed);

  }


}
