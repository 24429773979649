
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {QuizItem} from "@/Booker/lib/BookerTypes";
import QuestionHeader from "@/Booker/views/components/quiz/QuestionHeader.vue";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";

@Component({
  name: "QuizDesc",
  computed: {
    BookerUtility() {
      return BookerUtility
    }
  },
  components: {CommonInput, CommonTextArea, QuestionHeader}
})
export default class QuizDesc extends Vue {
  @Ref() refQuizImg!: HTMLImageElement
  @Prop() question!: QuizItem
  @Prop() quizModel!: ContentItemQuizEditorViewModel
  @Prop() difficultyIdx!: number
  @Prop() questionIdx!: number
  @Prop() difficulty!: number

  answerTextStatus = false
  explainStatus = false
  explainTextStatus = true
  correctTextStatus = true


  @Emit('checkSave')
  checkSaveSend() {
    return true;
  }

  onInputQuestion(e: Event) {
    this.question.question = (e.target as HTMLInputElement).value;
    this.checkSaveSend();
  }

  onInputAnwer(e: Event) {
    this.question.options[0].answer = (e.target as HTMLInputElement).value;

    this.checkSaveSend()
  }

  showCorrectAnswer() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.correctAnswerMsg;
      if (typeof subQ === "string") {
        this.quizModel?.delCorrectMsg(this.difficultyIdx, this.difficulty)
        this.correctTextStatus = true
      } else {
        this.quizModel?.setCorrectMsg(this.difficultyIdx, this.difficulty, '')
        this.correctTextStatus = false
      }
    }
  }

  showExplain() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.explain;
      if (typeof subQ === "string") {
        this.quizModel?.delExplain(this.difficultyIdx, this.difficulty)
        this.explainTextStatus = true
      } else {
        this.quizModel?.setExplain(this.difficultyIdx, this.difficulty, '')
        this.explainTextStatus = false
      }
    }
  }


  onInputCorrectAnswer(e: Event) {
    this.question.correctAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setCorrectMsg(this.difficultyIdx, this.difficulty, this.question.correctAnswerMsg);
  }

  onInputInCorrectAnswer(e: Event) {
    this.question.inCorrectAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setInCorrectMsg(this.difficultyIdx, this.difficulty, this.question.inCorrectAnswerMsg);
  }

  explainInput(e: Event) {
    this.question.explain = (e.target as HTMLTextAreaElement).value;
    this.quizModel.setExplain(this.difficultyIdx, this.difficulty, this.question.explain);
  }

  subQuestionInput(e: Event) {
    this.question.subQuestion = (e.target as HTMLTextAreaElement).value;
    this.quizModel.setSubQuestion(this.difficultyIdx, this.difficulty, this.question.subQuestion);
  }


}
