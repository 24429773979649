
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component({name: "CommonModal", components: {}})
export default class CommonModal extends Vue {
  @Prop() title!: string
  @Prop() btnText!: string
  @Prop() content!: string
  @Prop() modalStatus!: boolean

  @Emit('sendResult')
  sendResult(result: boolean) {
    return result
  }


}
