
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import App from "@/assets/lib/controller/App";

@Component({name: "ReportScore", components: {}})
export default class ReportScore extends Vue {
  @Prop() model!: AiReportViewModel
  @Prop() modalClass?: string

  get ratingClass() {
    if (this.modalClass) {
      switch (true) {
        case Number(this.model.myClassStepScore) < 50:
          return "soso";
        case Number(this.model.myClassStepScore) <= 75:
          return "good";
        default:
          return '';
      }
    }
    switch (true) {
      case this.model.myScore < 50:
        return "soso";
      case this.model.myScore <= 75:
        return "good";
      default:
        return '';
    }
  }

  get myScore() {
    if (this.modalClass) return Number(this.model.myClassStepScore)
    return this.model.myScore
  }

  get myLevel() {
    if (this.modalClass) return this.model.myClassStepLevel
    return this.model.myLevel
  }

  get levelText() {
    if (this.myLevel === 'high') return '훌륭해요!'
    if (this.myLevel === 'middle') return '잘했어요!'
    if (this.myLevel === 'low') return '아쉬워요'

    return '';
  }

  get myRank() {
    if (this.modalClass) return this.model.myClassStepRank
    return this.model.myRank
  }

  get auth() {
    return App.controller.auth
  }

  get userName() {
    return this.auth.userNameWithType
  }

  get userInfo() {
    return this.auth.userInfo
  }

  @Emit('showFeedback')
  showFeedback() {
    return;
  }


}
