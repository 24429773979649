
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";


@Component({name: "TimelineTools", components: {}})
export default class TimelineTools extends Vue {

  @Prop() viewModel!: ClassContentPlayerViewModel

  oldCurrentTime = 0;

  get playerIsPlay() {
    return this.viewModel.seekbar.isPlaying
  }

  /**
   * currentTime에 맞춰 시간 랜더링
   */
  get getSeekbarTime() {
    if (Math.round(this.oldCurrentTime - this.viewModel.seekbar.currentTime / 1000)) {
      const time = Math.round(this.viewModel.seekbar.currentTime / 1000);
      const sec = time % 60
      const min = time > 60 ? ((time - sec) / 60) : 0;
      this.oldCurrentTime = this.viewModel.seekbar.currentTime;
      return `${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
    }
    if (this.oldCurrentTime === 0) return '00:00'
    return '00:00'
  }

  get videoTimelineContentList() {
    return this.viewModel.timeline.getAllTimelineContents()
  }

  get timelineInputSize() {
    if (this.viewModel.timelineSize < 2) return 2;
    if (this.viewModel.timelineSize < 4) return 4;
    if (this.viewModel.timelineSize < 6) return 6;
    if (this.viewModel.timelineSize < 10) return 8;
    return 10;
  }

  @Emit('setSeekbarPlay')
  setSeekbarPlay() {
    return;
  }

  @Emit('setSeekbarPause')
  setSeekbarPause() {
    return;
  }

  /**
   * 플레이 버튼 클릭
   */

  playVideo() {
    if (!this.playerIsPlay) {
      this.viewModel?.seekbar.play();
      this.setSeekbarPlay();

      return;
    }
    this.viewModel?.seekbar.pause();
    this.setSeekbarPause();
  }

  getSeekbarAllTime() {
    const time = Math.round(this.viewModel.seekbar.length / 1000);
    const sec = time % 60
    const min = time > 60 ? ((time - sec) / 60) : 0;
    return `${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
  }

  /**
   * next 버튼 클릭시
   */
  @Emit('movePlayNextSection')
  movePlayNextSection() {
    console.log('next click')
    return;
  }

  @Emit('movePlayPrevSection')
  movePlayPrevSection() {
    console.log('prev click')
    return;
  }

  /**
   * 설정 버튼 클릭
   */

  clickSetting() {
    //TODO: 개발용확인으로 주석
    this.viewModel.clearTimeLine();
  }

  /**
   * 줌 전체화면
   */
  @Emit('changeScreen')
  changeScreen() {
    //
  }

  @Emit('EditZoomIn')
  editToolZoomIn() {
    //
  }

  @Emit('EditZoomOut')
  editToolZoomOut() {
//
  }


}
