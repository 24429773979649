
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component({name: "CommonInput", components: {}})
export default class CommonInput extends Vue {
  @Prop() type!: string
  @Prop() inputValue!: any
  @Prop() updateInput!: any
  @Prop() focusOut!: any
  @Prop() placeholder!: string
  @Prop() maxLength!: number
  @Prop() min!: number
  @Prop() step!: number
  @Prop() checked!: boolean
  @Prop() readonly !: boolean
  @Prop() inputClass !: string
  @Prop() dataIndex !: string
  @Prop() disabled!: boolean

  //숫자 증가 버튼
  @Emit('incNumber')
  incNumber() {
    return {score: this.inputValue, inputIndex: this.dataIndex}
  }

  //숫자 감소 버튼
  @Emit('decNumber')
  decNumber() {
    return {score: this.inputValue, inputIndex: this.dataIndex}
  }
}
