
import {Component, Vue} from "vue-property-decorator";
import CommonMainHeader from "@/views/ClassPage/component/CommonMainHeader.vue";
import SpeakingList from "@/views/ClassPage/component/speakingList.vue";
import MySpeakingModal from "@/views/ClassPage/component/MySpeakingModal.vue";
import MySpeakingViewModel from "@/assets/lib/viewModel/MySpeakingViewModel";

@Component({name: "MySpeakingPage", components: {MySpeakingModal, SpeakingList, CommonMainHeader}})
export default class MySpeakingPage extends Vue {

  vm: MySpeakingViewModel | null = null;
  gradeStatus = false
  classStatus = false
  modal = false

  created() {
    this.vm = new MySpeakingViewModel();
  }

  mounted() {
    if (!this.vm) this.vm = new MySpeakingViewModel();
  }

  focusGrade() {
    this.gradeStatus = !this.gradeStatus
  }

  focusClass() {
    this.classStatus = !this.classStatus
  }

  closeModal() {
    this.modal = false;
  }


  openModal() {
    this.modal = true;
  }

}
