
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import Hls from "hls.js";
import DeviceUtil from "@/assets/lib/utility/DeviceUtil";

@Component({
  name: 'VideoPlayer',
})
export default class VideoPlayer extends Vue {
  @Prop() source!: string // video source
  @Prop() isPlaying!: boolean // play or pause
  @Prop() isPreview?: boolean // play or pause
  @Prop() currentTime!: number // in ms
  @Prop() startAt?: number // in ms
  @Prop() endAt?: number // in ms
  @Ref() refVideo!: HTMLVideoElement
  @Prop() volume!: number;

  hls = new Hls();

  videoLength = 0 // in ms


  get nowTime() {
    if (this.startAt) return this.currentTime + this.startAt
    return this.currentTime
  }

  mounted() {
    this.hls.loadSource(this.source)
    this.hls.attachMedia(this.refVideo);
    this.refVideo.onload = () => {
      if (DeviceUtil.isSelectedAudio) {
        //@ts-ignore
        this.refVideo.setSinkId(DeviceUtil.selectedAudio.id)
      }
    }
    this.refVideo.pause()
    this.refVideo.volume = 1;
    // calculate video length
    this.hls.on(Hls.Events.LEVEL_LOADED, (event, data) => {
      this.videoLength = data.details.totalduration * 1000
    });

    if (this.isPreview) {
      this.refVideo.onplay = () => {
        this.refVideo.play().then(() => {
          this.refVideo.pause()
        })
      }
    }

  }

  /**
   * play or stop when isPlaying is changed
   */
  @Watch('isPlaying')
  onIsPlayingChanged() {
    this.playOrPause()
  }

  checkUpdateTime() {
    const timeValue = Math.abs(this.refVideo.currentTime * 1000 - this.nowTime);
    if (this.refVideo.paused) return (timeValue > 30)
    return timeValue > 1000
  }


  @Watch('currentTime')
  onCurrentTimeChanged() {
    if (!this.refVideo) return false;
    // update currentTime when the time diff is bigger than 30ms
    if (this.volume / 100 !== this.refVideo.volume) {
      this.refVideo.volume = this.volume / 100;
    }
    if (this.checkUpdateTime()) {
      // check boundary
      if (this.currentTime < 0) {
        this.refVideo.currentTime = 0
      } else if (this.nowTime > this.videoLength) {
        this.refVideo.currentTime = this.videoLength / 1000
      } else {
        this.refVideo.currentTime = this.nowTime / 1000
      }

      this.playOrPause()
    }
  }

  async play() {
    if (this.refVideo) await (this.refVideo as HTMLVideoElement).play();
  }

  onPlay() {
    // Update the playback status when the video starts playing
    this.$emit('update:isPlaying', true);
  }

  /**
   * play video or pause using isPlaying flag and currentTime
   */
  private async playOrPause() {

    if (this.isPreview) return;
    if (!this.refVideo) return;

    try {
      if (this.isPlaying && this.nowTime >= 0 && this.nowTime <= this.videoLength) {
        // play if video is not playing state
        if (this.refVideo.paused) {
          await this.play()
        }
      } else {
        this.refVideo.pause()
      }
    } catch (e) {
      console.error('e', e)
    }
  }


}
