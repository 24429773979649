
import {Component, Prop, Vue} from "vue-property-decorator";
import MySpeakingViewModel from "@/assets/lib/viewModel/MySpeakingViewModel";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import MySpeakingModal from "./MySpeakingModal.vue";
import {ClassContent} from "@/Booker/lib/BookerTypes";

@Component({name: "speakingList", components: {MySpeakingModal}})
export default class speakingList extends Vue {

  @Prop() vm!: MySpeakingViewModel

  headerText = [
    '소단원',
    '실습 문구',
    '진행률',
    '실습'
  ]

  BookerUtility = BookerUtility

  speakList(classContent: ClassContent) {
    return BookerUtility.getSpeakListInContent(classContent)
  }

  subTitle(classStep: number) {
    return BookerUtility.classSubTitle(classStep)
  }

  formatDate(timestamp: number): string {
    return BookerUtility.formatDate(timestamp);
  }

}
