
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";
import {QuizItem, QuizOptions} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import CommonPassageExplain from "@/Booker/views/components/common/CommonPassageExplain.vue";
import CommonPassageExplainBtnWrapper from "@/Booker/views/components/common/CommonPassageExplainBtnWrapper.vue";

@Component({
  name: "QuizStartBlank",
  computed: {
    utility() {
      return BookerUtility
    }
  },
  components: {
    CommonPassageExplainBtnWrapper,
    CommonPassageExplain, CommonInput, CommonTextArea
  }
})
export default class QuizStartBlank extends Vue {
  @Prop() quiz!: QuizItem
  @Prop() index!: number
  @Prop() imgItem!: string
  @Prop() quizOnSubmit!: boolean
  @Prop() isSubmit !: boolean
  @Prop() subQuestionItem!: string
  @Prop() commonTextAreaReadOnly !: boolean

  BookerUtility = BookerUtility;

  @Emit('zoomImg')
  zoomImg() {
    return this.imgItem
  }

  mounted() {
    console.log('this.blankQuiz', this.quiz)
  }

  @Emit('blankData')
  blankData(emitData: { textAnswer: QuizOptions[], optionIndex: number, quizIndex: number }) {
    if (this.isSubmit) {
      return;
    }
    console.log('emitData', emitData)
    return emitData
  }

  @Emit('checkSave')
  onInputAnswer(e: Event) {
    const input = (e.target as HTMLInputElement);
    console.log('input', input)
    if (input) {
      const optionIdx = parseInt(input.dataset.index as string);
      this.quiz.options[optionIdx].userAnswer = (e.target as HTMLInputElement).value;
      this.blankData({textAnswer: this.quiz.options, optionIndex: optionIdx, quizIndex: this.index});
    }
  }

  @Emit('checkSave')
  onInputSubAnswer(e: Event) {
    const input = (e.target as HTMLInputElement);

    if (input && typeof input.dataset.index === "string") {
      const subAnswersIndex = parseInt(input.dataset.index as string);
      this.quiz.options[subAnswersIndex].userSubAnswers = [(e.target as HTMLInputElement).value];
      this.setSubAnswer(input.value, subAnswersIndex);
    }


  }

  subQuestionInput(e: Event) {
    this.quiz.subQuestion = (e.target as HTMLInputElement).value;
  }

  explainInput(e: Event) {
    this.quiz.explain = (e.target as HTMLTextAreaElement).value;
  }


  @Emit('setSubAnswer')
  setSubAnswer(text: string, subAnswersIndex: number) {
    return {
      text: text,
      optionIdx: this.index,
      subAnswersIndex: subAnswersIndex
    }
  }
}
