
import {Component, Prop, Vue} from "vue-property-decorator";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";

@Component({name: "ScoreCardReal", components: {}})
export default class ScoreCardReal extends Vue {
  @Prop() model!: AiReportViewModel

  get quizScoreDetail() {
    return this.model.quizScoreDetail.real;
  }

  get totalRealScore() {
    return this.model.quizScoreDetail.real.reduce((total, item) => total + item.score, 0).toFixed(1);
  }
}
