
import {Component, Prop, Vue} from "vue-property-decorator";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

@Component({name: "AchievementEvaluation", components: {}})
export default class AchievementEvaluation extends Vue {
  @Prop() model!: AiReportViewModel
  BookerUtility = BookerUtility;

  get evaluation() {
    return this.model.getEvaluations
  }

  changeLevelToKorean(level: string) {
    return BookerUtility.changeLevelToKorean(level)
  }

  analyzeClass(level?: string) {
    return {
      'analyze-list': true,
      'normal': level === 'middle',
      'soso': level === 'low'
    };
  }

}
