import {ContentItemBase, ContentItemTextToSpeech, TextToSpeechVoiceType} from "@/Booker/lib/BookerTypes";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import GenieApi from "@/assets/lib/api/GenieApi";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import FileApi from "@/assets/lib/api/FileApi";

export default class ContentItemTextToSpeechViewModel {
  textToSpeech: ContentItemTextToSpeech
  classContentMediaListViewModel: ClassContentMediaListViewModel;

  //textToSpeechVoiceType: TextToSpeechVoiceType

  /**
   * 생성 작업시에는 classContentMediaListViewModel 에서 create template 으로 생성후 params로 사용
   * 수정 작업시에는 기존에 있던 params 로 사용
   */
  constructor(textToSpeech: ContentItemTextToSpeech, classContentMediaListViewModel: ClassContentMediaListViewModel) {
    this.textToSpeech = textToSpeech;
    this.classContentMediaListViewModel = classContentMediaListViewModel;
  }

  get actionContent() {
    return this.textToSpeech.actionContent
  }

  get voiceType() {
    return this.textToSpeech.voiceType;
  }

  setMessage(msg: string) {
    this.textToSpeech.message = msg;
    console.log('msg', msg)
  }

  setTitle(title: string) {
    this.textToSpeech.title = title;
  }

  setVoiceType(voiceType: TextToSpeechVoiceType) {
    this.textToSpeech.voiceType = voiceType;
  }

  addActionContent(item: ContentItemBase): ContentItemBase {
    this.textToSpeech.actionContent = [];
    this.textToSpeech.actionContent.splice(0, 0, item);
    return item
  }

  deleteActionContent() {
    this.textToSpeech.actionContent = [];
  }

  /**
   *  저장 및 수정
   *  서버 응답 후 저장이기때문에 내부는 비동기 요청
   */
  saveTextToSpeech() {
    return this.classContentMediaListViewModel.addTextToSpeechLibrary(this.textToSpeech);
  }

  /**
   *  저장가능 상태 전체 확인
   */
  checkState(): boolean {
    if (this.checkText(this.textToSpeech.title)) return false;
    if (this.checkText(this.textToSpeech.message)) return false;

    return true;
  }

  /**
   *  텍스트 글자 2자 미만인경우 false
   */
  checkText(text: string | undefined): boolean {
    if (!text) return false;
    if (text.length < 2) return false;
    return true;
  }

  /**
   * TTS 컨텐츠 아이템 생성
   * @param file
   * @param creatorTextToSpeech
   */
  async createTextToSpeechContentItem(): Promise<ContentItemTextToSpeech | null> {

    const file = await GenieApi.textToSpeech({
      text: this.textToSpeech.message,
      speaker: this.textToSpeech.voiceType
    }).then(res => {
      if (!res) return null
      return new File([res], this.textToSpeech.title);
    })

    if (!file) return null;

    const ret = await FileApi.uploadFile(file)
    const fileId = ret?.file_list[0].file_id
    if (!fileId) return null;

    try {
      const length = await BookerUtility.getAudioLengthInSeconds(file)
      this.textToSpeech.source = fileId
      this.textToSpeech.length = length * 1000;
      this.textToSpeech.file = file
      return this.textToSpeech
    } catch {
      return null
    }

  }


}
