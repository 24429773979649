
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {ContentItemType, DraggingObjectType, TimelineContent} from "@/Booker/lib/BookerTypes";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import ElementDragHandler from "@/assets/lib/dom/ElementDragHandler";
import DraggingItemModel from "@/Booker/lib/model/DraggingItemModel";
import {PT} from "@/assets/lib/dom/DomTypes";

@Component({name: "TextToSpeechTimelineItem", components: {}})
export default class TextToSpeechTimelineItem extends Vue {
  @Prop() model!: ClassContentMediaListViewModel
  @Prop() timelineContent!: TimelineContent;
  @Prop() timelineSize!: number;
  @Prop() ttsTitle!: string;
  @Prop() ttsText!: string
  @Prop() itemIndex!: number
  @Prop() elementDragHandler!: ElementDragHandler

  @Ref() refDragView!: HTMLDivElement;

  ttsStatus = false
  ContentItemType = ContentItemType
  /**
   * 삭제
   */
  deleteStatus = true

  get nowWidth() {
    return (((this.timelineContent.end! - this.timelineContent.start) / 1000) * this.timelineSize).toFixed(2);
  }

  /**
   * PreviewSeekBar 상태 변경
   */
  @Emit('setPreSeekBar')
  setPreSeekBar(isPreSeekBar: boolean) {
    return isPreSeekBar;
  }

  /**
   * timelineContent 시간 변경
   */
  @Emit('timelineContentTimeChange')
  timelineContentTimeChange(contentItemType: ContentItemType, itemIndex: number, endTime: number) {
    return {contentItemType, itemIndex, endTime};
  }

  /**
   * 시나리오 Drag Reposition
   */

  dragTimelineItem(offset: null | PT, pt?: PT) {
    if (!offset) {
      this.repositionMouseUp()
      return;
    }

    if (offset.x === 0 && offset.y === 0) {
      this.repositionMouseDown();
      return;
    }
    this.repositionMouseMove();
  }

  startDrag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.elementDragHandler.mouseDown(event, this.dragTimelineItem)
  }

  @Emit('endRepositionTimeline')
  repositionMouseUp() {
    this.$el.classList.remove('drag');
  }

  repositionMouseDown() {
    DraggingItemModel.setItem(DraggingObjectType.TimelineContent, this.timelineContent);
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');
    //this.deleteStatus = false
  }

  repositionMouseMove() {
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');

  }

  /**
   * ttsItem 클릭시 focus
   * @param e
   */

  ttsAddFocus(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.$el.classList.contains('focus')) {
      this.$el.classList.add('focus');
      //this.ttsStatus = true;

    } else {
      this.$el.classList.remove('focus');
      //this.ttsStatus = false;
    }
  }

  /**
   * return time duration in korean
   */
  getDurationKorean(duration: number): string {
    const durationSec = Math.floor(duration / 1000);
    const durationMin = Math.floor(durationSec / 60);
    const durationHour = Math.floor(durationMin / 60);
    const durationDay = Math.floor(durationHour / 24);

    if (durationDay > 0) {
      return `${durationDay}일 ${durationHour % 24}시간 ${durationMin % 60}분 ${durationSec % 60}초`
    } else if (durationHour > 0) {
      return `${durationHour}시간 ${durationMin % 60}분 ${durationSec % 60}초`
    } else if (durationMin > 0) {
      return `${durationMin}분 ${durationSec % 60}초`
    } else {
      return `${durationSec}초`
    }
  }

  /**
   * timelineContent 삭제
   */
  @Emit('delTimelineItem')
  delTimelineItem() {
    return this.timelineContent;
  }

  mounted() {
    this.refDragView.style.width = this.nowWidth + 'px';
  }
}
