import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import ClassContentModel from "@/Booker/lib/model/ClassContentModel";
import {
  ClassStepEvaluation,
  ClassStepScore,
  ContentItemQuiz,
  QuizItem,
  QuizItemType,
  QuizScoreType,
  StandardEvaluation,
  TotalUserContent,
  UserClassContent
} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

export default class AiReportViewModel {
  model: ClassContentModel
  contentKey = ''
  selectedEvaluationClassStep = 0;
  selectedQuizClassStep = -1;
  nowClassStep = 11;
  userContent: UserClassContent | null = null;
  totalUserContent: TotalUserContent;
  contentList = ClassContentManager.controller.model.userContents
  isLoaded = false;


  constructor() {
    this.model = ClassContentManager.controller.model
    this.totalUserContent = {
      totalScore: 0,
      totalResultText: '',
      standardEvaluations: [],
      classStepEvaluation: new Map<number, ClassStepEvaluation[]>(),
      classStepScoreList: [],
    }
    this.contentList = ClassContentManager.controller.model.userContents
    this.createTotalUserContent();
  }

  get isInitModel() {
    if (this.model.isInit) {
      this.contentList = ClassContentManager.controller.model.userContents
      this.createTotalUserContent();
    }
    return this.model.isInit
  }

  get completeClassList() {
    return this.contentList.filter((content) => content.isComplete);
  }

  get completeClassLength() {
    return this.completeClassList.length;
  }

  // 선택된 차시
  get classStepEvaluation() {
    return this.selectedEvaluationClassStep;
  }

  // 선택된 차시
  get classStepQuiz() {
    return this.selectedQuizClassStep;
  }

  // 나의 점수
  get myScore() {
    return this.totalUserContent.totalScore;
  }

  get myClassStepScore() {
    const data = this.totalUserContent.classStepEvaluation.get(this.nowClassStep);
    if (data) {
      const score = data.reduce((acc, cur) => acc + cur.score, 0)
      return score.toFixed(1)
    }
    return '0';
  }

  // 친구들 평균
  get averageScore() {
    return 50
  }

  // 나의 랭킹 %
  get myRank() {
    return 100 - this.myScore
  }

  get myClassStepRank() {
    return 100 - Number(this.myClassStepScore);
  }

  // 평가 수준
  get myLevel() {
    return this.myScore >= 75 ? 'high' : this.myScore >= 50 ? 'middle' : 'low'
  }

  // 평가 수준
  get myClassStepLevel() {
    return Number(this.myClassStepScore) >= 75 ? 'high' : Number(this.myClassStepScore) >= 50 ? 'middle' : 'low'
  }

  // 수강 현황 목록
  get userContentList() {
    return this.contentList.map((content, _idx) => {
      return {
        classStep: content.classStep,
        isComplete: content.isComplete,
        createdAt: content.createdAt ? content.createdAt : 0,
        updatedAt: content.updatedAt ? content.updatedAt : 0,
      }
    })
  }

  get userCompleteContentList() {
    return this.completeClassList.map((content, _idx) => {
      return {
        classStep: content.classStep,
        isComplete: content.isComplete,
        createdAt: content.createdAt ? content.createdAt : 0,
        updatedAt: content.updatedAt ? content.updatedAt : 0,
      }
    })
  }

  // 득점표
  get quizScoreList() {
    if (this.totalUserContent.classStepScoreList.length > 0)
      return this.totalUserContent.classStepScoreList

    return []
  }

  get nowQuizList() {
    const findContent = this.completeClassList.find((content) => content.classStep === this.classStepQuiz)
    if (!findContent) return []
    const quizListInContent = BookerUtility.getQuizListInContent(findContent)
    return quizListInContent;
  }


  // 득점표 상세
  get quizScoreDetail() {
    return this.scoreDetailByClassStep(this.classStepQuiz);
  }

  get userScores() {
    if (this.evaluationAnalysis) return this.evaluationAnalysis.map((evaluation) => evaluation.score.toFixed(1))
    return [0, 0, 0, 0]
  }

  get averageScores() {
    if (this.evaluationAnalysis) return this.evaluationAnalysis.map((evaluation) => evaluation.averageScore.toFixed(1))
    return [0, 0, 0, 0]
  }

  //총평
  get totalEvaluation() {
    return this.totalUserContent.totalResultText
  }

  //평가 영역별 분석
  get evaluationAnalysis() {


    const evaluationAnalysis = this.totalUserContent.classStepEvaluation.get(this.classStepEvaluation === 0 ? 11 : this.classStepEvaluation);
    if (evaluationAnalysis) return evaluationAnalysis;

    return this.totalUserContent.classStepEvaluation.get(this.classStepEvaluation)
  }

  // 단원 성취기준 평가
  get getEvaluations() {
    if (this.model.activeUserContent && this.model.activeUserContent.standardList) {
      if (this.model.activeUserContent.standardList?.length > 1) return this.totalUserContent.standardEvaluations
      else {
        const list = []
        list.push(this.totalUserContent.standardEvaluations[0])
        return list;
      }
    }
    return this.totalUserContent.standardEvaluations
  }

  scoreDetailByClassStep(classStep: number) {
    const findContent = this.completeClassList.find((content) => content.classStep === classStep)
    if (findContent) {
      const quizListInContent = BookerUtility.getQuizListInContent(findContent)
      const commonQuizList = quizListInContent.filter((quiz) => quiz.quizType === 2)
      const practiceQuizList = quizListInContent.filter((quiz) => quiz.quizType === 1)
      const realQuizList = quizListInContent.filter((quiz) => quiz.quizType === 0)

      const commonQuizItemsList = commonQuizList.map((quiz) => quiz.quizItems).flat(1);
      const practiceQuizItemsList = practiceQuizList.map((quiz) => quiz.quizItems).flat(1);
      const realQuizItemsList = realQuizList.map((quiz) => quiz.quizItems).flat(1);

      return {
        quiz: commonQuizItemsList.map((quiz) => this.exportIsCorrectAndScoreInQuizList(quiz)),
        practice: practiceQuizItemsList.map((quiz) => this.exportIsCorrectAndScoreInQuizList(quiz)),
        real: realQuizItemsList.map((quiz) => this.exportIsCorrectAndScoreInQuizListForReal(quiz)).flat(1),
      }

    }

    return {
      quiz: [{
        quizUuid: '',
        isCorrect: true,
        score: 3
      }],
      practice: [
        {
          quizUuid: '',
          isCorrect: true,
          score: 5
        }
      ],
      real: [
        {
          quizUuid: '',
          isCorrect: true,
          score: 5
        }
      ]
    }
  }

  quizScoreDetailForMyQuiz(quizListInContent: ContentItemQuiz[]) {

    const commonQuizList = quizListInContent.filter((quiz) => quiz.quizType === undefined || quiz.quizType === 2)
    const practiceQuizList = quizListInContent.filter((quiz) => quiz.quizType !== undefined && quiz.quizType === 1)
    const realQuizList = quizListInContent.filter((quiz) => quiz.quizType !== undefined && quiz.quizType === 0)

    const commonQuizItemsList = commonQuizList.map((quiz) => quiz.quizItems).flat(1);
    const practiceQuizItemsList = practiceQuizList.map((quiz) => quiz.quizItems).flat(1);
    const realQuizItemsList = realQuizList.map((quiz) => quiz.quizItems).flat(1);

    return {
      quiz: commonQuizItemsList.map((quiz) => this.exportScoresInQuizList(quiz)),
      practice: practiceQuizItemsList.map((quiz) => this.exportScoresInQuizList(quiz)),
      real: realQuizItemsList.map((quiz) => this.exportScoresInQuizList(quiz))
    }
  }

  contentQuizListByClassStep(classStep: number) {
    const findContent = this.completeClassList.find((content) => content.classStep === classStep)
    if (findContent) {
      return BookerUtility.getQuizListInContent(findContent);
    }
    return []
  }

  inCorrectQuizByUuid(quizUuid: string) {
    const test = this.contentQuizListByClassStep(this.selectedQuizClassStep)
      .map(q => q.quizItems)
      .flat(1)
      .filter(q => {
        if (this.quizScoreDetail.quiz.findIndex(quiz => quiz.quizUuid === quizUuid) > -1) return true;
        if (this.quizScoreDetail.practice.findIndex(quiz => quiz.quizUuid === quizUuid) > -1) return true;
        if (this.quizScoreDetail.real.findIndex(quiz => quiz.quizUuid === quizUuid) > -1) return true;
        return false;
      });
    return test.find(q => q.quizUuid === quizUuid)
  }

  userContentSet(userContent: UserClassContent) {
    this.isLoaded = false;
    this.userContent = userContent;
    this.setStandardEvaluation()
  }

  async setStandardEvaluation() {
    if (this.userContent && this.userContent.standardList) {
      const standard = await this.getEvaluationClassStep(this.userContent.standardList[0].code)
      if (standard) {

        const standardData: StandardEvaluation = {
          key: standard.key ? standard.key : '4국01-06',
          level: standard.level ? standard.level : 'low',
          criteriaItem: standard.criteriaItem,
          evaluationCriteriaTitle: standard.evaluationCriteriaTitle,
          content: standard.content,
        }

        this.totalUserContent.standardEvaluations = [];
        this.totalUserContent.standardEvaluations.splice(0, 0, standardData)

      }

      if (this.userContent.standardList.length > 1) {
        if (this.userContent.standardList[0].code === "") return;
        const standard = await this.getEvaluationClassStep(this.userContent.standardList[1].code)
        if (standard) {
          const standardData: StandardEvaluation = {
            key: standard.key ? standard.key : '4국01-02',
            level: standard.level ? standard.level : 'low',
            criteriaItem: standard.criteriaItem,
            evaluationCriteriaTitle: standard.evaluationCriteriaTitle,
            content: standard.content,
          }
          this.totalUserContent.standardEvaluations.splice(1, 0, standardData)
        }
      }


    }

    setTimeout(() => {
      this.isLoaded = true;
    }, 1000)
  }

  exportIsCorrectAndScoreInQuizList(quizItem: QuizItem) {
    let isCorrect = false;
    let score = 0;
    let correctCount = 0;

    if (quizItem.options.length > 0) {
      if (quizItem.type === QuizItemType.Select || quizItem.type === QuizItemType.OX) {
        score = quizItem.userScore?.reduce((acc, cur) => acc + cur.value, 0) ?? 0;
        const totalScore = quizItem.score.reduce((acc, cur) => acc + cur.value, 0);
        if (score >= totalScore) isCorrect = true;
      } else {
        quizItem.options.forEach(option => {
          if (option.isCorrect) correctCount++;
        })
        isCorrect = correctCount / quizItem.options.length >= 1;
        score = quizItem.userScore?.reduce((acc, cur) => acc + cur.value, 0) ?? 0;
      }
      return {
        quizUuid: quizItem.quizUuid,
        isCorrect,
        score
      }
    }

    if (quizItem.type === QuizItemType.Select || quizItem.type === QuizItemType.OX) {
      score = quizItem.userScore?.reduce((acc, cur) => acc + cur.value, 0) ?? 0;
      const totalScore = quizItem.score.reduce((acc, cur) => acc + cur.value, 0);
      if (score >= (totalScore)) isCorrect = true;

    } else {
      isCorrect = quizItem.options[0].isCorrect;
      score = quizItem.userScore?.reduce((acc, cur) => acc + cur.value, 0) ?? 0 / quizItem.options.length;
    }
    return {
      quizUuid: quizItem.quizUuid,
      isCorrect,
      score
    }
  }

  exportIsCorrectAndScoreInQuizListForReal(quizItem: QuizItem) {

    return quizItem.options.map((quizOption) => {
      let score = 0;
      const isCorrect = quizOption.isCorrect;
      const totalScore = quizItem.score.reduce((acc, cur) => acc + cur.value, 0);
      if (isCorrect) {
        score = totalScore / quizItem.options.length
      }
      return {
        quizUuid: quizItem.quizUuid,
        isCorrect,
        score
      }
    })

  }

  exportScoresInQuizList(quizItem: QuizItem) {
    let isCorrect = false;
    let score = 0;
    let totalScore = 0;

    if (quizItem.options.length > 0) {
      isCorrect = quizItem.options[0].isCorrect;
      score = quizItem.userScore?.reduce((acc, cur) => acc + cur.value, 0) ?? 0;
      totalScore = quizItem.score.reduce((acc, cur) => acc + cur.value, 0) ?? 0;
      return {
        isCorrect,
        score,
        totalScore
      }
    } else {
      console.error('error')
    }

    let correctCount = 0;
    quizItem.options.forEach(option => {
      if (option.isCorrect) correctCount++;
    })

    isCorrect = correctCount / quizItem.options.length >= 1;
    score = quizItem.userScore?.reduce((acc, cur) => acc + cur.value, 0) ?? 0 / quizItem.options.length;
    totalScore = quizItem.score.reduce((acc, cur) => acc + cur.value, 0) ?? 0;

    return {
      isCorrect,
      score,
      totalScore
    }
  }

  creatorClassStepScore(classStep: number, quizItem: QuizItem, classStepEvaluations: ClassStepEvaluation[]) {
    const classStepEvaluation: ClassStepEvaluation = {
      classStep: classStep,
      evaluation: '',
      level: '',
      text: '',
      score: 0,
      averageScore: 0,
    }

    const quizItemEvaluationUnderstanding: ClassStepEvaluation = {
      ...classStepEvaluation
    }
    quizItemEvaluationUnderstanding.evaluation = 'Understanding'

    const quizItemEvaluationExpressiveness: ClassStepEvaluation = {
      ...classStepEvaluation
    }
    quizItemEvaluationExpressiveness.evaluation = 'Expressiveness'

    const quizItemEvaluationAdaptability: ClassStepEvaluation = {
      ...classStepEvaluation
    }
    quizItemEvaluationAdaptability.evaluation = 'Adaptability'

    const quizItemEvaluationProblemSolving: ClassStepEvaluation = {
      ...classStepEvaluation
    }
    quizItemEvaluationProblemSolving.evaluation = 'ProblemSolving'

    if (quizItem.userScore) {
      quizItem.userScore.forEach((userScore) => {
        if (userScore.key === QuizScoreType.Understanding) {
          quizItemEvaluationUnderstanding.score += userScore.value
          quizItemEvaluationUnderstanding.averageScore += 15
        }
        if (userScore.key === QuizScoreType.Expressiveness) {
          quizItemEvaluationExpressiveness.score += userScore.value
          quizItemEvaluationExpressiveness.averageScore += 15
        }
        if (userScore.key === QuizScoreType.Adaptability) {
          quizItemEvaluationAdaptability.score += userScore.value
          quizItemEvaluationAdaptability.averageScore += 13
        }
        if (userScore.key === QuizScoreType.ProblemSolving) {
          quizItemEvaluationProblemSolving.score += userScore.value
          quizItemEvaluationProblemSolving.averageScore += 7
        }
      })
    }

    classStepEvaluations.push(quizItemEvaluationUnderstanding)
    classStepEvaluations.push(quizItemEvaluationExpressiveness)
    classStepEvaluations.push(quizItemEvaluationAdaptability)
    classStepEvaluations.push(quizItemEvaluationProblemSolving)
  }

  async createTotalUserContent() {
    let totalScore = 0;

    const classStepScoreList: ClassStepScore[] = []
    const classStepEvaluations: ClassStepEvaluation[] = []


    for (let i = 0; i < this.completeClassList.length; i++) {

      const userContent = this.completeClassList[i]

      const quizList = BookerUtility.getQuizListInContent(userContent);
      const scoreList = BookerUtility.getScoreInQuizList(quizList)
      const sumTotalScore = scoreList.map(score => score).reduce((a, b) => a + b, 0) / this.completeClassList.length

      totalScore += sumTotalScore

      const ClassStepScore: ClassStepScore = {
        classStep: userContent.classStep!,
        totalQuiz: 0,
        correctQuiz: 0,
      }

      const classStepEvaluation: ClassStepEvaluation = {
        classStep: userContent.classStep!,
        evaluation: '',
        level: '',
        text: '',
        score: 0,
        averageScore: 0,
      }

      const classStepEvaluationUnderstanding: ClassStepEvaluation = {
        ...classStepEvaluation
      }
      classStepEvaluationUnderstanding.evaluation = 'Understanding'

      const classStepEvaluationExpressiveness: ClassStepEvaluation = {
        ...classStepEvaluation
      }
      classStepEvaluationExpressiveness.evaluation = 'Expressiveness'

      const classStepEvaluationAdaptability: ClassStepEvaluation = {
        ...classStepEvaluation
      }
      classStepEvaluationAdaptability.evaluation = 'Adaptability'

      const classStepEvaluationProblemSolving: ClassStepEvaluation = {
        ...classStepEvaluation
      }
      classStepEvaluationProblemSolving.evaluation = 'ProblemSolving'

      if (!this.totalUserContent.classStepEvaluation.has(userContent.classStep!)) {
        this.totalUserContent.classStepEvaluation.set(userContent.classStep!, [
          classStepEvaluationUnderstanding,
          classStepEvaluationExpressiveness,
          classStepEvaluationAdaptability,
          classStepEvaluationProblemSolving,
        ])
      }

      if (!this.totalUserContent.classStepEvaluation.has(0)) {
        const classStepEvaluationUnderstandingToTal = JSON.parse(JSON.stringify(classStepEvaluationUnderstanding));
        const classStepEvaluationExpressivenessToTal = JSON.parse(JSON.stringify(classStepEvaluationExpressiveness));
        const classStepEvaluationAdaptabilityToTal = JSON.parse(JSON.stringify(classStepEvaluationAdaptability));
        const classStepEvaluationProblemSolvingToTal = JSON.parse(JSON.stringify(classStepEvaluationProblemSolving));
        classStepEvaluationUnderstandingToTal.classStep = 11;
        classStepEvaluationExpressivenessToTal.classStep = 11;
        classStepEvaluationAdaptabilityToTal.classStep = 11;
        classStepEvaluationProblemSolvingToTal.classStep = 11;

        this.totalUserContent.classStepEvaluation.set(0, [
          classStepEvaluationUnderstandingToTal,
          classStepEvaluationExpressivenessToTal,
          classStepEvaluationAdaptabilityToTal,
          classStepEvaluationProblemSolvingToTal,
        ])
      }


      quizList.forEach((quiz) => {

        // 실전문제인경우 option을 하나의 quizItem 으로 취급
        if (quiz.quizType === 0) {
          ClassStepScore.totalQuiz += quiz.quizItems[0].options.length

          quiz.quizItems[0].options.forEach((option) => {
            if (option.isCorrect) {
              ClassStepScore.correctQuiz += 1
            }
          })

          this.creatorClassStepScore(userContent.classStep!, quiz.quizItems[0], classStepEvaluations)

        } else {
          ClassStepScore.totalQuiz += quiz.quizItems.length

          quiz.quizItems.forEach((quizItem) => {

            let correctQuizCount = 0;

            if (quizItem.options.length > 1) {

              if (quizItem.type === QuizItemType.Select || quizItem.type === QuizItemType.OX) {
                quizItem.options.forEach((option) => {
                  if (option.isCorrect) {
                    ClassStepScore.correctQuiz += 1
                  }
                })

              } else {
                quizItem.options.forEach((option) => {
                  if (option.isCorrect) {
                    correctQuizCount += 1
                  }
                })

                if (correctQuizCount === quizItem.options.length) {
                  ClassStepScore.correctQuiz += 1
                }
              }


            } else {
              if (quizItem.options[0].isCorrect) {
                ClassStepScore.correctQuiz += 1
              }
            }

            this.creatorClassStepScore(userContent.classStep!, quizItem, classStepEvaluations)


          })

        }

      })

      classStepScoreList.push(ClassStepScore)
    }

    await this.initClassStepEvaluation(classStepEvaluations)

    this.totalUserContent.classStepScoreList = classStepScoreList;
    this.totalUserContent.totalScore = totalScore;

    const totalResult = await this.getEvaluationTotal('3단원')
    if (totalResult) this.totalUserContent.totalResultText = totalResult.content

    const standardEvaluation06 = await this.getEvaluationTotal('4국01-06')
    const standardEvaluation02 = await this.getEvaluationTotal('4국01-02')


    if (standardEvaluation06 && standardEvaluation02) {
      this.totalUserContent.standardEvaluations = [{
        key: standardEvaluation06.key ? standardEvaluation06.key : '4국01-06',
        level: standardEvaluation06.level ? standardEvaluation06.level : 'low',
        criteriaItem: standardEvaluation06.criteriaItem,
        evaluationCriteriaTitle: standardEvaluation06.evaluationCriteriaTitle,
        content: standardEvaluation06.content,
      }, {
        key: standardEvaluation02.key ? standardEvaluation02.key : '4국01-02',
        level: standardEvaluation02.level ? standardEvaluation02.level : 'low',
        criteriaItem: standardEvaluation02.criteriaItem,
        evaluationCriteriaTitle: standardEvaluation02.evaluationCriteriaTitle,
        content: standardEvaluation02.content,
      }]
    }

    this.isLoaded = true;

  }

  async initClassStepEvaluation(classStepEvaluations: ClassStepEvaluation[]) {
    const newClassStepEvaluationMap = new Map<number, ClassStepEvaluation[]>()

    const exportEvaluationScore = (classStep: number, evaluation: string) => {
      return classStepEvaluations
        .filter((classStepEvaluation) => classStepEvaluation.classStep === classStep && classStepEvaluation.evaluation === evaluation)
        .map((classStepEvaluation) => classStepEvaluation.score)
        .reduce((a, b) => a + b, 0);
    }

    const exportEvaluationTotalScore = (evaluation: string) => {
      return classStepEvaluations
        .filter((classStepEvaluation) => classStepEvaluation.evaluation === evaluation)
        .map((classStepEvaluation) => classStepEvaluation.score)
        .reduce((a, b) => a + b, 0) / (this.completeClassLength)
    }


    for (let idx = 0; idx < this.completeClassList.length; idx++) {

      const classStep = this.completeClassList[idx].classStep!
      const classStepEvaluation = this.totalUserContent.classStepEvaluation.get(classStep)

      if (classStepEvaluation) {
        classStepEvaluation[0].score = exportEvaluationScore(classStep, 'Understanding')
        classStepEvaluation[0].averageScore = 15
        classStepEvaluation[0].level = this.changeScoreToLevel(classStepEvaluation[0].score)
        const Understanding
          = await BookerUtility.getClassEvaluation('4국01-00', classStepEvaluation[0].evaluation, this.changeScoreToLevel(classStepEvaluation[0].score))
        if (Understanding) classStepEvaluation[0].text = Understanding.content
        classStepEvaluation[1].score = exportEvaluationScore(classStep, 'Expressiveness')
        classStepEvaluation[1].averageScore = 15
        classStepEvaluation[1].level = this.changeScoreToLevel(classStepEvaluation[1].score)
        const Expressiveness
          = await BookerUtility.getClassEvaluation('4국01-00', classStepEvaluation[1].evaluation, this.changeScoreToLevel(classStepEvaluation[1].score))
        if (Expressiveness) classStepEvaluation[1].text = Expressiveness.content
        classStepEvaluation[2].score = exportEvaluationScore(classStep, 'Adaptability')
        classStepEvaluation[2].averageScore = 13
        classStepEvaluation[2].level = this.changeScoreToLevel(classStepEvaluation[2].score)
        const Adaptability
          = await BookerUtility.getClassEvaluation('4국01-00', classStepEvaluation[2].evaluation, this.changeScoreToLevel(classStepEvaluation[2].score))
        if (Adaptability) classStepEvaluation[2].text = Adaptability.content
        classStepEvaluation[3].score = exportEvaluationScore(classStep, 'ProblemSolving')
        classStepEvaluation[3].averageScore = 7
        classStepEvaluation[3].level = this.changeScoreToLevel(classStepEvaluation[3].score)
        const ProblemSolving
          = await BookerUtility.getClassEvaluation('4국01-00', classStepEvaluation[3].evaluation, this.changeScoreToLevel(classStepEvaluation[3].score))
        if (ProblemSolving) classStepEvaluation[3].text = ProblemSolving.content

        newClassStepEvaluationMap.set(classStep, classStepEvaluation)
      }

    }

    const totalClassStepEvaluation = this.totalUserContent.classStepEvaluation.get(0)

    if (totalClassStepEvaluation) {

      totalClassStepEvaluation[0].score = exportEvaluationTotalScore('Understanding')
      totalClassStepEvaluation[0].averageScore = 15
      totalClassStepEvaluation[0].level = this.changeScoreToLevel(totalClassStepEvaluation[0].score)
      const Understanding
        = await BookerUtility.getClassEvaluation('4국01-00', totalClassStepEvaluation[0].evaluation, this.changeScoreToLevel(totalClassStepEvaluation[0].score))
      if (Understanding) totalClassStepEvaluation[0].text = Understanding.content
      totalClassStepEvaluation[1].score = exportEvaluationTotalScore('Expressiveness')
      totalClassStepEvaluation[1].averageScore = 15
      totalClassStepEvaluation[1].level = this.changeScoreToLevel(totalClassStepEvaluation[1].score)
      const Expressiveness
        = await BookerUtility.getClassEvaluation('4국01-00', totalClassStepEvaluation[1].evaluation, this.changeScoreToLevel(totalClassStepEvaluation[1].score))
      if (Expressiveness) totalClassStepEvaluation[1].text = Expressiveness.content
      totalClassStepEvaluation[2].score = exportEvaluationTotalScore('Adaptability')
      totalClassStepEvaluation[2].averageScore = 13
      totalClassStepEvaluation[2].level = this.changeScoreToLevel(totalClassStepEvaluation[2].score)
      const Adaptability
        = await BookerUtility.getClassEvaluation('4국01-00', totalClassStepEvaluation[2].evaluation, this.changeScoreToLevel(totalClassStepEvaluation[2].score))
      if (Adaptability) totalClassStepEvaluation[2].text = Adaptability.content
      totalClassStepEvaluation[3].score = exportEvaluationTotalScore('ProblemSolving')
      totalClassStepEvaluation[3].averageScore = 7
      totalClassStepEvaluation[3].level = this.changeScoreToLevel(totalClassStepEvaluation[3].score)
      const ProblemSolving
        = await BookerUtility.getClassEvaluation('4국01-00', totalClassStepEvaluation[3].evaluation, this.changeScoreToLevel(totalClassStepEvaluation[3].score))
      if (ProblemSolving) totalClassStepEvaluation[3].text = ProblemSolving.content

      newClassStepEvaluationMap.set(11, totalClassStepEvaluation)

    }

    this.totalUserContent.classStepEvaluation = newClassStepEvaluationMap;
  }

  changeScoreToLevel(score: number) {
    if (score >= 17.5) {
      return 'high'
    } else if (score >= 12.5) {
      return 'middle'
    } else {
      return 'low'
    }
  }

  changeScoreToLevelTotal(score: number) {
    if (score > 75) {
      return 'high'
    } else if (score > 50) {
      return 'middle'
    } else {
      return 'low'
    }
  }

  // 평가 차시 변경
  changeClassStepEvaluation(step: number) {
    this.selectedEvaluationClassStep = step;
  }

  // 퀴즈 차시 변경
  changeClassStepQuiz(step: number) {
    this.selectedQuizClassStep = step;
  }

  async getEvaluationTotal(title: string) {
    return await BookerUtility.getClassEvaluation(title, 'total', this.myLevel)
  }

  async getEvaluationClassStep(title: string) {
    return await BookerUtility.getClassEvaluation(title, 'total', this.myClassStepLevel)
  }


}
