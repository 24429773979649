
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import CommonMainHeader from "@/views/ClassPage/component/CommonMainHeader.vue";
import ReportScore from "@/views/ClassPage/component/ReportScore.vue";
import CourseStatus from "@/views/ClassPage/component/CourseStatus.vue";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import ScoreCard from "@/views/ClassPage/component/ScoreCard.vue";
import AnalysisByEvaluation from "@/views/ClassPage/component/AnalysisByEvaluation.vue";
import AiReportModal from "@/views/ClassPage/component/AiReportModal.vue";
import PinAuth from "@/router/PinAuth.vue";
import AchievementEvaluation from "@/views/ClassPage/component/AchievementEvaluation.vue";

@Component({
  name: "AiReport", components: {
    AchievementEvaluation,
    PinAuth,
    AiReportModal,
    AnalysisByEvaluation,
    ScoreCard, CourseStatus, ReportScore, CommonMainHeader
  }
})
export default class AiReport extends Vue {
  @Prop() contentKey !: string
  @Ref() refAnalyzeHeader !: HTMLDivElement
  model = new AiReportViewModel()
  modalStatus = true

  get totalEvaluation() {
    return this.model.totalEvaluation
  }

  get evaluation() {
    return this.model.getEvaluations
  }

  selectContent(classStep: number) {
    if (classStep === 0) return '전체'
    return `${classStep} 차시`
  }

  receiveResult(modalStatus: boolean) {
    this.modalStatus = modalStatus;
  }

  selectEvaluation(step: number) {
    this.model.changeClassStepEvaluation(step)
    this.scrollToAnalyze();
  }

  scrollToAnalyze() {
    const analyzeHeaderTop = this.refAnalyzeHeader.getBoundingClientRect().y
    console.log('analyzeHeaderTop', analyzeHeaderTop)

    window.scrollTo({
      top: (analyzeHeaderTop + window.pageYOffset) - 10,
      behavior: 'smooth'
    })

  }

}
