

import {Component, Emit, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import VideoShapeOption from "@/Booker/views/components/video/player/shape/VideoShapeOption.vue";
import {ContentItemFigure, ShapeItemType, TimelineContent} from "@/Booker/lib/BookerTypes";
import ContentItemFigureEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemFigureEditorViewModel";

interface ReShapeValue {
  width: number
  deltaX: number
  height: number
  deltaY: number
  isOldY: boolean
  isOldX: boolean
}

@Component({name: "VideoShape", components: {VideoShapeOption}})
export default class VideoShape extends Vue {

  @Prop() isOption!: boolean

  figureViewModel: ContentItemFigureEditorViewModel | null = null
  @Ref('refShape') refShape!: HTMLDivElement;
  @Ref('refShapeText') refShapeText!: HTMLTextAreaElement
  @Ref('refShapeItem') refShapeItem!: HTMLElement

  @Prop() timelineContent !: TimelineContent;
  @Prop({default: 1}) videoScale!: number;
  figure !: ContentItemFigure;
  ShapeItemType = ShapeItemType
  shapeStatus = false
  directionItems = ['tl', 'tr', 'bl', 'br', 'lm', 'rm', 'tm', 'bm']
  /**
   *  텍스트 Resize
   */
  isResizing = false;
  top = 0
  left = 0
  startX = 0
  startY = 0
  startWidth = 0
  startHeight = 0
  resizeDirection = '';
  leastSize = 100;
  /**
   *  텍스트 Rotate
   */
  angle = 0;
  isRotating = false
  mousePressX = 0
  mousePressY = 0
  /**
   * TextArea Color
   */
  currentColor = '#fff'
  /**
   * TextArea Size
   */
  currentSize = 20
  /**
   *  Shape Reposition
   */
  isDragging = false
  currentX = 0
  currentY = 0
  oldCurrentX = 0
  oldCurrentY = 0
  initialX = 0
  initialY = 0

  get boundStyle() {
    return this.figureViewModel!.boundStyle
  }

  get fontStyle() {
    return this.figureViewModel!.fontStyle
  }

  init() {
    this.figureViewModel = new ContentItemFigureEditorViewModel(this.timelineContent.content as ContentItemFigure)
  }

  created() {
    this.figure = (this.timelineContent.content as ContentItemFigure)
  }

  mounted() {
    switch (this.figure.figureType) {
      case ShapeItemType.Circle:
      case ShapeItemType.Square:
      case ShapeItemType.Polygon:
      case ShapeItemType.Star:
        this.shapeStatus = true;
        break;
      default:
        this.shapeStatus = false;
        break;
    }

    if (!this.figureViewModel) this.init()
  }

  @Watch('shapeType')
  onShapeTypeChange(newVal: ShapeItemType, oldVal: ShapeItemType) {
    switch (newVal) {
      case ShapeItemType.Circle:
      case ShapeItemType.Square:
      case ShapeItemType.Polygon:
      case ShapeItemType.Star:
        this.shapeStatus = true;
        break;
      default:
        this.shapeStatus = false;
    }
  }

  /**
   * TextArea Color
   */

  textColorChange(color: string) {
    this.figureViewModel?.changeColor(color)
    const newTextColor = this.figureViewModel?.getTextColor()
    console.log('newTextColor', newTextColor)
    if (typeof newTextColor === 'string') {
      this.currentColor = newTextColor
      console.log('this.currentColor', this.currentColor)
    }
    return newTextColor
  }

  changeShapeColor(color: string) {
    this.figureViewModel?.changeBackgroundColor(color)
  }

  /**
   * Textarea Font Size Change
   */
  changeShapeTextSize(size: number) {
    this.figureViewModel?.changeSize(size)
    const newSize = this.figureViewModel?.getTextSize();
    if (typeof newSize === 'number') {
      this.currentSize = newSize;
    }
    return newSize
  }

  /**
   * 도형 삭제
   */
  @Emit('removeShape')
  removeShape(result: boolean) {
    if (result) return this.timelineContent.key;
    else return '';
  }

  /**
   * 도형 리사이즈
   */

  changeBound() {
    this.figureViewModel?.changeBound({
      currentX: this.currentX,
      currentY: this.currentY,
      angle: this.angle,
      width: this.refShape.style.width,
      height: this.refShape.style.height
    })
  }


  minWidth = 50;
  minHeight = 50;
  initX = 0;
  initY = 0;
  initW = 0;
  initH = 0;
  initTop = 0;
  initLeft = 0;
  initRotate = 0;

  // 회전 각도
  getCurrentRotation(element: HTMLElement): number {
    const style = window.getComputedStyle(element);
    const matrix = style.getPropertyValue('transform');
    const values = matrix.split('(')[1].split(')')[0].split(',');
    const a = Number(values[0]);
    const b = Number(values[1]);
    const radians = Math.atan2(b, a);
    const degrees = radians * (180 / Math.PI);
    return degrees;
  }

  resize(w: number, h: number) {
    if (this.refShape) {
      if(w !== this.refShape.clientWidth || h !== this.refShape.clientHeight) {
        this.refShape.style.height = `${h}px`;
        this.refShape.style.width = `${w}px`;

      }
    }
  }


  /**
   * 도형 리사이즈
   * @param event
   * @param left
   * @param top
   * @param xResize
   * @param yResize
   */
  resizeHandler(event: MouseEvent, left: boolean, top: boolean, xResize: boolean, yResize: boolean) {
    //event.stopPropagation();
    this.isRotating = false;
    this.isDragging = false;
    this.isResizing = true;

    if(this.isDragging || this.isRotating) return;

    this.mousePressX = event.clientX;
    this.mousePressY = event.clientY;
    this.initW = Number(this.boundStyle.width.replace('px', ''));
    this.initH = Number(this.boundStyle.height.replace('px', ''));
    this.initX = Number(this.boundStyle.left.replace('px', ''));
    this.initY = Number(this.boundStyle.top.replace('px', ''));


    // 삼각비 계산
    this.initRotate = this.getCurrentRotation(this.refShape);
    let initRadians = this.initRotate * Math.PI / 180;
    let cosFraction = Math.cos(initRadians);
    let sinFraction = Math.sin(initRadians);


    let ptX = this.initX;
    let ptY = this.initY;
    if(left) ptX = this.initX + this.initW
    if(top) ptY = this.initY + this.initH
    let prevXY = this.xyOfLeftTopPoint(ptX, ptY, this.initW, this.initH, initRadians)

    // 크기 조정 코드
    const resizeMouseMove = (event: MouseEvent)=> {
      event.preventDefault();

      const wDiff = (event.clientX - this.mousePressX) / this.videoScale;
      const hDiff = (event.clientY - this.mousePressY) / this.videoScale;

      let rotatedWDiff = cosFraction * wDiff + sinFraction * hDiff;
      let rotatedHDiff = cosFraction * hDiff - sinFraction * wDiff;

      let newW = this.initW;
      let newH = this.initH;
      let newX = this.initX;
      let newY = this.initY;

      this.isRotating = false;
      this.isDragging = false;
      this.isResizing = true;

      if(this.isDragging || this.isRotating) return;

      if(this.isResizing) {
        if (xResize) {
          if (left) {
            newW = this.initW - rotatedWDiff;
            if (newW < this.minWidth) {
              rotatedWDiff = this.initW - this.minWidth;
              newW = this.minWidth;
            }
            newX += rotatedWDiff * cosFraction;
          } else {
            newW = this.initW + rotatedWDiff;
            if (newW < this.minWidth) {
              rotatedWDiff = this.minWidth - this.initW;
              newW = this.minWidth;
            }
          }
        }

        if (yResize) {
          if (top) {
            newH = this.initH - rotatedHDiff;
            if (newH < this.minHeight) {
              newH = this.minHeight;
              rotatedHDiff = this.initH - this.minHeight;
            }
            newY += rotatedHDiff * cosFraction;
          } else {
            newH = this.initH + rotatedHDiff;
            if (newH < this.minHeight) {
              newH = this.minHeight;
              rotatedHDiff = this.minHeight - this.initH ;
            }
          }
        }
      }

      let ptX = this.initX;
      let ptY = this.initY;
      if(left) ptX = this.initX + newW
      if(top) ptY = this.initY + newH
      const newXY = this.xyOfLeftTopPoint(ptX, ptY, newW, newH, initRadians)

      this.currentX = this.initX - (newXY.x - prevXY.x)
      this.currentY = this.initY - (newXY.y - prevXY.y)


      this.resize(newW, newH);
      this.changeBound();
    }

    // 리사이즈 마우스 업
    const resizeMouseUp = () => {
      window.removeEventListener('mousemove', resizeMouseMove);
      window.removeEventListener('mouseup', resizeMouseUp)
      this.isResizing = false;
    }

    window.addEventListener('mousemove', resizeMouseMove, false);
    window.addEventListener('mouseup', resizeMouseUp, false)
  }


 // 중심점
  xyOfLeftTopPoint(initX: number, initY: number, initW: number, initH: number, rad: number) {
    // 회전 없는경우 원래위치
    if(rad === 0) return {x: initX, y: initY}

    let x = initX
    let y = initY

    // 회전한 상태의 좌상단 점의 위치
    const xc = (initW) / 2
    const yc = (initH) / 2

    x -= xc
    y -= yc

    const xr = (x * Math.cos(rad)) - (y * Math.sin(rad)) + xc
    const yr = (x * Math.sin(rad)) + (y * Math.cos(rad)) + yc

    x = xr
    y = yr

    return {x: x, y: y}
  }

  /**
   * 도형 회전
   */
  rotateMouseDown(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isRotating = true;
    this.isDragging = false;
    this.isResizing = false;

    this.mousePressX = event.clientX;
    this.mousePressY = event.clientY;

    const realPosition = this.refShape.getBoundingClientRect();
    this.initX = realPosition.left + realPosition.width / 2;
    this.initY = realPosition.top + realPosition.height / 2;

    document.addEventListener('mousemove', this.rotateMouseMove);
    document.addEventListener('mouseup', this.rotateMouseUp);
  }

  rotateMouseMove(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    const realPosition = this.refShape.getBoundingClientRect();

    this.isRotating = true;
    this.isDragging = false;
    this.isResizing = false;

    if (this.isRotating) {
      const arrowX = realPosition.left + realPosition.width / 2;
      const arrowY = realPosition.top + realPosition.height / 2;
      const angle = Math.atan2(event.clientY - arrowY, event.clientX - arrowX);
      this.angle = angle / (Math.PI * 2) * 360 + 90
      this.changeBound()
    } else {
      return;
    }
  }

  rotateMouseUp() {
    this.isRotating = false;
    this.isDragging = false;
    document.removeEventListener('mousemove', this.rotateMouseMove);
    document.removeEventListener('mouseup', this.rotateMouseUp);
  }

  /**
   * 도형 Reposition
   */
  dragStart(event: MouseEvent) {
    if (!this.isOption || this.isResizing) return;
    this.isDragging = true;

    // const realPosition = this.refShape.getBoundingClientRect();
    this.initX = Number(this.refShape.style.left.replace('px', ''))
    this.initY = Number(this.refShape.style.top.replace('px', ''))
    this.initialX = event.clientX;
    this.initialY = event.clientY;

    this.highZIndex();
    window.addEventListener('mousemove', this.drag)
    window.addEventListener('mouseup', this.dragEnd)
  }

  drag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.isResizing || this.isRotating) return;

    const curX = event.clientX;
    const curY = event.clientY;

    if (this.isDragging) {
      this.currentX = this.initX + (curX - this.initialX) / this.videoScale;
      this.currentY = this.initY + (curY - this.initialY) / this.videoScale;
      this.changeBound()
    }
  }

  dragEnd(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;

    window.removeEventListener('mousemove', this.drag)
    window.removeEventListener('mouseup', this.dragEnd)
  }

  /**
   * 클릭시 맨 앞으로
   */
  highZIndex() {
    let maxIndex = parseInt(window.getComputedStyle(this.$el).zIndex);

    this.$parent?.$children.forEach((component) => {
      let index = parseInt(window.getComputedStyle(component.$el).zIndex);
      if (!isNaN(index) && index > maxIndex) {
        maxIndex = index;
      }
      (this.$el as HTMLElement).style.zIndex = `${maxIndex + 1}`;
    })
  }

  /**
   * 텍스트 입력
   * @param e
   */
  onInputText(e: Event) {
    this.figureViewModel?.setText((e.target as HTMLInputElement).value)
  }

}
