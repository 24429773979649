
import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator';
import YouTubePlayer from "@/views/YouTubePlayer.vue";
import {VideoContentSourceType} from "@/Booker/lib/BookerTypes";
import VideoPlayer from "@/Booker/views/components/video/player/VideoPlayer.vue";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";

@Component({
  name: 'VideoTimelineContentPlayer',
  components: {YouTubePlayer, VideoPlayer}
})
export default class VideoTimelineContentPlayer extends Vue {
  @Prop() source!: string // video source
  @Prop() isPlaying!: boolean // play or pause
  @Prop() isPreview?: boolean // is preview video
  @Prop() currentTime!: number // in ms
  @Prop() startAt?: number // in ms
  @Prop() endAt?: number // in ms
  @Prop() sourceType!: VideoContentSourceType // in ms
  @Prop() model!: ClassContentPlayerViewModel;

  @Ref() refYoutube!: YouTubePlayer

  VideoContentSourceType = VideoContentSourceType

  get youtubeId() {
    return this.source.split('/').pop()
  }

  created() {
    console.log('source', this.source)
    console.log('sourceType', this.sourceType)
  }

  play() {
    (this.$refs.video as HTMLVideoElement).play();
  }

  @Emit('onPlay')
  onPlay() {
    console.log('onPlay')
    // Update the playback status when the video starts playing
    return null
  }

  @Emit('update:isPlaying')
  onStop() {
    // Update the playback status when the video starts playing
    this.$emit('update:isNotPlaying', true);
  }

  @Emit('onPause')
  onPause() {
    console.log('onPause')
    return null;
  }


}
