
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import CommonBookerModal from "@/Booker/views/components/common/CommonBookerModal.vue";
import Card from "@/components/ui/Card.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import {ClassContent} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import FileApi from "@/assets/lib/api/FileApi";
import ClassContentListViewModel from "@/Booker/lib/viewModel/ClassContentListViewModel";

@Component({
  name: "BookMarkEditClassModal",
  computed: {
    BookerUtility() {
      return BookerUtility
    }
  },
  components: {CommonInput, Card, CommonBookerModal}
})
export default class BookMarkEditClassModal extends Vue {
  @Prop() newContent!: ClassContent
  @Prop() modalTitle!: string
  @Prop() model!: ClassContentListViewModel

  choiceSubmit = false
  standardList = false
  choiceStep = false
  submitList = false
  stepList = false
  selectClass = 1

  exClassStandard = ['4국01-02', '4국01-06']
  selectedStandardIndex: number | null = null


  get classStepContentList() {
    return `${this.newContent.classStep} 차시`
  }

  get classStandard() {
    return this.newContent.standardList
  }

  get thumbnailUrl() {
    console.log('this.newContent.thumbnailUrl', this.newContent.thumbnailUrl)
    return this.newContent.thumbnailUrl
  }


  @Emit('closeModal')
  closeModal() {
    return
  }

  @Emit('updateClass')
  async updateClass() {
    await this.model.updateContentInModal(this.newContent)
    this.closeModal();
  }


  titleSave(e: Event) {
    if (this.newContent) {
      this.newContent.title = (e.target as HTMLInputElement).value;
    }
  }

  async onFile(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target && target.files && target.files.length > 0) {

      const fileApiRes = await FileApi.uploadFile(target.files[0])
      const fileId = fileApiRes?.file_list[0].file_id
      if (fileId && this.newContent)
        this.newContent.thumbnailUrl = fileId;
      this.newContent = JSON.parse(JSON.stringify({
        ...this.newContent,
        thumbnailUrl: fileId
      })) as ClassContent;

      target.files = null;
    }
  }

  addFocusSelectSubmit() {
    this.submitList = !this.submitList
    this.choiceSubmit = true
  }

  addFocusSelectStep() {
    if (this.choiceSubmit) {
      this.stepList = !this.stepList
    }
    this.choiceStep = true
  }

  addFocusSelectStandard(index: number) {
    this.selectedStandardIndex = index
    if (this.choiceStep && this.selectedStandardIndex === index) {
      this.standardList = !this.standardList
    } else {
      return;
    }

  }

  choiceStandard(standardIdx: number, exClassStandardIdx: number) {
    if (!this.newContent.standardList) {
      this.newContent.standardList = [{
        text: this.exClassStandard[exClassStandardIdx],
        code: this.exClassStandard[exClassStandardIdx]
      }];
      return;
    }
    this.newContent.standardList[standardIdx] = {
      text: this.exClassStandard[exClassStandardIdx],
      code: this.exClassStandard[exClassStandardIdx]
    };
  }

  choiceClassStep(index: number) {
    console.log('index', index)
    return this.newContent.classStep = index;
  }

  choiceClass(className: string) {
    this.newContent.class = className
  }

  addStandard() {
    this.classStandard?.push({
      text: '',
      code: ''
    })
  }
}
