
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import ShapeColorPicker from "@/Booker/views/components/video/player/shape/ShapeColorPicker.vue"
import ContentItemFigureEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemFigureEditorViewModel";

@Component({name: "VideoShapeOption", components: {ShapeColorPicker}})
export default class VideoShapeOption extends Vue {

  @Prop()figureModel!: ContentItemFigureEditorViewModel

  // Font Size
  textSize = [
    20, 40, 60, 80, 100, 150
  ]
  currentSize = 20
  // Font Color
  selectedBgColor = '#FA5C3D'
  selectedTextColor = '#fff'

  /**
   * Textarea Font Size
   */
  @Emit('changeSize')
  changeSize(index: number) {
    this.currentSize = this.textSize[index]
    return this.currentSize;
  }

  /**
   * Textarea Delete
   */
  @Emit('removeShape')
  removeShape() {
    if (!confirm('삭제하시겠습니까?')) {
      return false
    } else {
      return true;
    }
  }

  /**
   * Shape Background Color Change
   */
  @Emit('bgColorChange')
  bgColorChange(color: string) {
    return this.selectedBgColor = color
  }

  /**
   * Shape Text Color Change
   */
  @Emit('textColorChange')
  textColorChange(color: string) {
    return this.selectedTextColor = color
  }
}
