
import {Component, Prop, Vue} from "vue-property-decorator";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";
import CommonPassageExplain from "@/Booker/views/components/common/CommonPassageExplain.vue";

@Component({name: "IncorrectQuestion", components: {CommonPassageExplain, CommonTextArea}})
export default class IncorrectQuestion extends Vue {
  @Prop() aiModel !: AiReportViewModel
  @Prop() quiz !: {
    quizUuid: string;
    isCorrect: boolean;
    score: number;
  }
  @Prop() quizIndex !: number
  @Prop() quizType !: string

  get quizItem() {
    return this.aiModel.inCorrectQuizByUuid(this.quiz.quizUuid)
  }

  get classStep() {
    return this.aiModel.selectedQuizClassStep;
  }

  mounted() {
    // console.log('this.quizItem', this.quizItem)
    // console.log('this.aiModel.inCorrectQuizByUuid(this.quiz.quizUuid)', this.aiModel.inCorrectQuizByUuid(this.quiz.quizUuid))
  }

  explainInput() {
    return;
  }

}
