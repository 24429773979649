import { render, staticRenderFns } from "./CardView.vue?vue&type=template&id=5b54a2d3&scoped=true&"
import script from "./CardView.vue?vue&type=script&lang=ts&"
export * from "./CardView.vue?vue&type=script&lang=ts&"
import style0 from "./CardView.vue?vue&type=style&index=0&id=5b54a2d3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b54a2d3",
  null
  
)

export default component.exports