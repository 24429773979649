export default class VirtualBackgroundStream {
  private canvas: HTMLCanvasElement | null = null;
  private canvasCtx: CanvasRenderingContext2D | null = null;
  private canvasStream: MediaStream

  // @ts-ignore
  private selfieSegmentation: SelfieSegmentation

  private _backgroundImagePath = '';
  private _backgroundImageName = '';
  private backgroundImage: null | HTMLImageElement = null
  private onRenderResult?: (result: boolean) => void

  constructor() {
    // release previous canvas stream
    // this.canvasStream?.getTracks().forEach(t => t.stop());

    this.canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    this.canvas.setAttribute('width', 100 + '');
    this.canvas.setAttribute('height', 100 + '');
    this.canvas.style.backgroundColor = 'black';
    this.canvasCtx = this.canvas.getContext('2d');

    // @ts-ignore
    this.canvasStream = this.canvas.captureStream();

    /**
     * dynamic import selfieSegmentation
     */

    if(!this.selfieSegmentation){
      const script = document.createElement('script');
      script.src = '/custom_assets/virtual_bg/selfie_segmentation/selfie_segmentation.js'
      document.querySelector('head')?.appendChild(script);

      script.onload = () =>{
        this.createSelfieSegmentation();
      }
    }
  }

  /**
   * get the canvas stream
   */
  get stream() {
    return this.canvasStream;
  }

  /**
   * send video frame to selfieSegmentation
   * @private
   */
  async sendVideoFrame(video: HTMLVideoElement, backgroundImagePath: string, backgroundImageName: string, backgroundImage: null | HTMLImageElement, onRenderResult: (result: boolean) => void) {
    this._backgroundImagePath = backgroundImagePath;
    this._backgroundImageName = backgroundImageName;
    this.backgroundImage = backgroundImage;
    this.onRenderResult = onRenderResult;

    if(video.videoWidth <= 0 || video.videoHeight <= 0) {
      this.onRenderResult?.(false)
      return
    }

    await this.selfieSegmentation.send({image: video});
  }

  /**
   * recreate selfieSegmentation
   * @private
   */
  recreateSelfieSegmentation() {
    this.createSelfieSegmentation();
  }

  private createSelfieSegmentation() {

      // @ts-ignore
      this.selfieSegmentation = new SelfieSegmentation({locateFile: (file: any) => {
          return `/custom_assets/virtual_bg/selfie_segmentation/${file}`;
        }});
      this.selfieSegmentation.setOptions({
        modelSelection: 1, //An integer index 0 or 1. Use 0 to select the general model, and 1 to select the landscape model
      });

      this.selfieSegmentation.onResults((results: any) => {
        // console.log('result received')

        // request next render
        this.onRenderResult?.(true)

        if(! this.canvasCtx) {
          console.log('no canvasCtx')
          return
        }
        if(! this.canvas) {
          console.log('no canvas')
          return
        }

        // if(! this.backgroundImage && this._backgroundImagePath !== 'blur') {
        //   console.log('no backgroundImage')
        //   return
        // }

        if(this._backgroundImageName === 'blur') {
          this.canvasCtx.save();
          // Draw the raw frame
          this.canvasCtx.drawImage(results.image, 0, 0, this.canvas.width, this.canvas.height); //exsiting canvas

          // Make all pixels not in the segmentation mask transparent
          this.canvasCtx.globalCompositeOperation = 'destination-atop';
          this.canvasCtx.drawImage(results.segmentationMask, 0, 0, this.canvas.width, this.canvas.height); //new shape

          // Blur the context for all subsequent draws then set the raw image as the background
          this.canvasCtx.filter = 'blur(8px)'; // FIXME Does not work on Safari
          this.canvasCtx.globalCompositeOperation = 'destination-over'; //New shapes are drawn behind the existing canvas content
          this.canvasCtx.drawImage(results.image, 0, 0, this.canvas.width, this.canvas.height);

          this.canvasCtx.restore();
        } else {
          this.canvasCtx.save();
          this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          // Only overwrite existing pixels.
          if(this.backgroundImage) {
            this.canvasCtx.drawImage(results.segmentationMask, 0, 0, this.canvas.width, this.canvas.height);
            this.canvasCtx.globalCompositeOperation = 'source-out';
            this.canvasCtx.drawImage(this.backgroundImage, 0, 0, this.canvas.width, this.canvas.height);
          }
          // Only overwrite missing pixels.
          this.canvasCtx.globalCompositeOperation = 'destination-atop';
          //참고:https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation

          this.canvasCtx.drawImage(results.image, 0, 0, this.canvas.width, this.canvas.height);

          this.canvasCtx.restore();
        }

        // console.log('result received', results.image)
      });

  }



  /**
   * update canvas size
   */
  updateCanvasSize(width: number, height: number) {
    if(! this.canvas) return
    this.canvas.setAttribute('width', width + '');
    this.canvas.setAttribute('height', height + '');
  }
}
