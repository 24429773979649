var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.ContentItemType.Text && _vm.subtitleStatus && _vm.textModel)?_c('div',{ref:"refCreateSubtitle",staticClass:"CreateSubtitle",class:{'focus' : _vm.optionStatus},style:({
    top: `${_vm.textModel.textItem.bound.top}`,
    left: `${_vm.textModel.textItem.bound.left}`,
    bottom: `${_vm.textModel.textItem.bound.bottom}`,
    transform: `${_vm.textModel.textItem.bound.transform}`,
    width: `${_vm.textModel.textItem.bound.width}`,
    height: `${_vm.textModel.textItem.bound.height}`,
    right: `${_vm.textModel.textItem.bound.right}`,
  }),on:{"click":_vm.clickSubtitleOption}},[_c('div',{staticClass:"subtitle-container"},[_c('textarea',{ref:"refSubtitle",staticClass:"subtitle-text",style:({
          fontSize: `${_vm.textModel?.textItem.size}px`,
          lineHeight: `${_vm.textModel?.textItem.size + 20}px`,
          color: `${_vm.textModel?.textItem.color}`,
          fontFamily: `${_vm.textModel?.textItem.font}`,
          backgroundColor : `${_vm.textModel?.textItem.background}`
        }),attrs:{"cols":"2","maxlength":"250","placeholder":"여기에 자막을 입력해주세요.","rows":"1","wrap":"on"},domProps:{"value":_vm.textModel.textItem.text},on:{"input":_vm.onInputText,"click":function($event){$event.preventDefault();return _vm.clickSubtitle.apply(null, arguments)}}}),(_vm.optionStatus)?_c('SubtitleOption',{ref:"refSubtitleOption",attrs:{"textModel":_vm.textModel},on:{"changeSize":_vm.changeSize,"onChecked":_vm.changeSubtitleBg,"removeSubtitle":_vm.removeSubtitle}}):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }