
import {Component, Vue} from 'vue-property-decorator'
import '@/assets/style/app.scss'
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import CommonLoading from "@/components/ui/loading/CommonLoading.vue";

@Component({
  name: 'App',
  components: {CommonLoading}
})
export default class App extends Vue {
  //
  ClassContentManager = ClassContentManager
}
