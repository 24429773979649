
import {Component, Prop, Vue} from "vue-property-decorator";
import {QuizItem} from "@/Booker/lib/BookerTypes";

@Component({name: "CommonSubQuestion", components: {}})
export default class CommonPassageExplain extends Vue {
  @Prop() inputClass !: string
  @Prop() placeholder!: string
  @Prop() readonly !: boolean
  @Prop() inputValue!: any
  @Prop() updateInput!: any
  @Prop() focusOut!: any
  @Prop() quiz!: QuizItem
}
