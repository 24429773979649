
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {
  ClassContent,
  ContentItemBase,
  ContentItemImage,
  ContentItemType,
  ContentItemVideo,
  VideoContentSourceType
} from "@/Booker/lib/BookerTypes";
import ClassContentListViewModel from "@/Booker/lib/viewModel/ClassContentListViewModel";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

@Component({ name: "BookMarkMainPageThumbnail",  components: {}})
export default class BookMarkMainPageThumbnail extends Vue {
  @Prop() thumbnailModalStatus!: boolean
  @Prop() contentListViewModel!: ClassContentListViewModel
  @Prop() content!: ClassContent
  ContentItemType = ContentItemType
  VideoContentSourceType = VideoContentSourceType
  emptyModal = true

  get thumbnailList() {
    console.log('this.content', this.content)
    return this.contentListViewModel.contentLibraryList(this.content);
  }

  mounted() {
    // 이미지 없을 경우 empty-thumbnail 출력
    if(this.thumbnailList.length >= 1) {
      this.emptyModal = !this.emptyModal
    }
  }

  /**
   * 썸네일 모달 닫기
   */
  @Emit('closeThumbnailModal')
  closeThumbnailModal() {
    return this.thumbnailModalStatus;
  }


  /**
   * 썸네일 전송
   */
  btnDisabled = true
  @Emit('sendImgSrc')
  selectThumbnail(event: MouseEvent, key: string) {
    //@ts-ignore
    this.contentListViewModel.updateThumbnailKey(this.content, key)
    const thumbnailList = document.querySelectorAll('.thumbnail-item');

    thumbnailList.forEach((list)=> {
      list.classList.remove('focus')
    })

    const clickedThumbnailItem = event.currentTarget as HTMLLIElement;
    clickedThumbnailItem.classList.add('focus');
    this.btnDisabled = false

  }

  saveImgSrc() {
    this.closeThumbnailModal()
  }


  getContentItemThumbnailUrl(content: ContentItemBase): null | string {
    switch (content.type) {
      case ContentItemType.Image:
        return (content as ContentItemImage).source
      case ContentItemType.Video:
        if ((content as ContentItemVideo).sourceType === VideoContentSourceType.Youtube) {
          return BookerUtility.getYoutubeThumbnailByUrl((content as ContentItemVideo).source);
        }
        return (content as ContentItemVideo).source
      default:
        return null
    }
  }


}
