
import {Component, Prop, Vue} from "vue-property-decorator";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import BookerUtility from "../../../Booker/lib/util/BookerUtility";

@Component({
  name: "CourseStatus",
  components: {}
})
export default class CourseStatus extends Vue {

  @Prop() model!: AiReportViewModel
  @Prop() modalClass!: string
  @Prop() nowClassStep!: number
  BookerUtility = BookerUtility;
  classSteps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  get courseList() {
    return this.model.userContentList
  }

  get releaseClassList() {
    return this.model.model.releaseClassList.sort((a, b) => a.classStep - b.classStep)
  }

  getReleaseClass(classStep: number) {
    return this.model.model.releaseClassList.find(c => c.classStep === classStep);
  }


  isCompleteCourse(clasStep?: number) {
    return this.courseList.findIndex(c => c.classStep === clasStep && c.isComplete) > -1
  }

  getCourseInfo(clasStep: number, type: string) {
    const course = this.courseList.find(c => c.classStep === clasStep);
    if (!course) return '';
    let timeStamp = course.createdAt
    if (type !== 'create') timeStamp = course.updatedAt
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };
    return new Date(timeStamp * 1000).toLocaleString(undefined, options); // createdAt을 날짜와 시간 형식으로 변환
  }

  isNowCompleteCourse(classStep?: number) {
    if (!this.nowClassStep) return false;
    return classStep === this.nowClassStep;
  }


}
