
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({name: "CommonButton", components: {}})
export default class CommonButton extends Vue {

  @Prop() btnText!: string;
  @Prop() clickEvent!: any;
  @Prop() isDisable!: boolean;


}
