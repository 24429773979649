import Logger from "../../../assets/lib/debug/Logger"
import {
  ClassContent,
  ContentItemBook,
  ContentItemImage,
  ContentItemQuiz,
  ContentItemScenario,
  ContentItemScenarioType,
  ContentItemType,
  TimelineContent,
  TimelineType
} from "@/Booker/lib/BookerTypes";
import {PlayingState, SeekbarStatus} from "@/Booker/lib/controller/ContentSeekbarController";
import {ChatBotPostMsg, ChatBotPostMsgType, CustomMsgUi, EventType} from "@/assets/lib/type/Types";
import Chatbot from "@/assets/lib/utility/Chatbot";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import Config from "@/config";

interface ScenarioType {
  timeline: TimelineContent,
  isSend: boolean,
}

interface CardCreator {
  text: string,
  eventType: EventType,
  scenarioType: ScenarioType,
  img?: string,
  isVoice: boolean
}

export default class ScenarioPlayController {

  private updateStatus: null | ((seekbarStatus: SeekbarStatus) => void) = null
  private readonly content: ClassContent
  private readonly logger = new Logger('[ScenarioPlayController]')
  private readonly interactionMap: Map<string, ScenarioType> = new Map();

  constructor(content: ClassContent) {
    this.content = content
    this.init();
  }

  setUpdateStatus(updateStatus: null | ((seekbarStatus: SeekbarStatus) => void)) {
    this.updateStatus = updateStatus
  }

  init() {
    const scenarioList = this.content.timeline.find(tl => tl.type === TimelineType.Scenario)?.contents || [];
    scenarioList.sort((a, b) => a.start > b.start ? 1 : -1)
    scenarioList.forEach((tl) => {
      this.interactionMap.set(tl.key, {timeline: tl, isSend: false});
    })
  }

  updatePlayingState(state: PlayingState) {
    this.interactionMap.forEach((inter) => {
      if (inter.isSend) return;
      if (inter.timeline.start > state.currentTime) return;
      const content = inter.timeline.content as ContentItemScenario

      switch (content.scenarioType) {
        case ContentItemScenarioType.OpenContentItem:
          if (content.actionContent && content.actionContent?.length > 0) {
            if (content.actionContent[0].type === ContentItemType.Book) this.sendBookCard(inter)
            if (content.actionContent[0].type === ContentItemType.Quiz) this.sendQuizCard(inter)
            if (content.actionContent[0].type === ContentItemType.Pronunciation) this.sendPronunciationCard(inter)
          } else {
            this.sendCommonMsg(inter)
          }
          break;
        case ContentItemScenarioType.GenieInside:
        case ContentItemScenarioType.WordDictionary:
          this.sendCommonMsg(inter)
          if (content.keyword) {
            this.sendKeyword(inter)
          }
          break;
      }
      if (this.updateStatus) this.updateStatus('paused');

    })
  }

  sendKeyword(scenarioType: ScenarioType) {
    const scenario = (scenarioType.timeline.content as ContentItemScenario)
    const keyword = (scenarioType.timeline.content as ContentItemScenario).keyword
    if (!keyword) return;
    const customMsg = Chatbot.defaultMsgTemplate(false)
    const chatBotPostMsg = this.chatBotPostMsgCreator(true)
    customMsg.text = keyword
    if (scenario.scenarioType === ContentItemScenarioType.GenieInside) chatBotPostMsg.data = 'GenieInside'
    if (scenario.scenarioType === ContentItemScenarioType.WordDictionary) chatBotPostMsg.data = 'WordDictionary'
    chatBotPostMsg.customMsg = customMsg
    chatBotPostMsg.chatBotPostMsgType = ChatBotPostMsgType.KeyWord
    this.sendChatBot(chatBotPostMsg)
  }

  cardCreator(cardCreator: CardCreator) {
    const customMsg = Chatbot.defaultMsgTemplate(true)
    customMsg.ui = CustomMsgUi.Card
    if (cardCreator.img) {
      customMsg.img = cardCreator.img
    }

    const customButton = Chatbot.customButtonCreator()
    const scenarioItem = cardCreator.scenarioType.timeline.content as ContentItemScenario
    const quizItem = scenarioItem.actionContent![0] as ContentItemQuiz

    customButton.text = cardCreator.text
    customButton.code = {
      eventType: cardCreator.eventType,
      value: {
        contentTitle: quizItem.title!,
        contentKey: quizItem.key,
        data: ''
      }
    }
    if (quizItem.timeLimit) {
      customMsg.highLightText = `풀이 시간 ${quizItem.timeLimit}분`
    }
    customMsg.buttons = [customButton];
    customMsg.text = scenarioItem.message
    const chatBotPostMsg: ChatBotPostMsg = {
      chatBotPostMsgType: ChatBotPostMsgType.UI,
      customMsg: customMsg,
      isVoice: cardCreator.isVoice,
      data: ''
    }

    return chatBotPostMsg;
  }

  sendBookCard(scenarioType: ScenarioType, isVoice = true) {
    scenarioType.isSend = true;

    const bookContent = (scenarioType.timeline.content as ContentItemScenario).actionContent![0] as ContentItemBook

    const cardCreator: CardCreator = {
      scenarioType: scenarioType,
      text: '교재 보기',
      eventType: EventType.Book,
      img: Config.CONFIG.PROD_HOST + BookerUtility.getThumbnail((bookContent.pages[0] as ContentItemImage).source),
      isVoice: isVoice
    }
    this.sendChatBot(this.cardCreator(cardCreator))
    if (this.interactionMap.has(scenarioType.timeline.key)) this.interactionMap.delete(scenarioType.timeline.key)
    this.interactionMap.set(scenarioType.timeline.key, scenarioType)
  }

  sendPronunciationCard(scenarioType: ScenarioType, isVoice = true) {
    scenarioType.isSend = true;
    const cardCreator: CardCreator = {
      scenarioType: scenarioType,
      text: '말하기 실습',
      eventType: EventType.Pronunciation,
      isVoice: isVoice
    }
    this.sendChatBot(this.cardCreator(cardCreator))
    if (this.interactionMap.has(scenarioType.timeline.key)) this.interactionMap.delete(scenarioType.timeline.key)
    this.interactionMap.set(scenarioType.timeline.key, scenarioType)
  }

  sendQuizCard(scenarioType: ScenarioType, isVoice = true) {
    scenarioType.isSend = true;
    const cardCreator: CardCreator = {
      scenarioType: scenarioType,
      text: '퀴즈 풀기',
      eventType: EventType.Quiz,
      isVoice: isVoice
    }
    this.sendChatBot(this.cardCreator(cardCreator))
    if (this.interactionMap.has(scenarioType.timeline.key)) this.interactionMap.delete(scenarioType.timeline.key)
    this.interactionMap.set(scenarioType.timeline.key, scenarioType)
  }

  chatBotPostMsgCreator(isVoice = true) {
    const customMsg = Chatbot.defaultMsgTemplate(false)
    const chatBotPostMsg: ChatBotPostMsg = {
      chatBotPostMsgType: ChatBotPostMsgType.UI,
      customMsg: customMsg,
      isVoice: isVoice,
      data: ''
    }
    return chatBotPostMsg
  }

  sendCommonMsg(inter: ScenarioType, isVoice = true) {
    inter.isSend = true;
    const chatBotPostMsg = this.chatBotPostMsgCreator(isVoice)
    if (chatBotPostMsg.customMsg) chatBotPostMsg.customMsg.text = (inter.timeline.content as ContentItemScenario).message
    this.sendChatBot(chatBotPostMsg)
    this.interactionMap.delete(inter.timeline.key);
    this.interactionMap.set(inter.timeline.key, inter);
  }

  sendChatBot(chatBotPostMsg: ChatBotPostMsg) {
    Chatbot.sendChatBot(chatBotPostMsg);
  }


}
