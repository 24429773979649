import {
  ContentItemQuiz,
  QuizItem,
  QuizItemType,
  QuizOptions,
  QuizScore,
  QuizScoreKrType,
  QuizScoreType
} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

export default class ContentItemQuizEditorViewModel {
  quiz: ContentItemQuiz

  constructor(quiz: ContentItemQuiz) {
    this.quiz = quiz;
  }


  get isDifficulty() {
    const diffIdx = this.quiz.quizItems.findIndex(quiz => quiz.difficulty !== 1)
    return diffIdx > -1;

  }

  /**
   * 질문 목록
   */
  get questionList() {
    return this.quiz.quizItems
  }

  get getRepeat() {
    return {
      repeatMinScore: this.quiz.repeatMinScore,
      repeatSection: this.quiz.repeatSection
    }
  }

  get quizType() {
    return this.quiz.quizType
  }

  setQuizType(type: number) {
    this.quiz.quizType = type;
  }

  delSubQuestion(difficultyIndex: number, difficulty: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(difficultyIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    delete quiz.subQuestion;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  setSubQuestion(difficultyIndex: number, difficulty: number, subQuestion: string) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(difficultyIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.subQuestion = subQuestion;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  delCorrectMsg(difficultyIndex: number, difficulty: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(difficultyIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    delete quiz.correctAnswerMsg;
    delete quiz.inCorrectAnswerMsg;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  setCorrectMsg(difficultyIndex: number, difficulty: number, msg: string) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(difficultyIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.correctAnswerMsg = msg;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  setInCorrectMsg(difficultyIndex: number, difficulty: number, msg: string) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(difficultyIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.inCorrectAnswerMsg = msg;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  setExplain(difficultyIndex: number, difficulty: number, msg: string) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(difficultyIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.explain = msg;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  delExplain(difficultyIndex: number, difficulty: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(difficultyIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    delete quiz.explain;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  scoreCreator() {

    let AdaptabilityScore = 0;
    let ExpressivenessScore = 0;
    let ProblemSolvingScore = 0;
    let UnderstandingScore = 0;

    switch (this.quizType) {
      case 0:
        AdaptabilityScore = 12;
        ProblemSolvingScore = 18;
        ExpressivenessScore = 18;
        UnderstandingScore = 12;
        break;
      case 1:
        UnderstandingScore = 2;
        AdaptabilityScore = 2;
        ExpressivenessScore = 2;
        break;
      case 2:
        AdaptabilityScore = 2;
        ExpressivenessScore = 2;
        break;
    }

    const quizScoreA: QuizScore = {
      key: QuizScoreType.Adaptability,
      krText: QuizScoreKrType.Adaptability,
      value: AdaptabilityScore
    }
    const quizScoreE: QuizScore = {
      key: QuizScoreType.Expressiveness,
      krText: QuizScoreKrType.Expressiveness,
      value: ExpressivenessScore
    }
    const quizScoreP: QuizScore = {
      key: QuizScoreType.ProblemSolving,
      krText: QuizScoreKrType.ProblemSolving,
      value: ProblemSolvingScore
    }
    const quizScoreU: QuizScore = {
      key: QuizScoreType.Understanding,
      krText: QuizScoreKrType.Understanding,
      value: UnderstandingScore
    }
    return {quizScoreA, quizScoreE, quizScoreP, quizScoreU}
  }

  quizNumberOptionCreator() {
    const option: QuizOptions = {
      uuid: BookerUtility.createKey(),
      isCorrect: false,
      answerType: 'number',
      text: '',
      answer: 0,
    }
    return option
  }

  quizVoiceOptionCreator() {
    const option: QuizOptions = {
      uuid: BookerUtility.createKey(),
      isCorrect: false,
      answerType: 'voice',
      subAnswers: [],
      text: '',
      userAnswer: '',
      answer: '',
    }
    return option
  }

  quizTextOptionCreator() {
    const option: QuizOptions = {
      uuid: BookerUtility.createKey(),
      isCorrect: false,
      answerType: 'string',
      subAnswers: [],
      text: '',
      userAnswer: '',
      answer: '',
      answerFileUrl: ''
    }
    return option
  }

  /**
   * 퀴즈 질문 추가
   * @param type
   * @param difficulty 난이도 0 : 하 , 1 : 중, 2 : 상
   */
  addQuestion(type: QuizItemType, difficulty = 1): QuizItem {

    const {quizScoreA, quizScoreE, quizScoreP, quizScoreU} = this.scoreCreator()

    const question: QuizItem = {
      quizUuid: BookerUtility.createKey(),
      contentKey: this.quiz.key,
      question: '',
      type: type,
      options: [],
      difficulty: difficulty,
      score: [quizScoreA, quizScoreE, quizScoreP, quizScoreU],
    }

    // 다지선다 형식인 경우 2개 옵션 공백으로 추가, 정답은 첫번째 항목 기본값으로 설정
    if (type === QuizItemType.Select || type === QuizItemType.OX) {
      question.options.push(this.quizNumberOptionCreator())
      question.options.push(this.quizNumberOptionCreator())
    }

    // 빈칸형인경우 정답을 2개 추가
    if (type === QuizItemType.Blank) {
      question.options.push(this.quizTextOptionCreator())
      question.options.push(this.quizTextOptionCreator())
    }

    // 텍스트형인 경우
    if (type === QuizItemType.Input || type === QuizItemType.Descriptive) {
      question.options.push(this.quizTextOptionCreator())
    }


    //말하기
    if (type === QuizItemType.Dictation) {
      question.options.push(this.quizVoiceOptionCreator())
      question.options.push(this.quizVoiceOptionCreator())
    }

    if (this.questionList.length > 0) this.quiz.quizItems.splice(this.questionList.length, 0, question)
    else this.quiz.quizItems.splice(0, 0, question)
    return question
  }

  /**
   * 질문 위치를 위로 이동
   * @param questionIndex
   * @param difficulty
   */
  moveUpQuestion(questionIndex: number, difficulty?: number) {
    if (questionIndex < 1 || questionIndex >= this.quiz.quizItems.length) return
    if (difficulty !== undefined) return this.isDifficultyMoveUpQuestion(questionIndex, difficulty);
    const rms = this.quiz.quizItems.splice(questionIndex, 1)
    this.quiz.quizItems.splice(questionIndex - 1, 0, ...rms)
  }

  isDifficultyMoveUpQuestion(questionIndex: number, difficulty: number) {
    const findDifficultyIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const findDifficultyBeforeIndex = this.findDifficultyIndexInAllList(questionIndex - 1, difficulty);
    const rms = this.quiz.quizItems.splice(findDifficultyIndex, 1)
    this.quiz.quizItems.splice(findDifficultyBeforeIndex, 0, ...rms)
  }

  /**
   * 질문 위치를 아래로 이동
   * @param questionIndex
   * @param difficulty
   */
  moveDownQuestion(questionIndex: number, difficulty?: number) {
    if (questionIndex < 0 || questionIndex >= this.quiz.quizItems.length - 1) return
    if (difficulty !== undefined) return this.isDifficultyMoveDownQuestion(questionIndex, difficulty);

    const rms = this.quiz.quizItems.splice(questionIndex, 1)
    this.quiz.quizItems.splice(questionIndex + 1, 0, ...rms)
  }

  isDifficultyMoveDownQuestion(questionIndex: number, difficulty: number) {
    const findDifficultyIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const findDifficultyNextIndex = this.findDifficultyIndexInAllList(questionIndex + 1, difficulty);
    const rms = this.quiz.quizItems.splice(findDifficultyIndex, 1)
    this.quiz.quizItems.splice(findDifficultyNextIndex, 0, ...rms)
  }

  findDifficultyIndexInAllList(difficultyQuestionIndex: number, difficulty: number) {
    const findQuiz = this.quizItemsByDifficulty(difficulty)[difficultyQuestionIndex];
    console.log('this.quizItemsByDifficulty(difficulty)', this.quizItemsByDifficulty(difficulty))
    console.log('questionIndex', difficultyQuestionIndex)
    console.log('findQuiz', findQuiz)
    let findIndex = 0;
    this.questionList.findIndex((quiz, index) => {
      if (quiz.quizUuid === findQuiz.quizUuid) {
        findIndex = index;
        return true;
      }
    })
    return findIndex;
  }

  findQuizIndexByUuid(uuid: string) {

    let quizIdx = -1;
    let optionIdx = -1;
    this.quiz.quizItems.forEach((quiz, index) => {
      const findIdx = quiz.options.findIndex((option, _optionIdx) =>
        option.uuid === uuid
      )
      if (findIdx > -1) {
        quizIdx = index;
      }
    })

    if (quizIdx > -1) {
      optionIdx = this.quiz.quizItems[quizIdx].options.findIndex((option, index) =>
        option.uuid === uuid
      )
    }

    return {quizIdx, optionIdx}
  }

  findQuizOptionIndexByUuid(uuid: string, quiz: QuizItem) {
    return quiz.options.findIndex((option, index) =>
      option.uuid === uuid
    )
  }


  /**
   * 퀴즈 문항 삭제
   * @param questionIndex
   * @param difficulty
   */
  deleteQuestion(questionIndex: number, difficulty?: number) {
    if (questionIndex < 0 || questionIndex >= this.quiz.quizItems.length) return
    if (difficulty !== undefined) questionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    this.quiz.quizItems.splice(questionIndex, 1)
  }

  /**
   * 퀴즈 옵션 항목 추가
   * @param question
   * @param type
   */
  addOption(question: QuizItem, type: QuizItemType) {
    let option: QuizOptions | undefined;
    switch (type) {
      case QuizItemType.Descriptive:
      case QuizItemType.Input:
      case QuizItemType.Blank:
        option = this.quizTextOptionCreator();
        break;
      case QuizItemType.Dictation:
        option = this.quizVoiceOptionCreator();
        break;
      case QuizItemType.OX:
      case QuizItemType.Select:
        option = this.quizNumberOptionCreator();
        break;

    }

    question.options.splice(question.options.length, 0, option)
  }

  /**
   * 퀴즈 옵션 항목 삭제
   * @param question
   * @param optionIndex
   */
  deleteOption(question: QuizItem, optionIndex: number) {
    if (optionIndex < 0 || optionIndex >= question.options.length) return
    question.options.splice(optionIndex, 1)
  }

  /**
   * 퀴즈 옵션 항목 위로 이동
   * @param question
   * @param optionIndex
   */
  moveUpOption(question: QuizItem, optionIndex: number) {
    if (optionIndex < 1 || optionIndex >= question.options.length) return

    const rms = question.options.splice(optionIndex, 1)
    question.options.splice(optionIndex - 1, 0, ...rms)
  }

  /**
   * 퀴즈 옵션 항목 아래로 이동
   * @param question
   * @param optionIndex
   */
  moveDownOption(question: QuizItem, optionIndex: number) {
    if (optionIndex < 0 || optionIndex >= question.options.length - 1) return

    const rms = question.options.splice(optionIndex, 1)
    question.options.splice(optionIndex + 1, 0, ...rms)
  }

  quizItemsByDifficulty(difficulty: number) {
    return this.quiz.quizItems.filter(quiz => quiz.difficulty === difficulty)
  }

  /**
   * 퀴즈 저장가능 상태 전체 확인
   */
  checkQuizItemsState(): boolean {
    let result = true;
    this.quiz.quizItems.forEach(q => {
      if (!result) return;
      switch (q.type) {
        case QuizItemType.OX:
        case QuizItemType.Select:
          if (!this.checkNumber(q)) result = false;
          break;
        case QuizItemType.Input:
        case QuizItemType.Descriptive:
          if (!this.checkTextOption(q)) result = false;
          break;
      }
    })

    return result;
  }


  /**
   * 객관식 퀴즈가 저장 가능 상태인지 확인
   * @param quiz
   */
  checkNumber(quiz: QuizItem): boolean {
    if (!quiz.question) return false;
    if (quiz.options.findIndex(o => typeof o.answer !== 'number') > -1) return false;
    if (quiz.options.length < 1) return false;
    return true
  }

  /**
   * 텍스트 퀴즈가 저장 가능 상태인지 확인
   * @param quiz
   */
  checkTextOption(quiz: QuizItem): boolean {
    if (!quiz.question) return false;
    if (quiz.options.length > 1) return false;
    if (quiz.options.findIndex(o => typeof o.answer !== 'string') > -1) return false;
    if (quiz.options.findIndex(o => (o.answer as string).length < 1) > -1) return false;
    return true
  }


  changeAllDifficulty() {
    this.questionList.forEach(quiz => quiz.difficulty = 1);
  }

  setDifficulty(questionIndex: number, oldDifficulty = 1, newDifficulty: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, oldDifficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.difficulty = newDifficulty;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  setTimeLimit(timeLimit: number) {
    this.quiz.timeLimit = timeLimit;
  }

  getTimeLimit() {
    return this.quiz.timeLimit
  }

  getQuiz() {
    return this.quiz
  }

  setRepeatSection(repeatSection: number) {
    this.quiz.repeatSection = repeatSection
  }

  setRepeatMinScore(repeatSection: number) {
    this.quiz.repeatMinScore = repeatSection
  }

  toggleRepeat() {
    this.quiz.useRepeat = !this.quiz.useRepeat
  }

  setOptionsImage(questionIndex: number, difficulty: number, imgUrl: string) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.img = imgUrl;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  setAnswerArrayText(questionIndex: number, difficulty = 1, answerIndex: number, text: string) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    if (quiz.type === QuizItemType.Dictation || quiz.type === QuizItemType.Blank) {
      quiz.options[answerIndex].answer = text
    }
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  addAnswerArray(questionIndex: number, difficulty = 1) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    if (quiz.type === QuizItemType.Dictation) {
      quiz.options.push(this.quizVoiceOptionCreator())
    }
    if (quiz.type === QuizItemType.Blank) {
      quiz.options.push(this.quizTextOptionCreator())
    }
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  delAnswerArray(questionIndex: number, difficulty = 1, answerIndex: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.options.splice(answerIndex, 1)
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  moveUpAnswerArray(questionIndex: number, difficulty = 1, answerIndex: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    if (answerIndex < 1 || answerIndex >= quiz.options.length) return
    const rms = quiz.options.splice(answerIndex, 1)
    quiz.options.splice(answerIndex - 1, 0, ...rms)
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  moveDownAnswerArray(questionIndex: number, difficulty = 1, answerIndex: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    if (answerIndex < 0 || answerIndex >= quiz.options.length) return
    const rms = quiz.options.splice(answerIndex, 1)
    quiz.options.splice(answerIndex + 1, 0, ...rms)
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  /**
   * 구술형 빈칸
   * @param questionIndex
   * @param difficulty
   * @param answerIndex
   * @param text
   */

  setDictationAnswerArrayText(questionIndex: number, difficulty = 1, answerIndex: number, text: string) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.options[answerIndex].answer = text
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  setDictationSubAnswerArrayText(questionIndex: number, difficulty = 1, answerIndex: number, text: string) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.options[answerIndex].answer = text
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  addDictationAnswerArray(questionIndex: number, difficulty = 1) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.options.push(this.quizVoiceOptionCreator())
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  delDictationAnswerArray(questionIndex: number, difficulty = 1, answerIndex: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.options.splice(answerIndex, 1)
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  addSubAnswer(questionIndex: number, difficulty = 1, answerIndex: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    let subAnswer = quiz.options[answerIndex].subAnswers as string[]
    if (subAnswer) {
      subAnswer.push('')
    } else {
      subAnswer = [''];
    }
    quiz.options[answerIndex].subAnswers = subAnswer;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }


  delSubAnswer(questionIndex: number, difficulty = 1, answerIndex: number, subAnswersIndex: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    const subAnswer = quiz.options[answerIndex].subAnswers as string[]
    subAnswer.splice(subAnswersIndex, 1);
    quiz.options[answerIndex].subAnswers = subAnswer;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  setSubAnswer(questionIndex: number, difficulty = 1, answerIndex: number, subAnswersIndex: number, text: string) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    const subAnswer = quiz.options[answerIndex].subAnswers as string[]
    subAnswer[subAnswersIndex] = text;
    quiz.options[answerIndex].subAnswers = subAnswer;
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  moveUpDictationAnswerArray(questionIndex: number, difficulty = 1, answerIndex: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    if (answerIndex < 1 || answerIndex >= quiz.options.length) return
    const rms = quiz.options.splice(answerIndex, 1)
    quiz.options.splice(answerIndex - 1, 0, ...rms)
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  moveDownDictationAnswerArray(questionIndex: number, difficulty = 1, answerIndex: number) {
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    if (answerIndex < 0 || answerIndex >= quiz.options.length) return
    const rms = quiz.options.splice(answerIndex, 1)
    quiz.options.splice(answerIndex + 1, 0, ...rms)
    this.questionList.splice(findQuestionIndex, 1, quiz)
    return quiz;
  }

  setScore(scoreData: { questionIndex: number, difficulty: number, scoreIdx: number, score: number }) {
    const {questionIndex, difficulty, scoreIdx, score} = scoreData
    const findQuestionIndex = this.findDifficultyIndexInAllList(questionIndex, difficulty);
    const quiz = this.questionList[findQuestionIndex]
    quiz.score[scoreIdx].value = score;
    this.questionList.splice(findQuestionIndex, 1, quiz)

    return quiz;
  }

}
