
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {ContentItemBook, ContentItemScenario, ContentItemType} from "@/Booker/lib/BookerTypes";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

@Component({
  name: "ClassTextBook",
  components: {}
})
export default class ClassTextBook extends Vue {
  @Prop() classBookKey!: string
  @Prop() model !: ClassContentPlayerViewModel
  @Ref() refBtnFull!: HTMLButtonElement;
  classBook: ContentItemBook | null = null

  BookerUtility = BookerUtility
  textbookDirection = true
  fullStatus = true
  currentTextbook = 0;
  direction = 1;
  transitionName = 'fade'

  get nowPage() {
    return this.currentTextbook + 1
  }

  get isMaxPage() {
    return this.currentTextbook + 1 === this.classBook?.pages.length || 0
  }

  created() {
    this.init();
  }

  mounted() {
//
  }

  init() {
    const textBook = this.model.findContent(this.classBookKey)
    if (textBook) {
      if (textBook.type === ContentItemType.Book) this.classBook = textBook as ContentItemBook
      if (textBook.type === ContentItemType.Scenario) {
        const actionContent = (textBook as ContentItemScenario).actionContent;
        if (actionContent)
          this.classBook = actionContent[0] as ContentItemBook
      }
    }
  }

  @Emit('endBook')
  close() {
    return true
  }

  slide(dir: number) {
    this.direction = dir;
    if (dir === 1) {
      this.transitionName = 'slide-next'
    } else {
      this.transitionName = 'slide-prev'
    }

    let len = this.classBook?.pages.length || 0;
    this.currentTextbook = (this.currentTextbook + dir % len + len) % len;

  }

  changeScreen() {
    if (this.fullStatus) {
      this.$el.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
    this.fullStatus = !this.fullStatus
  }


}
