
import {Component, Vue} from 'vue-property-decorator';
import ChatModal from '@/views/ClassPage/ChatRoom/ChatModal.vue'
import Floating from '@/components/Floating.vue'

@Component({
  name: 'FloatingWrapper',
  components: {Floating, ChatModal}
})
export default class FloatingWrapper extends Vue {

  defaultChat = true
  defaultBtn = false
  defaultFloat = true

  showModal() {
    this.defaultChat = true;
    this.defaultBtn = true;
    this.defaultFloat = false;
  }

  closeModal() {
    this.defaultChat = false
    this.defaultBtn = false
    this.defaultFloat = true
  }


}
