export default class CallRegulator {
  private callDelay: number
  private lastCallTime = 0
  private timeout: any = 0

  private lastCB: null | (() => void) = null
  private callRunning = false

  constructor(callDelay: number) {
    this.callDelay = callDelay;
  }

  call(cb: null | (() => void)) {
    this.lastCB = cb

    clearTimeout(this.timeout)

    const now = Date.now()
    const diff = now - this.lastCallTime
    if (diff >= this.callDelay) {
      this.callCB()
    } else {
      this.timeout = setTimeout(() => {
        this.callCB()
      }, this.callDelay - diff)
    }
  }

  private callCB() {
    this.lastCallTime = Date.now()

    // call 이 동작중이면 다음 frame 으로 예약
    if (this.callRunning) {
      this.call(this.lastCB)
      return
    }

    this.callRunning = true
    window.requestAnimationFrame(async () => {
      try {
        await this.lastCB?.()
      } catch (e) {
        //
      }
      this.callRunning = false
    })
  }
}
