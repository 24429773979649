
import { Component, Prop, Vue } from 'vue-property-decorator';
import AiProfile from '@/components/ui/chatting/AiProfile.vue'
import MsgTextBox from '@/components/ui/chatting/MsgTextBox.vue'

@Component({
  components: { AiProfile, MsgTextBox }
})
export default class TodayBook extends Vue {
  @Prop() title !: string
}
