
import {Component, Vue} from "vue-property-decorator";
import CommonMainHeader from "@/views/ClassPage/component/CommonMainHeader.vue";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import IncorrectNoteItem from "@/views/ClassPage/component/IncorrectNoteItem.vue";
import IncorrectNoteChart from "@/views/ClassPage/component/IncorrectNoteChart.vue";
import IncorrectQuestion from "@/views/ClassPage/component/IncorrectQuestion.vue";

@Component({ name: "MyIncorrectNote",  components: {
    IncorrectQuestion,
    IncorrectNoteChart, IncorrectNoteItem, CommonMainHeader}})
export default class MyIncorrectNote extends Vue {
  aiModel = new AiReportViewModel()
  gradeStatus = false
  hideChart = false
  focusGrade() {
    this.gradeStatus = !this.gradeStatus
  }
  classStatus = false
  focusClass() {
    this.classStatus = !this.classStatus
  }


  mounted() {
    console.log('this.aiModel', this.aiModel)
  }

  get quizScoreDetail() {
    return this.aiModel.quizScoreDetail;
  }


}
