
import {Component, Emit, Prop, Ref, Vue, Watch} from 'vue-property-decorator'
import QuizStart from '@/components/QuizStart.vue'
import {
  ClassStatus,
  ContentItemBook,
  ContentItemQuiz,
  ContentItemScenario,
  ContentItemType,
  TimelineContent,
  TimelineType
} from "@/Booker/lib/BookerTypes";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import VideoTimelineContentPlayer from "@/Booker/views/components/video/player/VideoTimelineContentPlayer.vue";
import ClassContentVideoTool from "@/views/ClassPage/ClassContentView/ClassContentVideoTool.vue";
import VideoShape from "@/Booker/views/components/video/player/shape/VideoShape.vue";
import ViewSubtitle from "@/components/ui/subtitle/ViewSubtitle.vue";
import ClassTextBook from "@/views/ClassPage/ClassTextBook.vue";
import ClassStartButton from "@/views/ClassPage/ClassStartButton.vue";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import {CustomButtonEvent, EventType} from "@/assets/lib/type/Types";
import SpeakingPractice from "@/components/ui/speaking/SpeakingPractice.vue";


@Component({
  name: 'ClassContentVideoView',
  components: {
    SpeakingPractice,
    ClassStartButton,
    ClassTextBook, VideoShape, VideoTimelineContentPlayer, QuizStart, ClassContentVideoTool, ViewSubtitle
  }
})
export default class ClassContentVideoView extends Vue {


  @Prop() playerList!: []
  @Prop() moveButton!: boolean
  @Ref() rangeInput!: HTMLInputElement;
  @Ref() refPlayer!: HTMLDivElement;
  @Ref() refVideoTool!: ClassContentVideoTool;

  @Prop() model!: ClassContentPlayerViewModel;
  @Prop() classStatus!: ClassStatus;
  timelineQuiz: TimelineContent | null = null;
  timelineBook: TimelineContent | null = null;
  allTimelineContent: TimelineContent[] = [];
  ContentItemType = ContentItemType;
  BookerUtility = BookerUtility;
  TimelineType = TimelineType;
  scaleRatio = 1;
  timeoutId: number | undefined;
  quizMap: Map<string, ContentItemQuiz> | null = null;
  bookMap: Map<string, ContentItemBook> | null = null;

  get quizList() {
    return this.model.timeline.getAllTimelineContents().filter(content => content.type === ContentItemType.Quiz)
  }

  get bookList() {
    return this.model.timeline.getAllTimelineContents().filter(content => content.type === ContentItemType.Book)
  }

  get isPlayOrPause() {
    return this.classStatus === 'Play' || this.classStatus === 'Pause'
  }

  get isQuiz() {
    if (!this.quizMap) return false
    if (this.timelineQuiz) return true;
    const quiz = this.quizList.find(tl => tl.start < this.model.seekbar.currentTime && this.quizMap?.has(tl.content.key))
    if (quiz && this.quizMap.has(quiz.content.key)) {
      this.timelineQuiz = quiz
      this.startQuiz();
      BookerUtility.playSound();
      return true;
    }
    return false
  }

  get isBook() {
    if (!this.bookMap) return false
    if (this.timelineBook) return true;
    const book = this.bookList.find(tl => tl.start < this.model.seekbar.currentTime && this.bookMap?.has(tl.content.key))
    if (book && this.bookMap.has(book.content.key)) {
      this.timelineBook = book
      this.startQuiz();
      BookerUtility.playSound();
      return true;
    }
    return false
  }

  nowPlay(timelineContent: TimelineContent) {
    // return true;
    return timelineContent.start <= this.model.seekbar.currentTime && timelineContent.end! > this.model.seekbar.currentTime && this.classStatus !== 'Quiz'
  }

  created() {
    window.removeEventListener('message', this.handleChatEvent)
    window.addEventListener('message', this.handleChatEvent)
  }

  beforeDestroy() {
    window.removeEventListener('message', this.handleChatEvent)
    onmousemove = () => null;
  }

  mounted() {

    if (this.refPlayer) {
      this.cssMount()
      new ResizeObserver(this.cssMount).observe(this.$el)
    }

    this.allTimelineContent = this.model.timeline.getAllTimelineContentsForPlayer();

    this.quizMap = new Map<string, ContentItemQuiz>()
    this.quizList.forEach((quiz) => {
      if (this.quizMap) this.quizMap.set(quiz.content.key, quiz.content as ContentItemQuiz)
    })
    this.bookMap = new Map<string, ContentItemBook>()
    this.bookList.forEach((book) => {
      if (this.bookMap) this.bookMap.set(book.content.key, book.content as ContentItemBook)
    })

  }


  @Watch('classStatus')
  onChangeClassStatus() {
    if (this.isPlayOrPause) this.playPlayer();
  }

  @Emit('screenFull')
  screenFull() {
    return;
  }

  @Emit('updateStatus')
  startQuiz() {
    return 'Quiz'
  }

  @Emit('updateStatus')
  updateStatusToPlay() {
    return 'Play'
  }

  endQuiz(emitData: { result: boolean, isRepeat: boolean }) {
    const {result, isRepeat} = emitData;
    if (!this.timelineQuiz) return;

    const content = this.timelineQuiz.content.type === ContentItemType.Quiz
      ? (this.timelineQuiz.content as ContentItemQuiz)
      : ((this.timelineQuiz.content as ContentItemScenario).actionContent![0] as ContentItemQuiz);

    if (isRepeat && !result) {
      this.model.setCurrenTimeByTimelineIdx(content.repeatSection)
    } else this.quizMap?.delete(content.key)

    this.updateStatusToPlay()
    this.timelineQuiz = null;
  }

  endBook() {
    this.updateStatusToPlay()
    if (this.timelineBook) this.bookMap?.delete(this.timelineBook.content.key)
    this.timelineBook = null;
  }

  handleChatEvent(message: MessageEvent) {
    if (message.data.chatBotPostMsgType) {
      return;
    }
    if (message.data.eventType) {
      const customButtonEvent: CustomButtonEvent = message.data
      switch (customButtonEvent.eventType) {
        case EventType.Quiz:
          if (!this.model.checkAlreadySend(customButtonEvent.value.contentKey)) {
            const quiz = this.model.timeline.getTimelineContents(TimelineType.Scenario).find(tl => {
              const actionContent = (tl.content as ContentItemScenario).actionContent
              if (actionContent) {
                return actionContent[0].key === customButtonEvent.value.contentKey
              }
              return false
            });
            if (quiz) {
              BookerUtility.playSound();
              this.timelineQuiz = quiz
              this.model.saveSend(customButtonEvent.value.contentKey);
            }
            this.startQuiz()
          }
          break;
        case EventType.Book:
          if (!this.model.checkAlreadySend(customButtonEvent.value.contentKey)) {
            const book = this.model.timeline.getTimelineContents(TimelineType.Scenario).find(tl => {
              const actionContent = (tl.content as ContentItemScenario).actionContent
              if (actionContent) {
                return actionContent[0].key === customButtonEvent.value.contentKey
              }
              return false
            });
            if (book) {
              BookerUtility.playSound();
              this.timelineBook = book
              this.model.saveSend(customButtonEvent.value.contentKey);
            }
            this.startQuiz()
          }
          break;
      }
    }
  }

  repeatQuiz(contentKey: string) {
    this.model.deleteSend(contentKey);
  }

  playOrPause() {
    if (this.classStatus === 'BeFore' || this.classStatus === 'Quiz') return;
    if (this.model.seekbar.isPlaying) {
      this.stopPlayer();
      return;
    }
    this.playPlayer();
  }

  stopPlayer() {
    this.model.seekbar.pause();
  }

  playPlayer() {
    this.model.seekbar.play();
  }

  goTimelineStartTime(startTime: number) {
    if (this.isPlayOrPause) this.model.setCurrenTimeByTimelineStartTime(startTime)
  }

  cssMount() {
    if (!this.$el) return;
    const defaultWidth = 1280;
    const defaultHeight = 720;
    let {width, height} = this.$el.getBoundingClientRect();
    let scaleRatio;

    if (this.refPlayer) {
      //scale 이 커질때
      if (width > defaultWidth) {
        scaleRatio = width / defaultWidth
        if (scaleRatio > height / defaultHeight) {
          scaleRatio = height / defaultHeight
        }
        this.refPlayer.style.scale = `${scaleRatio}`;
        return
      }

      //scale 이 작아질때
      scaleRatio = width / defaultWidth
      if (scaleRatio > height / defaultHeight) {
        scaleRatio = height / defaultHeight
      }
      // this.scaleRatio = scaleRatio;
      this.refPlayer.style.scale = `${scaleRatio}`;
    }
  }


}
