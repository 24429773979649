import VirtualBackgroundStream from "./VirtualBackgroundStream";

export default class VirtualBackground {

  public static readonly backgroundImages =  [
    { name: '사용 안함', path: require('@/assets/images/filter/filter-none.png'), idx: 0, nativeOnly: false},
    { name: 'blur', path: require('@/assets/images/filter/filter-blur.png'), idx: 1 , nativeOnly: false},
    { name: 'cafe1', path: require('@/assets/images/filter/filter-cafe.png'), idx: 2 , nativeOnly: false},
    { name: 'cafe2', path: require('@/assets/images/filter/filter-cafe2.png'), idx: 3 , nativeOnly: false},
    { name: 'office', path: require('@/assets/images/filter/filter-office.png'), idx: 4 , nativeOnly: false},
    { name: 'room', path: require('@/assets/images/filter/filter-room.png'), idx: 5 , nativeOnly: false},
    { name: 'fitness', path: require('@/assets/images/filter/filter-fitness.png'), idx: 6 , nativeOnly: false},
    { name: 'hall', path: require('@/assets/images/filter/filter-hall.png'), idx: 7 , nativeOnly: false},
  ]

  private origStream: MediaStream | null = null;

  private _backgroundImagePath = '';
  private _backgroundImageName = '';
  private backgroundImage: null | HTMLImageElement = null

  private readonly maxFrameWidth = 640

  private streamIndex = 0;
  private currentVideoTime = 0

  private video: HTMLVideoElement | null = null;
  private virtualBackgroundStream: VirtualBackgroundStream = new VirtualBackgroundStream()
  private canvasStream: MediaStream | null = null

  getBackgroundImagePath() {
    return this._backgroundImagePath;
  }
  setBackgroundImagePath(path: string) {
    if(this._backgroundImagePath == path) return;

    if(path) {
      const newBg = new Image()
      newBg.onload = () => {
        this.backgroundImage = newBg
      }
      newBg.src = path
    } else {
      this.backgroundImage = null
    }

    this._backgroundImagePath = path;
  }

  changeAudioTracks(newTracks : MediaStreamTrack[]){
    this.origStream?.getAudioTracks().forEach((t) => this.origStream?.removeTrack(t))
    newTracks.forEach((t)=>this.origStream?.addTrack(t))
  }

  get stream() {
    return this.origStream;
  }
  set stream(stream: MediaStream | null) {
    if(stream === this.origStream) return;

    this.streamIndex++;

    this.origStream = stream;
    if(this.origStream) { // new stream

      // setup output frame size
      const streamSettings = this.origStream.getVideoTracks()[0].getSettings();
      let streamWidth = streamSettings.width!
      let streamHeight = streamSettings.height!

      if(streamWidth > this.maxFrameWidth) {
        streamHeight = streamHeight * (this.maxFrameWidth / streamWidth)
        streamWidth = this.maxFrameWidth
      }

      this.virtualBackgroundStream.updateCanvasSize(streamWidth, streamHeight)

      // release previous canvas stream
      this.canvasStream?.getTracks().forEach(t => t.stop());

      this.canvasStream = this.virtualBackgroundStream.stream.clone()

      // add audio tracks to the canvas stream
      const audioTracks = this.origStream.getAudioTracks();
      if(audioTracks) {
        audioTracks.forEach(t => {
          this.canvasStream?.addTrack(t);
        });
      }

      if(! this.video) this.video = document.createElement('VIDEO') as HTMLVideoElement;
      // @ts-ignore
      this.video.playsInline = true;
      this.video.muted = true;
      this.video.autoplay = true;
      this.video.srcObject = this.origStream;
      setTimeout(async () => {
        try {
          await this.video?.play();
        } catch(e) {
          //
        }
      });

      this.render(this.streamIndex);
    } else { // no stream
      this.video?.pause();
      if(this.video) this.video.srcObject = null
      this.canvasStream?.getTracks().forEach(t => t.stop());
      this.canvasStream = null;
    }
  }

  destroyed(){
    this.origStream = null;
    this.video?.pause();
    if(this.video) this.video.srcObject = null
    this.canvasStream?.getTracks().forEach(t => t.stop());
    this.canvasStream = null;
  }

  get virtualStream() {
    if(this.origStream)
      return this.canvasStream;
    return null;
  }

  setBackground = (backgroundIdx:number) => {
    if(backgroundIdx > VirtualBackground.backgroundImages.length) return;
    this.setBackgroundImagePath(VirtualBackground.backgroundImages[backgroundIdx].path)
    this._backgroundImageName = VirtualBackground.backgroundImages[backgroundIdx].name
  }

  private render(streamIndex: number) {
    if(this.streamIndex !== streamIndex) return

    window.requestAnimationFrame(async () => {
      if(! this.video) return

      try {
        // send frame when the video time is changed
        if(this.currentVideoTime !== (this.video?.currentTime || 0) && ! this.video?.paused) {
          // console.log('send frame')

          // send frame
          await this.virtualBackgroundStream.sendVideoFrame(this.video, this._backgroundImagePath, this._backgroundImageName, this.backgroundImage, result => {
            this.render(streamIndex)
          })

          // save current video time
          this.currentVideoTime = (this.video?.currentTime || 0)
        } else { // video time is not changed
          // console.log('same frame dropped')
          // schedule render
          this.render(streamIndex)
        }

      } catch(e) {
        console.warn('sending virtual background error', e)
        setTimeout(() => {
          this.virtualBackgroundStream.recreateSelfieSegmentation()
          this.render(streamIndex)
        }, 100)
      }
    })
  }
}
