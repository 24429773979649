
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {TextToSpeechVoiceType} from "@/Booker/lib/BookerTypes";

@Component({name: "TtsAudioType", components: {}})
export default class TtsAudioType extends Vue {
  @Prop() voiceType!: TextToSpeechVoiceType

  TextToSpeechVoiceType = TextToSpeechVoiceType

  @Emit('setVoiceType')
  setVoiceType(voiceType: TextToSpeechVoiceType) {
    if (voiceType === TextToSpeechVoiceType.Man) {
      return TextToSpeechVoiceType.Man;
    } else if (voiceType === TextToSpeechVoiceType.Woman) {
      return TextToSpeechVoiceType.Woman;
    } else if (voiceType === TextToSpeechVoiceType.Kid) {
      return TextToSpeechVoiceType.Kid;
    } else {
      return TextToSpeechVoiceType.Man;
    }
  }



}
