
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import App from "@/assets/lib/controller/App";
import {UserType} from "@/assets/lib/type/ApiResponseTypes";


@Component
export default class MainHeader extends Vue {
  @Prop() isLogin!: boolean

  UserType = UserType

  get auth() {
    return App.controller.auth
  }

  get userName() {
    return this.auth.userNameWithType
  }

  get userInfo() {
    return this.auth.userInfo
  }

  @Emit('login')
  clickLogin() {
    //
  }

  @Emit('logout')
  clickLogout() {
    //
  }

  goSetting() {
    this.$router.push('/setting')
  }

  goBookMark() {
    console.log('App.controller.auth.isLogin', App.controller.auth.isLogin)
    console.log('this.$router', this.$router)
    if (App.controller.auth.isLogin) this.$router.push('/bookmark/main');
  }


}
