
import {Component, Ref, Vue} from 'vue-property-decorator'

import '@/assets/style/font.css'
import ClassContentListViewModel from "@/Booker/lib/viewModel/ClassContentListViewModel";
import BookMarkMainPageThumbnail from "@/Booker/views/components/BookMarkMainPageThumbnail.vue";
import {ClassContent, ContentItemType} from "@/Booker/lib/BookerTypes";
import App from "@/assets/lib/controller/App";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import CommonBookerModal from "@/Booker/views/components/common/CommonBookerModal.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import Card from "@/components/ui/Card.vue";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import BookMarkAddClassModal from "@/Booker/views/components/BookMarkAddClassModal.vue";
import BookMarkEditClassModal from "@/Booker/views/components/BookMarkEditClassModal.vue";
import CommonModal from "@/Booker/views/components/common/CommonModal.vue";

@Component({
  name: 'BookMarkMainPage',
  components: {
    CommonModal,
    BookMarkEditClassModal,
    BookMarkAddClassModal, Card, CommonInput, CommonBookerModal, BookMarkMainPageThumbnail
  }

})
// 시연용 이미지 페이지
export default class BookMarkMainPage extends Vue {

  @Ref() refInput!: HTMLInputElement

  viewModel: ClassContentListViewModel | null = null
  modalStatus = false
  commonModalStatus = false
  addModalStatus = false
  optionListStatus = false
  selectedContent: ClassContent | null = null;
  ContentItemType = ContentItemType
  BookerUtility = BookerUtility
  content: ClassContent | null = null;

  thumbnail = ''
  newContent: ClassContent | null = null

  /**
   * 옵션 리스트
   */
  optionListIndex = 0

  // 수업 수정하기
  editModalStatus = false

  get classList() {
    if (!this.viewModel) return []
    return this.viewModel.contentList;
  }

  get userName() {
    return App.controller.auth.userNameWithType
  }

  created() {
    this.init()
  }

  init() {
    if (!this.viewModel) this.viewModel = new ClassContentListViewModel();
    this.viewModel?.init()
    this.newContent = this.viewModel.classTemplate()
  }

  // BookerMain으로 이동
  goMain() {
    this.$router.push('/main')
  }

  // BookerTool로 이동
  goTool(key: string) {
    this.$router.push('/bookmark/tool/' + key)
  }

  // 새 수업 만들기
  classAddFunc() {
    this.addModalStatus = true
  }

  classEditFunc(classIndex: number) {
    if (!this.viewModel) return;
    this.newContent = this.viewModel.contentList[classIndex].content
    if (this.newContent.thumbnailUrl) {
      this.thumbnail = this.newContent.thumbnailUrl ? this.newContent.thumbnailUrl : ''
    }
    this.editModalStatus = true
  }

  //수업 지우기
  classDelFunc(contentKey: string) {
    this.viewModel?.deleteContent(contentKey)
  }

  toLocalTime(date: number) {
    const time = new Date(date)
    return `${time.toLocaleDateString()} ${time.getHours()}:${time.getMinutes()}`
  }

  /**
   * 내보내기 설정
   */

  async setRelease(content: ClassContent, isRelease: boolean, isOverWrite = false) {
    if (!await ClassContentManager.controller.setRelease(content, !content.isRelease, isOverWrite)) {
      this.commonModalStatus = true;
      this.content = content;
    }
  }

  receiveResult(result: boolean) {
    this.commonModalStatus = false;
    if (!result) {
      this.content = null;
      return;
    }
    if (this.content) this.setRelease(this.content, true, true);
    this.content = null;
  }

  showOptionList(idx: number) {
    if (this.optionListIndex === idx) {
      this.optionListStatus = !this.optionListStatus
    } else {
      this.optionListIndex = idx
      this.optionListStatus = true
    }
  }


  closeModal() {
    this.addModalStatus = false
    this.editModalStatus = false
    if (this.viewModel)
      this.newContent = this.viewModel.classTemplate()
  }

  async copyContent(contentKey: string) {
    await this.viewModel?.copyContent(contentKey)
  }


}
