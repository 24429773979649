
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import {ContentItemText, ContentItemType, TimelineContent} from "@/Booker/lib/BookerTypes";
import ContentItemSubtitleEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemSubtitleEditorViewModel";
import SubtitleOption from "@/Booker/views/components/subtitle/SubtitleOption.vue"
import VideoShapeOption from "@/Booker/views/components/video/player/shape/VideoShapeOption.vue";


@Component({
  name: "CreateSubtitle",
  components: {VideoShapeOption, SubtitleOption}
})
export default class CreateSubtitle extends Vue {
  @Prop() model!: ClassContentMediaListViewModel
  textModel: ContentItemSubtitleEditorViewModel | null = null;
  @Prop() timelineContent!: TimelineContent
  @Prop() textContent!: TimelineContent;
  @Ref() refSubtitle!: string
  @Ref() refCreateSubtitle!: string
  @Ref() refSubtitleOption!: SubtitleOption


  ContentItemType = ContentItemType
  optionStatus = true
  subtitleStatus = true

  init() {
    this.textModel = new ContentItemSubtitleEditorViewModel(this.timelineContent.content as ContentItemText);
  }



  mounted() {
    if (!this.textModel) this.init();

    this.$nextTick(() => {
      window.addEventListener('click', (event) => {
        if (
          this.$el &&
          !this.$el.contains(event.target as Node) &&
          this.refSubtitleOption &&
          this.refSubtitleOption.$el &&
          !this.refSubtitleOption.$el.contains(event.target as Node)
        ) {
          this.optionStatus = false;
        }
      });
    });
  }

  clickSubtitle() {
    this.optionStatus = true;
  }

  changeSize(index: number) {
    this.textModel?.changeSize(index);
    this.onInputHeight();
  }

  onInputHeight() {
    this.$nextTick(() => {
      const textarea = this.$refs.refSubtitle as HTMLTextAreaElement;
      const createSubtitle = this.$refs.refCreateSubtitle as HTMLElement;

      if (textarea && createSubtitle) {
        const fontSize = parseFloat(getComputedStyle(textarea).fontSize);
        const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight);
        let height = textarea.scrollHeight + lineHeight;

        switch (fontSize) {
          case 20:
            height = 100;
            break;
          case 40:
            height = 120;
            break;
          case 60:
            height = 200;
            break;
          case 80:
            height = 240;
            break;
          default:
            height = 280;
            break;
        }
        createSubtitle.style.height = `${height}px`;
      }
    });
  }

  /**
   * 위치 수정
   */
  changePosition() {
    if (this.timelineContent.content.bound?.top) {
      this.textModel?.changeToTop()
    } else if (this.timelineContent.content.bound?.bottom) {
      this.textModel?.changeToBottom()
    } else {
      this.textModel?.changeToMiddle()
    }
  }

  /**
   * Text 옵션
   */
  onInputText(e: Event) {
    if (!this.textModel) return;
    //this.textModel.textItem.text = (e.target as HTMLInputElement).value;
    this.textModel.textItem.text = (e.target as HTMLInputElement).value;
  }

  changeColor(index: number) {
    this.textModel?.changeColor(index);
  }

  /**
   * 자막 삭제
   */
  removeSubtitle() {
    if (!this.subtitleStatus) {
      return;
    }
    if (!confirm('취소하시겠습니까?')) {
      alert('취소');
      return;
    }
    this.subtitleStatus = false
  }

  /**
   * 자막 배경색 check
   * @param isChecked
   */
  changeSubtitleBg(isChecked: boolean) {
    if (isChecked) {
      this.textModel!.textItem.background = 'rgba(0,0,0,0.6)'
    } else {
      this.textModel!.textItem.background = ''
    }
  }

  blurSubtitle() {
    this.optionStatus = false;
    //this.subtitleStatus = false;
  }
  clickSubtitleOption() {
    this.optionStatus = true;
  }


}
