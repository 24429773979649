import { render, staticRenderFns } from "./IncorrectNoteItem.vue?vue&type=template&id=372e1f38&scoped=true&"
import script from "./IncorrectNoteItem.vue?vue&type=script&lang=ts&"
export * from "./IncorrectNoteItem.vue?vue&type=script&lang=ts&"
import style0 from "./IncorrectNoteItem.vue?vue&type=style&index=0&id=372e1f38&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "372e1f38",
  null
  
)

export default component.exports