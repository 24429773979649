export default class VariableChangeListener<T> {
  private _listeners: Map<any, (newValue: T) => void> = new Map();

  addListener(key: any, listener: (newValue: T) => void): void {
    this._listeners.set(key, listener);
  }

  removeListener(key: any): void {
    this._listeners.delete(key);
  }

  clearListeners(): void {
    this._listeners.clear();
  }

  notifyListeners(newValue: T): void {
    this._listeners.forEach(listener => listener(newValue));
  }
}
