
import {Component, Prop, Vue} from "vue-property-decorator";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";

@Component({name: "ScoreCardQuiz", components: {}})
export default class ScoreCardQuiz extends Vue {
  @Prop() model!: AiReportViewModel

  get quizScoreDetail() {
    return this.model.quizScoreDetail;
  }

  get totalRealScore() {
    return this.quizScoreDetail.quiz.reduce((total, item) => total + item.score, 0);
  }
}
