import GenieApi from "@/assets/lib/api/GenieApi";

export default class SoundMeter {
  public readonly errorMsg = '[ERROR]';
  public readonly errorCodes = [912, 914];
  instant = 0.0; // audio level
  slow = 0.0;
  clip = 0.0;
  // eslint-disable-next-line @typescript-eslint/ban-types
  pcmCallback ?: Function;
  isCallBack = true;
  private readonly cls = '[SoundMeter]';
  private audioContext: AudioContext | null = null;
  private mic: MediaStreamAudioSourceNode | null = null;
  private script: ScriptProcessorNode | null = null;
  private pcmData: Blob[] = [];

  constructor() {
    this.init();
  }

  get status() {
    return this.mic;
  }

  // 값이 1 ~ 1000 까지
  get soundLevel() {
    return this.instant * 1000;
  }

  init() {
    try {
      // @ts-ignore
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      // sampleRate 16000 지정
      this.audioContext = new AudioContext({
        sampleRate: 16000
      });
      // @ts-ignore
      // window.audioContext = this.audioContext;
    } catch (e) {
      this.error('Web Audio API not supported.', e);
      return;
    }

    this.instant = 0.0;
    this.slow = 0.0;
    this.clip = 0.0;
    this.script = this.audioContext.createScriptProcessor(2048, 1, 1);

    const pushPcmBlob = (input: Float32Array) => {
      const buffer_pcm = new window.Int16Array(input.length);
      this.floatTo16BitPCM(buffer_pcm, input);
      this.pcmData.push(new Blob([buffer_pcm], {type: 'audio/pcm'}))
    }

    this.script.onaudioprocess = (event: AudioProcessingEvent) => {
      const input = event.inputBuffer.getChannelData(0);
      let sum = 0.0;
      let clipCount = 0;
      input.forEach((channelData, channelDataIdx) => {
        sum += channelData * channelData;
        if (Math.abs(channelData) > 0.99) clipCount++;
      })
      this.instant = Math.sqrt(sum / input.length);
      this.slow = 0.95 * this.slow + 0.05 * this.instant;
      this.clip = clipCount / input.length;

      pushPcmBlob(input);
    }
  }

  floatTo16BitPCM(output: any, input: any) {
    for (let i = 0; i < input.length; i++) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  connectToSource(stream: MediaStream, callback?: Function) {
    if (!this.audioContext) {
      this.error('Web Audio API not supported.');
      return;
    }
    if (!this.script) {
      this.error('No script');
      return;
    }

    this.log('SoundMeter connecting');
    try {
      this.mic = this.audioContext.createMediaStreamSource(stream);
      this.mic.connect(this.script);
      // necessary to make sample run, but should not be.
      this.script.connect(this.audioContext.destination);
      callback?.(null);
    } catch (e) {
      this.error('createMediaStreamSource', e);
      callback?.(e);
    }
  }

  async stop() {
    if (!this.mic) return;
    if (!this.audioContext) {
      this.error('Web Audio API not supported.');
      return;
    }

    this.log('SoundMeter stopping');
    await this.audioContext.close();
    this.audioContext = null;

    this.mic?.disconnect();
    this.mic = null;

    this.script?.disconnect();
    this.script = null;

    this.instant = 0;
    this.slow = 0;
    this.clip = 0;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async getPcmText(): Promise<string> {
    let msg = "";
    const pcmFile = new File(this.pcmData, "output.pcm", {type: "audio/pcm"});
    return GenieApi.pcmTextIntention({pcm: pcmFile})
      .then(res => {
        res?.code === 200
          ? msg = res.data.finalResult
          : msg = this.errorMsg
        return msg;
      })
      .catch(res => {
        this.errorCodes.indexOf(res.code) > -1
          ? msg = res.message
          : msg = this.errorMsg
        return msg;
      })
      .finally(() => {
        this.pcmData = [];
        if (typeof this.pcmCallback === "function" && this.isCallBack) this.pcmCallback?.call(null, msg);
      })
  }

  private log(...data: any[]): void {
    console.log(this.cls, ...data);
  }

  private error(...data: any[]): void {
    console.error(this.cls, ...data);
  }

}
