
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import {CustomButtonEvent, EventType} from "@/assets/lib/type/Types";
import Chatbot from "@/assets/lib/utility/Chatbot";

@Component({
  name: 'Floating'
})
export default class Floating extends Vue {

  // eslint-disable-next-line @typescript-eslint/ban-types
  @Prop() showChatModal!: Function;
  @Prop() btnCloseImg!: string;
  @Prop() state!: boolean;
  @Ref() refMsg!: HTMLSpanElement;

  msgList: string[] = [];
  messageToggle = false
  msgCheckTimer: number | null = null;
  pushMsgIndex = 0;

  mounted() {
    Chatbot.removeChatBotEventListener(this.handleChatEvent)
    Chatbot.addChatBotEventListener(this.handleChatEvent)
  }

  beforeDestroy() {
    Chatbot.removeChatBotEventListener(this.handleChatEvent)
  }

  handleChatEvent(message: MessageEvent) {
    if (!this.state) return;
    if (!message.data.eventType) return;
    const customEvent: CustomButtonEvent = message.data
    if (customEvent.eventType === EventType.Common) this.pushMsg(customEvent.value.data as string);
  }


  showChat() {
    this.showChatModal()
    this.clearMsg()
    this.clearTimer()
  }

  badgeMaxItem() {
    if (this.msgList.length > 99) {
      return 99 + '+'
    } else {
      return this.msgList.length
    }
  }

  closeMessage() {
    this.messageToggle = false
  }

  checkPushMsg() {
    if (this.msgCheckTimer) return;
    this.msgCheckTimer = setInterval(() => {
      this.newMsg();
    }, 1 * 1000)
  }

  pushMsg(text: string) {
    console.log('text', text)
    if (text.trim().length > 0) {
      this.msgList.push(text);
      this.checkPushMsg();
    }
  }

  newMsg() {
    if (this.msgList.length - 1 < this.pushMsgIndex) return;
    if (!this.refMsg) return;
    const msg = this.msgList[this.pushMsgIndex];
    if (!msg || msg === '') return;
    this.refMsg.innerText = msg;
    this.messageToggle = true;
    this.pushMsgIndex++;
  }

  clearMsg() {
    this.msgList = [];
    this.refMsg.innerText = '';
    this.messageToggle = false;
  }

  clearTimer() {
    this.pushMsgIndex = 0;
    if (this.msgCheckTimer) clearInterval(this.msgCheckTimer)
    this.msgCheckTimer = null;
  }
}
