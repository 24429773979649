
import {Component, Vue} from 'vue-property-decorator';
import MainHeader from '@/components/layouts/MainHeader.vue';
import App from "@/assets/lib/controller/App";

@Component({
  methods: {
    App() {
      return App
    }
  },
  components: {MainHeader}
})
export default class LoginPage extends Vue {
  defaultInput = false
  defaultInput2 = false
  userId = ''
  userIdValidity = 'textingId'
  userPw: string | null = ''
  userPwValidity = 'textingPw'
  showNoMatch = false

  get checkToken() {
    return App.controller.auth.isLogin
  }

  created() {
    if (this.checkToken && this.$route.path.includes("/login")) this.$router.replace("/")
  }

  inputFocus() {
    this.defaultInput = !this.defaultInput
    this.defaultInput2 = false
  }

  inputFocus2() {
    this.defaultInput2 = !this.defaultInput2;
    this.defaultInput = false
  }

  validateId() {
    if (this.userId === '') {
      this.userIdValidity = 'invalid'
    } else {
      this.userIdValidity = 'valid'
    }
  }

  validatePw() {
    if (this.userPw === '') {
      this.userPwValidity = 'invalid'
    } else {
      this.userPwValidity = 'valid'
    }
  }

  clickTabPw() {
    this.defaultInput2 = false
  }

  async login() {
    if (this.userId.length < 2) {
      return
    }
    if (!this.userPw) return
    if (this.userPw?.length < 2) {
      return
    }

    if (await App.controller.auth.login(this.userId, this.userPw)) {
      await this.$router.push("/")
    }

    if(App.controller.auth.userInfo.user_id === '') {
      this.showNoMatch = true
    }
  }

  resetShowNoMatch() {
    this.showNoMatch = false
  }

}
