
import {Component, Prop, Vue} from 'vue-property-decorator';
import ChatRoom from '@/views/ClassPage/ChatRoom/ChatRoom.vue'

@Component({
  components: {ChatRoom}
})
export default class ChatModal extends Vue {

  @Prop() isOpacity!: boolean;

}
