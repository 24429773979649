
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import {QuizItem} from "@/Booker/lib/BookerTypes";

@Component({name: "QuizBlankOption", components: {CommonInput}})
export default class QuizBlankOption extends Vue {
  @Prop() optionIdx!: number;
  @Prop() question!: QuizItem;


  get options() {
    return this.question.options
  }

  get subAnswers() {
    return this.question.options[this.optionIdx].subAnswers
  }

  @Emit('delOptionEmit')
  delOptionEmit() {
    return this.optionIdx
  }

  @Emit('upOption')
  upOption() {
    return this.optionIdx
  }

  @Emit('downOption')
  downOption() {
    return this.optionIdx
  }

  @Emit('checkSaveSend')
  onInputQuestion(e: Event) {
    this.setText((e.target as HTMLInputElement).value);
  }

  onInputSubAnswer(e: Event) {
    const input = (e.target as HTMLInputElement);

    if (input && typeof input.dataset.index === "string") {
      const subAnswersIndex = parseInt(input.dataset.index);
      this.setSubAnswer(input.value, subAnswersIndex);
    }
  }

  @Emit('setSubAnswer')
  setSubAnswer(text: string, subAnswersIndex: number) {
    return {
      text: text,
      optionIdx: this.optionIdx,
      subAnswersIndex: subAnswersIndex
    }
  }

  @Emit('setText')
  setText(text: string) {
    return {
      text: text,
      optionIdx: this.optionIdx
    }
  }

  @Emit('addSubAnswer')
  addSubAnswer() {
    return this.optionIdx
  }

  @Emit('delSubAnswer')
  delSubAnswer(subAnswersIndex: number) {
    return {answerIndex: this.optionIdx, subAnswersIndex}
  }


}
