
import {Component, Vue} from "vue-property-decorator";
import CommonMainHeader from "@/views/ClassPage/component/CommonMainHeader.vue";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import MyQuizChart from "@/views/ClassPage/component/MyQuizChart.vue";
import {QuizItem} from "@/Booker/lib/BookerTypes";
import RetakeMyQuiz from "@/views/ClassPage/component/RetakeMyQuiz.vue";
import MyContentViewModel from "@/assets/lib/viewModel/MyContentViewModel";
import MyQuizModal from "@/components/ui/quiz/MyQuizModal.vue";

@Component({
  name: "MyQuizPage",

  components: {MyQuizModal, MyQuizChart, RetakeMyQuiz, CommonMainHeader}
})
export default class MyQuizPage extends Vue {
  vm: MyContentViewModel | null = null;
  ClassContentManager = ClassContentManager
  quiz: QuizItem[] = []
  currentContentKey = ''
  retakeStatus = false;
  chartHeaderList = [
    '응시횟수',
    '응시율',
    '퀴즈',
    '연습문제',
    '실전문제',
    '총점',
    ''
  ]

  get userContentList() {
    if (this.vm) return this.vm.aiReportModel.contentList.filter(item => item.isRelease)
    return []
  }

  mounted() {
    this.vm = new MyContentViewModel();
  }

}
