
import {Component, Vue} from 'vue-property-decorator'
import BookMarkToolHeader from '@/Booker/views/components/BookMarkToolHeader.vue'
import BookMarkToolMenu from '@/Booker/views/components/BookMarkToolMenu.vue'
import BookerVideoTimelineEditor from '@/Booker/views/components/video/BookerVideoTimelineEditor.vue'
import '@/assets/style/font.css'
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import CreateQuiz from "@/Booker/views/components/quiz/CreateQuiz.vue";
import ClassContentModel from "@/Booker/lib/model/ClassContentModel";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import {ContentLibraryType, ContentToolStateType} from "@/Booker/lib/BookerTypes";
import BookerVideoEditorLayout from "@/Booker/views/components/video/BookerVideoEditorLayout.vue";
import EditQuiz from "@/Booker/views/components/quiz/EditQuiz.vue";
import CreatePronunciation from "@/Booker/views/components/pronunciation/CreatePronunciation.vue";
import EditPronunciation from "@/Booker/views/components/pronunciation/EditPronunciation.vue";
import CreateScenario from "@/Booker/views/components/scenario/CreateScenario.vue";
import EditScenario from "@/Booker/views/components/scenario/EditScenario.vue";
import CreateTextToSpeech from "@/Booker/views/components/tts/CreateTextToSpeech.vue";
import EditTextToSpeech from "@/Booker/views/components/tts/EditTextToSpeech.vue";
import CommonLoading from "@/components/ui/loading/CommonLoading.vue";
import VideoEditModal from "@/Booker/views/components/video/VideoEditModal.vue";


@Component({
  name: 'BookMarkToolPage',
  computed: {
    ContentToolStateType() {
      return ContentToolStateType
    },
    ContentLibraryType() {
      return ContentLibraryType
    }
  },
  components: {
    VideoEditModal,
    CommonLoading,
    EditScenario,
    CreateScenario,
    CreatePronunciation,
    CreateQuiz,
    EditQuiz,
    BookerVideoEditorLayout,
    BookMarkToolHeader,
    BookMarkToolMenu,
    EditPronunciation,
    BookMarkToolEdit: BookerVideoTimelineEditor,
    CreateTextToSpeech, EditTextToSpeech
  }
})
export default class BookMarkToolPage extends Vue {
  libraryKey: string | null = null;
  viewModel: null | ClassContentMediaListViewModel = null
  timelineViewModel: null | ClassContentPlayerViewModel = null
  model = new ClassContentModel()
  selectedMenu = ContentLibraryType.Media
  state: ContentToolStateType = ContentToolStateType.Default;
  hideMenu = true

  get contentKey() {
    return this.$route.params.contentKey
  }

  created() {
    this.viewModel = new ClassContentMediaListViewModel(this.contentKey);
    this.timelineViewModel = new ClassContentPlayerViewModel(this.contentKey);
    this.timelineViewModel.setBooker();
  }

  beforeDestroy() {
    this.viewModel = null;
    this.timelineViewModel = null;
  }

  selectedMenuItem(selectedMenu: ContentLibraryType) {
    this.selectedMenu = selectedMenu;
    this.setToolState(ContentToolStateType.Default);
  }

  setToolState(state: ContentToolStateType) {
    this.state = state;
  }

  setLibraryKey(key: string) {
    this.libraryKey = key;
  }

  subtitlePosition(type: string) {
    return type;
  }

  setFullScreen(menuStatus: boolean) {
    console.log('menuStatus', menuStatus)
    this.hideMenu = !menuStatus
  }


}
