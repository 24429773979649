import {
  ClassContent,
  ContentItemBase,
  ContentItemBook,
  ContentItemFigure,
  ContentItemPronunciation,
  ContentItemQuiz,
  ContentItemRelaxation,
  ContentItemScenario,
  ContentItemText,
  ContentItemTextToSpeech,
  ContentItemType,
  ContentItemTypes,
  ContentItemVideo,
  ContentLibraryType,
  CreatorTextToSpeech,
  ShapeItemType,
  YoutubeItem
} from "@/Booker/lib/BookerTypes";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import GenieApi from "@/assets/lib/api/GenieApi";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import DocumentApi from "@/assets/lib/api/DocumentApi";
import {AxiosResponse} from "axios/index";
import FileApi from "@/assets/lib/api/FileApi";
import Config from "@/config";

/**
 * Booker 의 미디어 컨텐츠 목록
 * 이미지 컨텐츠와 동영상 컨텐츠형
 */
export default class ClassContentMediaListViewModel {
  relaxList = [];
  content: ClassContent

  constructor(contentKey: string, content?: ClassContent) {
    if (content) {
      this.content = content;
      return;
    }
    const _content = ClassContentManager.controller.model.getAllContent(contentKey)
    if (!_content) throw new Error('contentKey does not exists : ' + contentKey)
    this.content = _content

    // this.createRelaxList()
  }

  /**
   * 컨텐츠 라이브러리 목록 조회
   * @param type
   */
  getContentLibrary(type: ContentLibraryType): ContentItemTypes[] {
    switch (type) {
      case ContentLibraryType.Media:
        return this.content.contentLibrary.filter(cont => cont.type === ContentItemType.Image || cont.type === ContentItemType.Video)
      case ContentLibraryType.Audio:
        return this.content.contentLibrary.filter(cont => cont.type === ContentItemType.Audio)
      case ContentLibraryType.Book:
        return this.content.contentLibrary.filter(cont => cont.type === ContentItemType.Book)
      case ContentLibraryType.Subtitle:
        // todo 자막 미리 정의된 컨텐츠 유형으로 추가
        return this.subtitleList();
      case ContentLibraryType.Quiz:
        return this.content.contentLibrary.filter(cont => cont.type === ContentItemType.Quiz)
      case ContentLibraryType.Pronunciation:
        return this.content.contentLibrary.filter(cont => cont.type === ContentItemType.Pronunciation)
      case ContentLibraryType.Relaxation: {
        return this.content.contentLibrary.filter(cont => cont.type === ContentItemType.Relaxation)
        // todo 휴식 미리 정의된 컨텐츠 유형으로 추가
        //return this.relaxList;
        //return [];
        // const relaxationModel = new RelaxationModel(this.contentKey)
        // return [relaxationModel];
      }
      case ContentLibraryType.TextToSpeech:
        return this.content.contentLibrary.filter(cont => cont.type === ContentItemType.TextToSpeech);
      case ContentLibraryType.Scenario:
        return this.content.contentLibrary.filter(cont => cont.type === ContentItemType.Scenario)
    }
    return []
  }

  /**
   * contentKey 값에 해당되는 컨텐츠 라이브러리 삭제
   * File 객체로 신규로 추가된 library 인 경우 삭제된 컨텐츠의 source 값은 사용 불가
   * @param contentKey
   * @return 삭제된 컨텐츠
   */
  deleteContentLibrary(contentKey: string): null | ContentItemBase {
    try {
      const delLibrary = ClassContentManager.controller.deleteContentLibrary(this.content, contentKey)
      this.save()
      return delLibrary;
    } catch (e) {
      return null;
    }
  }

  /**
   * 미디어 추가 (동영상, 이미지, 오디오)
   * @param file
   */
  async addMediaLibrary(file: File): Promise<ContentItemBase | null> {
    try {
      const saveLibrary = await ClassContentManager.controller.addMediaLibrary(this.content, file);
      this.save()
      if (saveLibrary.type === ContentItemType.Video)
        this.checkHasHls(this.content.key, saveLibrary.key, (saveLibrary as ContentItemVideo).source)
      return saveLibrary;
    } catch (e) {
      console.error(e)
      return null;
    }
  }

  async checkHasHls(contentKey: string, itemKey: string, fileId: string) {
    console.log('checkHasHls')

    const ret = await FileApi.fileInfo({file_id: fileId})
    if (ret && ret.data.file_info.hls_convert_status === 2) {
      const findItemIdx = this.content.contentLibrary.findIndex(c => c.key === itemKey)
      if (findItemIdx > -1) {
        const contentVideo = this.content.contentLibrary[findItemIdx] as ContentItemVideo;
        contentVideo.source = Config.CONFIG.API_HOST + ret.data.file_info.hls_url;
        delete contentVideo.isLoading;
        this.content.contentLibrary.splice(findItemIdx, 1, contentVideo);
        this.save()
      }
    } else {
      setTimeout(() => {
        this.checkHasHls(contentKey, itemKey, fileId)
      }, 3000)
    }
  }

  /**
   * 빈페이지 라이브러리 추가
   * @param title
   * @param color
   */
  addEmptyPageLibrary(title: string, color: string) {
    const contentImg = ClassContentManager.controller.createEmptyPageImageContentItem(title, color)
    ClassContentManager.controller.addEmptyPageLibrary(this.content, contentImg)
    this.save()
  }

  /**
   * 퀴즈 템플릿 생성
   */
  createQuizTemplate(): ContentItemQuiz {
    return ClassContentManager.controller.createQuizTemplate()
  }

  /**
   * 퀴즈 라이브러리 추가
   * @param quiz
   */
  addQuizLibrary(quiz: ContentItemQuiz): ContentItemQuiz {
    return ClassContentManager.controller.addQuizLibrary(this.content, quiz)
  }

  /**
   * 발음연습 컨텐츠 템플릿 생성
   */
  createPronunciationTemplate(): ContentItemPronunciation {
    return ClassContentManager.controller.createPronunciationTemplate()
  }

  /**
   * 발음연습 컨텐츠 라이브러리 추가
   * @param item
   */
  addPronunciationLibrary(item: ContentItemPronunciation): ContentItemPronunciation {
    return ClassContentManager.controller.addPronunciationLibrary(this.content, item)
  }

  /**
   * 텍스트 오버레이 컨텐츠 템플릿 생성
   */
  createTextOverlayTemplate(): ContentItemText {
    return ClassContentManager.controller.createTextOverlayTemplate()
  }

  /**
   * 텍스트 오버레이 컨텐츠 라이브러리 추가
   * @param item
   */
  addTextOverlayLibrary(item: ContentItemText): ContentItemText {
    return ClassContentManager.controller.addTextOverlayLibrary(this.content, item)
  }

  /**
   * 텍스트 오버레이 리스트
   */

  subtitleList(): ContentItemText[] {

    const emptyItemText = this.createTextOverlayTemplate();

    const topBound = {
      left: 50 + '%',
      transform: `translateX(-50%)`,
      top: 10 + '%',
      width: 400 + 'px',
      height: 100 + 'px',
    }

    const middleBound = {
      left: 50 + '%',
      top: 50 + '%',
      transform: `translate(-50%, -50%)`,
      width: 400 + 'px',
      height: 100 + 'px',
    }

    const bottomBound = {
      left: 50 + '%',
      bottom: 5 + '%',
      transform: `translateX(-50%)`,
      width: 400 + 'px',
      height: 100 + 'px',
    }

    const top = {...emptyItemText};
    top.bound = topBound;
    const middle = {...emptyItemText};
    middle.bound = middleBound;
    const bottom = {...emptyItemText};
    bottom.bound = bottomBound;

    return [top, middle, bottom]
  }


  /**
   * 휴식 컨텐츠 템플릿 생성
   */
  createRelaxationTemplate(): ContentItemRelaxation {
    return ClassContentManager.controller.createRelaxationTemplate()
  }

  /**
   * 휴식 추가
   * @param item
   */
  addRelaxationLibrary(): ContentItemBase | null {
    try {
      const saveLibrary = ClassContentManager.controller.addRelaxationLibrary(this.content);
      this.save();
      return saveLibrary;
    } catch (e) {
      return null;
    }
  }


  /**
   * 교재 추가
   * @param file
   */
  async addBookLibrary(file: File): Promise<ContentItemBase | null> {
    try {
      const bookLibrary = await ClassContentManager.controller.addBookLibrary(this.content, file);
      if (bookLibrary) {
        this.content.contentLibrary.push(bookLibrary)
        this.save()
        await this.uploadDoc(file, bookLibrary)
        this.checkDocStatus(bookLibrary)
        return bookLibrary;
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  async uploadDoc(file: File, bookItem: ContentItemBook) {
    return await DocumentApi.uploadFile(file, bookItem.apiInfo.uploadUrl)
  }

  async checkDocStatus(bookItem: ContentItemBook, count = 0) {
    count++;
    if (count > 100) {
      alert('문서변환 실패')
      return;
    }
    const ret = await DocumentApi.checkUploadStatus(bookItem.apiInfo.checkUrl)
    if (ret.data.message === "지원하지 않는 변환 타입") {
      alert('지원하지 않는 문서입니다.');
      this.deleteContentLibrary(bookItem.key)
      return;
    }
    const {totalPage, donePage} = ret.data.result
    if (ret && Number(totalPage) > 0 && totalPage === donePage) {
      const findItemIdx = this.content.contentLibrary.findIndex(c => c.key === bookItem.key)
      if (findItemIdx > -1) {
        const contentBook = this.content.contentLibrary[findItemIdx] as ContentItemBook;
        const pages = BookerUtility.generateNumbers(`${totalPage}`)

        for (let i = 0; i < pages.length; i++) {
          const page = pages[i];
          const blob: AxiosResponse<Blob, Blob> = await DocumentApi.downloadFiles(bookItem.apiInfo.downloadUrl + page);
          const newFile = new File([new Blob([blob.data], {type: 'image/jpeg'})], page)
          const fileApiRes = await FileApi.uploadFile(newFile)
          const fileId = fileApiRes?.file_list[0].file_id
          if (fileId) {
            const contentImg = await ClassContentManager.controller.createImageContentItemByUrl(fileId)
            if (contentImg) contentBook.pages = [...contentBook.pages, contentImg]
          }
        }

        const newContentBook = JSON.parse(JSON.stringify(contentBook))

        this.content.contentLibrary.splice(findItemIdx, 1, newContentBook);
        this.save();
      }
    } else {
      setTimeout(() => {
        this.checkDocStatus(bookItem, count)
      }, 3000)
    }
  }


  /**
   * 시나리오 컨텐츠 템플릿 생성
   */
  createScenarioTemplate(): ContentItemScenario {
    return ClassContentManager.controller.createScenarioTemplate()
  }

  /**
   * 시나리오 추가
   * @param scenario
   */
  addScenarioLibrary(scenario: ContentItemScenario): ContentItemBase | null {
    try {
      const saveLibrary = ClassContentManager.controller.addScenarioLibrary(this.content, scenario);
      this.save()
      return saveLibrary;
    } catch (e) {
      return null;
    }
  }

  /**
   * 유튜브 추가
   * @param youtubeItem
   */
  async addYoutubeMediaLibrary(youtubeItem: YoutubeItem): Promise<ContentItemBase | null> {
    try {
      const saveLibrary = ClassContentManager.controller.addYoutubeMediaLibrary(this.content, youtubeItem);
      this.save()
      return saveLibrary
    } catch (e) {
      return null;
    }
  }

  /**
   * TTS 추가
   * @param textToSpeech
   */

  addTextToSpeechLibrary(textToSpeech: ContentItemTextToSpeech): ContentItemBase | null {
    try {
      const saveLibrary = ClassContentManager.controller.addTextToSpeechLibrary(this.content, textToSpeech);
      this.save()
      return saveLibrary;
    } catch (e) {
      return null;
    }
  }

  addFigureLibrary(type: ShapeItemType): ContentItemFigure | null {

    const shapeWidth = 300
    const shapeHeight = 300

    const figure: ContentItemFigure = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Figure,
      text: '',
      size: 20,
      bound: {
        width: `${shapeWidth}px`,
        height: `${shapeHeight}px`,
        transform: `translate(${shapeWidth / 2}px, ${shapeHeight / 2}px) rotate(0deg) translate(${-shapeWidth / 2}px, ${-shapeHeight / 2}px)`,
        left: `${(1280 - shapeWidth) / 2}px`,
        top: `${(720 - shapeHeight) / 2}px`
      },
      bgColor: '#FA5C3D',
      textColor: '#FFFFFF',
      show: false,
      createTime: Date.now(),
      lineNumber: 1,
      figureType: type
    }

    try {
      const saveLibrary = ClassContentManager.controller.addFigureLibrary(this.content, figure);
      this.save()
      return saveLibrary as ContentItemFigure;
    } catch (e) {
      return null;
    }
  }

  async editTextToSpeechLibrary(contentKey: string, creatorTextToSpeech: CreatorTextToSpeech) {

    const content =
      this.getContentLibrary(ContentLibraryType.TextToSpeech).find(content => content.key === contentKey) as ContentItemTextToSpeech;

    if (!content) return null;

    const file = await GenieApi.textToSpeech({
      text: creatorTextToSpeech.message,
      speaker: creatorTextToSpeech.voiceType
    }).then((res: Blob | null) => {
      if (!res) return null;
      return new File([res], creatorTextToSpeech.title);
    })

    if (!file) return null;

    content.title = creatorTextToSpeech.title;
    content.message = creatorTextToSpeech.message;
    content.voiceType = creatorTextToSpeech.voiceType;
    content.source = URL.createObjectURL(file)

    try {
      const saveLibrary = ClassContentManager.controller.addTextToSpeechLibrary(this.content, content);
      this.save()
      return saveLibrary;
    } catch (e) {
      return null;
    }
  }


  /**
   * TTs 컨텐츠 템플릿 생성
   */
  createTextToSpeechTemplate(): ContentItemTextToSpeech {
    return ClassContentManager.controller.createTextToSpeechTemplate()
  }


  /**
   * 임시저장 - 컨텐츠 저장
   */
  save(isAlert = false) {
    ClassContentManager.controller.model.saveContent(this.content, isAlert)
  }

  /**
   * 아바타 저장
   */
  setAvatar(isAvatar: boolean) {
    return ClassContentManager.controller.setAvatar(this.content, isAvatar)
  }

  async setRelease(isRelease: boolean, isOverWrite: boolean) {
    return await ClassContentManager.controller.setRelease(this.content, isRelease, isOverWrite);
  }

}
