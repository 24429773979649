
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {QuizItem, QuizItemType} from "@/Booker/lib/BookerTypes";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import QuizSelectOption from "@/Booker/views/components/quiz/QuizSelectOption.vue";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";

@Component({
  name: "QuizSelect",
  components: {CommonTextArea, QuizSelectOption, CommonInput, CommonButton}
})
export default class QuizSelect extends Vue {
  @Prop() question!: QuizItem
  @Prop() quizModel!: ContentItemQuizEditorViewModel
  @Prop() difficultyIdx!: number
  @Prop() difficulty!: number
  @Prop() questionIdx!: number

  explainTextStatus = true
  correctTextStatus = true

  BookerUtility = BookerUtility


  @Ref() refQuizImg!: HTMLImageElement

  @Emit('checkSave')
  checkSend() {
    return true;
  }

  @Emit('checkSave')
  checkSaveSend() {
    this.checkSend();
  }

  @Emit('checkSave')
  onInputQuestion(e: Event) {
    this.question.question = (e.target as HTMLInputElement).value;
  }

  @Emit('checkSave')
  delOptionEmit(optionIdx: number) {
    this.delOption(optionIdx)
  }

  @Emit('checkSave')
  addOption() {
    this.quizModel.addOption(this.question, QuizItemType.Select)
  }


  @Emit('checkSave')
  delOption(optionIdx: number) {
    this.quizModel.deleteOption(this.question, optionIdx)
  }

  upOption(optionIdx: number) {
    this.quizModel.moveUpOption(this.question, optionIdx)
  }

  downOption(optionIdx: number) {
    this.quizModel.moveDownOption(this.question, optionIdx)
  }

  showCorrectAnswer() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.correctAnswerMsg;
      if (typeof subQ === "string") {
        this.quizModel?.delCorrectMsg(this.difficultyIdx, this.difficulty)
        this.correctTextStatus = true
      } else {
        this.quizModel?.setCorrectMsg(this.difficultyIdx, this.difficulty, '')
        this.correctTextStatus = false
      }
    }
  }

  showExplain() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.explain;
      if (typeof subQ === "string") {
        this.quizModel?.delExplain(this.difficultyIdx, this.difficulty)
        this.explainTextStatus = true
      } else {
        this.quizModel?.setExplain(this.difficultyIdx, this.difficulty, '')
        this.explainTextStatus = false
      }
    }
  }

  onInputCorrectAnswer(e: Event) {
    this.question.correctAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setCorrectMsg(this.difficultyIdx, this.difficulty, this.question.correctAnswerMsg);
  }

  onInputInCorrectAnswer(e: Event) {
    this.question.inCorrectAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setInCorrectMsg(this.difficultyIdx, this.difficulty, this.question.inCorrectAnswerMsg);
  }

  explainInput(e: Event) {
    this.question.explain = (e.target as HTMLTextAreaElement).value;
    this.quizModel.setExplain(this.difficultyIdx, this.difficulty, this.question.explain);
  }

  subQuestionInput(e: Event) {
    this.question.subQuestion = (e.target as HTMLTextAreaElement).value;
    this.quizModel.setSubQuestion(this.difficultyIdx, this.difficulty, this.question.subQuestion);
  }


}
