import { render, staticRenderFns } from "./QuizSelectType.vue?vue&type=template&id=2588bef3&scoped=true&"
import script from "./QuizSelectType.vue?vue&type=script&lang=ts&"
export * from "./QuizSelectType.vue?vue&type=script&lang=ts&"
import style0 from "./QuizSelectType.vue?vue&type=style&index=0&id=2588bef3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2588bef3",
  null
  
)

export default component.exports