import axios from "axios";
import Api from "@/assets/lib/api/Api";
import {ConvertHlsRequest, EmptyRequest} from "@/assets/lib/type/ApiRequestTypes";
import {
  EmptyResponse,
  FileInfoDetailResponse,
  FileInfoResponse,
  ResponseData
} from "@/assets/lib/type/ApiResponseTypes";
import {AxiosError} from "axios/index";
import Config from "@/config";
import App from "@/assets/lib/controller/App";

export default class FileApi extends Api {
  private static fileAxios = axios.create({
    baseURL: Config.CONFIG.API_HOST + '/api/v1/file'
  })

  static get headerWithToken() {
    return {headers: {Authorization: `Bearer ${App.controller.auth.token}`}}
  }


  /**
   * 컨텐츠 파일 업로드
   * @param data
   * @param onSuc
   * @param onErr
   */
  static downloadFile<Q = EmptyRequest, R = ResponseData<File>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.fileAxios, '/download', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * 컨텐츠 파일 업로드
   * @param data
   * @param onSuc
   * @param onErr
   */
  static downloadThumbnail<Q = EmptyRequest, R = ResponseData<File>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.fileAxios, '/download/thumbnail', data, {}, onSuc, onErr);
  }

  /**
   * 컨텐츠 업 로드
   * @param data
   * @param onSuc
   * @param onErr
   */
  static async uploadFile(data: File): Promise<FileInfoResponse | null> {
    // return this.postRequest<Q, R>(this.fileAxios, '/upload', data, this.headerWithToken, onSuc, onErr);
    const form = new FormData()
    form.append("file", data)
    try {
      const ret = await this.fileAxios.post('/upload', form, this.headerWithToken)
      return ret.data.data
    } catch (err) {
      console.error(err)
    }
    return null;
  }

  /**
   * 컨텐츠 파일 정보 조회
   * @param data
   * @param onSuc
   * @param onErr
   */
  static fileInfo<Q = EmptyRequest, R = ResponseData<FileInfoDetailResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.getRequest<Q, R>(this.fileAxios, '/info', data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * 동영상 컨텐츠 HLS 변환 요청
   * @param data
   * @param onSuc
   * @param onErr
   */
  static convertHls<Q = ConvertHlsRequest, R = ResponseData<EmptyResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.fileAxios, '/convert/hls', data, this.headerWithToken, onSuc, onErr);
  }


}
