
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component({name: "QuizStartHeader", components: {}})
export default class QuizStartHeader extends Vue {
  @Prop() timeLimit !: number | undefined
  @Prop() quizOnSubmit !: boolean
  @Prop() quizAbleSubmit !: boolean

  intervalId: any
  timerMinute = this.timeLimit ? this.timeLimit : 1;
  timerSecond = 0
  timerMilliSecond = 0
  //퀴즈 결과 팝업 열기
  quizResultOpenPopup = false

  get getBtnClassObject() {
    return {
      'able': this.quizAbleSubmit, 'result': this.quizOnSubmit
    }
  }

  mounted() {
    this.timerMinute = this.timeLimit ? this.timeLimit : 1;
    this.intervalId = setInterval(() => {
      if (this.timerMilliSecond === 0) {
        this.timerMilliSecond = 100;
        this.timerSecond--
      }
      if (this.timerSecond < 0) {
        this.timerSecond = 59;
        this.timerMinute--
      }
      this.timerMilliSecond--
    }, 10)
  }

  //제출하기 버튼 활성화
  //quizAbleSubmit = false

  //제출하기 버튼 활성화 이후 퀴즈 제출
  //quizOnSubmit = false

  @Emit('quizSubmitCheck')
  quizSubmitCheck() {
    return
  }

  /**
   * 타이머
   */
  timerTimeLine(min: number, sec: number) {
    if (this.quizOnSubmit || (this.timerMinute === 0 && this.timerSecond === 0 && this.timerMilliSecond === 0)) {
      clearInterval(this.intervalId);
      return 100
    } else {
      if (this.timeLimit) return (sec + min * 60) / (60 * this.timeLimit) - 100
    }
  }

  //타이머 시간 10이하일 때 문자열 0 추가
  TimerBeforePadZero(func: number) {
    if (func < 10) {
      return `0${func}`
    } else {
      return func
    }
  }

  //타이머 텍스트로 변환
  expireTimerText() {
    if (this.timerSecond == 0 && this.timerMinute == 0 && this.timerMilliSecond == 0) {
      clearInterval(this.intervalId);
      this.onSubmitQuiz(true);
      return '시간 종료'
    } else {
      return `${this.TimerBeforePadZero(this.timerMinute)}:${this.TimerBeforePadZero(this.timerSecond)} 남음`
    }
  }

  /**
   * 제출하기 버튼 활성화
   */
  @Emit('btnClassObject')
  btnClassObject() {
    return {
      'able': this.quizAbleSubmit, 'result': this.quizOnSubmit
    }
  }

  @Emit('onSubmitQuiz')
  onSubmitQuiz(isTimeOut = false) {
    return isTimeOut;
  }


  beforeDestroy() {
    clearInterval(this.intervalId)
  }
}
