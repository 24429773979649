
import {Component, Emit, Prop, Ref, Vue, Watch} from "vue-property-decorator";

@Component({
  name: "QuizDifficulty",
  components: {}
})
export default class QuizDifficulty extends Vue {
  @Prop() selectedDifficulty !: number;
  @Prop() isDifficulty !: boolean;
  @Ref() refMiddleDifficulty !: HTMLInputElement;

  @Emit('changeDifficulty')
  onChangeDifficulty(difficulty: number) {
    return difficulty;
  }

  @Emit('onChangeIsDifficulty')
  onChangeIsDifficulty() {
    return !this.isDifficulty
  }

  @Watch('isDifficulty')
  watchChangeIsDifficulty() {
    if (!this.isDifficulty) {
      requestAnimationFrame(() => this.refMiddleDifficulty.click())
    }
  }

}
