
import {Component, Emit, Vue} from "vue-property-decorator";

@Component({name: "ClassStartButton", components: {}})
export default class ClassStartButton extends Vue {
  startButton = true

  startClass() {
    this.startButton = !this.startButton
    this.playClass();
  }

  @Emit('playClass')
  playClass() {
    return null;
  }
}
