
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import CommonLoading from "@/components/ui/loading/CommonLoading.vue";
import {ContentItemVideo} from "@/Booker/lib/BookerTypes";
import Hls from 'hls.js';


@Component({name: "BookerVideoThumbnail", components: {CommonLoading}})
export default class BookerVideoThumbnail extends Vue {

  @Prop() contentVideo!: ContentItemVideo
  hls = new Hls();
  video: HTMLVideoElement | null = null;

  get src() {
    return this.contentVideo.source
  }

  mounted() {
    this.video = document.getElementById(`video-${this.contentVideo.key}`) as HTMLVideoElement;
    if (this.src.indexOf('m3u8') !== -1) {
      this.hls.loadSource(this.src);
      this.hls.attachMedia(this.video);
    }
  }

  @Watch('src')
  initHls() {
    this.hls.loadSource(this.src);
    if (this.video) this.hls.attachMedia(this.video);
  }

}
