import {
  ClassContent,
  ContentItemBase,
  ContentItemImage,
  ContentItemQuiz,
  ContentItemType,
  ContentItemVideo,
  TimelineContent,
  TimelineType,
  UserClassContent,
  UserContent,
  VideoContentSourceType
} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import ContentCacheManager from "@/Booker/lib/util/ContentCacheManager";
import ContentApi from "@/assets/lib/api/ContentApi";
import {ReleaseClassInfoRequest} from "@/assets/lib/type/ApiRequestTypes";
import {UserClassInfoResponseData, UserContentInfoResponseData} from "@/assets/lib/type/ApiResponseTypes";

export interface MyQuizContentItem {
  contentKey: string;
  attemptCount: number;
  isComplete: boolean;
  createdAt: number;
  updatedAt: number;
}

export default class ClassContentModel {
  contentList: ClassContent[]
  releaseClassList: ClassContent[]
  userClassList: UserClassContent[]
  userContentList: UserContent[]
  userContentInfoList: UserContentInfoResponseData[]
  myQuizContentItemList: MyQuizContentItem[]

  activeUserContent: UserClassContent | null = null

  constructor() {
    this.contentList = []
    this.releaseClassList = [];
    this.userClassList = [];
    this.myQuizContentItemList = [];
    this.userContentList = [];
    this.userContentInfoList = [];
    this.init();
  }

  _isInit = false;

  get isInit() {
    return this._isInit
  }

  get userContents() {
    return this.userClassList;
  }

  /**
   * get content thumbnail
   */
  static async getContentThumbnail(content: ClassContent, cache: ContentCacheManager): Promise<null | HTMLImageElement> {
    const firstContent = this.getContentItemForThumbnailKey(content)
    if (!firstContent) return null

    return await this.getContentItemThumbnail(firstContent, cache)
  }

  /**
   * get content item thumbnail
   * @param content
   * @param cache
   */
  static async getContentItemThumbnail(content: ContentItemBase, cache: ContentCacheManager): Promise<null | HTMLImageElement> {
    const source = this.getContentItemThumbnailUrl(content)
    if (!source) return null

    switch (content.type) {
      case ContentItemType.Image:
        return await cache.cacheImage(source)
      case ContentItemType.Video:
        return await cache.cacheVideoThumbnail(source)
      default:
        return null
    }
  }

  /**
   * ClassContent 의 썸네일 주소 조회
   * @param content
   */
  static getContentThumbnailUrl(content: ClassContent): null | string {
    const firstContent = this.getContentItemForThumbnailKey(content)
    if (!firstContent) return null

    return this.getContentItemThumbnailUrl(firstContent)
  }

  /**
   * ContentItem 의 thumbnail 로 사용할 이미지/영상 url
   * @param content
   */
  static getContentItemThumbnailUrl(content: ContentItemBase): null | string {
    switch (content.type) {
      case ContentItemType.Image:
        return (content as ContentItemImage).source
      case ContentItemType.Video:
        if ((content as ContentItemVideo).sourceType === VideoContentSourceType.Youtube) {
          return BookerUtility.getYoutubeThumbnailByUrl((content as ContentItemVideo).source);
        }
        return (content as ContentItemVideo).source
      default:
        return null
    }
  }

  /**
   * ClassContent 에서 썸네일을 뽑기위한 컨텐츠 조회
   * @param content
   * @private
   */
  private static getContentItemForThumbnailKey(content: ClassContent): null | ContentItemBase {
    const findContent = content.contentLibrary.find(c => c.key === content.thumbnailKey)
    if (!findContent) return null
    return findContent
  }

  async init() {
    await ContentApi.classList(null, (res) => {
      const list: ClassContent[] = []
      res.data.contents.forEach((c) => {
        const content = JSON.parse(c.content) as ClassContent
        content.isRelease = c.isRelease
        list.push(content)
      })
      this.contentList = [...list]
      return true
    })

    await ContentApi.releaseClassList(null, (res) => {
      const list: ClassContent[] = []
      res.data.contents.forEach((c) => {
        const content = JSON.parse(c.content) as ClassContent;
        if (list.findIndex((item) => item.key === content.key) === -1) {
          content.isRelease = c.isRelease
          list.push(content)
        }
      })
      this.releaseClassList = [...list]
      return true
    })

    await this.userInit();
    await this.userContentInit();
    this._isInit = true;
  }

  async userInit() {
    return await ContentApi.userClass({}).then(res => {
      if (res?.code === 200) {
        const contents = res.data.contents as UserClassInfoResponseData[];
        const list: UserClassContent[] = [];
        if (contents && contents.length > 0) {
          contents.forEach((_content) => {
            const content = JSON.parse(_content.content) as UserClassContent;
            content.playTime = _content.playTime;
            content.totalTime = _content.totalTime;
            content.isComplete = _content.isComplete;
            content.userClassInfoIdx = _content.userClassInfoIdx;
            content.score = _content.score;
            content.classStep = _content.classStep;
            content.createdAt = _content.createdAt;
            content.updatedAt = _content.updatedAt;
            list.push(content);
          })
          this.userClassList = [...list].sort((a, b) => a.classStep! - b.classStep! > 0 ? 1 : -1);
          return true;
        }
      }
      return false;
    })
  }

  async userContentInit() {
    return await ContentApi.userContent({}).then(res => {
      if (res?.code === 200) {
        const contents = res.data.userContentList as UserContentInfoResponseData[];
        const list: UserContent[] = [];
        contents.forEach((_content) => {
          const _userContent: UserContent = JSON.parse(_content.userContent) as UserContent;
          if (_userContent) list.push(_userContent);
        });
        this.userContentInfoList = contents
        this.userContentList = [...list];
        return true;
      }
      return false;
    })
  }


  /**
   * 기존 컨텐츠 복사
   * @param contentKey
   */
  async copyContent(contentKey: string): Promise<ClassContent | undefined> {

    const oldContent = this.contentList.find(c => c.key === contentKey)
    if (oldContent) {
      const newContent: ClassContent = {
        key: BookerUtility.createKey(),
        title: oldContent.title + '- 복사',
        createTime: Date.now(),
        contentLibrary: [...oldContent.contentLibrary],
        classStep: oldContent.classStep,
        timeline: [
          {type: TimelineType.Video, contents: [...oldContent.timeline[0].contents]},
          {type: TimelineType.Audio, contents: [...oldContent.timeline[1].contents]},
          {type: TimelineType.Scenario, contents: [...oldContent.timeline[2].contents]},
          {type: TimelineType.Activity, contents: [...oldContent.timeline[3].contents]},
          {type: TimelineType.TextOverlay, contents: [...oldContent.timeline[4].contents]},
          {type: TimelineType.TextToSpeech, contents: [...oldContent.timeline[5].contents]},
          {type: TimelineType.Figure, contents: [...oldContent.timeline[6].contents]},
        ]
      }

      if (oldContent.standardList !== undefined)
        newContent.standardList = oldContent.standardList

      if (oldContent.thumbnailUrl !== undefined)
        newContent.thumbnailUrl = oldContent.thumbnailUrl

      if (oldContent.isAvatar !== undefined)
        newContent.isAvatar = oldContent.isAvatar


      this.contentList.splice(0, 0, newContent)

      ContentApi.addClass({
        key: newContent.key,
        title: newContent.title,
        content: JSON.stringify(newContent)
      }).then(res => {
        if (res?.code === 200) {
          this.init()
        }
      })

      return newContent
    }

    return undefined;

  }

  updateThumbnailKey(content: ClassContent, thumbnailKey: string) {
    content.thumbnailKey = thumbnailKey;
    const contentIndex = this.contentList.findIndex(c => c.key === content.key)
    if (contentIndex >= 0) {
      this.contentList.splice(contentIndex, 1, content)
      return true
    }
    return false
  }


  clearTimeLine(content: ClassContent) {
    content.timeline.forEach(t => {
      t.contents = []
    })
  }

  /**
   * Add Content
   */
  async addContent(content: ClassContent): Promise<boolean> {
    return ContentApi.addClass({
      key: content.key,
      title: content.title,
      content: JSON.stringify(content)
    }).then(async (res) => {
      if (res?.code === 200) {
        await this.init()
        return true
      }
      return false
    }).catch(err => {
      console.log(err)
      return false
    })
  }

  /**
   * Save Content
   */
  async saveContent(content: ClassContent, isAlert = false) {
    if (content.isRelease) {
      alert('내보내기된 수업은 수정할 수 없습니다.')
      await this.init()
      return;
    }

    await this.updateClass(content, isAlert)
  }

  /**
   * Release Content
   */
  async releaseContent(content: ClassContent, isRelease: boolean, isOverWrite: boolean) {

    content.isRelease = isRelease;

    const data: ReleaseClassInfoRequest = {
      key: content.key,
      content: JSON.stringify(content),
      isRelease: isRelease,
      classStep: content.classStep!,
      isOverWrite: isOverWrite
    }

    return ContentApi.releaseClass(data).then(async (res) => {
      if (res?.code === 200) {
        await this.init()
        return true
      }
      content.isRelease = false;
      await this.init()
      return false
    }).catch(async (err) => {
      console.log('err', err)
      content.isRelease = false;
      await this.init()
      return false
    })
  }

  /**
   * 컨텐츠 삭제
   * @param contentKey
   */
  async deleteContent(contentKey: string): Promise<boolean> {
    const content = this.getContent(contentKey);
    if (!content) return false;
    if (content.isRelease) return false;

    const contentIndex = this.contentList.findIndex(c => c.key === contentKey)
    if (contentIndex >= 0) {
      return ContentApi.delClass({
        key: contentKey
      }).then(async (res) => {
        if (res?.code === 200) console.log('삭제완료');
        await this.init();
        return true
      }).catch(err => {
        console.log('err', err)
        return false
      })
    }
    return false
  }

  /**
   * key 로 컨텐츠 조회
   * @param contentKey
   */
  getContent(contentKey: string): null | ClassContent {
    return this.contentList.find(cont => cont.key === contentKey) || null
  }

  /**
   * key 로 컨텐츠 조회
   * @param contentKey
   */
  getReleaseContent(contentKey: string): null | ClassContent {
    return this.releaseClassList.find(cont => cont.key === contentKey) || null
  }

  /**
   * key 로 컨텐츠 조회
   * @param contentKey
   */
  getUserContent(contentKey: string): null | UserClassContent {
    return this.userClassList.find(cont => cont.key === contentKey) || null
  }


  /**
   * key 로 컨텐츠 조회
   * @param contentKey
   */
  getAllContent(contentKey: string): null | ClassContent {
    const content = this.getContent(contentKey)
    if (content) return content
    const releaseContent = this.getReleaseContent(contentKey)
    if (releaseContent) return releaseContent
    return this.getUserContent(contentKey)
  }

  async userClassAdd(contentKey: string) {
    const content = this.getAllContent(contentKey);
    if (content) {
      const videos = content.timeline[0];

      videos.contents.sort((a, b) => a.end! < b.end! ? 1 : -1)

      return await ContentApi.userClassAdd({
        content_key: content.key,
        class_step: content.classStep,
        total_time: Math.round(videos.contents[0].end!),
        user_content: JSON.stringify(content),
      }).then(res => { return true})
    }

    return false
  }

  userClassUpdate(content: UserClassContent | null) {
    const _content = content ? content : this.activeUserContent;
    if (_content) {
      if (_content.userClassInfoIdx === 0) return;

      const videos = _content.timeline[0];

      videos.contents.sort((a, b) => a.end! < b.end! ? 1 : -1)

      const score = BookerUtility.getScoreInQuizList(BookerUtility.getQuizListInContent(_content))

      return ContentApi.userClassUpdate({
        user_class_info_idx: _content.userClassInfoIdx,
        content_key: _content.key,
        play_time: _content.playTime,
        total_time: Math.round(videos.contents[0].end!),
        is_complete: _content.isComplete,
        score: Math.round(score[0] + score[1] + score[2] + score[3]),
        user_content: JSON.stringify(_content),
      })

    }
  }

  changeUserClass(content: UserClassContent) {
    const contentIndex = this.userClassList.findIndex(c => c.key === content.key)
    if (contentIndex >= 0) {
      this.userClassList.splice(contentIndex, 1, content);
      this.userClassUpdate(content);
      return true
    }
  }

  updateActiveContentInTimeline(timelineContent: TimelineContent) {
    if (!this.activeUserContent) return;
    const timeline = this.activeUserContent.timeline[BookerUtility.getTimelineTypeFromContentItemType(timelineContent.content.type)]
    const contentIndex = timeline.contents.findIndex(c => c.key === timelineContent.content.key)
    if (contentIndex >= 0) {
      timeline.contents.splice(contentIndex, 1, timelineContent);
      this.userClassUpdate(null);
    }
  }

  async addUserContent(
    userContent: UserContent,
    classStep: number,
    isComplete: boolean,
    evaluationKey: string,
  ) {
    return ContentApi.userContentAdd({
      content_key: userContent.contentKey,
      key: userContent.key,
      class_step: classStep,
      content_type: userContent.type,
      is_complete: isComplete,
      evaluation_key: evaluationKey,
      user_content: JSON.stringify(userContent)
    }).then(async (res) => {
      if (res?.code === 200) {
        await this.userContentInit()
        return true;
      }
      return false
    })
  }

  async updateUserContent(
    userContent: UserContent
  ) {
    return ContentApi.userContentUpdate({
      is_complete: userContent.isComplete,
      key: userContent.key,
      user_content: JSON.stringify(userContent)
    }).then(async (res) => {
      if (res?.code === 200) {
        await this.userContentInit()
        return true;
      }
      return false
    })
  }

  findQuizByQuizKey(contentKey: string, quizKey: string): ContentItemQuiz | undefined {
    let quiz = undefined;
    this.contentList.forEach(content => {
      if (content.key === contentKey)
        quiz = content.contentLibrary.find(contentItem => quizKey === contentItem.key);
    })
    return quiz;
  }

  private async updateClass(content: ClassContent, isAlert = false) {
    if (JSON.stringify(content).length > 85000) {
      alert('컨텐츠가 너무 큽니다. 저장이 불가능합니다. 도형이나 자막 사용을 줄여주세요.');
      return;
    }
    ContentApi.updateClass({
      key: content.key,
      title: content.title,
      content: JSON.stringify(content)
    }).then(async (res) => {
      if (res?.code === 200) {
        if (isAlert) alert('저장완료');
        await this.init()
      }
    })
  }


}
