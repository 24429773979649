
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import IncorrectNoteChart from "@/views/ClassPage/component/IncorrectNoteChart.vue";
import IncorrectQuestion from "@/views/ClassPage/component/IncorrectQuestion.vue";

@Component({name: "IncorrectNoteItem", components: {IncorrectQuestion, IncorrectNoteChart}})
export default class IncorrectNoteItem extends Vue {
  @Prop() aiModel!: AiReportViewModel;
  @Ref() refChart!: HTMLDivElement;
  hideChart = false

  get quizScoreList() {
    return this.aiModel.quizScoreList;
  }

  get quizScoreDetail() {
    return this.aiModel.quizScoreDetail;
  }


  toggleSelection(classStep: number) {
    if (this.aiModel.classStepQuiz === classStep) {
      this.aiModel.changeClassStepQuiz(-1);
      this.hideChart = false;
    } else {
      this.aiModel.changeClassStepQuiz(classStep);
      this.hideChart = true;
      const scoreCardTop = this.refChart.getBoundingClientRect().y;
      window.scrollTo({
        top: scoreCardTop + window.pageYOffset - 65,
        behavior: 'smooth',
      });
    }
  }
}
