import Logger from "@/assets/lib/debug/Logger";

export default class STTService {
  private static readonly logger = new Logger('[STTService]');
  private static stt: any = null;
  private static sttResultHandler?: (message: null | string) => void;

  private static sttStarted = false;

  public static get isStarted() {
    return this.sttStarted;
  }

  static startSTT(onSTTResult?: (message: null | string) => void) {
    this.sttResultHandler = onSTTResult;

    if (this.sttStarted) {
      this.logger.warn('STT already started');
      return;
    }

    this.sttStarted = this.initGoogleStt();
  }

  static stopSTT() {
    this.sttStarted = false;

    if (this.stt) this.stt.stop();
    this.stt = null;
  }

  private static initGoogleStt(): boolean {
    // 소리나는 부분 확인 필요
    // TODO : iOS 에서 STT 사용하면 audio device 가 muted 상태가 되는 문제가 있음, 확인
    // if (DeviceInfo.isIos) return; // iOS 에서 STT 사용하면 audio device 가 muted 상태가 되는 문제가 있음

    // @ts-ignore
    if (!window.webkitSpeechRecognition) {
      this.logger.error("speech recognition is not supported");
      alert("speech recognition is not supported");
      this.stopSTT()
      this.sttResultHandler?.(null);
      return false;
    }

    // @ts-ignore
    this.stt = new window.webkitSpeechRecognition();
    // @ts-ignore
    this.stt.continuous = true;
    this.stt.interimResults = true;
    this.stt.onerror = (e: any) => {
      // this.logger.error('error', e);
      if (e.error === 'aborted') { // 다른 브라우저에서 사용 등으로 중단됨 상태
        this.stopSTT();
        this.sttResultHandler?.(null);
      }
    };
    // @ts-ignore
    // this.stt.onend = () => {
    //   // TODO 필요한처리
    //   this.stopSTT();
    //   this.sttResultHandler?.(null);
    // }

    this.stt.onresult = (e) => {
      this.logger.log('STT result', e);

      // @ts-ignore
      const message = [].map.call(e.results, (r) => r[0].transcript).join("\n");
      this.sttResultHandler?.(message);
    };
    // stt.onerror = (e) => console.log(e);
    // stt.continuous = true;
    // stt.interimResults = true;
    // @ts-ignore
    this.stt.lang = "ko-KR";
    // @ts-ignore
    this.stt.start();
    this.logger.log('google STT started');
    return true
  }
}
