export default class Rex {

  static readonly htmlReg = /<[^>]*>?/g
  /*
  * 특수문자 정규식
  *  **/
  static readonly reg = /[{}[\]/?.,;:|)*~`!^\\-_+<>@#$%&=('"]/gi;

  public static removeHtml(text: string) {
    return text.replace(this.htmlReg, '');
  }

  public static onlyText(text: string) {
    return text.replaceAll(this.reg, '').replaceAll(/ /g, "");
  }

}
