import {ClassContent, TimelineType} from "@/Booker/lib/BookerTypes";
import VariableChangeListener from "@/assets/lib/utility/VariableChangeListener";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";

export type SeekbarStatus = 'paused' | 'playing' | 'buffering' | 'end'

export interface PlayingState {
  currentTime: number
  status: SeekbarStatus
}

export default class ContentSeekbarController {
  status: SeekbarStatus = 'paused'
  playingStateChangeEventListener: VariableChangeListener<PlayingState> = new VariableChangeListener<PlayingState>()
  private readonly content: ClassContent
  private playStartedCurrentTime = 0
  private playStartedTime = 0

  constructor(content: ClassContent) {
    this.content = content;
    if (ClassContentManager.controller.model.activeUserContent)
      this._currentTime = ClassContentManager.controller.model.activeUserContent.playTime
  }

  private _currentTime = 0

  /**
   * 현재 플레이 중인 시간 조회
   */
  get currentTime() {
    return this._currentTime
  }

  /**
   * 외부에서 seek 할 경우 사용
   * @param val
   */
  set currentTime(val: number) {
    this._currentTime = val
    if (this.status === 'playing') {
      this.playStartedCurrentTime = this._currentTime
      this.playStartedTime = Date.now()
    }
    this.playingStateChangeEventListener.notifyListeners({currentTime: this._currentTime, status: this.status})
  }

  /**
   * 플레이어 종료 여부
   */
  get isEnd() {
    return this.status === 'end';
  }

  /**
   * 현재 플레이 중인지 조회
   */
  get isPlaying() {
    return this.status === 'playing'
  }

  /**
   * length in milliseconds
   */
  get length() {
    // TODO 많이 사용될 경우 최적화 필요
    const videoTimeline = this.content.timeline.find(tl => tl.type === TimelineType.Video)
    if (!videoTimeline) return 0
    return videoTimeline.contents.reduce((prev, cur) => prev + ((cur.end || cur.start) - cur.start), 0)
  }

  /**
   * 플레이 시작
   */
  play() {
    if (this.status === 'playing') return
    this.status = 'playing'
    this.playStartedCurrentTime = this._currentTime
    this.playStartedTime = Date.now()
    this.render()
    this.playingStateChangeEventListener.notifyListeners({currentTime: this._currentTime, status: this.status})
  }

  /**
   * 중지
   */
  pause() {
    if (this.status === 'paused') return
    this.status = 'paused'
    this.playingStateChangeEventListener.notifyListeners({currentTime: this._currentTime, status: this.status})
  }

  buffering() {
    this.status = 'buffering'
  }

  setCurrenTime(time: number) {
    this.currentTime = time
  }

  private render() {
    if (this.status !== 'playing') return
    if (this.length < this._currentTime) {
      this.pause();
      this.status = 'end';
      if (ClassContentManager.controller.model.activeUserContent)
        ClassContentManager.controller.model.activeUserContent.isComplete = true;
    }
    
    setTimeout(() => {
      if (this.status !== 'playing') return
      const now = Date.now()
      const elapsed = now - this.playStartedTime
      this._currentTime = this.playStartedCurrentTime + elapsed
      this.playingStateChangeEventListener.notifyListeners({currentTime: this._currentTime, status: this.status})
      if (ClassContentManager.controller.model.activeUserContent)
        ClassContentManager.controller.model.activeUserContent.playTime = this.currentTime
      this.render()
    }, 100)
  }


}
