
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
    YT: {
      Player: any;
    }
  }
}

export enum PlayerState {
  BeFore = 1,
  End = 0,
  Playing = 1,
  Pause = 2,
  Buffering = 3,
  CUED = 5,
}

@Component({
  name: "YouTubePlayer",
  computed: {},
  components: {}
})
export default class YouTubePlayer extends Vue {

  // player.getPlayerState()
  // -1 –시작되지 않음
  // 0 – 종료
  // 1 – 재생 중
  // 2 – 일시중지
  // 3 – 버퍼링
  // 5 – 동영상 신호
  @Prop() currentTime!: number
  @Prop() isPlaying!: boolean
  @Prop() isPreview?: boolean
  @Prop() videoId!: string
  @Prop() startAt?: number // in ms
  @Prop() endAt?: number // in ms
  @Prop() volume!: number;

  key = BookerUtility.createKey() as string;
  isLoad = false;
  isLoading = false;
  player: any | null = null;
  playerState: PlayerState = PlayerState.BeFore;
  onYouTubeIframeAPIReady: any;
  isBuffering = false;

  get nowTime() {
    if (this.startAt) return this.currentTime + this.startAt
    return this.currentTime
  }

  created() {
    let script = document.querySelector('script[src="https://www.youtube.com/iframe_api"]')

    if (script) {
      this.player = this.createTyPlayer();
      return;
    }
  }

  mounted() {
    this.initYoutube();
    console.log('asdfasdf')
  }

  initYoutube() {
    console.log('initYoutube')
    if (this.isLoading) return;
    let script = document.querySelector('script[src="https://www.youtube.com/iframe_api"]')

    if (script) {
      this.player = this.createTyPlayer();
      if (this.player === null) {
        this.isLoading = true;
        setTimeout(() => {
          this.initYoutube()
        }, 1000)
      }
      return;
    }

    const youtubeScript = (document.createElement('script') as HTMLScriptElement);
    if (youtubeScript instanceof HTMLScriptElement) {
      youtubeScript.src = "https://www.youtube.com/iframe_api";
      document.head.append(youtubeScript)
      youtubeScript.onload = () => {
        // YouTube IFrame API 로드 후에 onYouTubeIframeAPIReady 함수를 호출합니다.
        this.player = this.createTyPlayer();
      }
    }
  }

  createTyPlayer() {
    if (this.player) return null;
    try {
      console.log('this.videoId', this.videoId)
      if (window.YT) {
        const player = new window.YT.Player(`player-${this.key}`, {
          width: 100 + '%',
          height: 100 + '%',
          videoId: this.videoId,
          events: {
            'onReady': this.onPlayerReady,
            'onStateChange': this.onStateChange,
          },
          playerVars: {
            showinfo: 0,
            ecver: 2,
            modestbranding: 1,
            playsinline: 1,
            controls: 0,
            disablekb: 1,
            rel: 0,
            iv_load_policy: 3,
            cc_load_policy: 1,
          }

        });
        return player
      }
      return null;
    } catch (e) {
      setTimeout(() => {
        this.createTyPlayer();
      }, 1000)
    }
  }

  onStateChange(event: any) {
    console.log('onStateChange event', event)
    this.playerState = event.data;
    if (this.playerState === PlayerState.Buffering) this.onBuffering()
    if (this.playerState === PlayerState.BeFore) this.onBuffering()
    if (this.playerState === PlayerState.Pause) this.onPause()
    if (this.playerState === PlayerState.CUED) this.onBuffering()
    if (this.playerState === PlayerState.Playing) this.onPlay()
  }


  @Emit('onPlay')
  onPlay() {
    if (!this.isLoad) return null;
    if (this.isPreview && this.player) this.player.pauseVideo();
    if (!this.isPlaying && this.player) this.player.pauseVideo();
    // Update the playback status when the video starts playing
    return null;
  }

  @Emit('onBuffering')
  onBuffering() {
    this.isBuffering = true;
    if (this.isPreview) this.play();
    return null;
  }

  @Emit('onPause')
  onPause() {
    return null;
  }

  onPlayerReady(event: any) {
    //
    console.log('onPlayerReady')
    this.isLoad = true;

    this.play();

    if (this.isPreview) {
      this.play();
      setTimeout(() => {
        this.goTime(this.nowTime / 1000);
        this.player.pauseVideo();
      }, 300)
    }

  }

  /**
   * play or stop when isPlaying is changed
   */
  @Watch('isPlaying')
  onIsPlayingChanged() {
    this.playOrPause()
  }

  @Watch('volume')
  onChangeVolume() {
    if (this.player && this.player.getVolume && this.volume !== Number(this.player.getVolume().toString())) {
      if (this.volume === 0) {
        this.player.setVolume(0)
      } else {
        this.player.setVolume(this.volume)
      }
    }
  }

  checkUpdateTime() {
    if (!this.isLoad) return false;
    if (this.player) {

      const timeValue = Math.abs(this.player.playerInfo.currentTime - this.nowTime / 1000);
      if (this.playerState !== PlayerState.Playing) return timeValue > 0.3
      return timeValue > 1
    }
    return false
  }

  @Watch('currentTime')
  onCurrentTimeChanged() {
    // update currentTime when the time diff is bigger than 30ms
    if (!this.player) {
      this.player = this.createTyPlayer();
      return;
    }
    if (!this.isLoad && this.isLoading === false) {
      this.player = this.initYoutube();
      return;
    }
    if (this.checkUpdateTime()) {
      // check boundary
      if (this.nowTime <= (this.startAt ? this.startAt / 1000 : 0)) {
        this.goTime((this.startAt ? this.startAt / 1000 : 0))
      } else {
        this.goTime(this.nowTime / 1000)
      }

      this.playOrPause()
    }
  }

  play() {
    if (!this.isLoad) return null;
    if (this.playerState !== PlayerState.End && this.player) this.player.playVideo();
  }

  /**
   * set YouTube Player time
   * @param time YouTube player time 1sec : 1
   */
  goTime(time: number) {
    if (!this.isLoad) return null;
    if (Object.keys(this.player).indexOf('seekTo') > -1) {
      if (this.playerState !== PlayerState.End) this.player.seekTo(time)
    }
  }

  beforeDestroy() {
    if (this.player) this.player.destroy();
  }

  /**
   * play video or pause using isPlaying flag and currentTime
   */
  private async playOrPause() {
    if (!this.isLoad) return null;
    if (this.isPreview) return;
    if (this.playerState === PlayerState.Buffering) return;

    if (this.isPlaying && this.nowTime >= 0) {
      // play if video is not playing state
      if (this.playerState !== PlayerState.Playing) {
        await this.play()
      }
    }
    if (this.player && !this.isPlaying) this.player.pauseVideo()
  }

}
