
import {Component, Prop, Emit, ProvideReactive, Ref, Vue} from "vue-property-decorator";
import {ContentItemPronunciation, PronunciationSentence} from "@/Booker/lib/BookerTypes";

@Component({ name: "PronunciationItem",  components: {}})
export default class PronunciationItem extends Vue {

  @Prop() sentence!: PronunciationSentence;
  @Prop() index!: number;
  @Prop() length!: number;

  @Emit('selectItem')
  selectItem(){
    return this.index;
  }

  @Emit('delSentence')
  delSentence(){
    return this.index;
  }

  getTime(){
    const time = this.sentence.duration / 1000
    const sec = time % 60;
    const min = Math.floor(time / 60);

    return `${min < 10 ? '0'+min : min}:${sec < 10 ? '0'+sec : sec}`
  }





}
