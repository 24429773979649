import axios, {AxiosError} from "axios";
import {OAuthLoginResponse, UserInfoResponse} from "@/assets/lib/type/ApiResponseTypes";
import {EmptyRequest, OAuthLoginRequest} from "@/assets/lib/type/ApiRequestTypes";
import App from "@/assets/lib/controller/App";
import Config from "@/config";
import Api from "@/assets/lib/api/Api";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import {YoutubeItem} from "@/Booker/lib/BookerTypes"

// import axios library

export interface ResponseData<T> {
  code: number;
  message: string;
  data: T;
}

export default class YoutubeApi extends Api {

  private static youtubeAxios = axios.create({
    baseURL: Config.CONFIG.YOUTUBE_HOST + '/youtube/v3'
  })
  private static userAxios = axios.create({
    baseURL: Config.CONFIG.GENIE_HOST + '/api'
  })


  /**
   * request user info
   * @param token
   * @param data
   * @param onSuc
   * @param onErr
   */
  static userInfo<Q = EmptyRequest, R = ResponseData<UserInfoResponse>>(token: string, data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    if (!token) token = App.controller.auth.session.token?.access_token || ''
    if (!token) throw new Error('no token')
    return this.getRequest<Q, R>(this.youtubeAxios, '/v1/user/info', data, {headers: {Authorization: `Bearer ${token}`}}, onSuc, onErr);
  }

  // import axios library

  /**
   * 유튜브 request
   */
  static oAuthLogin<Q = OAuthLoginRequest, R = ResponseData<OAuthLoginResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.userAxios, '/v1/oauth/login', data, null, onSuc, onErr)
  }

  /**
   * 유튜브 Duration
   * @param duration
   */
  static getDurationConvertToTime(duration: string) {
    const regex = /[^0-9]/g;
    const timeList = duration.match(regex);
    if (!timeList) return 0;

    let hour = 0;
    let min = 0;
    let sec = 0;
    if (timeList.indexOf('H') !== -1) {
      hour = Number(duration.split('H')[0].split('PT')[1])
    }
    if (timeList.indexOf('M') !== -1) {
      min = Number(duration.split('M')[0].split('PT')[1])
    }
    if (timeList.indexOf('S') !== -1) {
      if (timeList.indexOf('M') !== -1)
        sec = Number(duration.split('S')[0].split('M')[1]);
      else if (timeList.indexOf('H') !== -1 && timeList.indexOf('M') === -1)
        sec = Number(duration.split('S')[0].split('H')[1]);
      else
        sec = Number(duration.split('S')[0].split('PT')[1]);
    }

    return hour * 60 * 60 + min * 60 + sec;
  }

  static removeNonUTF8Characters(input: string): string {
    // UTF-8 문자 범위 이외의 문자를 공백으로 대체
    // eslint-disable-next-line no-control-regex
    return (input.match(/[가-힣a-zA-Z0-9]+/g) || []).join('');
  }

  // define your YouTube Data API key
  static async setYoutubeAPI(youtubeUrl: string) {
    const apiKey = Config.CONFIG.YOUTUBE_KEY;
    const youtubeId = BookerUtility.getYoutubeDataByYoutubeAPI(youtubeUrl) || ''
    const thumbnail = BookerUtility.getYoutubeThumbnailById(youtubeId);
    const url = `https://www.youtube.com/embed/${youtubeId}`;

    const durationPromise = this.getRequest(this.youtubeAxios, `/videos?id=${youtubeId}&key=${apiKey}&part=contentDetails`, null, {}).then(res => {
      // retrieve video duration from response data
      // @ts-ignore
      const duration = res.items[0].contentDetails.duration;
      return this.getDurationConvertToTime(duration);
    });

    const snippetPromise = this.getRequest(this.youtubeAxios, `/videos?id=${youtubeId}&key=${apiKey}&part=snippet`, null, {}).then(res => {
      // retrieve video duration from response data
      // @ts-ignore
      const snippet = res.items[0].snippet;
      const title = snippet ? YoutubeApi.removeNonUTF8Characters(snippet.title) : '테스트 텍스트';

      return title;
    });

    const [youtubeDuration, youtube] = await Promise.all([durationPromise, snippetPromise]);

    if (youtubeDuration === 0) return null;

    const youtubeAll: YoutubeItem = {
      title: youtube,
      url: url,
      thumbnail: thumbnail,
      id: youtubeId,
      duration: youtubeDuration
    }

    console.log('youtubeAll', youtubeAll)

    return youtubeAll;
  }

}
