
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import CommonBookerModal from "@/Booker/views/components/common/CommonBookerModal.vue";

@Component({ name: "ScenarioGuideModal",  components: {CommonBookerModal}})
export default class ScenarioGuideModal extends Vue {
  @Prop()modalStatus!: boolean
  @Prop()modalTitle!: string

  @Emit('closeModal')
  closeModal() {
    return;
  }

  @Emit('timelineContentTimeChange')
  timelineContentTimeChange() {
    return;
  }

  exAll = [
    {title: '챗봇 메시지 예시', src : require('@/assets/images/bookmark/bookMarkTool/interactive-ex01.png')},
    {title: '교재 추천 예시', src: require('@/assets/images/bookmark/bookMarkTool/interactive-ex02.png')},
    {title: '퀴즈 예시', src: require('@/assets/images/bookmark/bookMarkTool/interactive-ex03.png')},
    {title: '말하기 실습 예시', src: require('@/assets/images/bookmark/bookMarkTool/interactive-ex04.png')},
  ]
}
