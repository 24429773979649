

// setSinkId 없다고 출력되어 추가
// eslint-disable-next-line @typescript-eslint/no-namespace
import {Device, DeviceDetail} from "@/assets/lib/type/Types";
import {Component, Emit, Ref, Vue} from 'vue-property-decorator';
import VirtualBackground from "@/assets/lib/media/VirtualBackground";
import SoundMeter from "@/assets/lib/media/SoundMeter";
import VirtualBackgroundFactory from "@/assets/lib/media/VirtualBackgroundFactory";
import ClassContentModel from "@/Booker/lib/model/ClassContentModel"
import CommonMainHeader from "@/views/ClassPage/component/CommonMainHeader.vue";
import DeviceUtil from "@/assets/lib/utility/DeviceUtil";

//TODO: safari에서는 스피커 목록이 출력 안됨.
declare global {
  interface HTMLAudioElement {
    setSinkId: any;
  }
}

@Component({
  components: {CommonMainHeader}
})
export default class PocEdit extends Vue {
  stream: null | MediaStream = null;
  audioMap: Map<string, MediaDeviceInfo> = new Map();
  videoMap: Map<string, MediaDeviceInfo> = new Map();
  selectedAudio: DeviceDetail = {id: '0', label: '스피커 미 선택', info: null};
  selectedMic: DeviceDetail = {id: '0', label: '마이크 미 선택', info: null};
  selectedCam: DeviceDetail = {id: '0', label: '카메라 미 선택', info: null};
  selectedFilter = 0;
  filters = VirtualBackground.backgroundImages;
  micStatus = true;
  speakerStatus = true
  hideFilter = false;
  hideMic = false;
  hideSpeaker = false
  speakerKinds: Device[] = []
  camStatus = true
  camLoading = false
  filterStatus = true
  virtualBackground: VirtualBackground = VirtualBackgroundFactory.virtualBackground
  soundMeter: null | SoundMeter = null;
  micKinds: Device[] = []
  soundBars: Element[] = [];
  inputFilter: string | null = VirtualBackground.backgroundImages[0].name
  defaultCam: string | null = 'FaceTime HD Camera';
  camKinds = [
    {title: 'FaceTime HD Camera', idx: "0"},
    {title: 'Camera2', idx: "1"},
  ]
  soundInterval: number | null | undefined = null;
  // 초기화 클릭시
  camToggleDefault = true;
  micToggleDefault = true;
  speakerToggleDefault = true;
  oldStream: MediaStream[] = [];
  model = new ClassContentModel()

  @Ref() refVideo!: HTMLVideoElement;
  @Ref() refSampleAudio!: HTMLAudioElement;
  DeviceUtil = DeviceUtil;

  // 카메라 기능 구현
  camListHide = false
  //Z-index
  zIndexDefault = 10


  mounted() {

    this.selectedMic = DeviceUtil.selectedMic;
    this.selectedAudio = DeviceUtil.selectedAudio;
    this.selectedCam = DeviceUtil.selectedCam;
    this.selectedFilter = DeviceUtil.selectedFilter;
    if (this.selectedFilter >= 0)
      this.inputFilter = VirtualBackground.backgroundImages[this.selectedFilter].name

    this.updateDeviceList();

    this.getMedia();
    this.soundBars = [...document.querySelectorAll('div.sound-bar').values()]

    navigator.mediaDevices.ondevicechange = () => this.updateDeviceList()
  }

  beforeDestroy() {
    this.removeTracks();
  }

  removeTracks() {
    navigator.mediaDevices.ondevicechange = () => null;
    this.stream?.getTracks().forEach((t) => t.stop());
    this.stream = null;
    this.refVideo.srcObject = null;
    this.virtualBackground.destroyed()
    if (this.soundMeter) this.soundMeter?.stop();

    if (this.oldStream.length > 0) {
      for (const stream of this.oldStream) {
        stream.getTracks().forEach(t => t.stop())
      }
    }
  }

  @Emit()
  updateDeviceList() {
    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        this.audioMap = new Map();
        this.videoMap = new Map();

        devices.forEach((device) => {
          if (device.kind === "videoinput") {
            this.videoMap.set(device.deviceId, device);
          } else {
            this.audioMap.set(device.deviceId, device);
          }
        });

        this.updateSettingList();
        // 바뀐 디바이스 정보 체크 및 변경
        this.checkDeviceList()
      });
  }

  updateSettingList() {
    const speakerList: Device[] = [];
    const micList: Device[] = [];
    const camList: Device[] = [];

    let isSafari = false;

    const agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf('safari') > -1 && agent.indexOf('chrome') === -1 && agent.indexOf('crios') === -1) {
      isSafari = true;
      speakerList.push({
        title: '사파리는 스피커목록을 지원하지 않습니다.',
        idx: "0"
      })
      this.selectedAudio = {
        label: '사파리는 스피커목록을 지원하지 않습니다.',
        id: "0",
        info: null
      }
    }

    this.audioMap.forEach((audio) => {
      if (audio.kind === "audioinput")
        micList.push({
          title: audio.label,
          idx: audio.deviceId
        })
      else {
        if (!isSafari) speakerList.push({
          title: audio.label,
          idx: audio.deviceId
        })
      }

    })

    this.videoMap.forEach((video) => {
      camList.push({
        title: video.label,
        idx: video.deviceId
      })
    })
    this.micKinds = micList;
    this.speakerKinds = speakerList;
    this.camKinds = camList;
  }

  checkDeviceList() {
    //마이크
    if (!this.audioMap.has(this.selectedMic.id)) {
      const list = [...this.audioMap.values()];
      const inputList = list.filter(item => item.kind === "audioinput")
      if (inputList.length > 0)
        this.selectedMic = {id: inputList[0].deviceId, label: inputList[0].label, info: inputList[0]}
    }

    console.log('this.selectedAudio', this.selectedAudio.id)
    //스피커
    if (!this.audioMap.has(this.selectedAudio.id)) {
      const list = [...this.audioMap.values()];
      const outputList = list.filter(item => item.kind === "audiooutput")
      if (outputList.length > 0)
        this.selectedAudio = {id: outputList[0].deviceId, label: outputList[0].label, info: outputList[0]}
    }

    //카메라
    if (!this.videoMap.has(this.selectedCam.id)) {
      const list = [...this.videoMap.values()];
      if (list.length > 0)
        this.selectedCam = {id: list[0].deviceId, label: list[0].label, info: list[0]}
    }
  }

  async getMedia() {
    await this.getStream()
    if (this.camStatus) await this.adaptStream();
  }

  adaptStream() {

    if (this.refVideo.srcObject && this.refVideo.srcObject === this.stream && this.selectedFilter === 0) return

    try {

      this.camLoading = true

      if (this.selectedFilter != 0) {
        this.virtualBackground.stream = this.stream;
        this.virtualBackground.setBackground(this.selectedFilter)
        this.refVideo.srcObject = this.virtualBackground.virtualStream;
      } else {
        this.virtualBackground.destroyed();
        this.refVideo.srcObject = this.stream;
      }
      if (this.refVideo?.paused) this.refVideo.onloadeddata = () => {
        this.refVideo?.play();
        this.refVideo.muted = true;
        this.camLoading = false;
      }
    } catch (e) {
      alert("카메라를 실행할 수 없습니다.")
      console.error(e)
      this.camLoading = false;
    }
  }

  //스트림에 연결된 장치를 변경한다
  async changeStreamDevice(type: string, deviceId: string) {
    const constraints: { audio: true | { deviceId: string }, video: true | { deviceId: string } } = {
      audio: true,
      video: true
    }

    if (type == 'audio') {
      if (deviceId === this.stream?.getAudioTracks()[0].id) return
      constraints.audio = {
        deviceId: deviceId
      }

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.virtualBackground.changeAudioTracks(stream.getAudioTracks())
      if (this.soundMeter && this.soundMeter.status) {
        await this.soundMeter.stop()
        this.soundMeter.init();
        await this.soundMeter.connectToSource(stream);
      }

    } else {
      if (deviceId === this.stream?.getVideoTracks()[0].id) return
      constraints.video = {
        deviceId: deviceId
      }

      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      this.oldStream.push(this.stream)
      await this.adaptStream();

    }
  }

  changeTracks(stream: MediaStream, newTracks: MediaStreamTrack[]) {
    stream.getAudioTracks().forEach((t) => stream.removeTrack(t))
    newTracks.forEach((t) => stream.addTrack(t))
    return stream;
  }

  async getStream() {
    const video: true | { deviceId: string } = this.selectedCam.id == "0" ? true : {deviceId: this.selectedCam.id};
    const audio: true | { deviceId: string } = this.selectedMic.id == "0" ? true : {deviceId: this.selectedMic.id};

    const stream = await navigator.mediaDevices
      .getUserMedia({video: video, audio: audio});
    if (stream.id == this.stream?.id) return;
    this.stream = stream;
    this.oldStream.push(this.stream)

    if (this.soundMeter === null && this.micStatus) {
      this.soundMeter = new SoundMeter();
      this.soundMeter.init();
      await this.soundMeter?.connectToSource(this.stream);
      this.soundInterval = setInterval(() => {
        if (this.soundMeter) this.soundLevel(this.soundMeter.soundLevel)
      }, 200);
    }


    this.updateDeviceList();
  }

  selectFilter(idx: number) {
    if (VirtualBackground.backgroundImages.length < idx) return;
    this.virtualBackground.setBackground(idx);
    this.selectedFilter = idx
    this.inputFilter = VirtualBackground.backgroundImages[idx].name
    this.adaptStream();
    DeviceUtil.changeFilter(idx);
  }

  showFilter() {
    if (this.camStatus) {
      this.hideFilter = !this.hideFilter;
    } else {
      this.hideFilter = false;
    }
  }

  // 카메라 토글 버튼 클릭시, 배경 필터 텍스트 변경 및 카메라 상태 변경
  cameraOn() {

    this.camStatus = !this.camStatus;
    this.filterStatus = !this.filterStatus;
    this.hideFilter = false;
    this.camListHide = false;
    this.hideMic = false;
    this.hideSpeaker = false;

    if (!this.camStatus) {
      this.refVideo.srcObject = null;
      this.virtualBackground.destroyed();
      return this.inputFilter = '카메라 미사용 시 배경 설정이 불가능 합니다.';
    } else {
      this.getMedia();
      return this.inputFilter = VirtualBackground.backgroundImages[this.selectedFilter].name;
    }
  }

  showCamList() {
    if (this.camStatus)
      this.camListHide = !this.camListHide
    else
      this.camListHide = false
  }

  inputCam(idx: string) {
    const cam = this.videoMap.get(idx);
    if (!cam) return;
    if (cam.deviceId === this.selectedCam.id) return;

    this.selectedCam = {
      id: idx,
      label: cam.label,
      info: cam
    };
    DeviceUtil.changeCam(this.selectedCam);
    this.changeStreamDevice('video', idx);
  }

  showMic() {
    this.hideMic = !this.hideMic;
  }

  micOn() {
    this.micStatus = !this.micStatus
    if (this.soundMeter === null) {
      this.soundMeter = new SoundMeter();
      this.soundMeter.init();
      if (this.stream) this.soundMeter.connectToSource(this.stream)
    }
    if (this.soundMeter !== null) {

      if (this.micStatus) {
        this.soundInterval = setInterval(() => {
          if (this.soundMeter) this.soundLevel(this.soundMeter.soundLevel)
        }, 200);
      } else {
        if (typeof this.soundInterval == 'number') {
          clearInterval(this.soundInterval);
          this.soundLevel(0);
          this.soundMeter.stop();
          this.soundMeter = null;
        }
      }

    }
    //this.hideMic = true
  }

  async inputMic(idx: string) {
    if (this.selectedMic.id == idx) return;
    const mic = this.audioMap.get(idx);

    if (mic) {
      this.selectedMic = {
        id: idx,
        label: mic.label,
        info: mic
      };

      DeviceUtil.changeMic(this.selectedMic)

      await this.changeStreamDevice('audio', idx);
    }
  }

  showSpeaker() {
    this.hideSpeaker = !this.hideSpeaker;
  }

  inputSpeaker(idx: string) {
    const mic = this.audioMap.get(idx);
    if (!mic) return
    this.selectedAudio = {
      id: idx,
      label: mic.label,
      info: mic
    };
    DeviceUtil.changeAudio(this.selectedAudio)
  }

  speakerOn() {
    this.speakerStatus = (document.getElementById("speakerToggle") as HTMLInputElement).checked
    if (this.stream) this.stream.getAudioTracks()[0].enabled = this.speakerStatus
  }

  btnReset() {
    this.camStatus = true
    this.micStatus = true
    this.speakerStatus = true
    this.filterStatus = true
    this.camListHide = false
    this.hideSpeaker = false
    this.hideMic = false
    this.selectedFilter = 0;
    this.selectedCam = {id: '0', label: '카메라 미 선택', info: null};
    this.selectedMic = {id: '0', label: '마이크 미 선택', info: null};
    this.selectedAudio = {id: '0', label: '스피커 미 선택', info: null};

    this.inputFilter = VirtualBackground.backgroundImages[0].name;

    DeviceUtil.selectedCam = this.selectedCam;
    DeviceUtil.selectedMic = this.selectedMic;
    DeviceUtil.selectedAudio = this.selectedAudio;
    DeviceUtil.selectedFilter = this.selectedFilter;

    this.stream = null;

    this.soundMeter?.init();
    this.updateDeviceList();
    this.getMedia();
  }

  toggleDefault() {
    this.camToggleDefault = true;
    this.micToggleDefault = true;
    this.speakerToggleDefault = false;
  }

  playSampleMp3() {
    try {
      if (this.selectedAudio.id !== "0") this.refSampleAudio.setSinkId(this.selectedAudio.id)
    } finally {
      if (this.speakerStatus) this.refSampleAudio.play();
    }
  }

  soundLevel(instant: number) {
    this.soundBars.forEach((el: Element, index) => {
      if ((index + 1) * 20 <= instant) el.classList.add("on")
      else el.classList.remove("on")
    })

  }


}
