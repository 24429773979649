
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import ShapeColorPicker from "@/Booker/views/components/video/player/shape/ShapeColorPicker.vue";
import ContentItemSubtitleEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemSubtitleEditorViewModel";

@Component({name: "SubtitleOption", components: {ShapeColorPicker}})

export default class SubtitleOption extends Vue {
  @Prop() textModel !: ContentItemSubtitleEditorViewModel

  isChecked = false;


  @Emit('removeSubtitle')
  removeShape() {
    return;
  }

  @Emit('changeSize')
  changeSize(index: number) {
    this.textModel?.changeSize(index)
    console.log('this.textModel?.changeSize(index)', index)
  }

  /**
   * 자막 텍스트 색상 변경
   */
  subtitleColorChange(color: string) {
    console.log('color', color)
    return this.textModel.textItem.color = color
  }

  subtitleColorChangeInput(color: string) {
    this.textModel.textItem.color = color
  }

  subtitleColorChangeWhite(color: string) {
    this.textModel.textItem.color = color
  }

  /**
   * 자막 배경
   */
  @Emit('onChecked')
  onChecked() {
    return this.isChecked
  }


}
