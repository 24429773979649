
import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator';
import WFPlayer from 'wfplayer';
import App from "@/assets/lib/controller/App";
import {ContentItemPronunciation} from '@/Booker/lib/BookerTypes'
import BookerUtility from '@/Booker/lib/util/BookerUtility';
import STTService from "@/lib/STTService";
import FileApi from "@/assets/lib/api/FileApi";

export enum RecordStatus {
  before = 'before',
  ready = 'ready',
  record = 'record',
  reRecord = 'reRecord',
  stop = 'stop',
  play = 'play',
  result = 'result'
}

@Component({
  components: {}
})
export default class SpeakingPractice extends Vue {

  @Ref() audio!: HTMLAudioElement;
  @Ref() refCanvas!: HTMLCanvasElement;
  @Ref() refWaveBar!: HTMLDivElement;
  @Ref() refWaveform!: HTMLDivElement;

  @Prop() pronunciationItem!: ContentItemPronunciation;

  recordStatus: RecordStatus = RecordStatus.before;

  modalClose = true
  timer: any

  timerHour = 0
  timerMinute = 0
  playMinute = 0
  timerSecond = 0
  playSecond = 0

  playerTimer: any = null;
  isForceStop = false;

  /**
   * 말하기 실습 결과
   */
  speakingResult = '';
  BookerUtility = BookerUtility;
  STTService = STTService
  //WfPlayer
  private wf: WFPlayer | null = null;
  private audioStream: MediaStream | null = null;
  private mediaRecorder?: MediaRecorder;
  private recordedBlobs: Blob[] = [];
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private dataArray: Uint8Array | undefined;
  private canvasCtx: CanvasRenderingContext2D | null = null;
  private drawVisual: number | null = null;


  get isBefore() {
    return this.recordStatus === RecordStatus.before
  }

  get isReady() {
    return this.recordStatus === RecordStatus.ready
  }

  get isRecord() {
    return this.recordStatus === RecordStatus.record
  }

  get isReRecord() {
    return this.recordStatus === RecordStatus.reRecord
  }

  get isStop() {
    return this.recordStatus === RecordStatus.stop
  }

  get isPlay() {
    return this.recordStatus === RecordStatus.play
  }

  get isResult() {
    return this.recordStatus === RecordStatus.result
  }


  get image() {
    if (this.pronunciationItem?.img)
      return BookerUtility.getFile(this.pronunciationItem?.img)
    return ''
  }

  mounted() {
    this.init();
  }

  init() {
    App.controller.auth.setUuid();
  }

  startRecord() {
    this.speakingResult = '';
    STTService.startSTT((message) => {
      console.log('message', message)
      message ? this.speakingResult = message : ''
    })
    this.initMediaRecord();
  }

  /**
   * 타이머
   */
  restTimer() {
    return `${this.TimerPadZero(this.timerMinute)}:${this.TimerPadZero(this.timerSecond)}`
  }

  //타이머 시간 10이하일 때 문자열 0 추가
  TimerPadZero(func: number) {
    if (func < 10) {
      return `0${func}`
    } else {
      return func
    }
  }

  recordClick() {
    this.recordStart()
  }

  setRecordTimer() {
    this.timer = setInterval(() => {
      this.timerSecond++
      if (this.timerSecond === 60) {
        this.timerMinute++
        this.timerSecond = 0

        if (this.timerMinute === 60) {
          this.timerHour++
          this.timerMinute = 0
        }
      }
    }, 1000);
  }

  timerTimeLine(min: number, sec: number) {
    if (sec > 59.9) {
      this.playMinute = this.playMinute + 1;
      this.playSecond = 0;
    } else this.playSecond = this.playSecond + 0.1;
    const nowPosition = (this.playSecond + (this.playMinute * 60)) / (this.timerMinute * 60 + this.timerSecond) * 100;
    this.setWaveBarStyle((nowPosition))
  }

  setWaveBarStyle(left: number) {
    this.refWaveBar.style.width = `${left}%`
  }

  /**
   * 녹음 시작
   */
  recordStart() {

    this.timerSecond = 0
    this.timerMinute = 0
    this.setRecordTimer();

    this.initWFPlayer();
    this.recordStatus = RecordStatus.record;

    this.startRecord();
  }

  /**
   * 녹음 정지
   */
  pauseRecordFunc() {
    this.audio.pause()
    this.recordStatus = RecordStatus.stop;
    this.playMinute = this.timerMinute;
    this.playSecond = this.timerSecond;
  }

  recordStopFunc() {
    this.mediaRecorder?.stop();
    clearInterval(this.timer)
    this.playMinute = this.timerMinute;
    this.playSecond = this.timerSecond;
    this.recordStatus = RecordStatus.result;
    if (this.drawVisual && this.drawVisual > -1) window.cancelAnimationFrame(this.drawVisual);
  }


  /**
   * 녹음 재생
   */
  playRecordFunc() {
    this.audioPlay();
  }

  audioPlay() {
    this.audio.play();
    this.audio.onplay = () => {
      this.recordStatus = RecordStatus.play;

      this.playSecond = this.audio.currentTime % 60
      this.playMinute = (this.audio.currentTime - this.playSecond) / 60
      if (this.playerTimer) {
        clearInterval(this.playerTimer);
        this.setWaveBarStyle(0);
        this.playerTimer = null;
      }
      this.playerTimer = setInterval(() => {
        this.timerTimeLine(this.playMinute, this.playSecond)
      }, 100)
    }
    this.audio.onpause = () => {
      clearInterval(this.playerTimer);
      this.playerTimer = null;
      this.recordStatus = RecordStatus.stop;
    }
    this.audio.onended = () => {
      this.recordStatus = RecordStatus.result;

      this.audio.currentTime = 0;
      clearInterval(this.playerTimer)
      this.setWaveBarStyle(0);
      this.playerTimer = null;
    }
  }


  /**
   * 모달 닫기
   */
  @Emit('endPronunciation')
  closeModal() {
    this.isForceStop = true;
    this.modalClose = false
    if (this.isRecord) this.destroyMediaRecorder()
  }


  /**
   * WFPlyer
   */

  initWFPlayer() {

    if (this.wf === null) this.wf = new WFPlayer({
      container: this.refWaveform,

      // Media element like: video tag or audio tag
      mediaElement: this.audio,
      // Whether to use scroll mode
      waveColor: "#888CE8",

      // Background color
      backgroundColor: "#F0F3FF",

      // Whether to display cursor
      cursor: true,

      // Cursor color
      cursorColor: "#EC6D51",

      // Whether to display progress
      progress: true,

      // progress color
      progressColor: "#888CE8",

      // Whether to display grid
      grid: false,

      // Grid color

      // Whether to display ruler
      ruler: false,

      // Ruler color
      rulerColor: "rgba(255, 255, 255, 0.5)",

      // Whether to display ruler at the top
      wave: true,
      // Indicates whether to do http fetching with cookies
      waveSize: 1,
      // Indicates whether to enable CORS for http fetching

      // Initialize http headers

      // Pixel ratio
      pixelRatio: window.devicePixelRatio,

    });
    if (this.audio.src.length > 0) this.wf.load(this.audio)
  }

  async initMediaRecord() {
    // if(this.mediaRecorder) return
    if (!this.audioStream) {
      this.audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          sampleRate: 16000
        }
      })
    }

    // @ts-ignore
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext({sampleRate: 16000});
    this.analyser = this.audioContext?.createAnalyser();
    this.analyser.minDecibels = -90;
    this.analyser.maxDecibels = -10;
    this.analyser.smoothingTimeConstant = 0.85;
    this.analyser.fftSize = 2048;

    const distortion = this.audioContext.createWaveShaper();
    const gainNode = this.audioContext.createGain();
    const biquadFilter = this.audioContext.createBiquadFilter();
    const convolver = this.audioContext.createConvolver();
    const echoDelay = this.createEchoDelayEffect(this.audioContext);
    const source = this.audioContext.createMediaStreamSource(this.audioStream);
    source.connect(distortion);
    distortion.connect(biquadFilter);
    biquadFilter.connect(gainNode);
    convolver.connect(gainNode);
    echoDelay.placeBetween(gainNode, this.analyser);
    this.analyser.connect(this.audioContext.destination);

    const bufferLength = this.analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(bufferLength);
    this.analyser.getByteTimeDomainData(this.dataArray);
    this.analyser.connect(this.audioContext?.destination)
    this.canvasCtx = this.refCanvas.getContext("2d");

    this.mediaRecorder = new MediaRecorder(this.audioStream);
    if (this.mediaRecorder) this.mediaRecorder.start();
    if (this.mediaRecorder) this.mediaRecorder.onstart = () => {
      this.recordStatus = RecordStatus.record;
      this.visualize();
      if (!this.timer) this.setRecordTimer();
    }

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        this.recordedBlobs.push(event.data);
      }
    };

    this.mediaRecorder.onstop = async () => {
      this.recordStatus = RecordStatus.result;
      if (!this.isForceStop) {
        const blob = new Blob(this.recordedBlobs, {type: "audio/mpeg"});
        // const file = new File([blob], BookerUtility.createKey() + '-quizAnswer.mp3', {type: "audio/mpeg"});
        // this.fileUploader(file);
        this.audio.src = URL.createObjectURL(blob);
        this.audio.onloadeddata = () => {
          this.wf?.load(this.audio);
          this.audio.play();
          setTimeout(() => {
            this.audio.pause();
            this.initWFPlayer();
          }, 100)
        }
      }
      gainNode.gain.value = 0;
      source.disconnect();
      distortion.disconnect();
      biquadFilter.disconnect();
      convolver.disconnect();
      this.analyser?.disconnect()
      this.recordedBlobs = [];
      if (this.drawVisual) window.cancelAnimationFrame(this.drawVisual);
      this.audioStream?.getTracks().forEach((t) => t.stop())
      STTService.stopSTT();
      this.analyser = null;
      this.audioStream = null;
      this.audioContext = null;
    }

  }

  createEchoDelayEffect(audioContext: AudioContext) {
    const delay = audioContext.createDelay(1);
    const dryNode = audioContext.createGain();
    const wetNode = audioContext.createGain();
    const mixer = audioContext.createGain();
    const filter = audioContext.createBiquadFilter();

    delay.delayTime.value = 0.75;
    dryNode.gain.value = 1;
    wetNode.gain.value = 0;
    filter.frequency.value = 1100;
    filter.type = "highpass";

    return {
      apply: function () {
        wetNode.gain.setValueAtTime(0.75, audioContext.currentTime);
      },
      discard: function () {
        wetNode.gain.setValueAtTime(0, audioContext.currentTime);
      },
      isApplied: function () {
        return wetNode.gain.value > 0;
      },
      placeBetween: function (inputNode: AudioNode, outputNode: AudioNode) {
        inputNode.connect(delay);
        delay.connect(wetNode);
        wetNode.connect(filter);
        filter.connect(delay);

        inputNode.connect(dryNode);
        dryNode.connect(mixer);
        wetNode.connect(mixer);
        mixer.connect(outputNode);
      },
    };
  }

  visualize() {
    if (!this.analyser) return;
    if (!this.canvasCtx) return;
    const WIDTH = this.refCanvas.width;
    const HEIGHT = this.refCanvas.height;
    this.analyser.fftSize = 1024 * 8;
    const bufferLength = this.analyser.fftSize;

    // We can use Float32Array instead of Uint8Array if we want higher precision
    // const dataArray = new Float32Array(bufferLength);
    const dataArray = new Uint8Array(bufferLength);

    this.canvasCtx.fillStyle = "rgb(240, 243, 255)";
    this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

    const draw = () => {
      if (!this.analyser) return;
      if (!this.canvasCtx) return;
      this.drawVisual = requestAnimationFrame(draw);

      this.analyser.getByteTimeDomainData(dataArray);

      this.canvasCtx.fillStyle = "rgb(240, 243, 255)";
      this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      this.canvasCtx.lineWidth = 4;
      this.canvasCtx.strokeStyle = "#888CE8";

      this.canvasCtx.beginPath();

      const sliceWidth = (WIDTH) / bufferLength;

      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        let v = dataArray[i] / 128.0;
        let y = (v * HEIGHT) / 2;

        if (i === 0) {
          this.canvasCtx.moveTo(x, y);
        } else {
          this.canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      //this.canvasCtx.lineTo(this.refCanvas.width, this.refCanvas.height / 2);
      this.canvasCtx.stroke();
    };

    draw();
  }

  beforeDestroy() {
    this.destroyMediaRecorder();
  }

  destroyMediaRecorder() {
    if (this.mediaRecorder) {
      if (this.isRecord) this.mediaRecorder.stop();
      this.mediaRecorder = undefined;
      this.audioStream?.getTracks().forEach(t => t.stop())
    }
  }

  async fileUploader(newFile: File) {
    const fileApiRes = await FileApi.uploadFile(newFile)
    const fileId = fileApiRes?.file_list[0].file_id
    if (fileId) {
      this.speakingResultFile(fileId)
    }
  }

  @Emit('speakingResultFile')
  speakingResultFile(fileUrl: string) {
    return {key: this.pronunciationItem.key, fileUrl: fileUrl}
  }

}
