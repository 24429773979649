var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"QuizAddItem"},[_c('div',{staticClass:"head"},[_c('span',[_vm._v("추가 문항 선택")]),_c('div',{staticClass:"difficulty"},[_c('input',{ref:"refInput",attrs:{"id":"toggle","type":"checkbox"},on:{"change":function($event){$event.preventDefault();return _vm.onChangeIsDifficulty.apply(null, arguments)}}}),_vm._m(0),_c('label',{staticClass:"difficulty-text",attrs:{"for":"toggle"}},[_vm._v("난이도")])])]),(_vm.isDifficulty)?_c('QuizDifficulty',{attrs:{"isDifficulty":_vm.isDifficulty,"selectedDifficulty":_vm.selectedDifficulty},on:{"changeDifficulty":_vm.onChangeDifficulty}}):_vm._e(),_c('div',{staticClass:"container"},[_c('div',{staticClass:"choice-container"},[_c('button',{staticClass:"small",on:{"click":_vm.addInput}},[_vm._m(1),_vm._v(" 단답형 ")]),_c('button',{staticClass:"small",on:{"click":_vm.addDescriptive}},[_vm._m(2),_vm._v(" 서술형 ")]),_c('button',{staticClass:"small",on:{"click":_vm.addSelect}},[_vm._m(3),_vm._v(" 다지선다형 ")]),_c('button',{staticClass:"small",on:{"click":_vm.addOX}},[_vm._m(4),_vm._v(" OX형 ")]),_c('button',{staticClass:"small",on:{"click":_vm.addDictation}},[_vm._m(5),_vm._v(" 구술형 ")]),_c('button',{staticClass:"small",on:{"click":_vm.addBlank}},[_vm._m(6),_vm._v(" 빈칸형 ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"toggle-custom",attrs:{"for":"toggle"}},[_c('span',{staticClass:"btn-toggle"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/bookmark/bookMarkTool/quiz/ic_shortQuiz.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/bookmark/bookMarkTool/quiz/ic_longQuiz.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/bookmark/bookMarkTool/quiz/ic_manyQuiz.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/bookmark/bookMarkTool/quiz/ic_oxQuiz.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/bookmark/bookMarkTool/quiz/ic_dictationQuiz.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/bookmark/bookMarkTool/quiz/icQuizType06.png")}})])
}]

export { render, staticRenderFns }