import {PlayingState} from "@/Booker/lib/controller/ContentSeekbarController";
import {ContentItemAudio, ContentItemTextToSpeech, TimelineContent} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import DeviceUtil from "@/assets/lib/utility/DeviceUtil";

export default class ClassContentAudioPlayer {

  audioContent: ContentItemAudio | ContentItemTextToSpeech | null = null;
  audio = document.createElement('audio');
  oldTime = 0;
  source = '';


  constructor() {
    this.audio.onload = () => {
      if (DeviceUtil.isSelectedAudio) {
        this.audio.setSinkId(DeviceUtil.selectedAudio.id)
      }
    }
  }

  get isPlay(): boolean {
    return !this.audio.paused
  }

  checkUpdateTime(currentTime: number) {
    const updateTime = Math.abs(currentTime - this.oldTime) * 1000;
    if (!this.isPlay) {
      this.oldTime = currentTime;
      return updateTime > 30
    }
    this.oldTime = currentTime;
    return updateTime > 1000
  }

  updatePlayingState(state: PlayingState, audioContents: TimelineContent[]) {

    if (audioContents.length < 1) {
      this.pauseAudio(this.audio);
      return;
    }

    this.audioContent = (audioContents[0].content as ContentItemAudio | ContentItemTextToSpeech)

    const currentTime = this.currentTimeChanger(audioContents[0].start, this.audioContent.length, state.currentTime)

    this.setSource(this.audioContent.source, this.audio);
    if (this.checkUpdateTime(currentTime))
      this.setCurrentTime(this.audio, currentTime);
    if (state.status === 'playing')
      this.playAudio(this.audio)
    if (state.status === 'paused')
      this.pauseAudio(this.audio);


  }

  currentTimeChanger(start: number, length: number, currentTime: number): number {
    if (currentTime < start + length) return (currentTime - start) / 1000;
    return (currentTime - start - length) / 1000;
  }

  setCurrentTime(audio: HTMLAudioElement, currentTime: number) {
    audio.currentTime = currentTime;
  }

  setSource(source: string, audio: HTMLAudioElement) {
    if (this.source !== source) {
      audio.src = BookerUtility.getFile(source)
      this.source = source;
    }
    audio.onended = () => this.oldTime = 0;
  }

  playAudio(audio: HTMLAudioElement) {
    if (this.isPlay) return;
    audio.play();
  }

  pauseAudio(audio: HTMLAudioElement) {
    if (!this.isPlay) return;
    audio.pause();
  }

}
