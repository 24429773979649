
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import CommonBookerModal from "@/Booker/views/components/common/CommonBookerModal.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import {ContentItemType, ContentItemVideo, TimelineContent, TimelineType} from "@/Booker/lib/BookerTypes";
import BookerUtility from "../../lib/util/BookerUtility";
import VideoTimelineContentPlayer from "@/Booker/views/components/video/player/VideoTimelineContentPlayer.vue";

@Component({
  name: "BookMarkVideoEditModal",
  components: {VideoTimelineContentPlayer, CommonInput, CommonBookerModal}
})
export default class BookMarkVideoEditModal extends Vue {
  @Prop() modalStatus!: boolean
  @Prop() modalTitle!: string
  // @Prop() source!: string
  // @Prop() isPlaying!: boolean
  // @Prop() currentTime!: number
  // @Prop() startAt!: number
  // @Prop() endAt!: number

  @Prop() viewModel!: ClassContentPlayerViewModel
  @Prop() videoContent!: TimelineContent

  startAt = {min: 0, sec: 0};
  endAt = {min: 0, sec: 0};

  BookerUtility = BookerUtility;
  timelineIdx = -1;


  mounted() {
    this.timelineIdx = this.viewModel.timeline.getTimelineContents(TimelineType.Video).findIndex(t => t.key === this.videoContent.key);
    if (this.videoContent.startAt) {
      this.startAt.min = Math.round(Math.floor(this.videoContent.startAt / 1000 / 60));
      this.startAt.sec = Math.round(Math.floor(this.videoContent.startAt / 1000 % 60));
    }


    if (this.videoContent.endAt) {
      const endTime = ((this.videoContent.content as ContentItemVideo).length - this.videoContent.endAt)
      this.endAt.min = Math.round(Math.floor((endTime) / 1000 / 60));
      this.endAt.sec = Math.round(Math.floor((endTime) / 1000 % 60));
    }

  }

  @Emit('timelineContentTimeChange')
  timelineContentTimeChange() {
    return
  }

  @Emit('closeModal')
  closeModal() {
    return
  }

  padTwoDigits(value: number): string {
    return value.toString().padStart(2, '0');
  }


  onInputMin(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    let inputValue: number | string = inputElement.value;
    inputValue = inputValue.replace(/\D/g, '');

    if (inputValue !== '') {
      inputValue = parseInt(inputValue, 10);
      inputValue = Math.min(Math.max(inputValue, 0), 59);
    }

    const inputValueString = inputValue !== '' ? inputValue.toString().padStart(2, '0') : '';
    inputElement.value = inputValueString.slice(0, 2);
    this.videoContent.startAt = parseInt(inputElement.value, 10);
    this.startAt.min = this.videoContent.startAt;
    this.setStartAt();
  }


  onInputSec(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    let inputValue: number | string = inputElement.value;
    inputValue = inputValue.replace(/\D/g, '');

    if (inputValue !== '') {
      inputValue = parseInt(inputValue, 10);
      inputValue = Math.min(Math.max(inputValue, 0), 59);
    }

    const inputValueString = inputValue !== '' ? inputValue.toString().padStart(2, '0') : '';
    inputElement.value = inputValueString.slice(0, 2);

    this.videoContent.startAt = parseInt(inputElement.value, 10);

    this.startAt.sec = this.videoContent.startAt
    this.setStartAt()
  }

  onInputEndMin(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    let inputValue: number | string = inputElement.value;
    inputValue = inputValue.replace(/\D/g, '');

    if (inputValue !== '') {
      inputValue = parseInt(inputValue, 10);
      inputValue = Math.min(Math.max(inputValue, 0), 59);
    }

    const inputValueString = inputValue !== '' ? inputValue.toString().padStart(2, '0') : '';
    inputElement.value = inputValueString.slice(0, 2);
    this.videoContent.endAt = parseInt(inputElement.value, 10)
    this.endAt.min = this.videoContent.endAt
    this.setEndAt();
  }

  onInputEndSec(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    let inputValue: number | string = inputElement.value;
    inputValue = inputValue.replace(/\D/g, '');

    if (inputValue !== '' || inputValue === null) {
      inputValue = parseInt(inputValue, 10);
      inputValue = Math.min(Math.max(inputValue, 0), 59);
    }

    const inputValueString = inputValue !== '' ? inputValue.toString().padStart(2, '0') : '';
    inputElement.value = inputValueString.slice(0, 2);

    this.videoContent.endAt = parseInt(inputElement.value, 10);
    this.endAt.sec = this.videoContent.endAt
    this.setEndAt()
  }

  setStartAt() {
    let {min, sec} = this.startAt;
    if (isNaN(min)) min = 0
    if (isNaN(sec)) min = 0
    const startAtTime = (min * 60 + sec) * 1000;
    this.viewModel.timeline.setTimelineVideoStartAt(ContentItemType.Video, this.timelineIdx, startAtTime)
  }


  setEndAt() {
    let {min, sec} = this.endAt;
    if (isNaN(min)) min = 0
    if (isNaN(sec)) min = 0
    const endAtTime = (min * 60 + sec) * 1000;
    const endAt = (this.videoContent.content as ContentItemVideo).length - endAtTime
    this.viewModel.timeline.setTimelineContentEndTime(ContentItemType.Video, this.timelineIdx, endAt)
  }


}
