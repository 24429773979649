
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import TextColorPicker from "@/Booker/views/components/video/player/text/TextColorPicker.vue"

@Component({
  name: "VideoText",
  components: {TextColorPicker}
})
export default class VideoText extends Vue {

  @Ref() refText!: HTMLDivElement;
  @Ref('containerRef') containerRef!: HTMLDivElement;
  @Ref('refInput') refInput!: HTMLTextAreaElement;
  @Ref('refResize') refResize!: HTMLDivElement;
  @Ref('refColorPicker') refColorPicker!: HTMLInputElement;

  @Prop() videoWrapX !: number;
  @Prop() videoWrapW !: number;
  @Prop() videoWrapY !: number;
  @Prop() videoWrapH !: number;
  // @Prop() parentRect !: {x : number,y : number, width:number, height:number};

  items = ['tl', 'tr', 'bl', 'br', 'lm', 'rm', 'tm', 'bm']

  /**
   *  텍스트 Resize
   */
  isResizing = false;
  top = 0
  left = 0
  startX = 0
  startY = 0
  startWidth = 0
  startHeight = 0
  resizeDirection = '';
  leastSize = 100;
  /**
   *  텍스트 Reposition
   */
  isDragging = false
  currentX = 0
  currentY = 0
  oldCurrentX = 0
  oldCurrentY = 0
  initialX = 0
  initialY = 0
  /**
   *  텍스트 Rotate
   */
  angle = 0;
  dx = 0
  dy = 0
  isRotating = false
  mousePressX = 0
  mousePressY = 0

  /**
   * Text 옵션
   */
    // Font Size
  textSize = [
    20, 40, 60, 80, 100, 150
  ]
  currentSize = this.textSize[1]
  // Font Color
  currentColor = '#fff'

  testTextStatus = true

  /**
   * Textarea Resize 1
   */

  resizeMouseDown(event: MouseEvent, direction: string) {
    event.preventDefault();
    event.stopPropagation();

    this.isResizing = true;
    this.isDragging = false;
    this.isRotating = false;

    this.startX = event.clientX;
    this.startY = event.clientY;

    this.startWidth = this.refText.offsetWidth;
    this.startHeight = this.refText.offsetHeight;
    this.resizeDirection = direction

    document.addEventListener('mousemove', this.resizeMouseMove)
    document.addEventListener('mouseup', this.resizeMouseUp)
  }

  resizeMouseMove(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isResizing = true;
    this.isDragging = false;
    this.isRotating = false;

    if (!this.isResizing && !this.isDragging) {
      return;
    }

    const deltaX = (event.clientX - this.startX) * 2;
    const deltaY = (event.clientY - this.startY) * 2;

    if (this.isDragging) {
      this.startX = event.clientX;
      this.startY = event.clientY;
    } else {
      // Top-Left
      if (this.resizeDirection === 'tl') {
        const tlWidth = this.startWidth - deltaX;
        const tlHeight = this.startHeight - deltaY;
        if (tlWidth > this.leastSize) {
          this.refText.style.width = Math.floor(tlWidth) + 'px';
          this.currentX = deltaX + this.oldCurrentX;
        }
        if (tlHeight > this.leastSize) {
          this.refText.style.height = Math.floor(tlHeight) + 'px';
          this.currentY = deltaY + this.oldCurrentY;
        }

        console.log('tlHeight', tlHeight)
        console.log('this.currentY', this.currentY)
        console.log('deltaY', deltaY)
        console.log('this.oldCurrentY', this.oldCurrentY)


        // Top-Right
      } else if (this.resizeDirection === 'tr') {
        const trWidth = (this.startWidth + deltaX);
        const trHeight = (this.startHeight - deltaY);
        if (trWidth > this.leastSize) {
          this.refText.style.width = trWidth + 'px';
        }
        if (trHeight > this.leastSize) {
          this.refText.style.height = trHeight + 'px';
          this.currentY = deltaY + this.oldCurrentY;
        }
        console.log('this.currentY', this.currentY)
        console.log('trHeight', trHeight)

        // Bottom-Left
      } else if (this.resizeDirection === 'bl') {
        const blWidth = this.startWidth - deltaX;
        const blHeight = this.startHeight + deltaY;
        if (blWidth > this.leastSize) {
          this.refText.style.width = blWidth + 'px';
          this.currentX = deltaX + this.oldCurrentX / 2;
        }
        if (blHeight > this.leastSize) {
          this.refText.style.height = blHeight + 'px';
        }

        // Bottom-right
      } else if (this.resizeDirection === 'br') {
        const brWidth = this.startWidth + deltaX;
        const brHeight = this.startHeight + deltaY;
        if (brWidth > this.leastSize) {
          this.refText.style.width = brWidth + 'px';
        }
        if (brHeight > this.leastSize) {
          this.refText.style.height = brHeight + 'px';
        }

        // Top-Middle
      } else if (this.resizeDirection === 'tm') {
        console.log('tm')
        const tmWidth = this.startWidth - deltaX;
        const tmHeight = this.startHeight - deltaY;
        if (tmWidth > this.leastSize) {
          this.refText.style.width = tmWidth + 'px';
        }
        if (tmHeight > this.leastSize) {
          this.refText.style.height = Math.floor(tmHeight) + 'px';
          this.currentY = deltaY + this.oldCurrentY;
        }

        // Bottom-Middle
      } else if (this.resizeDirection === 'bm') {
        console.log('bm')
        const bmWidth = this.startWidth + deltaX;
        const bmHeight = this.startHeight + deltaY;
        if (bmWidth > this.leastSize) {
          this.refText.style.width = bmWidth + 'px';
        }
        if (bmHeight > this.leastSize) {
          this.refText.style.height = Math.floor(bmHeight) + 'px';
          //this.currentY = deltaY + this.oldCurrentY;
        }

        // Left-Middle
      } else if (this.resizeDirection === 'lm') {
        console.log('lm')
        const lmWidth = this.startWidth - deltaX;
        const lmHeight = this.startHeight + deltaY;
        if (lmWidth > this.leastSize) {
          this.refText.style.width = Math.floor(lmWidth) + 'px';
          this.currentX = deltaX + this.oldCurrentX;

          console.log('this.currentX', this.currentX)
          console.log('trWidth', lmWidth)
        }
        if (lmHeight > this.leastSize) {
          this.refText.style.height = Math.floor(lmHeight) + 'px';
          //this.currentY = deltaY + this.oldCurrentY;
        }

        // Right-Middle
      } else if (this.resizeDirection === 'rm') {
        console.log('rm')
        const rmWidth = this.startWidth + deltaX;
        const rmHeight = this.startHeight + deltaY;
        if (rmWidth > this.leastSize) {
          this.refText.style.width = Math.floor(rmWidth) + 'px';
        }
        if (rmHeight > this.leastSize) {
          this.refText.style.height = Math.floor(rmHeight) + 'px';
          //this.currentY = deltaY + this.oldCurrentY;
        }
      }
    }
  }

  resizeMouseUp() {
    document.removeEventListener('mousemove', this.resizeMouseMove);
    document.removeEventListener('mouseup', this.resizeMouseUp);
    this.isResizing = false;
    this.resizeDirection = '';
  }

  /**
   * Textarea Reposition
   */
  dragStart(event: MouseEvent) {
    // event.preventDefault();
    // event.stopPropagation();
    this.isDragging = true;
    this.isResizing = false;
    this.oldCurrentX = this.currentX;
    this.oldCurrentY = this.currentY
    this.initialX = event.clientX;
    this.initialY = event.clientY;

    window.addEventListener('mousemove', this.drag)
    window.addEventListener('mouseup', this.dragEnd)

  }

  drag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.isDragging) {
      let x = (event.clientX - this.initialX) / 0.5 + this.oldCurrentX;
      // 텍스트가 비디오의 너비를 벗어나지 못하게
      // if (x > this.videoWrapW * 4) {
      //   x = this.videoWrapW * 4;
      // } else if (x <= -`${this.refText.offsetWidth}` / 2) {
      //   x = -`${this.refText.offsetWidth}` / 2;
      // }
      this.currentX = x;

      console.log('this.videoWrapW', this.videoWrapW)

      // 텍스트가 비디오의 높이를 벗어나지 못하게
      let y = (event.clientY - this.initialY) / 0.5 + this.oldCurrentY;
      // if (y > (this.videoWrapH + this.refText.offsetHeight) * 1.25) {
      //   y = (this.videoWrapH + this.refText.offsetHeight) * 1.25;
      // } else if (y < -`${this.refText.offsetHeight}` / 2) {
      //   y = -`${this.refText.offsetHeight}` / 2;
      // }
      this.currentY = y;

      console.log('this.videoWrapH', this.videoWrapH)
    }
  }

  dragEnd(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;

    window.removeEventListener('mousemove', this.drag)
    window.removeEventListener('mouseup', this.dragEnd)
  }

  /**
   * Textarea 회전
   */
  rotateVideo(event: MouseEvent) {
    event.preventDefault();

    this.isRotating = true;
    this.isDragging = false;
    this.isResizing = false;

    console.log('mousedown')
    console.log('event.clientX', event.clientX)
    console.log('event.clientY', event.clientY)

    this.mousePressX = event.clientX;
    this.mousePressY = event.clientY;

    window.addEventListener('mousemove', this.rotateMouseMove);
    window.addEventListener('mouseup', this.rotateMouseUp);
  }

  rotateMouseMove(event: MouseEvent) {
    event.preventDefault();

    this.isRotating = true;
    this.isDragging = false;
    this.isResizing = false;

    if (this.isRotating) {
      const arrowRects = this.refText.getBoundingClientRect();

      const arrowX = arrowRects.left + arrowRects.width / 2;
      const arrowY = arrowRects.top + arrowRects.height / 2;
      const angle = Math.atan2(event.clientY - arrowY, event.clientX - arrowX);

      this.angle = angle / (Math.PI * 2) * 360 + 90
      console.log('mousemove')
      console.log('angle', angle)

    } else {
      return;
    }
  }

  rotateMouseUp() {
    console.log('mouseup')
    this.isRotating = false;
    this.isDragging = false;
    window.removeEventListener('mousemove', this.rotateMouseMove);
    window.removeEventListener('mouseup', this.rotateMouseUp);
  }

  /**
   * Textarea 에 텍스트가 두줄 이상일때 자동으로 높이 길어지게
   */
  onInput(event: any) {
    this.refText.style.height = 'auto';
    this.refText.style.height = event.target.scrollHeight + "px";
  }

  /**
   * Textarea @blur border
   */
  delBorder() {
    this.containerRef.classList.add('del');
  }

  addBorder() {
    this.containerRef.classList.remove('del');
  }

  /**
   * Textarea Font Size
   */
  changeSize(index: number) {
    this.currentSize = this.textSize[index]
  }

  /**
   * Textarea Delete
   */
  removeOption() {
    if (!confirm('삭제하시겠습니까?')) {
      alert('취소');
    } else {

      //
    }
  }

  /**
   * Textarea Font Color
   */
  changeTextareaColor(color: string) {
    this.currentColor = color
  }

}
