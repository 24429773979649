
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import {QuizItem} from "@/Booker/lib/BookerTypes";


@Component({
  name: "CommonTextAreaQuizStart",

  components: {}
})
export default class CommonTextArea extends Vue {
  @Prop() inputClass !: string
  @Prop() disabled!: boolean
  @Prop() maxLength!: number
  @Prop() placeholder!: string
  @Prop() readonly !: boolean
  @Prop() inputValue!: any
  @Prop() updateInput!: any
  @Prop() focusOut?: any
  @Prop() quiz!: QuizItem
  @Ref() refTextarea!: HTMLTextAreaElement

}
