
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
  name: 'ClassTitle'
})
export default class ClassTitle extends Vue {
  @Prop() courseName!: string
  @Prop() className!: string
}
