import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import ClassContentModel from "@/Booker/lib/model/ClassContentModel";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import {ContentItemQuiz, ContentItemType, UserContent} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

export default class MyContentViewModel {
  model: ClassContentModel
  aiReportModel: AiReportViewModel;
  selectedQuizKey: string | null = null;
  selectedContentKey: string | null = null;
  selectedUserContentInfoIndex: number | null = null;
  openedContent: UserContent | null = null;
  scrollCallBack: (() => void) | null = null;

  constructor() {
    this.model = ClassContentManager.controller.model
    this.aiReportModel = new AiReportViewModel();
  }

  get selectedQuiz() {
    if (this.openedContent && this.selectedQuizKey) {
      return this.openedContent.userContentList.find((quiz) => quiz.key === this.selectedQuizKey) || null
    }
    return null;
  }

  get userContentList() {
    return this.model.userClassList.filter((content) => content.isComplete);
  }

  get userContentInfoList() {
    return this.model.userContentInfoList;
  }

  get isLastQuiz() {
    if (!this.openedContent || !this.selectedQuizKey) return false;
    const findIndex = this.openedContent.userContentList.findIndex((content) => content.key === this.selectedQuizKey);
    return findIndex === this.openedContent.userContentList.length - 1;
  }

  userContentByClassStep(classStep: number) {
    return this.model.userContentInfoList
      .filter((content) => content.classStep === classStep)
      .map((content) => {
        return {
          info: content,
          userContent: JSON.parse(content.userContent) as UserContent
        }
      });
  }

  async closeQuiz() {
    this.selectedQuizKey = null;
    this.selectedContentKey = null;
    this.selectedUserContentInfoIndex = null;
    this.openedContent = null;
    await this.model.userContentInit()
  }

  quizRate(userContent: UserContent) {
    const userContentCount = userContent.userContentList.filter((content) => content.quizItems[0].userScore).length;
    return ((userContentCount / userContent.userContentList.length) * 100).toFixed(0);
  }

  scoreDetailByClassStep(userContent: UserContent) {
    const commonQuizList = userContent.userContentList.filter((quiz) => quiz.quizType === 2)
    const practiceQuizList = userContent.userContentList.filter((quiz) => quiz.quizType === 1)
    const realQuizList = userContent.userContentList.filter((quiz) => quiz.quizType === 0)

    const commonQuizItemsList = commonQuizList.map((quiz) => quiz.quizItems).flat(1);
    const practiceQuizItemsList = practiceQuizList.map((quiz) => quiz.quizItems).flat(1);
    const realQuizItemsList = realQuizList.map((quiz) => quiz.quizItems).flat(1);

    return {
      quiz: commonQuizItemsList.map((quiz) => this.aiReportModel.exportIsCorrectAndScoreInQuizList(quiz)),
      practice: practiceQuizItemsList.map((quiz) => this.aiReportModel.exportIsCorrectAndScoreInQuizList(quiz)),
      real: realQuizItemsList.map((quiz) => this.aiReportModel.exportIsCorrectAndScoreInQuizListForReal(quiz)).flat(1),
    }
  }

  quizUpdatedAt(userContentInfoIndex: number) {
    return this.model.userContentInfoList.find((content) => content.contentKey === this.selectedContentKey)?.updatedAt
  }

  openContent(selectedContentKey: string) {
    this.selectedContentKey = selectedContentKey;
    this.addUserContent(selectedContentKey);
  }

  openUserContent(selectedContentKey: string) {
    this.selectedContentKey = selectedContentKey;
    const userContent = this.model.userContentList.find(u => u.key === selectedContentKey);
    if (userContent) {
      console.log('userContent', userContent)
      this.openedContent = userContent;
      this.selectedQuizKey = this.openedContent.userContentList.find(u => u.quizItems[0].userScore === undefined)?.key || null;
    } else {
      this.selectedContentKey = null;
    }
  }

  async nextContent() {
    if (!this.openedContent) return
    const findIndex = this.openedContent.userContentList.findIndex((content) => content.key === this.selectedQuizKey);
    if (findIndex === -1) return
    if (findIndex < this.openedContent.userContentList.length - 1) {
      this.selectedQuizKey = this.openedContent.userContentList[findIndex + 1].key;
      if (this.scrollCallBack) this.scrollCallBack.call(null);
    }
  }

  async completeContent() {
    if (!this.openedContent) return;
    this.openedContent.isComplete = true;
    await this.updateUserContent();
    await this.closeQuiz();
  }


  async addUserContent(contentKey: string) {
    const findContent = this.model.releaseClassList.find((content) => content.key === contentKey)
    console.log('findContent', findContent)
    if (findContent) {

      const quizContentList = BookerUtility.getQuizListInContent(findContent)
      quizContentList.forEach((quiz) => {
        quiz.quizItems.forEach((quizItem) => {
          delete quizItem.userScore
          quizItem.options.forEach((option) => {
            option.isCorrect = false;
            delete option.userAnswer
          })
        })
      })
      const userContentList: ContentItemQuiz[] = [...quizContentList].sort((a, b) => b.quizType - a.quizType)

      const userContent: UserContent = {
        key: BookerUtility.createKey(),
        contentKey: contentKey,
        type: ContentItemType.Quiz,
        isComplete: false,
        classStep: findContent.classStep,
        userContentList
      }

      const result = await this.model.addUserContent(userContent, findContent.classStep, false, '');
      if (result) {
        // 성공 처리
        this.openedContent = userContent;
        this.selectedQuizKey = this.openedContent.userContentList[0].key;
        console.log('selectedQuiz', this.selectedQuiz)
        return;
      }
      alert('다시 풀기에 실패하였습니다.');
    }
  }

  async updateUserContent() {
    if (this.openedContent) {
      const result = await this.model.updateUserContent(this.openedContent);
      if (result) {
        return;
      }
      alert('저장에 실패하였습니다.');
    }
  }

}
