
import {Component, Emit, Ref, Vue} from "vue-property-decorator";
import * as THREE from 'three';

@Component({name: "Avatar", components: {}})
export default class Avatar extends Vue {

  @Ref() refThree!: HTMLDivElement;

  mounted() {
    removeEventListener('load', this.drawAvatar)
    removeEventListener('resize', this.resize)
    // 페이지 로드가 완료된 상태에서는 load 이벤트가 발생하지 않으므로 직접 호출
    if (document.readyState === 'complete') {
      this.drawAvatar();
    } else {
      addEventListener('load', this.drawAvatar)
    }
    addEventListener('resize', this.resize)
  }

  beforeDestroy() {
    removeEventListener('load', this.drawAvatar)
    removeEventListener('resize', this.resize)
  }

  @Emit('loadingAvatar')
  loadedAvatar() {
    //
  }

  resize() {
    const size = 600
    const canvas = document.querySelector('#three canvas') as HTMLCanvasElement;
    if (!canvas) return;
    canvas.style.width = `${size}px`;
    canvas.style.height = `${size}px`;
  }

  drawAvatar() {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(60, this.refThree.offsetWidth / this.refThree.offsetHeight, 0.1, 1000);
    const mixers: any [] = [];
    const clock = new THREE.Clock();

    let character;

    const renderer = new THREE.WebGLRenderer({alpha: true});
    renderer.setClearColor(0x000000, 0);
    const size = 600;
    renderer.setSize(size, size);
    renderer.outputEncoding = THREE.sRGBEncoding;

    this.refThree.appendChild(renderer.domElement);
    camera.position.set(0, 180, 100);
    // const controls = new OrbitControls( camera, renderer.domElement );
    // controls.target = new THREE.Vector3(0, 140, 160);
    // controls.update();

    const ambientLight = new THREE.AmbientLight(0x222222, 13);


    scene.add(ambientLight);


    // var fbxLoader = new FBXLoader();


    const loader = new THREE.ObjectLoader();

    loader.load('/custom_assets/json/model_2.json', (object: any) => {
      console.log('parse', object)
      character = object;
      object.mixer = new THREE.AnimationMixer(object);

      mixers.push(object.mixer);

      scene.add(object);

      const action = object.mixer.clipAction(object.animations[0]);
      // action.setLoop(THREE.LoopRepeat, 10);
      action.reset().play();
      // console.log('object action : ',action )

      // action.play();
      this.loadedAvatar();
      this.resize();
    })


    function animate() {
      requestAnimationFrame(animate);

      if (mixers.length > 0) {

        for (let i = 0; i < mixers.length; i++) {

          //@ts-ignore
          if (mixers[i].update) mixers[i].update(clock.getDelta());
          // clock.getDelta()
        }

      }


      renderer.render(scene, camera);
    }

    animate();

  }

}
