
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {
  ContentItemFigure,
  ContentItemType,
  DraggingObjectType,
  ShapeItemType,
  TimelineContent
} from "@/Booker/lib/BookerTypes";
import ElementDragHandler from "@/assets/lib/dom/ElementDragHandler";
import {PT} from "@/assets/lib/dom/DomTypes";
import DraggingItemModel from "@/Booker/lib/model/DraggingItemModel";

@Component({
  name: "FigureTimelineItem",
  components: {}
})
export default class FigureTimelineItem extends Vue {

  @Prop() timelineContent!: TimelineContent
  @Prop() elementDragHandler!: ElementDragHandler
  @Prop() timelineSize!: number
  @Prop() itemIndex!: number

  @Ref() refView!: HTMLElement;
  @Ref() refFigure!: HTMLLIElement;

  figure: ContentItemFigure | undefined;
  captionStatus = false
  exStatus = false
  isResizing = false

  startWidth = 0
  startX = 0

  shapesMenu = [
    {
      imgSrc: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_square.png"),
      type: ShapeItemType.Square
    },
    {
      imgSrc: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_circle.png"),
      type: ShapeItemType.Circle
    },
    {
      imgSrc: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_triangle.png"),
      type: ShapeItemType.Polygon
    },
    {
      imgSrc: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_star.png"),
      type: ShapeItemType.Star
    },
  ]

  get shapeIcon() {
    switch (this.figure?.figureType) {
      case ShapeItemType.Square:
        return require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_square.png")
      case ShapeItemType.Circle:
        return require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_circle.png")
      case ShapeItemType.Text:
        return require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_text.png")
      case ShapeItemType.Star:
        return require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_star.png")
      case ShapeItemType.Polygon:
        return require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_triangle.png")
    }
    return require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_text.png")
  }

  get shapeBgColor() {
    return this.figure?.bgColor
  }

  get shapeTextColor() {
    return this.figure?.textColor
  }


  get nowWidth() {
    return ((((this.timelineContent.end! - this.timelineContent.start) / 1000) * this.timelineSize)).toFixed(0);
  }

  created() {
    this.figure = this.timelineContent.content as ContentItemFigure;
  }

  mounted() {
    addEventListener('keydown', this.deleteKeyInput)
  }

  beforeDestroy() {
    removeEventListener('keydown', this.deleteKeyInput)
  }

  deleteKeyInput(event: KeyboardEvent) {
    if (event.code === 'Delete' && this.$el.classList.contains('focus')) {
      if (confirm('삭제하시겠습니까?')) this.delTimelineItem();
    }
  }

  addFocus(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.$el.classList.contains('focus')) {
      this.$el.classList.add('focus');
    } else {
      this.$el.classList.remove('focus')
    }

  }

  /**
   * timelineContent 삭제
   */
  @Emit('delTimelineItem')
  delTimelineItem() {
    return this.timelineContent;
  }


  /**
   * SeekBar
   */
  @Emit('setPreSeekBar')
  setPreSeekBar(isPreSeekBar: boolean) {
    return isPreSeekBar;
  }

  /**
   * timelineContent 시간 변경
   */
  @Emit('timelineContentTimeChange')
  timelineContentTimeChange(contentItemType: ContentItemType, itemIndex: number, endTime: number) {
    return {contentItemType, itemIndex, endTime};
  }


  /**
   * return time duration in korean
   */
  getDurationKorean(duration: number): string {
    const durationSec = Math.floor(duration / 1000);
    const durationMin = Math.floor(durationSec / 60);
    const durationHour = Math.floor(durationMin / 60);
    const durationDay = Math.floor(durationHour / 24);

    if (durationDay > 0) {
      return `${durationDay}일 ${durationHour % 24}시간 ${durationMin % 60}분 ${durationSec % 60}초`
    } else if (durationHour > 0) {
      return `${durationHour}시간 ${durationMin % 60}분 ${durationSec % 60}초`
    } else if (durationMin > 0) {
      return `${durationMin}분 ${durationSec % 60}초`
    } else {
      return `${durationSec}초`
    }
  }

  /**
   * 자막 리사이즈
   */

  subtitleEnter(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isResizing) return;
    this.exStatus = true
  }

  subtitleLeave(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isResizing) return;
    this.exStatus = false
  }

  deleteStatus = true
  resizeSubtitleDown(event: MouseEvent) {
    if (!(event.target as HTMLElement).classList.contains('side-bar')) return;

    this.isResizing = true;
    this.startX = event.clientX;
    this.startWidth = this.$el.clientWidth;
    this.exStatus = false
    this.deleteStatus = false;

    this.setPreSeekBar(false);
    window.addEventListener('mousemove', this.resizeMove);
    window.addEventListener('mouseup', this.resizeUp);
  }

  resizeMove(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const liWidth = this.refFigure.getBoundingClientRect().width;
    if(liWidth <= 40) {
      this.refFigure.classList.add('overflow-hidden')
    } else {
      this.refFigure.classList.remove('overflow-hidden')
    }


    if (this.isResizing) {
      const deltaX = event.clientX - this.startX
      if (this.timelineContent.end) {
        let endTime = (1000 / this.timelineSize * (this.startWidth + deltaX)) + this.timelineContent.start
        const minTime = this.timelineContent.start + 1000
        if (endTime < minTime) endTime = minTime
        this.timelineContentTimeChange(this.timelineContent.type, this.itemIndex, endTime);
      }
    }
  }

  resizeUp(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isResizing = false;
    this.setPreSeekBar(true);
    this.deleteStatus = true;

    window.removeEventListener('mousemove', this.resizeMove);
    window.removeEventListener('mouseup', this.resizeUp);
  }

  /**
   * 퀴즈 말하기 교재 Drag Reposition
   */

  startDrag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.elementDragHandler.mouseDown(event, this.dragTimelineItem)
  }


  dragTimelineItem(offset: null | PT, pt?: PT) {
    if (!offset) {
      this.repositionMouseUp();
      return;
    }

    if (offset.x === 0 && offset.y === 0) {
      this.repositionMouseDown();
      return;
    }

    this.repositionMouseMove();
  }

  @Emit('endRepositionTimeline')
  repositionMouseUp() {
    this.$el.classList.remove('drag');
    return {timelineContent: this.timelineContent, timelineIndex: this.itemIndex}
  }

  repositionMouseDown() {
    DraggingItemModel.setItem(DraggingObjectType.TimelineContent, this.timelineContent);
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');
  }

  repositionMouseMove() {
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');
  }


}
