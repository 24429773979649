
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {QuizItem} from "@/Booker/lib/BookerTypes";
import CommonInput from "../common/CommonInput.vue";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";
import QuizScore from "@/components/ui/quiz/QuizScore.vue";

@Component({
  name: "QuizInput",
  components: {QuizScore, CommonTextArea, CommonInput}
})
export default class QuizInput extends Vue {

  @Ref() refQuizImg!: HTMLImageElement
  @Prop() question!: QuizItem;
  @Prop() quizModel!: ContentItemQuizEditorViewModel

  @Prop() difficultyIdx!: number
  @Prop() difficulty!: number
  @Prop() questionIdx!: number

  BookerUtility = BookerUtility
  explainTextStatus = true
  correctTextStatus = true

  get getQuizImg() {
    return this.question.img
  }

  @Emit('checkSave')
  onInputAnswer(e: Event) {
    //
    this.question.options[0].answer = (e.target as HTMLInputElement).value;
  }

  @Emit('checkSave')
  onInputQuestion(e: Event) {
    this.question.question = (e.target as HTMLInputElement).value;
  }

  onInputCorrectAnswer(e: Event) {
    this.question.correctAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setCorrectMsg(this.difficultyIdx, this.difficulty, this.question.correctAnswerMsg);
  }

  onInputInCorrectAnswer(e: Event) {
    this.question.inCorrectAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setInCorrectMsg(this.difficultyIdx, this.difficulty, this.question.inCorrectAnswerMsg);
  }

  showCorrectAnswer() {

    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.correctAnswerMsg;
      if (typeof subQ === "string") {
        this.quizModel?.delCorrectMsg(this.difficultyIdx, this.difficulty)
        this.correctTextStatus = true
      } else {
        this.quizModel?.setCorrectMsg(this.difficultyIdx, this.difficulty, '')
        this.correctTextStatus = false
      }
    }
  }

  showExplain() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.explain;
      if (typeof subQ === "string") {
        this.quizModel?.delExplain(this.difficultyIdx, this.difficulty)
        this.explainTextStatus = true
      } else {
        this.quizModel?.setExplain(this.difficultyIdx, this.difficulty, '')
        this.explainTextStatus = false
      }
    }
  }

  explainInput(e: Event) {
    this.question.explain = (e.target as HTMLTextAreaElement).value;
  }

  subQuestionInput(e: Event) {
    this.question.subQuestion = (e.target as HTMLTextAreaElement).value;
  }

  setShortAnswer(text: string) {
    this.question.options[0].answer = text;
  }

  onInputSubAnswer(e: Event) {
    const input = (e.target as HTMLInputElement);

    if (input && typeof input.dataset.index === "string") {
      const subAnswersIndex = parseInt(input.dataset.index);
      this.setSubAnswer(input.value, subAnswersIndex);
    }
  }

  setSubAnswer(text: string, subAnswersIndex: number) {
    this.quizModel.setSubAnswer(this.questionIdx, this.difficulty, 0, subAnswersIndex, text);
  }

  addSubAnswer() {
    this.quizModel.addSubAnswer(this.difficultyIdx, this.difficulty, 0);
  }

  delSubAnswer(subAnswersIndex: number) {
    this.quizModel.delSubAnswer(this.difficultyIdx, this.difficulty, 0, subAnswersIndex);
  }

}
