import {BoundTransform, ContentItemFigure} from "@/Booker/lib/BookerTypes";

export type TextOverlayAlign = 'left' | 'center' | 'right'

export default class ContentItemFigureEditorViewModel {

  figureItem: ContentItemFigure

  constructor(figureItem: ContentItemFigure) {
    this.figureItem = figureItem;
  }

  get boundStyle() {
    return {
      width: this.figureItem.bound?.width ? this.figureItem.bound?.width : '',
      height: this.figureItem.bound?.height ? this.figureItem.bound?.height : '',
      transform: this.figureItem.bound?.transform ? this.figureItem.bound?.transform : '',
      left: this.figureItem.bound?.left ? this.figureItem.bound?.left : '',
      top: this.figureItem.bound?.top ? this.figureItem.bound?.top : '',
    }
  }

  get fontStyle() {
    return {
      textColor: this.figureItem.textColor,
      size: this.figureItem.size,
      bgColor: this.figureItem.bgColor
    }
  }

  changeColor(color: string) {
    this.figureItem.textColor = color;
  }

  changeSize(size: number) {
    this.figureItem.size = size;
  }

  changeBackgroundColor(color: string) {
    this.figureItem.bgColor = color;
  }

  changeBound(bound: BoundTransform) {
    const width = Number(bound.width.replace('px', ''))
    const height = Number(bound.height.replace('px', ''))
    this.figureItem.bound = {
      transform: `translate(${width / 2}px, ${height / 2}px) rotate(${bound.angle}deg) translate(${-width / 2}px, ${-height / 2}px)`,
      left : `${bound.currentX}px`,
      top : `${bound.currentY}px`,
      width: bound.width,
      height: bound.height
    }
  }

  setText(text: string) {
    this.figureItem.text = text;
  }

  getText() {
    return this.figureItem.text
  }

  getBgColor() {
    return this.figureItem.bgColor
  }

  getTextColor() {
    return this.figureItem.textColor
  }

  getTextSize() {
    return this.figureItem.size
  }

}
