
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component({name: "CommonBookerModal", components: {}})
export default class CommonBookerModal extends Vue {
  @Prop() modalTitle!: string

  @Emit('closeModal')
  closeModal() {
    console.log('123')
    return
  }

}
