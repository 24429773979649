
import {Component, Prop, Vue} from "vue-property-decorator";
import {QuizItem} from "@/Booker/lib/BookerTypes";

@Component({name: "CommonPassageExplainBtnWrapper", components: {}})
export default class CommonPassageExplainBtnWrapper extends Vue {
  @Prop() inputClass !: string
  @Prop() placeholder!: string
  @Prop() readonly !: boolean
  @Prop() inputValue!: any
  @Prop() updateInput!: any
  @Prop() quiz!: QuizItem

  moreExplainStatus = false

  moreExplain() {
    this.moreExplainStatus = !this.moreExplainStatus
  }
}
