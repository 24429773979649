
import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator'
import MyMsg from '@/components/ui/chatting/MyMsg.vue'
import AiMsg from '@/components/ui/chatting/AiMsg.vue'
import TodayQuiz from '@/components/ui/chatting/TodayQuiz.vue'
import AiProfile from '@/components/ui/chatting/AiProfile.vue'
import TodayBook from '@/components/ui/chatting/TodayBook.vue'
import QuizMsg from '@/components/ui/chatting/QuizMsg.vue'
import VoiceRecognition from "@/components/VoiceRecognition.vue";
import {ChatBotPostMsg, ChatBotPostMsgType, EventType} from "@/assets/lib/type/Types";
import GenieApi from "@/assets/lib/api/GenieApi";
import Chatbot from "@/assets/lib/utility/Chatbot";
import Config from "@/config";


@Component({
  components: {MyMsg, AiMsg, TodayQuiz, AiProfile, TodayBook, QuizMsg, VoiceRecognition}
})
export default class ChatRoom extends Vue {

  @Ref() refAudio!: HTMLAudioElement;
  @Prop() isFull!: boolean
  @Ref() readonly voiceRecognition!: VoiceRecognition;
  @Ref() refChatBot!: HTMLDivElement;
  isMute = true;
  msgList: string[] = [];
  isInit = false;

  defaultVoice = false

  mounted() {

    const checkIframe = document.querySelector('iframe');
    if (checkIframe) {
      return;
    }

    const iframe = document.createElement('iframe');
    iframe.src = window.location.origin.indexOf('localhost:808') > -1 ? 'http://localhost:8081' : Config.CONFIG.RASA_URL;
    this.refChatBot.append(iframe);
    iframe.style.border = 'none';
    iframe.style.width = 100 + '%';
    iframe.style.height = 100 + '%';
    iframe.style.fontFamily = 'NanumSquareRound';
    iframe.allow = 'autoplay'
    this.initChatEvent();

  }

  @Emit('showModal')
  showModal() {
    return true
  }

  getVoiceText(voiceData: { isSuc: boolean, msg: string }) {
    const {isSuc, msg} = voiceData;
    if (!isSuc) return;
    const chatBotPostMsg: ChatBotPostMsg = {
      chatBotPostMsgType: ChatBotPostMsgType.Speech,
      data: msg
    }
    this.sendChatBot(chatBotPostMsg)
  }

  sendChatBot(chatBotPostMsg: ChatBotPostMsg) {
    const chatBot = document.querySelector('iframe');
    if (chatBot && chatBot.contentWindow) chatBot.contentWindow.postMessage(chatBotPostMsg, '*')
  }

  beforeDestroy() {
    Chatbot.removeChatBotEventListener(this.handleChatEvent)
  }

  changeVolume() {
    this.refAudio.muted = !this.isMute;
    this.isMute = this.refAudio.muted
    if (this.isMute) this.refAudio.volume = 0;
    else this.refAudio.volume = 1;
  }

  initChatEvent() {
    Chatbot.addChatBotEventListener(this.handleChatEvent)
    window.addEventListener('message', this.handleChatEvent)
    window.addEventListener('click', () => {
      if (this.refAudio && !this.isInit) {
        this.refAudio.volume = 1;
        this.refAudio.muted = false;
        this.isInit = true;
        this.isMute = false;
      }
    })
  }

  handleChatEvent(message: MessageEvent) {
    switch (message.data.eventType) {
      case EventType.Speech:
        this.openVoice();
        break;
      case EventType.URL:
        this.$router.push(message.data.value.data)
        break;
      case EventType.Voice:
        this.msgList.push(message.data.value.data)
        this.checkMsgList();
        break;
    }

  }

  checkMsgList() {
    if (this.msgList.length === 0) return;
    if (!this.refAudio.paused) {
      return;
    }
    const text = this.msgList.shift();
    if (text) this.voiceAnswer(text)
  }

  voiceAnswer(text: string) {
    GenieApi.textToSpeech({
      text: text,
      speaker: 'man1'
    }).then(async (res: Blob | null) => {
      if (!res) return null;
      this.refAudio.src = URL.createObjectURL(res)
      this.refAudio.play();
      this.refAudio.onended = () => {
        if (this.refAudio) URL.revokeObjectURL(this.refAudio.src)
        this.checkMsgList()
        if (this.msgList.length === 0) {
          this.playerStart();
        }
      }
    })
  }

  @Emit('playerStart')
  playerStart() {
    //
  }

  @Emit('playerPause')
  openVoice() {
    this.defaultVoice = true
  }

  async closePopup() {
    await this.voiceRecognition?.stopRecord();
    this.defaultVoice = false;
  }

}

