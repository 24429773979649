import {CreateUUIDRequest, OAuthTokenRequest} from "@/assets/lib/type/ApiRequestTypes";
import App from "@/assets/lib/controller/App";
import CryptoUtil from "@/assets/lib/crypto/CryptoUtil";
import ObjectStorage from '@/assets/lib/utility/ObjectStorage';
import {UserSession, UserUuid} from "@/assets/lib/type/Types";
import Logger from "../debug/Logger"
import Config from "@/config";
import GenieApi from "@/assets/lib/api/GenieApi";
import UserApi from "@/assets/lib/api/UserApi";
import {LoginResponse, LoginUserInfo, UserType} from "@/assets/lib/type/ApiResponseTypes";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";

export default class AuthController {
  private readonly logger = new Logger('[AuthController]')
  private _userDifficulty = 0;
  private defaultToken: LoginResponse = {
    token_info: {
      token: '',
      expire_at: 0
    },
    user_info: {
      user_id: '',
      user_name: '',
      user_level: 0,
      user_type: UserType.Student
    }
  }
  private _loginResponse = new ObjectStorage<LoginResponse>('ai-tutor-user-token', this.defaultToken)

  get token(): string {
    return this._loginResponse.value.token_info.token
  }

  get userInfo(): LoginUserInfo {
    return this._loginResponse.value.user_info
  }

  get isTeacherLogin() {
    return this.isLogin && this.userInfo.user_type === UserType.Teacher
  }

  get userNameWithType(): string {
    const userInfo = this.userInfo
    switch (userInfo.user_type) {
      case UserType.Student:
        return `${userInfo.user_name} 학생`
      case UserType.Teacher:
        return `${userInfo.user_name} 선생님`
    }
    return userInfo.user_name
  }

  private _session = new ObjectStorage<UserSession>('ai-tutor-std-session', {
    user_idx: 0,
    uid: '',
    name: '',
    token: null
  })

  get session() {
    return this._session.value
  }

  private _uuid = new ObjectStorage<UserUuid>('ai-tutor-std-uuid', {uid: '', uuid: null})

  get uuid() {
    return this._uuid.value
  }

  get hasValidSession() {
    return !!(this.session.token && this.session.uid)
  }

  get isLogin() {
    const {token_info, user_info} = this._loginResponse.value
    const {token, expire_at} = token_info
    if (!token) return false // no token
    if (expire_at <= Math.floor(Date.now() / 1000)) return false // token expired
    if (!user_info) return false // no user id
    if (user_info.user_id === '') return false // no user id
    this.setUserDifficulty(user_info.user_level);
    return true
  }

  setUserDifficulty(difficulty: number) {
    this._userDifficulty = difficulty
  }

  getUserDifficulty() {
    return this._userDifficulty
  }

  /**
   * request access token using code
   * @param code
   */
  oAuthToken(code: string) {
    // generate login hash
    const hash = CryptoUtil.sha256(App.OAuthClientId + App.OAuthClientSecret + code)

    const req: OAuthTokenRequest = {
      client_id: App.OAuthClientId,
      code,
      hash
    }
    return GenieApi.oAuthToken(req, async res => {
      this.session.token = res.data
      this._session.save()
    }, err => {
      this.logger.error('oAuthToken error', err)
    })
  }

  /**
   * update user session
   */
  updateUserSession() {
    if (!this.session.token) {
      this.logger.error('no token exists')
      return
    }

    return GenieApi.userInfo(this.session.token.access_token, {}, res => {
      Object.assign(this._session.value, res.data.user_info)
      this._session.save()
    }, err => {
      this.logger.error('updateUserSession error', err)
    })
  }

  /**
   * request uuid using signature
   * @param uid
   */
  setUuid(uid = 'test3211') {
    // generate login hash
    const timeStamp = (new Date()).toISOString().replace(/[^0-9]/g, '')

    const req: CreateUUIDRequest = {
      clientKey: Config.CONFIG.CLIENT_KEY,
      userId: uid,
      timestamp: timeStamp,
      signature: CryptoUtil.signature(timeStamp)
    }
    return GenieApi.getUuid(req, async res => {
      this.uuid.uuid = res.data.uuid
      this._uuid.save()
    }, err => {
      this.logger.error('uuid error', err)
    })
  }

  /**
   * request login and token
   * @param userId
   * @param userPw
   */
  async login(userId: string, userPw: string) {
    return await UserApi.login({
      user_id: userId,
      user_pw: userPw
    }, async (onSuc) => {
      this._loginResponse.value = onSuc.data
      await ClassContentManager.controller.model.init()
      return true
    }, (err) => {
      console.log('err', err)
      // alert('아이디와 비밀번호를 확인해주세요.')
      return false
    })
  }

  async logout() {
    await ClassContentManager.controller.model.init()
    this._loginResponse.value = this.defaultToken;
    window.location.reload();
  }


}
