import { render, staticRenderFns } from "./EditPronunciation.vue?vue&type=template&id=080faf87&scoped=true&"
import script from "./EditPronunciation.vue?vue&type=script&lang=ts&"
export * from "./EditPronunciation.vue?vue&type=script&lang=ts&"
import style0 from "./EditPronunciation.vue?vue&type=style&index=0&id=080faf87&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080faf87",
  null
  
)

export default component.exports