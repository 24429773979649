import ClassContentModel from "@/Booker/lib/model/ClassContentModel";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import {ContentItemPronunciation} from "@/Booker/lib/BookerTypes";

export default class MySpeakingViewModel {
  model: ClassContentModel
  openedContent: ContentItemPronunciation | null = null;

  constructor() {
    this.model = ClassContentManager.controller.model
  }

  get releasedContentList() {
    return this.model.releaseClassList.filter((content) => content.isRelease);
  }


  get userClassList() {
    return this.model.userClassList
      .filter((content) =>
        this.releasedContentList.findIndex(releasedContent => releasedContent.key === content.key) !== -1);

  }

  openContent(content: ContentItemPronunciation) {
    console.log('content',content)
    this.openedContent = content;
  }


}
