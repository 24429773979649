
import {Component, Prop, Vue} from "vue-property-decorator";
import {ContentItemQuiz, UserContent} from "@/Booker/lib/BookerTypes";
import RetakeMyQuiz from "@/views/ClassPage/component/RetakeMyQuiz.vue";
import MyContentViewModel from "@/assets/lib/viewModel/MyContentViewModel";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

@Component({name: "MyQuizChart", components: {RetakeMyQuiz}})
export default class MyQuizChart extends Vue {
  @Prop() vm !: MyContentViewModel
  @Prop() list!: ContentItemQuiz
  @Prop() quizType ?: string;
  @Prop() quizIndex !: number;
  @Prop() classStep !: number;

  get userContentListByClassStep() {
    return this.vm.model.userContentList.filter((item: UserContent) => item.classStep === this.classStep)
  }

  get scoreData() {
    return this.vm.aiReportModel.scoreDetailByClassStep(this.classStep)
  }

  get quizCorrectCount() {
    const quizCount = this.scoreData.quiz.filter(
      (item: {
        quizUuid: string,
        isCorrect: boolean,
        score: number
      }) => item.isCorrect).length
    const quizScore = this.scoreData.quiz.reduce((acc, cur) => acc + cur.score, 0)
    const quizLength = this.scoreData.quiz.length
    const practiceCount = this.scoreData.practice.filter(
      (item: {
        quizUuid: string,
        isCorrect: boolean,
        score: number
      }) => item.isCorrect).length
    const practiceScore = this.scoreData.practice.reduce((acc, cur) => acc + cur.score, 0)
    const practiceLength = this.scoreData.practice.length
    const realCount = this.scoreData.real.filter(
      (item: {
        quizUuid: string,
        isCorrect: boolean,
        score: number
      }) => item.isCorrect).length
    const realScore = this.scoreData.real.reduce((acc, cur) => acc + cur.score, 0)
    const realLength = this.scoreData.real.length
    return {
      quiz: {
        count: quizCount,
        length: quizLength,
        score: quizScore
      },
      practice: {
        count: practiceCount,
        length: practiceLength,
        score: practiceScore
      },
      real: {
        count: realCount,
        length: realLength,
        score: realScore
      },
      total: {
        count: quizCount + practiceCount + realCount,
        length: quizLength + practiceLength + realLength,
        score: quizScore + practiceScore + realScore
      }
    }
  }

  get content() {
    return this.vm.userContentList.find((item) => item.classStep === this.classStep)
  }

  get subTitle() {
    return BookerUtility.classSubTitle(this.classStep)
  }

  get quizList() {
    return this.vm.aiReportModel.quizScoreDetailForMyQuiz
  }

  get quizListForMyQuiz() {
    return this.vm.aiReportModel.quizScoreDetailForMyQuiz
  }

  get isAllComplete() {
    if (this.vm.userContentByClassStep(this.classStep).length === 0) return true
    const lastContentIndex = this.vm.userContentByClassStep(this.classStep).length - 1
    return this.vm.userContentByClassStep(this.classStep)[lastContentIndex].info.isComplete
  }

  quizCorrectCountByUserContent(userContent: UserContent) {

    const scoreData = this.vm.scoreDetailByClassStep(userContent)

    const quizCount = scoreData.quiz.filter(
      (item: {
        quizUuid: string,
        isCorrect: boolean,
        score: number
      }) => item.isCorrect).length
    const quizScore = scoreData.quiz.reduce((acc, cur) => acc + cur.score, 0)
    const quizLength = scoreData.quiz.length
    const practiceCount = scoreData.practice.filter(
      (item: {
        quizUuid: string,
        isCorrect: boolean,
        score: number
      }) => item.isCorrect).length
    const practiceScore = scoreData.practice.reduce((acc, cur) => acc + cur.score, 0)
    const practiceLength = scoreData.practice.length
    const realCount = scoreData.real.filter(
      (item: {
        quizUuid: string,
        isCorrect: boolean,
        score: number
      }) => item.isCorrect).length
    const realScore = scoreData.real.reduce((acc, cur) => acc + cur.score, 0)
    const realLength = scoreData.real.length
    return {
      quiz: {
        count: quizCount,
        length: quizLength,
        score: quizScore
      },
      practice: {
        count: practiceCount,
        length: practiceLength,
        score: practiceScore
      },
      real: {
        count: realCount,
        length: realLength,
        score: realScore
      },
      total: {
        count: quizCount + practiceCount + realCount,
        length: quizLength + practiceLength + realLength,
        score: quizScore + practiceScore + realScore
      }
    }
  }

  formatDate(timestamp: number): string {
    return BookerUtility.formatDate(timestamp);
  }

  classByScore(score: number) {
    switch (true) {
      case score < 50:
        return 'bad';
      case score < 75:
        return 'soso';
      default:
        return '';
    }
  }

}
