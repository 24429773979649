
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class QuizResult extends Vue {
  @Prop() quizFail!: boolean
  @Prop() quizGood!: boolean
  @Prop() isRepeat!: boolean

  @Emit('closePopUp')
  closePopUp() {
    return;
  }
}
