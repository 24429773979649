
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import MyContentViewModel from "@/assets/lib/viewModel/MyContentViewModel";
import MyQuizStart from "@/components/ui/quiz/MyQuizStart.vue";

@Component({name: "MyQuizModal", components: {MyQuizStart}})
export default class MyQuizModal extends Vue {
  @Prop() vm !: MyContentViewModel
  @Ref() refQuizBody!: HTMLDivElement

  mounted() {
    this.vm.scrollCallBack = () => this.scrollTop.bind(this)
  }

  scrollTop() {
    this.refQuizBody.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  }

}
