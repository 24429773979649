
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import ShapeColorPicker from "@/Booker/views/components/video/player/shape/ShapeColorPicker.vue";

@Component({
  name: "CreatePage",
  computed: {},
  components: {ShapeColorPicker, CommonInput}
})
export default class CreatePage extends Vue {

  @Prop() pageModalStatus!: boolean
  @Prop() model!: ClassContentMediaListViewModel;
  @Ref() refPageItem!: HTMLElement


  currentBgColor = '#fff'
  pageTitle = true
  pageTitleInput = ''

  bgColorChange(color: string) {
    this.currentBgColor = color
  }

  changeTitle() {
   this.pageTitle = !this.pageTitle
  }

  init() {
    // this.figureViewModel = new ContentItemFigureEditorViewModel(this.timelineContent.content as ContentItemFigure)
  }

  created() {
    // this.figure = (this.timelineContent.content as ContentItemFigure)
  }

  mounted() {
    // if (!this.figureViewModel) this.init()
  }

  @Emit('closeModal')
  closeModal() {
    return this.pageModalStatus
  }

  @Emit('addPage')
  addPage() {
    return [this.pageTitleInput, this.currentBgColor];
  }

}
