
import {Component, Emit, Prop, Ref, Vue} from 'vue-property-decorator';
import {
  ContentItemBase,
  ContentItemImage,
  ContentItemType,
  ContentItemVideo,
  DraggingObjectType,
  TimelineContent,
  VideoContentSourceType,
  YoutubeItem
} from "@/Booker/lib/BookerTypes";
import DraggingItemModel from "@/Booker/lib/model/DraggingItemModel";
import ElementDragHandler from "@/assets/lib/dom/ElementDragHandler";
import DomHelper from "@/assets/lib/dom/DomHelper";
import {PT} from "@/assets/lib/dom/DomTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import Hls from "hls.js";

@Component({
  name: 'VideoTimelineItem',
})
export default class VideoTimelineItem extends Vue {
  @Prop() itemIndex!: number
  @Prop() timelineContent!: TimelineContent
  @Prop() elementDragHandler!: ElementDragHandler
  @Prop() timelineSize!: number
  @Ref() refView!: HTMLDivElement;
  @Ref() refDragView!: HTMLDivElement;
  @Ref() refVideo!: HTMLLIElement;
  @Ref() refVideoHls!: HTMLVideoElement;
  @Ref() refVideoHls2!: HTMLVideoElement;

  @Prop() youtubeDuration!: YoutubeItem

  BookerUtility = BookerUtility;
  VideoContentSourceType = VideoContentSourceType
  ContentItemType = ContentItemType
  exStatus = false
  isResizing = false
  startWidth = 0
  startX = 0
  oldStartAt? = 0
  oldEndAt? = 0
  mouseRelativeCoordinatePT: PT = {x: 0, y: 0}

  deleteStatus = true
  hls = new Hls();

  thumbnail: string | null = null;
  endTime = 0
  liWidth = 0
  // 비디오 설정 모달
  @Prop() modalStatus !: boolean

  get nowWidth() {
    return (this.duration / 1000 * this.timelineSize).toFixed(0);
  }

  get isVideo() {
    if (this.timelineContent) return this.timelineContent.content.type === ContentItemType.Video
    return false;
  }

  get duration() {
    return this.timelineContent.end! - this.timelineContent.start;
  }

  get getTotalDurationKorean() {
    return this.getDurationKorean(this.duration)
  }

  mounted() {

    const liElement = this.refVideo;
    if (liElement) {
      const liWidth = liElement.getBoundingClientRect().width
      this.liWidth = liWidth
    }

    addEventListener('keydown', this.deleteKeyInput)
    if (this.refVideoHls && this.timelineContent.content.type === ContentItemType.Video) {
      this.hls.loadSource((this.timelineContent.content as ContentItemVideo).source)
      this.hls.attachMedia(this.refVideoHls);
      const hls = new Hls()
      hls.loadSource((this.timelineContent.content as ContentItemVideo).source)
      hls.attachMedia(this.refVideoHls2);

      hls.once(Hls.Events.FRAG_PARSED, () => {
        setTimeout(() => {
          if (this.thumbnail === null) {
            const canvas = document.createElement('canvas');
            canvas.width = this.refVideoHls.videoWidth;
            canvas.height = this.refVideoHls.videoHeight;
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.drawImage(this.refVideoHls, 0, 0, canvas.width, canvas.height);
              this.thumbnail = canvas.toDataURL()
            }
          }
        }, 1000)
      });

    }
  }

  beforeDestroy() {
    removeEventListener('keydown', this.deleteKeyInput)
  }

  /**
   * vue 이벤트
   */

  deleteKeyInput(event: KeyboardEvent) {
    if (event.code === 'Delete' && this.$el.classList.contains('focus')) {
      if (confirm('삭제하시겠습니까?')) this.delTimelineItem();
    }
  }

  /**
   * PreviewSeekBar 상태 변경
   */
  @Emit('setPreSeekBar')
  setPreSeekBar(isPreSeekBar: boolean) {
    return isPreSeekBar;
  }

  /**
   * timelineContent 삭제
   */
  @Emit('delTimelineItem')
  delTimelineItem() {
    return this.timelineContent;
  }

  /**
   * timelineContent 시간 변경
   */
  @Emit('timelineContentTimeChange')
  timelineContentTimeChange(contentItemType: ContentItemType, itemIndex: number, endTime: number) {
    return {contentItemType, itemIndex, endTime};
  }

  /**
   * timelineVideo 시작 시간 변경
   */
  @Emit('timelineContentStartAtChange')
  setTimelineVideoStartAt(contentItemType: ContentItemType, itemIndex: number, startAt: number) {
    return {contentItemType, itemIndex, startAt};
  }

  convertTypeToVideo(content: ContentItemBase): ContentItemVideo {
    return content as ContentItemVideo;
  }

  convertTypeToImage(content: ContentItemBase): ContentItemImage {
    return content as ContentItemImage;
  }

  /**
   * return time duration in korean
   */
  getDurationKorean(duration: number): string {
    const durationSec = Math.floor(duration / 1000);
    const durationMin = Math.floor(durationSec / 60);
    const durationHour = Math.floor(durationMin / 60);
    const durationDay = Math.floor(durationHour / 24);

    if (durationDay > 0) {
      return `${durationDay}일 ${durationHour % 24}시간 ${durationMin % 60}분 ${durationSec % 60}초`
    } else if (durationHour > 0) {
      return `${durationHour}시간 ${durationMin % 60}분 ${durationSec % 60}초`
    } else if (durationMin > 0) {
      return `${durationMin}분 ${durationSec % 60}초`
    } else {
      return `${durationSec}초`
    }
  }

  // 미디어 클릭시
  mediaAddFocus(e: MouseEvent, direction: 'left' | 'right') {
    e.preventDefault();
    e.stopPropagation();
    this.resizeMediaUp(e, direction);
    this.repositionMouseUp();
    if (!this.$el.classList.contains('focus')) this.$el.classList.add('focus')
    else this.$el.classList.remove('focus')
  }

  mediaEnter(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isResizing) return;
    this.exStatus = true
  }

  mediaLeave(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isResizing) return;
    this.exStatus = false
  }

  resizeMediaDownLeft(event: MouseEvent) {
    if (!(event.target as HTMLElement).classList.contains('side-bar')) return;

    this.resizeMediaDown(event, 'left');
    //this.resizeMediaDownTwo()
  }

  resizeMediaDownRight(event: MouseEvent) {
    if (!(event.target as HTMLElement).classList.contains('side-bar')) return;

    this.resizeMediaDown(event, 'right');
  }

  resizeMediaDown(event: MouseEvent, direction: 'right' | 'left') {
    if (!(event.target as HTMLElement).classList.contains('side-bar')) return

    this.isResizing = true;
    this.deleteStatus = false

    this.startX = event.clientX;
    this.oldStartAt = this.timelineContent.startAt ? this.timelineContent.startAt : 0;
    this.oldEndAt = this.timelineContent.endAt ? this.timelineContent.endAt : 0;
    this.startWidth = this.$el.clientWidth
    this.exStatus = false

    this.setPreSeekBar(false);

    window.addEventListener('mousemove', (e) => this.resizeMediaMove(e, direction));
    window.addEventListener('mouseup', (e) => this.resizeMediaUp(e, direction));
  }

  resizeMediaMove(event: MouseEvent, direction: 'right' | 'left') {
    event.preventDefault();
    event.stopPropagation();

    // const videoW = this.refVideoHls2.getBoundingClientRect()
    // console.log('this.refVideoHls2.getBoundingClientRect()', this.refVideoHls2.getBoundingClientRect())


    if (this.liWidth <= 40) {
      this.refVideo.classList.add('overflow-hidden')
    } else {
      // this.refVideo.classList.remove('overflow-hidden')
    }

    if (this.isResizing) {
      const deltaX = Math.round(event.clientX - this.startX)
      if (Math.abs(deltaX) < 10) return;
      if (direction === 'left' && this.isVideo) {
        if (this.timelineContent.end) {
          let startTime = this.oldStartAt! + ((deltaX) * 1000 / this.timelineSize)
          const minTime = 0
          if (startTime < minTime) startTime = minTime
          this.setTimelineVideoStartAt(this.timelineContent.type, this.itemIndex, startTime);
        }
      } else if (direction === 'right') {
        let endTime = (1000 / this.timelineSize * (this.startWidth + deltaX)) + this.timelineContent.start

        if (this.isVideo) {
          const maxTime = (this.timelineContent.content as ContentItemVideo).length - (this.timelineContent.startAt ? this.timelineContent.startAt : 0)
          endTime = (1000 / this.timelineSize * (-1 * deltaX)) + (this.oldEndAt ? this.oldEndAt : 0)
          console.log('endTime', endTime)
          if (endTime > maxTime) endTime = maxTime
        }

        if (this.timelineContent.end && !this.isVideo) {
          endTime = (1000 / this.timelineSize * (this.startWidth + deltaX)) + this.timelineContent.start
          const minTime = this.timelineContent.start + 1000
          if (endTime < minTime) endTime = minTime
        }

        this.timelineContentTimeChange(this.timelineContent.type, this.itemIndex, endTime);
      }

      this.setElStyle();
      this.setElDragStyle();
    }
  }

  resizeMediaUp(event: MouseEvent, direction: 'right' | 'left') {
    event.preventDefault();
    event.stopPropagation();

    this.isResizing = false;
    this.deleteStatus = true;
    this.setPreSeekBar(true);
    window.removeEventListener('mousemove', (e) => this.resizeMediaMove(e, direction));
    window.removeEventListener('mouseup', (e) => this.resizeMediaUp(e, direction));
  }

  resizeMediaDownTwo() {
    this.isResizing = true;
  }

  dragTimelineItem(offset: null | PT, pt?: PT) {
    if (!offset) {
      this.repositionMouseUp()
      return;
    }

    if (offset.x === 0 && offset.y === 0) {
      this.repositionMouseDown();
      return;
    }

    if (pt) this.repositionMouseMove(offset, pt);
  }

  startDrag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.elementDragHandler.mouseDown(event, this.dragTimelineItem)
    const rect = this.elementDragHandler.usePtConverter(this.$el.getBoundingClientRect())
    const pt = this.elementDragHandler.usePtConverter(DomHelper.getMouseDownPt(event))
    this.mouseRelativeCoordinatePT = {
      x: rect.x - pt.x,
      y: rect.y - pt.y
    }
  }

  @Emit('endRepositionTimeline')
  repositionMouseUp() {
    this.$el.classList.remove('drag');
    this.setElStyle();
    this.setElDragStyle();
    return {timelineContent: this.timelineContent, timelineIndex: this.itemIndex}
  }

  repositionMouseDown() {
    DraggingItemModel.setItem(DraggingObjectType.TimelineContent, this.timelineContent);
  }

  repositionMouseMove(offset: null | PT, pt: PT) {
    if (!offset) return;
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');

    const rectPt = {x: this.$el.getBoundingClientRect().x, y: this.$el.getBoundingClientRect().y}
    const mouseRelativeCoordinatePtX = pt.x - this.elementDragHandler.usePtConverter(rectPt).x + this.mouseRelativeCoordinatePT.x

    if (Math.abs(offset.x) > 1 || Math.abs(offset.y) > 1) {
      this.setElDragStyle(mouseRelativeCoordinatePtX, offset.y);
      return;
    }
    this.setElStyle();
  }

  setElStyle() {
    this.$el.setAttribute('style',
      `width : ${this.nowWidth}px;  maxWidth : ${(this.timelineContent.content as ContentItemVideo).length}`
    );
  }

  setElDragStyle(x = 0, y = 0) {
    this.refDragView.setAttribute('style',
      `width : ${parseInt(this.nowWidth)}px; position : absolute; left: ${x}px; height: 66px; top:${y - 12}px;`
    );
  }

  @Emit('showEditModal')
  showEditModal() {
    console.log('123')
    return this.timelineContent
  }

  @Emit('closeModal')
  closeModal() {
    return this.modalStatus
  }

}
