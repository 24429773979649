
import {Component, Emit, Vue} from "vue-property-decorator";

@Component({name: "", components: {}})
export default class ShapeColorPicker extends Vue {
  bgColorAll = [
    '#FA5C3D', '#FBA33E', '#FCD718', '#7BB252', '#2FA6ED', '#8873F3', '#000000'
  ]

  @Emit('bgColorChange')
  bgColorChange(color: string) {
    console.log('color', color)
    // return color;
  }

  bgColorChangeByIndex(index: number) {
    this.bgColorChange(this.bgColorAll[index])
  }

  bgColorChangeWhite() {
    this.bgColorChange('#fff')
  }

  bgColorChangeInput(event: MouseEvent) {
    const target = event.target as HTMLInputElement;
    if (!target) return
    this.bgColorChange(target.value)
  }
}
