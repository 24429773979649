
import {Component, Emit, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import QuizRepeat from "@/Booker/views/components/quiz/QuizRepeat.vue";
import QuizAddItem from "@/Booker/views/components/quiz/QuizAddItem.vue";
import QuizShortAnswer from "@/Booker/views/components/quiz/QuizInput.vue";
import QuizInput from "@/Booker/views/components/quiz/QuizInput.vue";
import QuizDesc from "@/Booker/views/components/quiz/QuizDesc.vue";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";
import {ContentItemQuiz, ContentLibraryType, ContentToolStateType, QuizItemType} from "@/Booker/lib/BookerTypes";
import QuizSelect from "@/Booker/views/components/quiz/QuizSelect.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import QuestionHeader from "@/Booker/views/components/quiz/QuestionHeader.vue";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import QuizOX from "@/Booker/views/components/quiz/QuizOX.vue";
import QuizDictation from "@/Booker/views/components/quiz/QuizDictation.vue";
import QuizTimeLimit from "@/Booker/views/components/quiz/QuizTimeLimit.vue";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import FileApi from "@/assets/lib/api/FileApi";
import QuizBlank from "@/Booker/views/components/quiz/QuizBlank.vue";
import QuizSelectType from "@/Booker/views/components/quiz/QuizSelectType.vue";

@Component({
  name: "EditQuiz",
  computed: {
    QuizItemType() {
      return QuizItemType
    }
  },
  components: {
    QuizSelectType,
    QuizBlank,
    CommonButton,
    QuizTimeLimit,
    QuizDictation,
    CommonInput, QuizOX, QuizSelect, QuizInput, QuizDesc, QuizShortAnswer, QuizRepeat, QuizAddItem, QuestionHeader
  }
})
export default class EditQuiz extends Vue {

  @Prop() model!: ClassContentMediaListViewModel
  @Prop() libraryKey!: string

  @Ref() refInput!: HTMLInputElement;

  quizModel: ContentItemQuizEditorViewModel | null = null;
  isSave = false;
  uploadImgIdx = -1;
  uploadImgDiff = -1;
  quizStatus = false
  isDifficulty = false
  selectedDifficulty = 1;
  selectedQuizType = 0

  get questionList() {
    return this.quizModel ? this.quizModel.questionList : [];
  }

  get isQuizType() {
    return this.quizModel?.getQuiz().quizType !== undefined;
  }

  // 지문 추가하기
  changeSubQuestion(eventData: { difficultyIdx: number, difficulty: number }) {
    const {difficultyIdx, difficulty} = eventData
    const quizIdx = this.quizModel?.findDifficultyIndexInAllList(difficultyIdx, difficulty)
    if (quizIdx !== undefined && quizIdx > -1) {
      const subQ = this.quizModel?.getQuiz().quizItems[quizIdx].subQuestion;
      if (typeof subQ === "string") {
        this.quizModel?.delSubQuestion(difficultyIdx, difficulty)
      } else {
        this.quizModel?.setSubQuestion(difficultyIdx, difficulty, '')
      }
    }
    return {difficultyIdx, difficulty}
  }

  @Watch('libraryKey')
  libraryKeyWatch() {
    this.init();
  }

  init() {
    const quiz = this.model.getContentLibrary(ContentLibraryType.Quiz).find(content => content.key === this.libraryKey) as ContentItemQuiz
    if (!quiz) {
      alert('알수없는 데이터 입니다.');
      this.$router.back();
    }
    this.quizModel = new ContentItemQuizEditorViewModel(JSON.parse(JSON.stringify(quiz)));
    this.isDifficulty = this.quizModel.isDifficulty
  }

  created() {this.init()}

  mounted() {
    this.init()
  }

  checkSave() {
    this.isSave = !this.isSave
    this.isSave = this.isSaveAble();
  }

  addQuiz(type: QuizItemType) {
    this.quizModel?.addQuestion(type, this.selectedDifficulty)
    this.quizStatus = false
  }

  saveQuiz() {
    if (!this.quizModel) {
      alert('페이지에 오류가 발생했습니다.')
      return;
    }
    this.quizModel.quiz.quizItems = this.questionList;
    this.model.addQuizLibrary(this.quizModel.quiz)
    this.model.save();
    this.setToolState(ContentToolStateType.Default);
  }

  isSaveAble() {
    if (!this.quizModel!.quiz.title) return false;
    if (this.quizModel!.quiz.title.length < 2) return false;
    if (this.questionList.length < 1) return false;
    if (!this.quizModel!.checkQuizItemsState()) return false;
    return true;
  }

  onInputTitle(e: Event) {
    this.quizModel!.quiz.title = (e.target as HTMLInputElement).value;
    this.checkSave();
  }

  @Emit('setToolState')
  setToolState(state: ContentToolStateType) {
    return state;
  }

  checkRenderByDifficulty(difficulty: number) {
    if (this.isDifficulty) return this.selectedDifficulty === difficulty
    return true;
  }

  difficultyIndex(quizIndex: number, difficulty: number) {
    if (!this.isDifficulty) return quizIndex;
    const index = quizIndex - this.questionList.filter((quiz, index) =>
      quiz.difficulty !== difficulty && index < quizIndex
    ).length;
    return index
  }

  difficultyLastIndex(difficulty: number) {
    if (!this.isDifficulty) return this.questionList.length - 1;
    return this.questionList.filter(quiz => quiz.difficulty === difficulty).length - 1
  }

  async onFile(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target && target.files && target.files.length > 0) {

      const fileApiRes = await FileApi.uploadFile(target.files[0])
      const fileId = fileApiRes?.file_list[0].file_id
      if (fileId) {
        if (this.uploadImgIdx !== -1 && this.uploadImgDiff !== -1) {
          this.quizModel?.setOptionsImage(this.uploadImgIdx, this.uploadImgDiff, fileId)

          this.uploadImgIdx = -1
          this.uploadImgDiff = -1
        }
      }

      // target.files = null;
    }
  }

  onChangeIsDifficulty(isDifficulty: boolean) {
    this.isDifficulty = isDifficulty;
  }

  receiveOnChangeDifficulty(difficulty: number) {
    this.selectedDifficulty = difficulty
  }

  onFileSelected(eventData: { difficultyIdx: number, difficulty: number }) {
    const {difficultyIdx, difficulty} = eventData;
    this.uploadImgIdx = difficultyIdx;
    this.uploadImgDiff = difficulty;
    this.refInput.click();
  }

  /**
   * 퀴즈 타입 선택
   * @param type
   */

  onChangeQuizType(type: number) {
    if (type < 0) return;
    if (type > 2) return;
    this.selectedQuizType = type;
    if (this.quizModel) this.quizModel.setQuizType(type)
    console.log('this.selectedQuizType', this.selectedQuizType)
  }


}
