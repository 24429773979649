
import { Component, Vue } from 'vue-property-decorator';
import AiProfile from '@/components/ui/chatting/AiProfile.vue'
import MsgTextBox from '@/components/ui/chatting/MsgTextBox.vue'

@Component({
  components: { AiProfile, MsgTextBox }
})
export default class QuizMsg extends Vue {

}
