
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import BookerVideoPlayer from "@/Booker/views/components/video/BookerVideoPlayer.vue";
import BookerVideoTimelineEditor from "@/Booker/views/components/video/BookerVideoTimelineEditor.vue";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import VideoEditModal from "@/Booker/views/components/video/VideoEditModal.vue";

@Component({
  name: 'BookerVideoEditorLayout',
  components: {VideoEditModal, BookMarkToolEdit: BookerVideoTimelineEditor, BookerVideoPlayer}
})
export default class BookerVideoEditorLayout extends Vue {
  @Prop() contentKey!: string
  @Prop() timelineViewModel!: ClassContentPlayerViewModel
  model: null | ClassContentMediaListViewModel = null;


  currentZoom = false
  @Emit('setIsFullVideo')
  setIsFullVideo(isFullVideo: boolean) {
    this.currentZoom = isFullVideo
    console.log('this.currentZoom', this.currentZoom)
    return this.currentZoom
  }

  mounted() {
    this.model = new ClassContentMediaListViewModel(this.contentKey);
  }

}
