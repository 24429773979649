import {
  ActionContentType,
  ContentItemBase,
  ContentItemScenario,
  ContentItemScenarioType,
  ContentItemType,
  ContentLibraryType,
} from "@/Booker/lib/BookerTypes";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";

export default class ContentItemScenarioViewModel {
  scenario: ContentItemScenario
  classContentMediaListViewModel: ClassContentMediaListViewModel;
  actionContentType: ActionContentType;

  /**
   * 생성 작업시에는 classContentMediaListViewModel 에서 create template 으로 생성후 params로 사용
   * 수정 작업시에는 기존에 있던 scenario params 로 사용
   */
  constructor(scenario: ContentItemScenario, classContentMediaListViewModel: ClassContentMediaListViewModel) {
    this.scenario = scenario;
    this.classContentMediaListViewModel = classContentMediaListViewModel;
    this.actionContentType = this.getActionContentType(scenario);
  }

  /**
   * 시나리오 컨텐츠 목록
   */
  get actionContent() {
    return this.scenario.actionContent
  }

  get quizList() {
    return this.classContentMediaListViewModel.getContentLibrary(ContentLibraryType.Quiz)
  }

  get bookList() {
    return this.classContentMediaListViewModel.getContentLibrary(ContentLibraryType.Book)
  }

  get pronunciationList() {
    return this.classContentMediaListViewModel.getContentLibrary(ContentLibraryType.Pronunciation)
  }

  get relaxationList() {
    return this.classContentMediaListViewModel.getContentLibrary(ContentLibraryType.Relaxation)
  }

  get getActionContent(): undefined | string {
    if (this.actionContent && this.actionContent.length > 0) {
      console.log('this.scenarioViewModel?.actionContent[0]', this.actionContent[0]);
      return this.actionContent[0].title;
    }
    return undefined
  }

  getActionContentType(scenario: ContentItemScenario): ActionContentType {
    if (scenario.actionContent && scenario.actionContent.length > 0) {
      switch (scenario.actionContent[0].type) {
        case ContentItemType.Quiz:
          return ActionContentType.Quiz;
        case ContentItemType.Relaxation:
          return ActionContentType.Relaxation;
        case ContentItemType.Pronunciation:
          return ActionContentType.Pronunciation;
        case ContentItemType.Book:
          return ActionContentType.RecommendBook;
      }
    }
    return ActionContentType.None
  }

  clearActionContent() {
    this.scenario.actionContent = undefined;
  }

  setMessage(msg: string) {
    this.scenario.message = msg;
  }

  setTitle(title: string) {
    this.scenario.title = title;
  }

  setKeyword(keyword: string) {
    this.scenario.keyword = keyword;
  }

  setActionContentType(actionContentType: ActionContentType) {
    this.actionContentType = actionContentType;
  }

  /**
   * 타입 변경
   * @param scenarioType
   */
  changeScenarioType(scenarioType: ContentItemScenarioType) {
    console.log('scenarioType', scenarioType)
    this.scenario.scenarioType = scenarioType;
    if (scenarioType === ContentItemScenarioType.OpenContentItem) {
      if (!this.scenario.actionContent) this.scenario.actionContent = [];
    }
    if (scenarioType === ContentItemScenarioType.WordDictionary) {
      if (!this.scenario.keyword) this.scenario.keyword = '';
    }
    if (scenarioType === ContentItemScenarioType.GenieInside) {
      if (!this.scenario.keyword) this.scenario.keyword = '';
    }
  }

  /**
   * 컨텐츠 추가
   * @param item
   */
  addActionContent(item: ContentItemBase): ContentItemBase {
    this.scenario.actionContent = [];
    this.scenario.actionContent.splice(0, 0, item);
    console.log('this.actionContent', this.actionContent);
    return item
  }

  /**
   * 컨텐츠 삭제
   */
  deleteActionContent() {
    this.scenario.actionContent = [];
  }


  /**
   * 컨텐츠 위로 이동
   * @param item
   * @param actionIndex
   */
  moveUpActionContent(item: ContentItemBase, actionIndex: number) {
    if (!this.actionContent) return;
    if (actionIndex < 1 || actionIndex >= this.actionContent.length) return

    const rms = this.actionContent.splice(actionIndex, 1)
    this.actionContent.splice(actionIndex - 1, 0, ...rms)
  }

  /**
   * 컨텐츠 아래로 이동
   * @param actionIndex
   */
  moveDownActionContent(actionIndex: number) {
    if (!this.actionContent) return;
    if (actionIndex < 1 || actionIndex >= this.actionContent.length) return

    const rms = this.actionContent.splice(actionIndex, 1)
    this.actionContent.splice(actionIndex + 1, 0, ...rms)
  }

  saveScenario() {
    return this.classContentMediaListViewModel.addScenarioLibrary(this.scenario);
  }


  /**
   *  저장가능 상태 전체 확인
   */
  checkState(): boolean {
    if (this.checkText(this.scenario.title)) return false;
    if (this.checkText(this.scenario.message)) return false;
    if (!this.checkWordDictionary()) return false;


    return true;
  }

  checkText(text: string | undefined): boolean {
    if (!text) return false;
    if (text.length < 2) return false;
    return true;
  }

  checkWordDictionary(): boolean {
    if (this.scenario.scenarioType !== ContentItemScenarioType.WordDictionary &&
      this.scenario.scenarioType !== ContentItemScenarioType.GenieInside
    ) return true;

    if (this.checkText(this.scenario.keyword)) return false
    return true
  }


}
