
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import PinAuth from "@/router/PinAuth.vue";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip
} from 'chart.js'
import {Bar, Line, Radar} from 'vue-chartjs'
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

@Component({
  name: "AnalysisByEvaluation",
  components: {
    CommonButton,
    PinAuth,
    Bar,
    LineChart: Line,
    Radar,
  }
})

export default class AnalysisByEvaluation extends Vue {

  @Prop() model !: AiReportViewModel
  @Ref() refGraphWrapper!: HTMLDivElement
  @Ref() refAnalysis!: HTMLLIElement

  /****************
   * Chart JS
   ****************/

    // 평가지표별 균형비교 분석
    // Radar
  radarData: null | any = null;
  options: null | any = null;
  // Bar
  chartData: null | any = null;
  chartOptions: null | any = null;

  duration = 800


  // 평가지표 점수 그래프 분석

  get analysis() {
    if (this.model.evaluationAnalysis) {
      this.initGraph()
    }
    return this.model.evaluationAnalysis
  }

  changeLevelToKorean(level: string) {
    return BookerUtility.changeLevelToKorean(level)
  }

  changeEvaluationKeyToKorean(evaluationKey: string) {
    return BookerUtility.changeEvaluationKeyToKorean(evaluationKey)
  }

  analyzeClass(level: string) {
    return {
      'analyze-list': true,
      'normal': level === 'middle',
      'soso': level === 'low'
    };
  }

  graphWrapperWidth = 0
  listWidth = 0
  mounted() {
    this.initGraph()
    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, RadialLinearScale, PointElement, Filler, LineElement)
    console.log('this.refGraphWrapper', this.refGraphWrapper.getBoundingClientRect().width)

    this.graphWrapperWidth = this.refGraphWrapper.getBoundingClientRect().width
    this.listWidth = this.graphWrapperWidth / 4
    console.log('listwidth', this.listWidth)
  }

  initGraph() {

    console.log('initGraph')

    this.radarData = null;
    this.options = null;
    this.chartData = null;
    this.chartOptions = null;

    requestAnimationFrame(() => {
      if (!this.model) return;
      if (!this.model.userScores) return;
      this.radarData = {
        labels: [
          '이해력',
          '표현력',
          '응용력',
          '문제 해결력',
        ],
        datasets: [
          {
            backgroundColor: 'rgba(18, 28, 238, 0.5)',
            borderColor: '#787DFA',
            borderWidth: 2,
            pointBackgroundColor: '#7277E2',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#787DFA',
            pointHoverBorderColor: '#fff',
            data: this.model.userScores,
            pointStyle:'rectRounded',
            pointRadius: 5,
            pointHoverRadius: 6,
            min: 0,
            max: 25,
          },
          {
            backgroundColor: 'rgba(129, 133, 139, 0.1)',
            borderColor: '#81858B',
            borderDash: [5, 5],
            borderWidth: 2,
            pointBackgroundColor: '#81858B',
            pointHoverBackgroundColor: '#81858B',
            pointHoverBorderColor: '#fff',
            data: this.model.averageScores,
            pointRadius: 5,
            pointHoverRadius: 6,
            min: 0,
            max: 25,
            pointStyle:'rectRounded',
            pointBorderColor: '#fff'
          }
        ]
      }
      this.options = {
        transitions: ['active'],
        maintainAspectRatio: false,
        responsive: true,
        chartArea: {backgroundColor: 'red'},
        animation: {
          duration: this.duration,
          easing: 'linear',
        },
        plugins: {
          fill: {
            backgroundColor: 'rgba(255, 255, 255, 1)',
          },
          legend: {
            display: false,
          },
          tooltip: {
            usePointStyle: true,
            titleFont: {size: 13, weight: 800, family: 'NanumSquareRound'},
            bodyFont: {size: 13, weight: 800, family: 'NanumSquareRound'},
            borderWidth: 1,
            borderColor: '#CED0D8',
            backgroundColor: ' #FFFFFF',
            titleColor: '#5F636B',
            bodyColor: '#5A60D4',
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.15)',
            padding: 8,
          },
        },
        font: {
          family: 'NanumSquareRound',
          color: '#5F636B',
          weight: 800,
        },
        scales: {
          r: {
            pointLabels: {
              font: {
                size: 15,
                family: 'NanumSquareRound',
              }
            },
            ticks: {
              display: false,
            },
            angleLines: {
              display: false
            },
            min: 0,
            max: 25

          },
          y: {
            display: false,
            max: 25,
            min: 0,
            border: {
              display: false,
            },
            grid: {
              gridLines: {
                drawBorder: false,
                drawOnChartArea: false,
                display: false,
              }
            },
          },
          x: {
            display: false,
            grid: {
              drawBorder: false,
              drawOnChartArea: false,
              display: false,
              gridLines: {
                drawBorder: false,
                drawOnChartArea: false,
                display: false,
              },
              borderColor: 'green',
              lineWidth: 5,
            },
          },


        },
      }
      // Bar
      this.chartData = {
        labels: ['이해력', '표현력', '응용력', '문제해결력'],
        datasets: [
          {
            // 내 점수
            data: this.model.userScores,
            backgroundColor: 'rgba(120, 125, 250, 0.9)',
            borderRadius: 50,
            maxBarThickness: 20,
            hoverBackgroundColor: '#787DFA',
            order: 2,
            type: 'bar',
          },
          {
            // 평균
            data: this.model.averageScores,
            backgroundColor: '#fff',
            borderColor: '#81858B',
            pointBackgroundColor: '#81858B',
            pointBorderColor: '#fff',
            pointHoverBorderColor: '#fff',
            pointWidth: 100,
            borderWidth: 2,
            hoverBackgroundColor: '#81858B',
            tension: 0.01,
            order: 1,
            type: 'line',
            pointRadius: 5,
            pointHoverRadius: 6
          }
        ],
        dataAverage: [{
          type: 'line'
        }]
      }
      this.chartOptions = {
        responsive: true,
        animation: {
          duration: this.duration
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            usePointStyle: true,
            titleFont: {sizedd: 13, weight: 800, family: 'NanumSquareRound'},
            bodyFont: {size: 13, weight: 800, family: 'NanumSquareRound'},
            borderWidth: 1,
            borderColor: '#CED0D8',
            backgroundColor: ' #FFFFFF',
            titleColor: '#5F636B',
            bodyColor: '#5A60D4',
            boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.15)',
            padding: 8,

          },
        },
        font: {
          family: 'NanumSquareRound',
          color: '#5F636B',
          size: 15,
          weight: 800,
        },
        scales: {
          y: {
            stacked: true,
            max: 25,
            min: 0,
            beginAtZero: true,
            border: {
              display: false,
            },
            titleFont: {
              size: 15,
              weight: 800,
              family: 'NanumSquareRound',
              color: '#A6A9B1',
            },
            grid: {
              gridLines: {
                drawBorder: false,
                drawOnChartArea: false,
                display: false,
              }
            },
            ticks: {
              stepSize: 5,
              padding: 20,
              position: 'left',
              display: true,
              color: '#A6A9B1',
              font: {
                size: 15,
                weight: 800,
                letterSpacing: -0.02,
              }
            },
          },
          x: {
            stacked: true,
            grid: {
              drawBorder: false,
              drawOnChartArea: false,
              display: false,
              gridLines: {
                drawBorder: false,
                drawOnChartArea: false,
                display: false,
              },
              borderColor: 'green',
              lineWidth: 5,
            },
            ticks: {
              font: {
                size: 15,
                weight: 800,
                color: '#5F636B',
                letterSpacing: -0.02,
                family: 'NanumSquareRound'
              }
            },

          },

        },


      }
    })
  }

}
