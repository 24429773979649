
import {Component, Vue} from 'vue-property-decorator';
import LocalStorageUtil from "@/assets/lib/utility/LocalStorageUtil";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import Config from "@/config";

@Component({
  name: 'PinAuth',
  components: {CommonButton}
})

export default class PinAuth extends Vue {

  pinNumber = '';

  get checkIsPinAuth() {
    const isFirstCheckTimeKey = `isPinAuth`
    return LocalStorageUtil.getBoolean(isFirstCheckTimeKey, false)
  }

  mounted() {
    // if (this.validateAuth()) return
    // this.$router.back();

    if (!this.checkIsPinAuth) addEventListener('keypress', (ev) => {
      if (ev.key === 'Enter') this.validateAuth();
    })

  }

  beforeDestroy() {
    if (!this.checkIsPinAuth) removeEventListener('keypress', (ev) => {
      if (ev.key === 'Enter') this.validateAuth();
    })
  }

  onInputPin(e: Event) {
    this.pinNumber = (e.target as HTMLInputElement).value;
  }

  validateAuth() {
    if (this.checkIsPinAuth) return;
    if (this.escapeHTML(this.pinNumber) === Config.CONFIG.PIN_SECRET) {
      this.setPinAuth();
      if (this.$route.path.indexOf('/main') !== -1) this.$router.replace({name: 'main'});
      window.location.replace(window.location.origin + "/main");
      return;
    }
    this.$router.back();
  }

  escapeHTML(str: string): string {

    const escapeHtmlMap = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': '&quot;',
      "'": '&#39;',
      "/": '&#x2F;'
    };

    Object.values(escapeHtmlMap).forEach((escapeHtml) => {
      str.replaceAll(escapeHtml, '')
    })

    return str

  }

  setPinAuth() {
    const isFirstCheckTimeKey = `isPinAuth`
    LocalStorageUtil.setBoolean(isFirstCheckTimeKey, true)
  }

  //
  // beforeDestroy() {
  //   console.error('auth wrap destroyed')
  // }
}
