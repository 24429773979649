
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import {
  ClassContent,
  ContentItemAudio,
  ContentItemBase,
  ContentItemBook,
  ContentItemImage,
  ContentItemPronunciation,
  ContentItemScenario,
  ContentItemText,
  ContentItemTextToSpeech,
  ContentItemType,
  ContentItemTypes,
  ContentItemVideo,
  ContentLibraryType,
  ContentSideMenu,
  ContentToolStateType,
  DraggingObjectType,
  VideoContentSourceType,
  YoutubeItem
} from "@/Booker/lib/BookerTypes";
import DraggingItemModel from "@/Booker/lib/model/DraggingItemModel";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import CreateYoutubeUrl from "@/Booker/views/components/CreateYoutubeUrl.vue";
import CreateTextToSpeech from "@/Booker/views/components/tts/CreateTextToSpeech.vue"
import CommonModal from "@/Booker/views/components/common/CommonModal.vue"
import BookerVideoThumbnail from "@/Booker/views/components/video/thumbanil/BookerVideoThumbnail.vue";
import CreatePage from "@/Booker/views/components/CreatePage.vue";
import CommonLoading from "@/components/ui/loading/CommonLoading.vue";
// import CommonAudio from "@/Booker/views/components/common/CommonAudio.vue"

@Component({
  name: "BookMarkToolMenu",
  components: {CommonLoading, CreatePage, CreateYoutubeUrl, CreateTextToSpeech, CommonModal, BookerVideoThumbnail}
})
export default class BookMarkToolMenu extends Vue {
  @Prop() model!: ClassContentMediaListViewModel;
  @Prop() contentKey!: string;
  @Prop() timelineViewModel!: ClassContentPlayerViewModel;

  @Ref() fileInput!: HTMLInputElement;
  @Ref() refColor!: HTMLInputElement;
  @Ref() refAudio!: HTMLAudioElement;
  @Ref() refPage!: HTMLDivElement;

  ContentItemType = ContentItemType
  showLibraryContentList = true
  ContentLibraryType = ContentLibraryType
  selectedMenuKey: ContentLibraryType = ContentLibraryType.Media
  // asideMenu의 키값
  libraryKey: string | null = null;
  VideoContentSourceType = VideoContentSourceType
  playStatus = false
  playTtsStatus = false

  classContent: ClassContent | null = null


  /**
   * Aside Menu
   */

  asideMenu: ContentSideMenu[] = [
    {
      key: ContentLibraryType.Media,
      text: '미디어',
      imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_mediaOff.png'),
      imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_mediaOn.png')
    },
    {
      key: ContentLibraryType.Audio,
      text: '오디오',
      imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_audioOff.png'),
      imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_audioOn.png')
    },
    {
      key: ContentLibraryType.TextToSpeech,
      text: 'TTS',
      imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_ttsOff.png'),
      imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_ttsOn.png')
    },
    {
      key: ContentLibraryType.Book,
      text: '교재',
      imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_bookOff.png'),
      imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_bookOn.png')
    },
    // { text: ContentLibraryType.Page ? '페이지' : '', imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_pageOff.png'), imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_pageOn.png') },
    {
      key: ContentLibraryType.Subtitle,
      text: '자막',
      imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_captionOff.png'),
      imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_captionOn.png')
    },
    {
      key: ContentLibraryType.Quiz,
      text: '퀴즈',
      imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_quizOff.png'),
      imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_quizOn.png')
    },
    {
      key: ContentLibraryType.Pronunciation,
      text: '말하기 실습',
      imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_speakOff.png'),
      imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_speakOn.png')
    },
    {
      key: ContentLibraryType.Relaxation,
      text: '휴식',
      imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_restOff.png'),
      imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_restOn.png')
    },
    {
      key: ContentLibraryType.Scenario,
      text: '인터랙티브',
      imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_scenarioOff.png'),
      imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_scenarioOn.png')
    },
    {
      key: ContentLibraryType.Avatar,
      text: '아바타',
      imgOff: require('@/assets/images/bookmark/bookMarkTool/ic_scenarioOff.png'),
      imgOn: require('@/assets/images/bookmark/bookMarkTool/ic_scenarioOn.png')
    },

  ]
  /**
   * 자막 Subtitle
   */
  subtitleText = [
    {title: '상단 자막'},
    {title: '중앙 자막'},
    {title: '하단 자막'},
  ]
  /**
   * 휴식 Relaxation
   */
  relaxationImgList = [
    {title: '눈운동 휴식', url: ''},
    {title: '스트레칭', url: ''},
  ]
  /**
   * 유튜브 모달
   */
  modalStatus = false
  youtubeThumbnail = ''
  youtubeTitle = ''
  youtubeThumb = ''
  youtubeDuration = 0
  audioIndex = 0
  //
  ttsIndex = 0

  /***
   * 페이지 모달
   */
  pageModalStatus = false
  /**
   * 아바타
   */
  addAvatar = true

  /**
   * tts 모달
   */
  //ttsStatus = false

  // TTS 추가

  get selectedMenuItem() {
    const item = this.asideMenu.find(m => m.key === this.selectedMenuKey);
    if (!item) return null
    this.sendSelectedMenuItem(item.key)

    return item
  }

  get contentList(): ContentItemTypes[] {
    console.log('this.model.getContentLibrary(this.selectedMenuKey)', this.model.getContentLibrary(this.selectedMenuKey))
    return this.model.getContentLibrary(this.selectedMenuKey)
  }

  get subtitleList(): ContentItemText[] {
    return this.model.subtitleList()
  }

  mounted() {
    if (this.model.getContentLibrary(ContentLibraryType.Relaxation).length === 0) this.model.addRelaxationLibrary()
  }

  getAudioLength(content: ContentItemBase) {
    const audio = (content as ContentItemAudio).length

    const seconds = Math.floor(audio / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const timeString = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

    // console.log('audio', audio);
    // console.log('timeString', timeString);

    return timeString;
  }

  // 말하기 실습
  getPronunciationText(content: ContentItemBase) {
    const pronunciation = (content as ContentItemPronunciation).sentences[0];
    if (pronunciation) return pronunciation.sentence;
    else return '말하기 실습'
  }

  // TTS 메시지 가져오기
  getTextToSpeechText(content: ContentItemBase) {
    const textToSpeech = (content as ContentItemTextToSpeech).message;
    if (textToSpeech) return textToSpeech
    else return '-'
  }

  // TTS 보이스 타입 가져오기
  getTextToSpeechVoiceType(content: ContentItemBase) {
    const textToSpeechVoiceType = (content as ContentItemTextToSpeech).voiceType;

    if (textToSpeechVoiceType === 'man1') {
      return '남'
    } else if (textToSpeechVoiceType === 'woman1') {
      return '여'
    } else if (textToSpeechVoiceType === 'kid') {
      return '아이'
    } else {
      return '-'
    }
  }

  // getContentItemDuration(content: ContentItemBase): null | string {
  //   switch (content.type) {
  //     case ContentItemType.Image:
  //     //return (content as ContentItemImage).
  //   }
  // }

  // 인터랙티브 텍스트 가져오기
  getInteractive(content: ContentItemBase) {
    const interactiveText = (content as ContentItemScenario).message;
    return interactiveText;
  }

  // 인터랙티브 유형 가져오기
  getInteractiveType(content: ContentItemBase) {
    const interactiveActivity = (content as ContentItemScenario).scenarioType;
    if (interactiveActivity === 'OpenContentItem') return '챗봇 메시지'
    else if (interactiveActivity === 'WordDictionary') return '낱말 사전'
    else if (interactiveActivity === 'GenieInside') return '지니 인사이드'
    else return '-'
  }

  getIsType() {
    const type = this.selectedMenuItem?.key;
    return type === ContentLibraryType.Quiz
      || type === ContentLibraryType.TextToSpeech
      || type === ContentLibraryType.Pronunciation
      || type === ContentLibraryType.Scenario
  }

  /**
   * 플레이 TTS
   */
  playTts(sound: string, idx: number) {
    // idx가 같고, 재생 중일때
    if (this.playStatus && this.ttsIndex === idx) {
      this.playTtsStatus = false;
      this.playStatus = false;
      this.refAudio.pause();
    } else {
      this.ttsIndex = idx;
      if (sound) {
        this.refAudio.src = BookerUtility.getFile(sound);
        this.playTtsStatus = true;
        this.playStatus = true;
        this.refAudio.play();
        this.timelineViewModel.seekbar.pause();
      }
      // 다시 플레이버튼으로
      this.refAudio.onended = () => {
        this.playTtsStatus = false;
        this.playStatus = false;
      }
    }
  }

  /**
   * 플레이 Audio
   */


  playAudio(sound: string, idx: number) {
    this.refAudio.load()
    if (this.playStatus && this.audioIndex === idx) {
      this.playStatus = false;
      this.refAudio.pause();
    } else {
      this.audioIndex = idx;
      this.refAudio.load();

      if (sound) {
        this.refAudio.src = BookerUtility.getFile(sound);
        this.playStatus = true;
        this.refAudio.play();
        this.timelineViewModel.seekbar.pause();
      }
    }
    this.refAudio.onended = () => this.playStatus = false;
  }

  toLocalTime(date: number) {
    const time = new Date(date)
    return `${time.toLocaleDateString()} ${time.getHours()}:${time.getMinutes()}`
  }

  /**
   * ContentItem 의 thumbnail 로 사용할 이미지/영상 url
   * @param content
   */
  getContentItemThumbnailUrl(content: ContentItemBase): null | string {
    switch (content.type) {
      case ContentItemType.Image:
        return BookerUtility.getFile((content as ContentItemImage).source)
      case ContentItemType.Video:
        if ((content as ContentItemVideo).sourceType === VideoContentSourceType.Youtube) {
          return BookerUtility.getYoutubeThumbnailByUrl((content as ContentItemVideo).source);
        }
        return BookerUtility.getFile((content as ContentItemImage).source)

      case ContentItemType.Book:
        // eslint-disable-next-line no-case-declarations
        const book = content as ContentItemBook;
        if (book.pages.length > 0) return BookerUtility.getFile((book.pages[0] as ContentItemImage).source)
        return null
      default:
        return null
    }

  }

  /**
   * ContentItem 의 title 로 사용할 이미지/영상 title
   * @param content
   */

  getContentItemTitle(content: ContentItemBase): null | string {
    switch (content.type) {
      case ContentItemType.Image:
        return (content as ContentItemImage).title ?? null
      case ContentItemType.Video:
        if ((content as ContentItemVideo).sourceType === VideoContentSourceType.Youtube) {
          return (content as ContentItemVideo).title ?? null;
        }
        return (content as ContentItemVideo).title ?? null
      default:
        return null
    }
  }

  closeModal() {
    this.modalStatus = false
    this.pageModalStatus = false
    //this.ttsStatus = false
  }

  // 유튜브 모달
  showModal() {
    this.modalStatus = !this.modalStatus
    console.log('this.modalStatus', this.modalStatus)
  }

  // 페이지 모달
  showPageModal() {
    this.pageModalStatus = !this.pageModalStatus
    console.log('this.pageModalStatus', this.pageModalStatus)
  }

  @Emit('sendSelectedMenuItem')
  sendSelectedMenuItem(menuType: ContentLibraryType) {
    return menuType;
  }

  @Emit('setToolState')
  setToolState(state: ContentToolStateType) {
    return state;
  }

  clickMenu(menu: ContentSideMenu) {
    this.selectedMenuKey = menu.key;
    this.showLibraryContentList = true

    const inputLabel = document.querySelector('.btn-add') as HTMLLabelElement;
    // if(this.selectedMenuKey === ContentLibraryType.Relaxation) {
    //   inputLabel.style.display = 'none';
    // } else {
    //   inputLabel.style.display = 'block';
    // }

    console.log('this.selectedMenuKey', this.selectedMenuKey);
  }

  getThumbnail() {
    let img;
    if (!this.selectedMenuItem) {
      img = require('@/assets/images/bookmark/bookMarkTool/toolEdit/icGuideCaption.png')
      return img
    }
    switch (this.selectedMenuItem.key) {
      case ContentLibraryType.Quiz:
        img = require('@/assets/images/bookmark/bookMarkTool/quiz_library.png')
        break;
      case ContentLibraryType.Pronunciation:
        img = require('@/assets/images/bookmark/bookMarkTool/pronunciation_library.png')
        break;
      case ContentLibraryType.Audio:
        img = require('@/assets/images/bookmark/bookMarkTool/ic_audio-library.png')
        break;
      case ContentLibraryType.Scenario:
        img = require('@/assets/images/bookmark/bookMarkTool/ic_interactiveOn.png')
        break;
      case ContentLibraryType.Subtitle:
        img = require('@/assets/images/bookmark/bookMarkTool/toolEdit/icGuideCaption.png')
        break;
      case ContentLibraryType.TextToSpeech:
        img = require('@/assets/images/bookmark/bookMarkTool/icTtsThumb.png')
        break;
    }
    return img
  }

  addTimelineContent(content: ContentItemTypes) {
    if (content.type === ContentItemType.Video) {
      if ((content as ContentItemVideo).isLoading) {
        alert('변환중인 비디오 입니다.');
        return;
      }
    }
    const start = this.timelineViewModel?.seekbar.currentTime
    const duration = BookerUtility.getContentLength(content)
    if (duration > 0) this.timelineViewModel?.timeline.addTimelineContent(content, start, duration)
    else this.timelineViewModel?.timeline.addTimelineContent(content, start)
  }

  checkAddLibraryType(e: any) {
    switch (this.selectedMenuKey) {

      case ContentLibraryType.Subtitle:
      case ContentLibraryType.Quiz:
      case ContentLibraryType.Pronunciation:
      case ContentLibraryType.Scenario:
      case ContentLibraryType.TextToSpeech:
        this.setToolState(ContentToolStateType.Create);
        break;

      case ContentLibraryType.Book :
        this.addBookFunc(e)
        break;

      default :
        this.addFileFunc(e)
        break;
    }

  }

  // 교재 파일 추가
  async addBookFunc(e: any) {
    const file = e.target.files[0]
    await this.model.addBookLibrary(file);
    this.fileInput.value = ''
  }

  // 미디어 파일 추가
  async addFileFunc(e: any) {
    for (const file of [...e.target.files]) {
      await this.model.addMediaLibrary(file);
    }
    this.fileInput.value = ''
  }

  // 페이지 추가
  addPageFunc(emitDataList: string[]) {
    const [title, color] = emitDataList
    this.pageModalStatus = false
    this.model.addEmptyPageLibrary(title, color)
  }

  // 유튜브 썸네일 추가
  async addYoutubeFunc(youtubeItem: YoutubeItem) {
    await this.model.addYoutubeMediaLibrary(youtubeItem);
    this.modalStatus = false
    this.youtubeTitle = youtubeItem.title
    this.youtubeThumb = youtubeItem.thumbnail
    this.youtubeDuration = youtubeItem.duration
  }

  @Emit('setLibraryKey')
  editContent(key: string) {
    this.libraryKey = key;
    this.setToolState(ContentToolStateType.Edit);
    return key;
  }

  deleteContent(key: string) {
    if (confirm('삭제하시겠습니까?')) {
      this.model.deleteContentLibrary(key)
    }
    if (this.libraryKey === key) {
      this.setToolState(ContentToolStateType.Default);
    }
  }

  /**
   * set dragging content info
   * @param e
   * @param item
   */
  setDraggingData(e: DragEvent, item: ContentItemTypes) {
    DraggingItemModel.setItem(DraggingObjectType.ContentItemBase, item)

    e.dataTransfer!.effectAllowed = 'move';
    const img = new Image(128, 128)
    img.src = this.getThumbnail()
    e.dataTransfer!.setDragImage(img, 128, 128)
    //console.log('set dragging data', e.dataTransfer, draggingObject, e.dataTransfer?.getData('text/plain'))
    console.log('setDraggingData-item', item);
  }

  // 자막 subtitleSetDraggingData
  subtitleSetDraggingData(e: DragEvent, text: ContentItemText) {
    const newContentItemText = JSON.parse(JSON.stringify(text));
    if (!newContentItemText) return;
    newContentItemText.key = BookerUtility.createKey();
    DraggingItemModel.setItem(DraggingObjectType.ContentItemBase, newContentItemText);
    e.dataTransfer!.effectAllowed = 'move';
  }


  /**
   * drag end
   * @param e
   */
  onDragEnd(e: DragEvent) {
    // reset dragging object cache
    DraggingItemModel.reset()
    this.timelineViewModel?.timeline.cancelPreviewContent();
  }

  showAvatar() {
    this.addAvatar = !this.addAvatar
  }

  saveAvatarStatus() {
    this.model.setAvatar(this.addAvatar)
    this.setToolState(ContentToolStateType.Default);
    return this.addAvatar;
  }


}
