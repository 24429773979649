
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {QuizItem} from "@/Booker/lib/BookerTypes";
import QuestionHeader from "@/Booker/views/components/quiz/QuestionHeader.vue";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";
import QuizBlankOption from "@/Booker/views/components/quiz/QuizBlankOption.vue";
import QuizDictationOption from "@/Booker/views/components/quiz/QuizDictationOption.vue";
import CommonSubQuestion from "@/Booker/views/components/common/CommonPassageExplain.vue";

@Component({
  name: "QuizDictation",
  components: {CommonSubQuestion, QuizDictationOption, QuizBlankOption, CommonInput, CommonTextArea, QuestionHeader}
})
export default class QuizDesc extends Vue {
  @Ref() refQuizImg!: HTMLImageElement
  @Prop() question!: QuizItem
  @Prop() quizModel!: ContentItemQuizEditorViewModel
  @Prop() difficultyIdx!: number
  @Prop() difficulty!: number
  @Prop() questionIdx!: number
  @Prop() subQuestion!: string

  BookerUtility = BookerUtility

  explainStatus = false
  answerTextStatus = false
  explainTextStatus = true
  correctTextStatus = true

  get options() {
    return this.question.options
  }


  @Emit('checkSave')
  checkSaveSend() {
    return true;
  }

  onInputQuestion(e: Event) {
    this.question.question = (e.target as HTMLInputElement).value;
    this.checkSaveSend();
  }

  onInputCorrectAnswer(e: Event) {
    this.question.correctAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setCorrectMsg(this.difficultyIdx, this.difficulty, this.question.correctAnswerMsg);
  }

  onInputInCorrectAnswer(e: Event) {
    this.question.inCorrectAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setInCorrectMsg(this.difficultyIdx, this.difficulty, this.question.inCorrectAnswerMsg);
  }

  showCorrectAnswer() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.correctAnswerMsg;
      if (typeof subQ === "string") {
        this.quizModel?.delCorrectMsg(this.difficultyIdx, this.difficulty)
        this.correctTextStatus = true
      } else {
        this.quizModel?.setCorrectMsg(this.difficultyIdx, this.difficulty, '')
        this.correctTextStatus = false
      }
    }
  }

  showExplain() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.explain;
      if (typeof subQ === "string") {
        this.quizModel?.delExplain(this.difficultyIdx, this.difficulty)
        this.explainTextStatus = true
      } else {
        this.quizModel?.setExplain(this.difficultyIdx, this.difficulty, '')
        this.explainTextStatus = false
      }
    }
  }

  explainInput(e: Event) {
    this.question.explain = (e.target as HTMLTextAreaElement).value;
    this.quizModel.setExplain(this.difficultyIdx, this.difficulty, this.question.explain);
  }

  subQuestionInput(e: Event) {
    this.question.subQuestion = (e.target as HTMLTextAreaElement).value;
  }

  // 구술형 옵션

  setText(emitData: { text: string, optionIdx: number }) {
    const {text, optionIdx} = emitData;
    this.quizModel.setDictationAnswerArrayText(this.difficultyIdx, this.difficulty, optionIdx, text);
  }

  @Emit('checkSave')
  delOption(optionIdx: number) {
    this.quizModel.delDictationAnswerArray(this.difficultyIdx, this.difficulty, optionIdx)
  }

  delOptionEmit(optionIdx: number) {
    this.delOption(optionIdx)
  }

  downOption(optionIdx: number) {
    this.quizModel.moveDownDictationAnswerArray(this.difficultyIdx, this.difficulty, optionIdx)
  }

  upOption(optionIdx: number) {
    this.quizModel.moveUpDictationAnswerArray(this.difficultyIdx, this.difficulty, optionIdx)
  }

  @Emit('checkSave')
  addOption() {
    this.quizModel.addDictationAnswerArray(this.difficultyIdx, this.difficulty);
  }

  setSubAnswer(emitData: { text: string, optionIdx: number, subAnswersIndex: number }) {
    const {text, optionIdx, subAnswersIndex} = emitData;
    this.quizModel.setSubAnswer(this.difficultyIdx, this.difficulty, optionIdx, subAnswersIndex, text);
  }

  addSubAnswer(answerIndex: number) {
    this.quizModel.addSubAnswer(this.difficultyIdx, this.difficulty, answerIndex);
  }

  delSubAnswer(emitData: { answerIndex: number, subAnswersIndex: number }) {
    const {answerIndex, subAnswersIndex} = emitData;
    this.quizModel.delSubAnswer(this.difficultyIdx, this.difficulty, answerIndex, subAnswersIndex);
  }

}
