
import {Component, Emit, Ref, Vue} from "vue-property-decorator";

@Component({name: "", components: {}})
export default class TextColorPicker extends Vue {
  @Ref('refColorPicker') refColorPicker!: HTMLInputElement;
  @Ref('selectedColorLabel') selectedColorLabel!: HTMLInputElement;
  selectedColor = '#fff'

  @Emit('colorChange')
  colorChange() {
    return this.selectedColor;
  }

}
