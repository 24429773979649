import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import {ClassContent, ContentItemType, ContentItemTypes, TimelineType} from "@/Booker/lib/BookerTypes";
import BookerConf from "@/Booker/BookerConf";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

export interface ContentListItem {
  thumbnail?: string // empty when no thumbnail
  content: ClassContent
}

/**
 * 학습 컨텐츠 목록
 */
export default class ClassContentListViewModel {

  model = ClassContentManager.controller.model;
  private list: ContentListItem[] = [];
  private releaseList: ContentListItem[] = [];

  constructor() {
    if (BookerConf.isLocalDev) {
      // @ts-ignore
      window.ClassContentListViewModel = this
    }
  }

  /**
   * content list 조회
   */
  get contentList(): ContentListItem[] {
    const list = ClassContentManager.controller.model.contentList
      .sort((a, b) => a.createTime > b.createTime ? -1 : 1)
      .map(content => {
        return {
          thumbnail: content.thumbnailUrl,
          content: content
        }
      })
    this.list = [...list];
    return this.list;
  }

  get releaseClassList(): ContentListItem[] {
    const releaseList: ContentListItem[] = [];
    ClassContentManager.controller.model.releaseClassList.forEach((content) => {
      releaseList.push({
        content: content,
        thumbnail: content.thumbnailUrl
      })
    })
    releaseList.sort((a, b) => a.content.classStep! > b.content.classStep! ? -1 : 1)

    this.releaseList = [...releaseList];
    return this.releaseList;
  }

  init() {
    this.list = [];
  }

  classTemplate(): ClassContent {
    return {
      key: BookerUtility.createKey(),
      title: '제목 없는 수업',
      createTime: Date.now(),
      contentLibrary: [],
      class: '국어 말하기',
      classStep: 1,
      standardList: [{
        text: '4국01-06',
        code: '4국01-06'
      }],
      timeline: [
        {type: TimelineType.Video, contents: []},
        {type: TimelineType.Audio, contents: []},
        {type: TimelineType.Scenario, contents: []},
        {type: TimelineType.Activity, contents: []},
        {type: TimelineType.TextOverlay, contents: []},
        {type: TimelineType.TextToSpeech, contents: []},
        {type: TimelineType.Figure, contents: []},
      ]
    }
  }

  contentLibraryList(content: ClassContent): ContentItemTypes[] {
    const findContent = ClassContentManager.controller.model.contentList.find(c => c.key === content.key)
    if (findContent) {
      return findContent.contentLibrary.filter(l => l.type === ContentItemType.Image || l.type === ContentItemType.Video)
    }
    return []
  }

  /**
   * 신규 컨텐츠 모달로 추가
   * @param newContent
   */
  async addNewContentInModal(newContent: ClassContent): Promise<ClassContent> {
    await ClassContentManager.controller.model.addContent(newContent);
    this.init();
    return newContent
  }

  /**
   * 컨텐츠 정보 모달에서 수정
   * @param editContent
   */
  async updateContentInModal(editContent: ClassContent): Promise<ClassContent> {
    await ClassContentManager.controller.model.saveContent(editContent);
    this.init();
    return editContent
  }

  /**
   * 컨텐츠 삭제
   * @param contentKey
   */
  async deleteContent(contentKey: string): Promise<boolean> {
    if (await ClassContentManager.controller.model.deleteContent(contentKey)) {
      this.init()
      return true
    }
    return false
  }

  /**
   * 컨텐츠 복사
   * @param contentKey
   */
  async copyContent(contentKey: string): Promise<ClassContent | undefined> {
    const copyContent = await ClassContentManager.controller.model.copyContent(contentKey)
    if (copyContent) this.init()
    return copyContent
  }

  updateThumbnailKey(content: ClassContent, thumbnailKey: string) {
    ClassContentManager.controller.model.updateThumbnailKey(content, thumbnailKey);
  }


}
