
import {Component, Emit, Vue} from 'vue-property-decorator';
import PcmCreator from "@/assets/lib/media/PcmCreator";
import App from "@/assets/lib/controller/App";

@Component({name: "VoiceRecognition", components: {}})
export default class extends Vue {

  recommendQuestionExample: string[] = []
  pcmCreator: PcmCreator | null = null;

  @Emit('getVoiceText')
  sendVoice(voiceData: { isSuc: boolean, msg: string }) {
    return voiceData
  }

  @Emit('closePopup')
  closePopup() {
    //
  }

  mounted() {
    this.init();
  }

  init() {
    this.recommendQuestionExample = ['마이크가 안돼', '카메라가 안돼', '웃어른', '회의', '높임말', '지금 몇시니?'];
    if (!this.pcmCreator) this.pcmCreator = new PcmCreator()
    App.controller.auth.setUuid();
    this.startRecord();
  }

  startRecord() {
    this.pcmCreator?.createPcm(this.endPcmSend)
  }

  async stopRecord() {
    await this.pcmCreator?.stop();
  }

  sendQuestionExample(msg: string) {
    this.pcmCreator?.setSoundMeterCanCelCallBack()
    this.endPcmSend(msg)
  }

  endPcmSend(msg: string) {
    this.sendVoice({
      isSuc: msg !== '[ERROR]',
      msg: msg
    });
    this.closePopup();
  }

}
