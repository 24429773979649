
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component({name: "LoadingModal", components: {}})
export default class LoadingModal extends Vue {
  @Prop() isLoaded!: boolean;

  @Emit('startClass')
  startClass() {
    return true;
  }

}
