
import {Component, Vue} from "vue-property-decorator";
import UserApi from "@/assets/lib/api/UserApi";

@Component({name: "JoinPage", components: {}})
export default class JoinPage extends Vue {
  userId = ''
  userPw = ''
  userName = ''
  userLevel = 0;
  userType = 'student'
  userIdValidity = 'textingId'
  userPwValidity = 'textingPw'
  userNameValidity = 'textingName'

  validateId() {
    if (this.userId === '') {
      this.userIdValidity = 'invalid'
    } else {
      this.userIdValidity = 'valid'
    }
  }

  validateName() {
    if (this.userName === '') {
      this.userNameValidity = 'invalid'
    } else {
      this.userNameValidity = 'valid'
    }
  }

  changeUserLevel(level: number) {
    this.userLevel = level
  }

  join() {
    if (this.userIdValidity === 'valid' && this.userNameValidity === 'valid') {
      UserApi.join({
        user_id: this.userId,
        user_name: this.userName,
        user_type: this.userType,
        user_level: this.userLevel
      }, (res) => {
        if (res && res.code === 200) {
          alert('회원가입이 완료되었습니다.')
        } else {
          alert(res.message)
        }
      }, (err) => {
        if (err.message?.includes("403")) {
          alert('이미 존재하는 아이디입니다.');
          return;
        }
        console.log('err.message', err.message)
        alert(err.message)
      });
    } else {
      alert('입력값을 확인해주세요.')
    }
  }

}
