
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {
  ContentItemQuiz,
  ContentItemScenario,
  ContentItemType,
  QuizItem,
  QuizItemType,
  QuizOptions,
  TimelineContent
} from "@/Booker/lib/BookerTypes";
import QuizStart from "@/components/QuizStart.vue";
import QuizStartHeader from "@/components/ui/quiz/QuizStartHeader.vue";
import MyContentViewModel from "@/assets/lib/viewModel/MyContentViewModel";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import Rex from "@/assets/lib/utility/Rex";
import Chatbot from "@/assets/lib/utility/Chatbot";
import App from "@/assets/lib/controller/App";
import QuizStartDictation from "@/components/ui/quiz/quizTypes/QuizStartDictation.vue";
import QuizStartOx from "@/components/ui/quiz/quizTypes/QuizStartOx.vue";
import QuizStartBlank from "@/components/ui/quiz/quizTypes/QuizStartBlank.vue";
import QuizStartDescriptive from "@/components/ui/quiz/quizTypes/QuizStartDescriptive.vue";
import QuizStartInput from "@/components/ui/quiz/quizTypes/QuizStartInput.vue";
import QuizStartSelect from "@/components/ui/quiz/quizTypes/QuizStartSelect.vue";

@Component({
  name: "RetakeMyQuiz",
  computed: {
    QuizItemType() {
      return QuizItemType
    }
  },
  components: {
    QuizStartSelect, QuizStartInput, QuizStartDescriptive, QuizStartBlank, QuizStartOx, QuizStartDictation,
    QuizStartHeader,
    QuizStart,
  }
})
export default class RetakeMyQuiz extends Vue {
  @Prop() timelineQuizItem !: TimelineContent
  @Prop() quiz !: ContentItemQuiz
  @Prop() vm !: MyContentViewModel
  @Prop() contentKey !: string
  timelineQuiz: TimelineContent | null = null;
  modalStatus = false;
  quizMap: Map<string, ContentItemQuiz> | null = null;
  quizAbleSubmit = false
  quizOnSubmit = false
  content: ContentItemQuiz | null = null
  quizResultOpenPopup = false
  quizFail = false
  quizGood = false
  quizItem: QuizItem[] = []
  commonTextAreaReadOnly = true


  mounted() {
    //this.init();

    console.log('this.contentKey', this.contentKey)
  }

  @Emit('retakeQuiz')
  retakeQuiz(key: string) {
    console.log('key', key)
    return key
  }

  endQuiz(isGood: boolean) {
    if (!this.timelineQuiz) return;

    this.updateStatusToPlay()

    if (isGood) this.quizMap?.delete(this.timelineQuiz.content.key)
    else {
      const quizContent = (this.timelineQuiz.content as ContentItemQuiz);
      if (quizContent.useRepeat) {
        //this.model.setCurrenTimeByTimelineIdx(quizContent.repeatSection)
      }
    }

    this.timelineQuiz = null;
  }

  @Emit('updateStatus')
  updateStatusToPlay() {
    return 'Play'
  }

  @Emit('modalClose')
  modalClose() {
    return
  }

  /**
   * QuizStartHeader
   */
  btnClassObject() {
    return {
      'able': this.quizAbleSubmit, 'result': this.quizOnSubmit
    }
  }

  //제출하기 클릭하면 결과 나옴
  onSubmitQuiz() {
    //버튼 클릭 했을 경우 결과 팝업 출력
    this.recordStopFunc();

    if (this.isSubmit) {
      this.quizResultOpenPopup = true
    } else {
      //모두 답변했을 경우, 버튼 클릭 가능
      this.checkIsSubmit();
      if (this.quizAbleSubmit) {
        this.quizOnSubmit = true
        this.quizScore();
      }
    }
  }

  recordStopFunc() {
    return;
  }

  get isSubmit() {
    return this.quizOnSubmit
  }


  checkIsSubmit() {
    let isSubmit = true;
    this.quizItem.forEach((quiz) => {
      switch (quiz.type) {
        case QuizItemType.Dictation:
          if (!this.checkTextOption(quiz)) isSubmit = false;
          break;
        case QuizItemType.Input:
        case QuizItemType.Descriptive:
          if (!this.checkTextOption(quiz)) isSubmit = false;
          break;
        case QuizItemType.Blank:
          if (!this.checkTextArrayOption(quiz)) isSubmit = false;
          break;
        case QuizItemType.Select:
        case QuizItemType.OX:
          if (!this.checkNumberQuiz(quiz)) isSubmit = false;
          break;
      }
    })

    if (isSubmit) this.quizAbleSubmit = true;
  }

  quizScore() {

    const quiz = this.content;

    let totalScore = 0;

    const list: QuizItem[] = [];

    this.quizItem.forEach((quizItem, index) => {
      let rate = 0;
      if (quizItem.type !== QuizItemType.Descriptive) {
        switch (quizItem.type) {
          case QuizItemType.Blank:
          case QuizItemType.Dictation:
          case QuizItemType.Input:
            rate = this.quizScoreString(quizItem.options)
            break;
          case QuizItemType.OX:
          case QuizItemType.Select:
            rate = this.quizScoreNumber(quizItem.options)
            break;
        }
      }

      quizItem.userScore = JSON.parse(JSON.stringify(quizItem.score));
      if (quizItem.userScore) {
        quizItem.userScore[0].value = Math.round(quizItem.score[0].value * rate * 10) / 10
        quizItem.userScore[1].value = Math.round(quizItem.score[1].value * rate * 10) / 10
        quizItem.userScore[2].value = Math.round(quizItem.score[2].value * rate * 10) / 10
        quizItem.userScore[3].value = Math.round(quizItem.score[3].value * rate * 10) / 10
      }

      list.push(quizItem);

    })

    this.quizItem = [...list];

    this.checkCorrectMsgInQuizList();

    const actContent = ClassContentManager.controller.model.activeUserContent;
    if (actContent) {
      const activityList = actContent.timeline[BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Quiz)]
      const activityIdx = activityList.contents.findIndex(c => c.content.key === this.content?.key)
      if (activityIdx > -1) {
        ((actContent
          .timeline[BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Quiz)]
          .contents[activityIdx].content) as ContentItemQuiz).quizItems = [...list];
      } else {
        const scenarioList = actContent.timeline[BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Scenario)]
        if (scenarioList) {
          const scenarioIdx = scenarioList.contents.findIndex(c => {
            const actionContent = (c.content as ContentItemScenario).actionContent;
            return actionContent && actionContent[0].key === this.content?.key;

          })
          if (scenarioIdx > -1) {
            const actionContent = (actContent
              .timeline[BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Scenario)]
              .contents[scenarioIdx].content as ContentItemScenario).actionContent;
            if (actionContent) {
              (actionContent[0] as ContentItemQuiz).quizItems = [...list];
            }
          }
        }

      }

    }

    ClassContentManager.controller.userClassUpdate()

    this.quizGood = false;
    this.quizFail = false;

    if (quiz && !quiz?.useRepeat) {
      this.quizGood = true;
      return;
    }

    quiz && totalScore >= quiz.repeatMinScore ? this.quizGood = true : this.quizFail = false
  }

  quizScoreNumber(quizOptions: QuizOptions[]) {
    let isCorrect = false;
    quizOptions.forEach((quizOption) => {
      if (quizOption.answer === 1 && quizOption.userAnswer === 1) {
        isCorrect = true;
        quizOption.isCorrect = true;
      } else {
        quizOption.isCorrect = false;
      }
    })

    return isCorrect ? 1 : 0;
  }

  quizScoreString(quizOptions: QuizOptions[]) {
    let score = 0;
    quizOptions.forEach((quizOption) => {
      let isCorrect = false;
      if (Array.isArray(quizOption.subAnswers)) {
        quizOption.subAnswers.forEach((subAnswer) => {
          if (!isCorrect && this.checkString(subAnswer as string, quizOption.userAnswer as string)) isCorrect = true
        })
      }
      if (!isCorrect && this.checkString(quizOption.answer as string, quizOption.userAnswer as string)) isCorrect = true
      quizOption.isCorrect = isCorrect;
      if (quizOption.isCorrect) score += 1;
    })
    return score / quizOptions.length;
  }


  checkString(str1: string, str2: string) {
    return encodeURI(Rex.onlyText(str1)) == encodeURI(Rex.onlyText(str2))
  }

  /**
   * 텍스트 퀴즈가 저장 가능 상태인지 확인
   * @param quiz
   */
  checkTextOption(quiz: QuizItem): boolean {
    if ((quiz.options[0].userAnswer as string).length < 1) return false;
    return true
  }

  /**
   * 텍스트 퀴즈가 저장 가능 상태인지 확인
   * @param quiz
   */
  checkTextArrayOption(quiz: QuizItem): boolean {
    let result = true;
    quiz.options.forEach(option => {
      if ((option.userAnswer as string).length < 1) result = false
    })
    return result
  }

  /**
   * 다지선다, OX 퀴즈가 저장 가능 상태인지 확인
   * @param quiz
   */
  checkNumberQuiz(quiz: QuizItem): boolean {
    return quiz.options.findIndex((option) => option.userAnswer === 1) > -1
  }

  checkCorrectMsgInQuizList() {
    this.quizItem.forEach(quiz => {
      if (!quiz.correctAnswerMsg) return;
      if (!quiz.inCorrectAnswerMsg) return;
      if (Array.isArray(quiz.options)) {

        //50점 이상일때 보냄
        let correctCount = 0;
        quiz.options.forEach((option) => {
          if (option.isCorrect) correctCount++
        })

        if (correctCount >= (quiz.options.length / 2)) Chatbot.sendCommonMsg(quiz.correctAnswerMsg)
        else Chatbot.sendCommonMsg(quiz.inCorrectAnswerMsg)
      }
    })
  }
  isDifficulty = false;
  difficulty = 1;
  init() {
    // 퀴즈 데이터를 넣어준다.
    // QuizOptions를 추가해준다.

    const content = this.timelineQuizItem.content.type === ContentItemType.Quiz
      ? (this.timelineQuizItem.content as ContentItemQuiz)
      : ((this.timelineQuizItem.content as ContentItemScenario).actionContent![0] as ContentItemQuiz);

    console.log('content', content)

    this.content = (JSON.parse(JSON.stringify(content)) as ContentItemQuiz);
    if (!this.content) return;

    if (this.content.quizItems.findIndex(q => q.difficulty == 0 || q.difficulty == 2) > 0) {
      this.isDifficulty = true;
      this.difficulty = App.controller.auth.getUserDifficulty()
    }

    this.quizItem = this.content.quizItems;

  }

  get quizTypeText() {
    if (this.content?.quizType !== undefined) {
      switch (this.content?.quizType) {
        case 0:
          return '[퀴즈] - '
        case 1:
          return '[연습 문제] - '
        case 2:
          return '[실전 문제] - '
      }
    }
    return ''
  }

  //퀴즈 문제 한자릿수 0 더하기
  quizItemsBeforePadZero(index: number) {
    if (index < 10) {
      return `0${index}`
    } else {
      return index
    }
  }

  updateInputAnswer(quiz: QuizItem) {
    const findIdx = this.quizItem.findIndex((q) => q.quizUuid === quiz.quizUuid)
    this.quizItem.splice(findIdx, 1, quiz);
    this.checkIsSubmit();
  }
  modalSrc = ''
  zoomImg(url: string) {
    this.modalStatus = true
    this.modalSrc = url;

  }

  setDictationAnswerFile(emitData: { uuid: string, fileUrl: string }) {
    const {uuid, fileUrl} = emitData;
    const quizIndex = this.quizItem.findIndex(q => q.quizUuid === uuid);
    const quiz = this.quizItem[quizIndex];
    if (quiz) quiz.options[0].answerFileUrl = fileUrl;
    this.quizItem.splice(quizIndex, 1, quiz);
    this.checkIsSubmit();
  }

  setDictationAnswer(emitData: { uuid: string, myDictation: string }) {
    const {uuid, myDictation} = emitData;
    const quizIndex = this.quizItem.findIndex(q => q.quizUuid === uuid);
    const quiz = this.quizItem[quizIndex];
    if (quiz) quiz.options[0].userAnswer = myDictation;
    this.quizItem.splice(quizIndex, 1, quiz);
    this.checkIsSubmit();
  }

  setMyDictationAnswer(emitData: { uuid: string, myDictationAnswer: string, optionIndex: number }) {
    const {uuid, myDictationAnswer, optionIndex} = emitData;

    const quizIndex = this.quizItem.findIndex(q => q.quizUuid === uuid);
    const quiz = this.quizItem[quizIndex];
    quiz.options[optionIndex].userAnswer = myDictationAnswer;
    this.quizItem.splice(quizIndex, 1, quiz);
    this.checkIsSubmit();
  }

  blankData(emitData: { textAnswer: QuizOptions[], optionIndex: number, quizIndex: number }) {
    const {textAnswer, optionIndex, quizIndex} = emitData

    this.quizItem[quizIndex].options = textAnswer;

    return emitData
  }
}
