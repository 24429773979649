import { render, staticRenderFns } from "./QuizTimeLimit.vue?vue&type=template&id=87f8c496&scoped=true&"
import script from "./QuizTimeLimit.vue?vue&type=script&lang=ts&"
export * from "./QuizTimeLimit.vue?vue&type=script&lang=ts&"
import style0 from "./QuizTimeLimit.vue?vue&type=style&index=0&id=87f8c496&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87f8c496",
  null
  
)

export default component.exports