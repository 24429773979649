import {ContentItemTypes, DraggingObjectType, TimelineContent} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

class DraggingItemModel {
  private type: null | DraggingObjectType = null
  private item: null | ContentItemTypes | TimelineContent = null

  setItem(type: DraggingObjectType, item: ContentItemTypes | TimelineContent) {
    this.type = type
    this.item = item
  }

  reset() {
    this.type = null
    this.item = null
  }

  getDraggingContentItem(): null | ContentItemTypes {
    if (!this.item) return null
    if (this.type === DraggingObjectType.ContentItemBase) return this.item as ContentItemTypes
    return null
  }

  getDraggingTimelineContent(): null | TimelineContent {
    if (!this.item) return null
    if (this.type === DraggingObjectType.TimelineContent) return this.item as TimelineContent
    return null
  }

  getContentLength(): number {

    if (this.type === DraggingObjectType.ContentItemBase) {
      const content = (this.item as ContentItemTypes);
      return BookerUtility.getContentLength(content)
    }

    const content = (this.item as TimelineContent).content;
    return BookerUtility.getContentLength(content)

  }

}

export default new DraggingItemModel()

