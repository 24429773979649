
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import QuizResult from '@/components/ui/quiz/QuizResult.vue'
import {
  ContentItemQuiz,
  ContentItemScenario,
  ContentItemType,
  QuizItem,
  QuizItemType,
  QuizOptions,
  TimelineContent
} from "@/Booker/lib/BookerTypes";
import QuizStartHeader from "@/components/ui/quiz/QuizStartHeader.vue"
import CommonInput from "@/Booker/views/components/common/CommonInput.vue"
import QuizStartInput from "@/components/ui/quiz/quizTypes/QuizStartInput.vue"
import QuizStartDescriptive from "@/components/ui/quiz/quizTypes/QuizStartDescriptive.vue"
import QuizStartSelect from "@/components/ui/quiz/quizTypes/QuizStartSelect.vue"
import QuizStartOx from "@/components/ui/quiz/quizTypes/QuizStartOx.vue"
import QuizStartDictation from "@/components/ui/quiz/quizTypes/QuizStartDictation.vue"
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import App from "@/assets/lib/controller/App";
import Rex from "@/assets/lib/utility/Rex";
import Chatbot from "@/assets/lib/utility/Chatbot";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import QuizStartBlank from "@/components/ui/quiz/quizTypes/QuizStartBlank.vue";

@Component({
  components: {
    QuizStartBlank,
    QuizResult,
    QuizStartHeader,
    CommonInput,
    QuizStartInput,
    QuizStartDescriptive,
    QuizStartSelect,
    QuizStartOx,
    QuizStartDictation
  }
})
export default class QuizStart extends Vue {

  @Prop() quizItem !: TimelineContent

  content: ContentItemQuiz | null = null

  //퀴즈 결과 팝업 열기
  quizResultOpenPopup = false
  quizFail = false
  quizGood = false
  isRepeat = false
  //제출하기 버튼 활성화
  quizAbleSubmit = false
  //제출하기 버튼 활성화 이후 퀴즈 제출
  quizOnSubmit = false
  QuizItemType = QuizItemType;
  BookerUtility = BookerUtility;
  intervalId: any
  selectIndex: number | null = null
  quiz: QuizItem[] = []
  // 이미지 모달
  modalStatus = false;
  modalSrc = ''
  isDifficulty = false;
  commonTextAreaReadOnly = true

  get isSubmit() {
    return this.quizOnSubmit
  }

  get getUserDifficulty() {
    return App.controller.auth.getUserDifficulty()
  }

  get quizTypeText() {
    if (this.content?.quizType !== undefined) {
      switch (this.content?.quizType) {
        case 0:
          return '[실전 문제] - '
        case 1:
          return '[연습 문제] - '
        case 2:
          return '[퀴즈] - '
      }
    }
    return ''
  }


  mounted() {
    this.init();
  }

  @Emit('endQuiz')
  endQuiz() {
    //
    return {
      result: this.quizGood ? true : false,
      isRepeat: this.content?.useRepeat
    }
  }

  closePopUp() {
    this.endQuiz();
    if (!this.content) return;
  }

  //제출하기 클릭하면 결과 나옴
  onSubmitQuiz(isTimeOut = false) {
    if (isTimeOut) {
      this.quizOnSubmit = true
      this.quizScore();
      return;
    }
    if (this.isSubmit) {
      this.quizResultOpenPopup = true
    } else {
      //모두 답변했을 경우, 버튼 클릭 가능
      this.checkIsSubmit();
      if (this.quizAbleSubmit) {
        this.quizOnSubmit = true
        this.quizScore();
      }
    }
  }

  quizScore() {

    const quiz = this.content;

    let totalScore = 0;
    let quizTotalScore = 0;

    const list: QuizItem[] = [];

    this.quiz.forEach((quizItem, index) => {
      let rate = 0;
      switch (quizItem.type) {
        case QuizItemType.Blank:
        case QuizItemType.Dictation:
        case QuizItemType.Input:
          rate = this.quizScoreString(quizItem.options)
          break;
        case QuizItemType.OX:
        case QuizItemType.Select:
          rate = this.quizScoreNumber(quizItem.options)
          break;
        case QuizItemType.Descriptive:
          quizItem.options[0].isCorrect = true;
          rate = 1
          break;
      }

      quizItem.userScore = JSON.parse(JSON.stringify(quizItem.score));
      if (quizItem.userScore) {
        quizItem.userScore[0].value = Math.round(quizItem.score[0].value * rate * 10) / 10
        quizItem.userScore[1].value = Math.round(quizItem.score[1].value * rate * 10) / 10
        quizItem.userScore[2].value = Math.round(quizItem.score[2].value * rate * 10) / 10
        quizItem.userScore[3].value = Math.round(quizItem.score[3].value * rate * 10) / 10

        totalScore += quizItem.userScore.reduce((a, b) => a + b.value, 0);
        quizTotalScore += quizItem.score.reduce((a, b) => a + b.value, 0);
      }

      list.push(quizItem);

    })

    this.quiz = [...list];

    this.checkCorrectMsgInQuizList();

    const actContent = ClassContentManager.controller.model.activeUserContent;
    if (actContent) {
      const activityList = actContent.timeline[BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Quiz)]
      const activityIdx = activityList.contents.findIndex(c => c.content.key === this.content?.key)
      if (activityIdx > -1) {
        ((actContent
          .timeline[BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Quiz)]
          .contents[activityIdx].content) as ContentItemQuiz).quizItems = [...list];
      } else {
        const scenarioList = actContent.timeline[BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Scenario)]
        if (scenarioList) {
          const scenarioIdx = scenarioList.contents.findIndex(c => {
            const actionContent = (c.content as ContentItemScenario).actionContent;
            return actionContent && actionContent[0].key === this.content?.key;

          })
          if (scenarioIdx > -1) {
            const actionContent = (actContent
              .timeline[BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Scenario)]
              .contents[scenarioIdx].content as ContentItemScenario).actionContent;
            if (actionContent) {
              (actionContent[0] as ContentItemQuiz).quizItems = [...list];
            }
          }
        }

      }

    }

    ClassContentManager.controller.userClassUpdate()

    this.quizGood = false;
    this.isRepeat = false;
    this.quizFail = true;

    if (quiz && quiz.useRepeat && quiz.repeatMinScore) {
      if (quiz && totalScore >= quiz.repeatMinScore) {
        this.quizGood = true
      } else {
        this.quizFail = true
        this.isRepeat = true;
      }
    }
    if (quiz && totalScore >= quizTotalScore) {
      this.quizGood = true
      this.quizFail = false;
    }
  }

  quizScoreNumber(quizOptions: QuizOptions[]) {
    let isCorrect = false;
    quizOptions.forEach((quizOption) => {
      if (quizOption.answer === 1 && quizOption.userAnswer === 1) {
        isCorrect = true;
        quizOption.isCorrect = true;
      } else {
        quizOption.isCorrect = false;
      }
    })

    return isCorrect ? 1 : 0;
  }


  checkString(str1: string, str2: string) {
    return encodeURI(Rex.onlyText(str1)) == encodeURI(Rex.onlyText(str2))
  }

  quizScoreString(quizOptions: QuizOptions[]) {
    let score = 0;
    quizOptions.forEach((quizOption) => {
      let isCorrect = false;
      if (Array.isArray(quizOption.subAnswers)) {
        quizOption.subAnswers.forEach((subAnswer) => {
          if (!isCorrect && this.checkString(subAnswer as string, quizOption.userAnswer as string)) isCorrect = true
        })
      }
      if (!isCorrect && this.checkString(quizOption.answer as string, quizOption.userAnswer as string)) isCorrect = true
      quizOption.isCorrect = isCorrect;
      if (quizOption.isCorrect) score += 1;
    })
    return score / quizOptions.length;
  }


  checkCorrectMsgInQuizList() {
    this.quiz.forEach(quiz => {
      if (!quiz.correctAnswerMsg) return;
      if (Array.isArray(quiz.options)) {

        //100점 일때 보냄
        let correctCount = 0;
        if (quiz.type === QuizItemType.OX || quiz.type === QuizItemType.Select) {
          quiz.options.forEach((option) => {
            if (option.isCorrect && option.userAnswer === option.answer) correctCount++
          })
          if (correctCount > 0) Chatbot.sendCommonMsg(quiz.correctAnswerMsg)
          else if (quiz.inCorrectAnswerMsg) Chatbot.sendCommonMsg(quiz.inCorrectAnswerMsg)
        } else {
          quiz.options.forEach((option) => {
            if (option.isCorrect) correctCount++
          })
          if (correctCount === quiz.options.length) Chatbot.sendCommonMsg(quiz.correctAnswerMsg)
          else if (quiz.inCorrectAnswerMsg) Chatbot.sendCommonMsg(quiz.inCorrectAnswerMsg)
        }

      }
    })
  }


  updateInputAnswer(quiz: QuizItem) {
    const findIdx = this.quiz.findIndex((q) => q.quizUuid === quiz.quizUuid)
    this.quiz.splice(findIdx, 1, quiz);
    this.checkIsSubmit();
  }

  checkIsSubmit() {
    let isSubmit = true;
    this.quiz.forEach((quiz) => {
      switch (quiz.type) {
        case QuizItemType.Dictation:
          if (!this.checkTextOption(quiz)) isSubmit = false;
          break;
        case QuizItemType.Input:
        case QuizItemType.Descriptive:
          if (!this.checkTextOption(quiz)) isSubmit = false;
          break;
        case QuizItemType.Blank:
          if (!this.checkTextArrayOption(quiz)) isSubmit = false;
          break;
        case QuizItemType.Select:
        case QuizItemType.OX:
          if (!this.checkNumberQuiz(quiz)) isSubmit = false;
          break;
      }
    })

    if (isSubmit) this.quizAbleSubmit = true;
  }

  /**
   * 텍스트 퀴즈가 저장 가능 상태인지 확인
   * @param quiz
   */
  checkTextOption(quiz: QuizItem): boolean {
    if ((quiz.options[0].userAnswer as string).length < 1) return false;
    return true
  }

  /**
   * 텍스트 퀴즈가 저장 가능 상태인지 확인
   * @param quiz
   */
  checkTextArrayOption(quiz: QuizItem): boolean {
    let result = true;
    quiz.options.forEach(option => {
      if ((option.userAnswer as string).length < 1) result = false
    })
    return result
  }

  blankData(emitData: { textAnswer: QuizOptions[], optionIndex: number, quizIndex: number }) {
    const {textAnswer, optionIndex, quizIndex} = emitData

    this.quiz[quizIndex].options = textAnswer;

    return emitData
  }


  /**
   * 다지선다, OX 퀴즈가 저장 가능 상태인지 확인
   * @param quiz
   */
  checkNumberQuiz(quiz: QuizItem): boolean {
    return quiz.options.findIndex((option) => option.userAnswer === 1) > -1
  }

  btnClassObject() {
    return {
      'able': this.quizAbleSubmit, 'result': this.quizOnSubmit
    }
  }

  //퀴즈 문제 한자릿수 0 더하기
  quizItemsBeforePadZero(index: number) {
    if (index < 10) {
      return `0${index}`
    } else {
      return index
    }
  }

  init() {
    // 퀴즈 데이터를 넣어준다.
    // QuizOptions를 추가해준다.

    const content = this.quizItem.content.type === ContentItemType.Quiz
      ? (this.quizItem.content as ContentItemQuiz)
      : ((this.quizItem.content as ContentItemScenario).actionContent![0] as ContentItemQuiz);

    this.content = (JSON.parse(JSON.stringify(content)) as ContentItemQuiz);
    if (!this.content) return;

    if (this.content.quizItems.findIndex(q => q.difficulty == 0 || q.difficulty == 2) > -1) {
      this.isDifficulty = true;
    }

    if (this.isDifficulty) {
      this.quiz = this.content.quizItems.filter(q => q.difficulty === this.getUserDifficulty);
    } else {
      this.quiz = this.content.quizItems;
    }

  }

  beforeDestroy() {
    clearInterval(this.intervalId)
  }

  zoomImg(url: string) {
    this.modalStatus = true
    this.modalSrc = url;
  }

  modalClose() {
    this.modalStatus = false
  }

  recordStopFunc() {
    return;
  }

  setDictationAnswerFile(emitData: { uuid: string, fileUrl: string }) {
    const {uuid, fileUrl} = emitData;
    const quizIndex = this.quiz.findIndex(q => q.quizUuid === uuid);
    const quiz = this.quiz[quizIndex];
    if (quiz) quiz.options[0].answerFileUrl = fileUrl;
    this.quiz.splice(quizIndex, 1, quiz);
    this.checkIsSubmit();
  }

  setDictationAnswer(emitData: { uuid: string, myDictation: string }) {
    const {uuid, myDictation} = emitData;
    const quizIndex = this.quiz.findIndex(q => q.quizUuid === uuid);
    const quiz = this.quiz[quizIndex];
    if (quiz) quiz.options[0].userAnswer = myDictation;
    this.quiz.splice(quizIndex, 1, quiz);
    this.checkIsSubmit();
  }

  setMyDictationAnswer(emitData: { uuid: string, myDictationAnswer: string, optionIndex: number }) {
    const {uuid, myDictationAnswer, optionIndex} = emitData;

    const quizIndex = this.quiz.findIndex(q => q.quizUuid === uuid);
    const quiz = this.quiz[quizIndex];
    quiz.options[optionIndex].userAnswer = myDictationAnswer;
    this.quiz.splice(quizIndex, 1, quiz);
    this.checkIsSubmit();
  }

}
