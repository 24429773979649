
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import QuizRepeat from "@/Booker/views/components/quiz/QuizRepeat.vue";
import QuizAddItem from "@/Booker/views/components/quiz/QuizAddItem.vue";
import QuizShortAnswer from "@/Booker/views/components/quiz/QuizInput.vue";
import QuizInput from "@/Booker/views/components/quiz/QuizInput.vue";
import QuizDesc from "@/Booker/views/components/quiz/QuizDesc.vue";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";
import {ContentToolStateType, QuizItemType} from "@/Booker/lib/BookerTypes";
import QuizSelect from "@/Booker/views/components/quiz/QuizSelect.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import QuestionHeader from "@/Booker/views/components/quiz/QuestionHeader.vue";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import QuizOX from "@/Booker/views/components/quiz/QuizOX.vue";
import QuizDifficulty from "@/Booker/views/components/quiz/QuizDifficulty.vue";
import QuizTimeLimit from "@/Booker/views/components/quiz/QuizTimeLimit.vue";
import QuizDictation from "@/Booker/views/components/quiz/QuizDictation.vue";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import FileApi from "@/assets/lib/api/FileApi";
import QuizBlank from "@/Booker/views/components/quiz/QuizBlank.vue";
import QuizSelectType from "@/Booker/views/components/quiz/QuizSelectType.vue";


@Component({
  name: "CreateQuiz",
  components: {
    QuizSelectType,
    QuizBlank,
    QuizDifficulty,
    CommonInput,
    QuizOX,
    QuizSelect,
    QuizInput,
    QuizDesc,
    QuizShortAnswer,
    QuizRepeat,
    QuizAddItem,
    QuestionHeader,
    QuizTimeLimit,
    CommonButton,
    QuizDictation
  }
})
export default class CreateQuiz extends Vue {

  @Prop() model!: ClassContentMediaListViewModel
  @Ref() refInput!: HTMLInputElement;
  quizModel: ContentItemQuizEditorViewModel | null = null;
  isSave = false;
  isDifficulty = false;
  QuizItemType = QuizItemType
  quizStatus = true;

  uploadImgIdx = -1;
  uploadImgDiff = -1;

  selectedDifficulty = 1;

  selectedQuizType = this.quizModel ? this.quizModel?.quizType : 2;
  isQuizType = false


  get questionList() {
    if (this.quizModel) return this.quizModel.questionList
    return []
  }

  init() {
    this.quizModel = new ContentItemQuizEditorViewModel(this.model.createQuizTemplate())
    this.isDifficulty = this.quizModel.isDifficulty
  }

  mounted() {
    this.init()
  }

  difficultyIndex(quizIndex: number, difficulty: number) {
    if (!this.isDifficulty) return quizIndex;
    const index = quizIndex - this.questionList.filter((quiz, index) =>
      quiz.difficulty !== difficulty && index < quizIndex
    ).length;

    return index
  }

  difficultyLastIndex(difficulty: number) {
    if (!this.isDifficulty) return this.questionList.length - 1;
    return this.questionList.filter(quiz => quiz.difficulty === difficulty).length - 1
  }

  /**
   * 저장 확인
   * 하위 컴포넌트들의 Emit으로 호출되는 함수
   * 하위 컴포넌트들의 데이터를 조회해서 저장 가능상태인지 판단.
   */
  checkSave() {
    this.isSave = this.isSaveAble();
  }

  addQuiz(type: QuizItemType) {
    console.log('this.selectedDifficulty', this.selectedDifficulty)
    this.quizModel?.addQuestion(type, this.selectedDifficulty)
    this.quizStatus = false
  }

  /**
   * 퀴즈 저장
   * 만약 난이도 설정이 안되어 있는 경우
   * 내부의 모든 난이도 중 값으로 변경
   * 저장후 기본 메뉴로 이동
   * 실패시 alert
   */
  saveQuiz() {
    if (!this.quizModel) return;

    console.log('this.quizModel!.quiz', this.quizModel!.quiz)
    if (!this.isDifficulty) this.quizModel.changeAllDifficulty()
    if (this.model.addQuizLibrary(this.quizModel!.quiz)) {
      this.model.save();
      this.setToolState(ContentToolStateType.Default);
      return;
    }
    alert('퀴즈 저장에 실패하였습니다. 퀴즈 상태를 확인해주세요.');
  }

  /**
   * 저장 가능 상태인지 확인
   * 퀴즈의 제목, 질문 갯수, 질문이 비었는지 여부 확인
   */
  isSaveAble() {
    if (!this.quizModel) return false;
    if (!this.quizModel.quiz.title) return false;
    if (this.quizModel.quiz.title.length < 2) return false;
    if (this.questionList.length < 1) return false;
    return this.quizModel.checkQuizItemsState();

  }

  onInputTitle(e: Event) {
    this.quizModel!.quiz.title = (e.target as HTMLInputElement).value;
    this.checkSave();
  }

  onChangeIsDifficulty(isDifficulty: boolean) {
    this.isDifficulty = isDifficulty;
  }

  @Emit('setToolState')
  setToolState(state: ContentToolStateType) {
    return state;
  }


  /**
   * 퀴즈 타입 선택
   * @param type
   */
  onChangeQuizType(type: number) {
    if (type < 0) return;
    if (type > 2) return;
    this.selectedQuizType = type;
    if (this.quizModel) this.quizModel.setQuizType(type)
    console.log('this.selectedQuizType', this.selectedQuizType)
  }


  // url, idx 같이 넘겨서 어떤 퀴즈인지 확인
  // 그 퀴즈에 img에 url 값을 넣어줘야함
  // 재 랜더링 되게끔 데이터 수정
  // 이미지 파일 업로드
  onFileSelected(eventData: { difficultyIdx: number, difficulty: number }) {
    const {difficultyIdx, difficulty} = eventData;
    console.log('onFileSelected', {difficultyIdx, difficulty})
    this.uploadImgIdx = difficultyIdx;
    this.uploadImgDiff = difficulty;
    this.refInput.click();
  }

  async onFile(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target && target.files && target.files.length > 0) {

      const fileApiRes = await FileApi.uploadFile(target.files[0])
      const fileId = fileApiRes?.file_list[0].file_id
      if (fileId) {
        if (this.uploadImgIdx !== -1 && this.uploadImgDiff !== -1) {
          this.quizModel?.setOptionsImage(this.uploadImgIdx, this.uploadImgDiff, fileId)

          this.uploadImgIdx = -1
          this.uploadImgDiff = -1
        }
      }

      // target.files = null;
    }
  }


  receiveOnChangeDifficulty(difficulty: number) {
    this.selectedDifficulty = difficulty
    console.log('receiveOnChangeDifficulty', difficulty)
  }


}
