
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import {ActionContentType} from "@/Booker/lib/BookerTypes";
import ContentItemScenarioViewModel from "@/Booker/lib/viewModel/classContent/ContentItemScenarioViewModel";

@Component({name: "ScenarioActivity", components: {CommonInput}, computed: {}})
export default class ScenarioActivity extends Vue {

  @Prop() scenarioViewModel!: ContentItemScenarioViewModel
  @Prop() ContentItemType: any;
  @Prop() ContentItemScenarioType: any;
  @Prop() ActionContentType: any;
  @Prop() length!: number;
  @Prop() itemIndex!: number
  selectStatus = false
  activityOption = false
  selectedOption = ''
  option = '테스트'
  optionStatus = false
  optionIndex = 0

  get scenarioType() {
    return this.scenarioViewModel?.scenario.scenarioType
  }

  get actionContentTypes() {
    return this.scenarioViewModel.actionContentType
  }

  /**
   * 시나리오 컨텐츠 목록
   */

  get relaxationList() {
    return this.scenarioViewModel?.relaxationList;
  }

  get pronunciationList() {
    return this.scenarioViewModel?.pronunciationList;
  }

  get quizList() {
    console.log('this.scenarioViewModel?.quizList;', this.scenarioViewModel?.quizList)
    return this.scenarioViewModel?.quizList;
  }

  get bookList() {
    return this.scenarioViewModel?.bookList;
  }

  // changeActionContent(item: string) {
  //   this.selectedOption = item
  //   this.scenarioViewModel.addActionContent(item)
  // }

  mounted() {
    console.log('actionContentTypes', this.actionContentTypes)
  }

  @Emit('checkSave')
  checkSaveEnd() {
    return true;
  }

  addFocusSelect() {
    this.selectStatus = !this.selectStatus
    this.activityOption = !this.activityOption
    this.scenarioViewModel?.setActionContentType(this.scenarioViewModel?.actionContentType)
  }

  setNone() {
    this.scenarioViewModel?.setActionContentType(ActionContentType.None);
    console.log('this.scenarioViewModel?.setActionContentType(ActionContentType.None);', this.scenarioViewModel?.setActionContentType(ActionContentType.None))
    console.log('this.actionContent', ActionContentType.None);
  }

  setRecommendBook() {
    this.scenarioViewModel?.setActionContentType(ActionContentType.RecommendBook);
    this.deleteActionContent();
  }

  setQuiz() {
    this.scenarioViewModel?.setActionContentType(ActionContentType.Quiz);
    this.deleteActionContent();
  }

  setPronunciation() {
    this.scenarioViewModel?.setActionContentType(ActionContentType.Pronunciation);
    this.deleteActionContent();
  }

  setRelaxation() {
    this.scenarioViewModel?.setActionContentType(ActionContentType.Relaxation);
    this.deleteActionContent();
  }

  @Emit('checkSaveKeyword')
  onInputKeyword(e: Event) {
    this.scenarioViewModel.scenario.keyword = (e.target as HTMLInputElement).value;
    //this.scenarioViewModel?.saveScenario();
  }

  @Emit('deleteAction')
  deleteActionContent() {
    console.log(this.itemIndex)
    return this.itemIndex
  }

  choiceOption(index: number) {
    this.optionIndex = index
  }


}
