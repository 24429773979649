import {DeviceDetail} from "@/assets/lib/type/Types";
import ObjectStorage from "@/assets/lib/utility/ObjectStorage";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

export default class DeviceUtil {

  static defaultAudio: DeviceDetail = {id: '0', label: '스피커 미 선택', info: null};
  static defaultMic: DeviceDetail = {id: '0', label: '마이크 미 선택', info: null};
  static defaultCam: DeviceDetail = {id: '0', label: '카메라 미 선택', info: null};
  static defaultFilter = 0;
  static camStorage = new ObjectStorage<DeviceDetail>('selectedCam', this.defaultAudio);
  static micStorage = new ObjectStorage<DeviceDetail>('selectedMic', this.defaultMic);
  static audioStorage = new ObjectStorage<DeviceDetail>('selectedAudio', this.defaultCam);
  static filterStorage = new ObjectStorage<number>('selectedFilter', this.defaultFilter);
  static selectedAudio: DeviceDetail
  static selectedMic: DeviceDetail
  static selectedCam: DeviceDetail
  static selectedFilter: number


  constructor() {
    DeviceUtil.selectedAudio = DeviceUtil.audioStorage.value;
    DeviceUtil.selectedMic = DeviceUtil.micStorage.value;
    DeviceUtil.selectedCam = DeviceUtil.camStorage.value;
    DeviceUtil.selectedFilter = DeviceUtil.filterStorage.value;
  }

  static get isSelectedAudio(): boolean {
    return DeviceUtil.selectedAudio.id !== '0';
  }

  static changeFilter(filter: number) {
    this.selectedFilter = filter;
    this.filterStorage.value = filter;
    this.filterStorage.save();
  }

  static changeAudio(device: DeviceDetail) {
    this.selectedAudio = device;
    this.audioStorage.value = device;
    this.audioStorage.save();
    BookerUtility.sound.setSinkId(device.id);
  }

  static changeMic(device: DeviceDetail) {
    this.selectedMic = device;
    this.micStorage.value = device;
    this.micStorage.save();
  }

  static changeCam(device: DeviceDetail) {
    this.selectedCam = device;
    this.camStorage.value = device;
    this.camStorage.save();
  }


}
