import BookerUtility from "@/Booker/lib/util/BookerUtility";
import {
  ChatBotPostMsg,
  ChatBotPostMsgType,
  CustomButtonCreate,
  CustomMsg,
  CustomMsgUi,
  EventType,
  MsgSenderType
} from "@/assets/lib/type/Types";
import {EventListener} from "three";

export default class Chatbot {

  public static createButtonEventEncode(str: string) {
    return encodeURIComponent(str)
      .replace(
        /[!'()*]/g,
        (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
      );
  }

  public static defaultMsgTemplate(isRead = false): CustomMsg {
    return {
      uuid: BookerUtility.createKey(),
      type: '',
      ui: CustomMsgUi.Text,
      createdAt: new Date().getTime(),
      sender: MsgSenderType.bot,
      text: '',
      isRead: isRead
    }
  }

  public static customButtonCreator(): CustomButtonCreate {
    return {
      text: '',
      code: {
        eventType: EventType.Common,
        value: {
          contentKey: '',
          contentTitle: '',
          data: ''
        }
      }
    }
  }

  public static sendCommonMsg(msg: string, sender: MsgSenderType = MsgSenderType.bot) {
    const customMsg = Chatbot.defaultMsgTemplate(false)
    customMsg.text = msg
    customMsg.sender = sender
    const chatBotPostMsg: ChatBotPostMsg = {
      chatBotPostMsgType: ChatBotPostMsgType.UI,
      customMsg: customMsg,
      isVoice: true,
      data: ''
    }
    this.sendChatBot(chatBotPostMsg)
  }

  public static sendChatBotInit() {
    const customMsg = this.defaultMsgTemplate()
    const chatBotPostMsg: ChatBotPostMsg = {
      chatBotPostMsgType: ChatBotPostMsgType.Init,
      customMsg: customMsg,
      data: ''
    }
    const chatBot = document.querySelector('iframe');
    if (chatBot && chatBot.contentWindow) chatBot.contentWindow.postMessage(chatBotPostMsg, '*')
  }

  public static sendChatBot(chatBotPostMsg: ChatBotPostMsg) {
    const chatBot = document.querySelector('iframe');
    if (chatBot && chatBot.contentWindow) chatBot.contentWindow.postMessage(chatBotPostMsg, '*')
  }


  public static addChatBotEventListener(eventListener: EventListener<any, any, any>) {
    window.addEventListener('message', eventListener)
  }

  public static removeChatBotEventListener(eventListener: EventListener<any, any, any>) {
    window.removeEventListener('message', eventListener)
  }

}

// ========= msg create example =========
// const button:CustomMsg = {
//   type : "slab:custom",
//   text : "버튼 텍스트",
//   ui :  CustomMsgUi.card,
//   img : "https://refactoring.guru/images/content-public/logos/logo-military.png?id=3eaf203cb26279327252e2c5f5d37c7f"
// }
//
// const buttons :CustomButtonCreate[] = [
//   {
//     text : 'button1',
//     code : {
//       type : 'test-button-1',
//       value : {
//         contentTitle : 'test',
//         contentKey : 'test-key',
//         data : {
//           asdf : 'asdfasdf',
//           ffff : 'qwerasdf',
//         },
//       }
//     }
//   }
// ]
//
//
//   window.botpressWebChat.sendPayload(Chatbot.createMsg({
//     ui : CustomMsgUi.card, text : 'button1', buttons
//   }));
