// MediaStream 을 녹음하여 PCM 으로 만든다

import SoundMeter from "@/assets/lib/media/SoundMeter";

export default class PcmCreator {

  private soundMeter = new SoundMeter();
  private timerCount = 0;
  private silenceCount = 0;
  private stopTime = 2 * 1000; //1 초 단위 = 2초
  private stopForQuizTime = 5 * 1000; //1 초 단위 = 2초
  private maxTime = 20 * 1000; //1 초 단위 = 20초
  private maxForQuizTime = 50 * 1000; //1 초 단위 = 20초
  private timer: number | undefined = undefined;
  private isRecord = false;
  private stream: MediaStream | null = null;

  get getStream() {
    return this.stream
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  createPcm(callback?: Function) {
    // Get the audio stream from the user's microphone
    try {
      this.soundMeter.init();
      this.soundMeter.pcmCallback = callback
      this.timer = this.soundCheckTimer();
      navigator.mediaDevices.getUserMedia({
        audio: {
          sampleRate: 16000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      }).then(stream => {
        this.isRecord = true;
        this.soundMeter.connectToSource(stream);
        this.stream = stream
      });
    } catch (e) {
      console.error(e)
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async createPcmForQuiz(callback?: Function, stream: boolean | MediaStream = false) {
    // Get the audio stream from the user's microphone
    try {
      this.soundMeter.init();
      this.soundMeter.pcmCallback = callback
      this.timer = this.soundCheckTimerForQuiz();
      if (stream instanceof MediaStream) {
        this.isRecord = true;
        this.soundMeter.connectToSource(stream);
        return;
      }
      return await navigator.mediaDevices.getUserMedia({
        audio: {
          sampleRate: 16000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      }).then(stream => {
        this.isRecord = true;
        this.soundMeter.connectToSource(stream);
        this.stream = stream
        return stream
      }).catch(() => {
        return null;
      });
    } catch (e) {
      console.error(e)
      return null;
    }
  }

  stop = async () => {
    if (!this.timer) return;
    if (this.timer) clearInterval(this.timer)
    this.timer = undefined;
    this.silenceCount = 0;
    this.timerCount = 0;
    await this.soundMeter.stop();
    if (this.soundMeter.isCallBack) await this.soundMeter.getPcmText()
  }

  setSoundMeterCanCelCallBack() {
    this.soundMeter.isCallBack = false;
  }

  soundCheckTimer = (): number => {
    return setInterval(async () => {
      //최대시간 계산
      this.timerCount += 100;
      const level = this.soundMeter.soundLevel;

      // 일정값 이하로 되는 시간 체크
      if (level < 20) this.silenceCount += 100;
      // 일정값 이상으로 올라가면 다시 초기화
      if (level > 20) this.silenceCount = 0;

      const stopTimeCheck = (this.silenceCount >= this.stopTime || this.timerCount >= this.maxTime);
      if (stopTimeCheck) await this.stop();

    }, 100)
  }

  soundCheckTimerForQuiz = (): number => {
    return setInterval(async () => {
      //최대시간 계산
      this.timerCount += 100;
      const level = this.soundMeter.soundLevel;

      // 일정값 이하로 되는 시간 체크
      if (level < 20) this.silenceCount += 100;
      // 일정값 이상으로 올라가면 다시 초기화
      if (level > 20) this.silenceCount = 0;

      const stopTimeCheck = (this.silenceCount >= this.stopForQuizTime || this.timerCount >= this.maxForQuizTime);
      if (stopTimeCheck) await this.stop();

    }, 100)
  }

}

