import axios from "axios";
import Api from "@/assets/lib/api/Api";
import {CreateUserRequest2, LoginRequest} from "@/assets/lib/type/ApiRequestTypes";
import {LoginResponse, ResponseData} from "@/assets/lib/type/ApiResponseTypes";
import {AxiosError} from "axios/index";
import Config from "@/config";

export default class UserApi extends Api {
  private static userAxios = axios.create({
    baseURL: Config.CONFIG.API_HOST + '/api/v1/user'
  })


  /**
   * OAuth login request
   * @param data
   * @param onSuc
   * @param onErr
   */
  static login<Q = LoginRequest, R = ResponseData<LoginResponse>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.userAxios, '/login', data, null, onSuc, onErr);
  }


  /**
   * join
   * @param data
   * @param onSuc
   * @param onErr
   */
  static join<Q = CreateUserRequest2, R = ResponseData<ResponseData<any>>>(data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.userAxios, '/add2', data, null, onSuc, onErr);
  }

}
