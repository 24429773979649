export default class ContentCacheManager {
  private cache: Map<string, any> = new Map();
  private loading: Map<string, Promise<any>> = new Map();

  public get(key: string): any {
    return this.cache.get(key);
  }

  public set(key: string, value: any): void {
    this.cache.set(key, value);
  }

  public has(key: string): boolean {
    return this.cache.has(key);
  }

  public clear(): void {
    this.cache.clear();
    this.loading.clear();
  }

  public cacheImage(url: string): Promise<HTMLImageElement> {
    if (this.has(url)) {
      return Promise.resolve(this.get(url));
    }

    if (this.loading.has(url)) {
      return this.loading.get(url)!;
    }

    const promise = new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = () => {
        this.set(url, image);
        this.loading.delete(url);
        resolve(image);
      };
      image.onerror = (error) => {
        this.loading.delete(url);
        reject(error);
      };
    });

    this.loading.set(url, promise);

    return promise;
  }

  public cacheAudio(url: string): Promise<HTMLAudioElement> {
    if (this.has(url)) {
      return Promise.resolve(this.get(url));
    }

    if (this.loading.has(url)) {
      return this.loading.get(url)!;
    }

    const promise = new Promise<HTMLAudioElement>((resolve, reject) => {
      const audio = new Audio();
      audio.src = url;
      audio.oncanplaythrough = () => {
        this.set(url, audio);
        this.loading.delete(url);
        resolve(audio);
      };
      audio.onerror = (error) => {
        this.loading.delete(url);
        reject(error);
      };
    });

    this.loading.set(url, promise);

    return promise;
  }

  public cacheVideoThumbnail(url: string, timeout = 5000): Promise<HTMLImageElement> {
    if (this.has(url)) {
      return Promise.resolve(this.get(url));
    }

    if (this.loading.has(url)) {
      return this.loading.get(url)!;
    }

    const promise = new Promise<HTMLImageElement>((resolve, reject) => {
      const video = document.createElement('video');
      video.src = url;
      video.preload = 'metadata';
      video.addEventListener('loadedmetadata', async () => {
        const duration = video.duration;
        let thumbnailPosition = 5;

        if (duration < 10) {
          thumbnailPosition = duration / 2;
        }

        video.currentTime = thumbnailPosition;

        try {
          await Promise.race([
            new Promise(resolve => video.addEventListener('seeked', resolve)),
            new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout waiting for seeked event')), timeout))
          ]);

          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const context = canvas.getContext('2d');
          context!.drawImage(video, 0, 0, canvas.width, canvas.height);

          const image = new Image();
          image.src = canvas.toDataURL();
          this.set(url, image);
          this.loading.delete(url);
          resolve(image);
        } catch (error) {
          this.loading.delete(url);
          reject(error);
        }
      });
      video.addEventListener('error', (error) => {
        this.loading.delete(url);
        reject(error);
      });
    });

    this.loading.set(url, promise);

    return promise;
  }

}
