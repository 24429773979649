var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"IncorrectNoteChart"},[_c('div',{staticClass:"note-chart"},[_c('div',{staticClass:"quiz"},[_c('div',{staticClass:"head"},[_vm._v("퀴즈")]),_c('ul',_vm._l((_vm.quizScoreDetail.quiz),function(quiz,index){return _c('li',{key:`quizScoreDetail.quiz-${index}`,staticClass:"quiz-list"},[_c('ul',{staticClass:"quiz-index"},[_c('li',{style:([!quiz.isCorrect ? {color: '#E28773'} : {}])},[_vm._v(_vm._s(index + 1)+"번")])]),_c('ul',{staticClass:"ox"},[(quiz.isCorrect)?_c('li',[_vm._m(0,true)]):_vm._e(),(!quiz.isCorrect)?_c('li',[_vm._m(1,true)]):_vm._e()])])}),0)]),_c('div',{staticClass:"practice"},[_c('div',{staticClass:"head"},[_vm._v("연습문제")]),_c('ul',_vm._l((_vm.quizScoreDetail.practice),function(quiz,index){return _c('li',{key:`quizScoreDetail.practice-${index}`,staticClass:"quiz-list"},[_c('ul',{staticClass:"quiz-index"},[_c('li',{style:([!quiz.isCorrect ? {color: '#E28773'} : {}])},[_vm._v(_vm._s(index + 1 + _vm.quizScoreDetail.quiz.length)+"번 ")])]),_c('ul',{staticClass:"ox"},[(quiz.isCorrect)?_c('li',[_vm._m(2,true)]):_vm._e(),(!quiz.isCorrect)?_c('li',[_vm._m(3,true)]):_vm._e()])])}),0)]),_c('div',{staticClass:"test"},[_c('div',{staticClass:"head"},[_vm._v("실전문제")]),_c('ul',_vm._l((_vm.quizScoreDetail.real),function(quiz,index){return _c('li',{key:`quizScoreDetail.real-${index}`,staticClass:"quiz-list"},[_c('ul',{staticClass:"quiz-index"},[_c('li',{style:([!quiz.isCorrect ? {color: '#E28773'} : {}])},[_vm._v(" "+_vm._s(index + 1 + _vm.quizScoreDetail.quiz.length + _vm.quizScoreDetail.practice.length)+"번 ")])]),_c('ul',{staticClass:"ox"},[(quiz.isCorrect)?_c('li',[_vm._m(4,true)]):_vm._e(),(!quiz.isCorrect)?_c('li',[_vm._m(5,true)]):_vm._e()])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/icon/icon-myquiz-O.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/icon/icon-myquiz-X.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/icon/icon-myquiz-O.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/icon/icon-myquiz-X.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/icon/icon-myquiz-O.png")}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('i',[_c('img',{attrs:{"alt":"","src":require("@/assets/images/icon/icon-myquiz-X.png")}})])
}]

export { render, staticRenderFns }