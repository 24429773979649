
import {Component, Prop, Vue} from "vue-property-decorator";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";

@Component({name: "IncorrectNoteChart", components: {}})
export default class IncorrectNoteChart extends Vue {
  @Prop() aiModel !: AiReportViewModel

  get quizScoreDetail() {
    return this.aiModel.quizScoreDetail;
  }
}
