
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({ name: "CommonMainHeader",  components: {}})
export default class CommonMainHeader extends Vue {
  @Prop()title!: string

  goBack() {
    this.$router.back();
  }
}
