import CryptoUtil from "@/assets/lib/crypto/CryptoUtil";
import {
  ClassContent,
  ContentItemBase,
  ContentItemImage,
  ContentItemPronunciation,
  ContentItemQuiz,
  ContentItemScenario,
  ContentItemScenarioType,
  ContentItemType,
  ContentItemTypes,
  ContentItemVideo,
  EvaluationForView,
  TimelineContent,
  UserClassContent,
  VideoContentSourceType
} from "@/Booker/lib/BookerTypes";
import App from "@/assets/lib/controller/App";
import Config from "@/config";
import {EvaluationCriteriaRequest} from "@/assets/lib/type/ApiRequestTypes";
import ContentApi from "@/assets/lib/api/ContentApi";

export default class BookerUtility {

  static sound = new Audio(`${require("@/assets/audio/open_quiz.mp3")}`);
  static cacheMap: Map<string, EvaluationForView> = new Map<string, EvaluationForView>();

  static playSound() {
    BookerUtility.sound.volume = 1;
    BookerUtility.sound.play()
    BookerUtility.sound.onended = () => {
      BookerUtility.sound.currentTime = 0;
    }
  }

  static createKey() {
    const base = CryptoUtil.getRandomString(10) + Date.now()
    return CryptoUtil.sha256Hex(base)
  }

  /**
   * 오디오 파일의 길이를 초단위로 리턴
   * @param file
   */
  static getAudioLengthInSeconds(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        // @ts-ignore
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContext.decodeAudioData(reader.result as ArrayBuffer, (buffer) => {
          const length = buffer.duration;
          resolve(length);
        }, reject);
      };
      reader.readAsArrayBuffer(file);
    });
  }

  /**
   * 영상의 길이 조회
   * @param file
   */
  static getVideoLengthInSeconds(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      const objectUrl = URL.createObjectURL(file);
      const video = document.createElement('video');
      video.src = objectUrl;
      video.onloadeddata = () => {
        const duration = video.duration;
        URL.revokeObjectURL(objectUrl);
        resolve(duration);
      };
      video.onerror = (error) => {
        URL.revokeObjectURL(objectUrl);
        reject(error);
      };
    });
  }

  /**
   * file 객체로 부터 이미지 사이즈 조회
   * @param file
   */
  static getImageDimensions(file: File): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const objectUrl = URL.createObjectURL(file);
      const img = document.createElement('img');
      img.src = objectUrl;
      img.onload = () => {
        const {width, height} = img;
        URL.revokeObjectURL(objectUrl);
        resolve({width, height});
      };
      img.onerror = (error) => {
        URL.revokeObjectURL(objectUrl);
        reject(error);
      };
    });
  }

  /**
   * url 로 부터 유튜브 영상 id, start, duration, thumbnail, title 추출
   * @param youtubeUrl
   * <iframe width="560" height="315"
   *  src="https://www.youtube.com/embed/6OugH2W1cG8"
   *  title="YouTube video player"
   *  frameborder="0"
   *  allow="accelerometer;
   *  autoplay;
   *  clipboard-write;
   *  encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
   */
  static getYoutubeDataByYoutubeAPI(url: string): string | null {
    const domainMatch = url.match(/^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(youtube\.com|youtu\.be)/);
    if (!domainMatch) return null;  // Domain is not YouTube

    // Check for standard, embed, and live YouTube URL formats
    const standardMatch = url.match(/watch\?v=([a-zA-Z0-9_-]+)/);
    const embedMatch = url.match(/youtube\.com\/embed\/([a-zA-Z0-9_-]+)/);
    const liveMatch = url.match(/youtube\.com\/live\/([a-zA-Z0-9_-]+)/);
    const shortMatch = url.match(/youtu\.be\/([a-zA-Z0-9_-]+)/);

    for (const match of [standardMatch, embedMatch, liveMatch, shortMatch]) {
      if (match && match[1]) {
        const id = match[1];
        const ampIndex = id.indexOf('&');
        return ampIndex !== -1 ? id.substring(0, ampIndex) : id;
      }
    }

    return null;
  }


  static getYoutubeThumbnailById(youtubeId: string) {
    return `https://i.ytimg.com/vi/${youtubeId}/hqdefault.jpg`
  }

  static getYoutubeThumbnailByUrl(youtubeUrl: string) {
    if (!youtubeUrl) return '';
    return this.getYoutubeThumbnailById(this.getYoutubeDataByYoutubeAPI(youtubeUrl) || '');
  }


  static findIndexInContentList(list: ContentItemBase[], itemKey: string) {
    let findItemIndex = 0;
    if (list.length < 1) return 0;
    list.find((content, index) => {
      if (content.key === itemKey) {
        findItemIndex = index;
        return true;
      }
    })
    return findItemIndex;
  }

  static findIndexInTimelineList(list: TimelineContent[], itemKey: string) {
    let findItemIndex = 0;
    if (list.length < 1) return 0;
    list.find((timeline, index) => {
      if (timeline.key === itemKey) {
        findItemIndex = index;
        return true;
      }
    })
    return findItemIndex;
  }

  static getContentLength(content: ContentItemTypes): number {

    const isLength = (type: ContentItemType) =>
      type === ContentItemType.Relaxation || ContentItemType.Video || type === ContentItemType.Audio || type === ContentItemType.TextToSpeech;
    const isNotLength = (type: ContentItemType) =>
      type === ContentItemType.Quiz || type === ContentItemType.Pronunciation || type === ContentItemType.Book || type === ContentItemType.Scenario;

    const getLength = (content: ContentItemBase): number => {
      if (isLength(content.type))

        // if(content.type === ContentItemType.Video){
        //   const isYoutube = (content as ContentItemVideo).sourceType === VideoContentSourceType.Youtube;
        //   if(isYoutube) {
        //     // BookerUtility.getYoutubeDataByYoutubeAPI()
        //   }
        // }

        // @ts-ignore
        return content.length
      else
        return 10 * 1000
    }

    console.log('getLength', getLength(content))

    const length = getLength(content);

    return length ? length : 10 * 1000

  }

  static createProfileColor(colorText: string, light = 0.64) {
    const hash = CryptoUtil.sha256Hex(colorText)
    const code = hash.substring(0, 6)

    let r = parseInt(code.substring(0, 2), 16)
    let g = parseInt(code.substring(2, 4), 16)
    let b = parseInt(code.substring(4, 6), 16)

    const hsl = this.rgbToHsl(r, g, b)

    hsl[2] = light // change light

    const rgb = this.hslToRgb(hsl[0], hsl[1], hsl[2])

    r = Math.max(0, Math.min(255, Math.round(rgb[0])));
    g = Math.max(0, Math.min(255, Math.round(rgb[1])));
    b = Math.max(0, Math.min(255, Math.round(rgb[2])));
    const color = '#' + r.toString(16) + g.toString(16) + b.toString(16)

    return color
  }

  /**
   * Converts an RGB color value to HSL. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes r, g, and b are contained in the set [0, 255] and
   * returns h, s, and l in the set [0, 1].
   *
   * @param   Number  r       The red color value
   * @param   Number  g       The green color value
   * @param   Number  b       The blue color value
   * @return  Array           The HSL representation
   */
  static rgbToHsl(r: number, g: number, b: number) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h = (max + min) / 2
    let s = (max + min) / 2
    const l = (max + min) / 2

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return [h, s, l];
  }

  /**
   * Converts an HSL color value to RGB. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes h, s, and l are contained in the set [0, 1] and
   * returns r, g, and b in the set [0, 255].
   *
   * @param  h       The hue
   * @param  s       The saturation
   * @param  l       The lightness
   * @return  Array           The RGB representation
   */
  static hslToRgb(h: number, s: number, l: number) {
    let r, g, b;

    if (s == 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [r * 255, g * 255, b * 255];
  }

  static getFile(fileId: string) {
    return Config.CONFIG.API_HOST + '/api/v1/file/download' + `?file_id=${fileId}&token=${App.controller.auth.token}`
  }

  static getThumbnail(fileId: string) {
    return Config.CONFIG.API_HOST + '/api/v1/file/download/thumbnail' + `?file_id=${fileId}`
  }

  static getDurationHHMMSS(time: number) {
    if (time === undefined) return '00:00'
    let sec = Math.abs(Math.floor(time / 1000))
    let min = Math.abs(Math.floor(sec / 60))
    const hour = Math.abs(Math.floor(min / 60))

    if (min > 60) {
      min = min % 60
    }


    if (sec >= 60) {
      sec = sec % 60
    }

    const timeWithZero = (time: number) => {
      if (time >= 10) return `${time}`
      return `0${time}`
    }

    if (timeWithZero(hour) === '00') {
      return `${timeWithZero(min)}:${timeWithZero(sec)}`
    }

    return `${timeWithZero(hour)}:${timeWithZero(min)}:${timeWithZero(sec)}`
  }

  static generateNumbers(number: string): string[] {
    const n = parseInt(number);
    const numbers: string[] = [];
    for (let i = 1; i <= n; i++) {
      const paddedNumber = '/' + i.toString().padStart(4, '0') + '.jpg';
      numbers.push(paddedNumber);
    }
    return numbers;
  }

  /**
   * ContentItem 의 thumbnail 로 사용할 이미지/영상 url
   * @param content
   */
  static getContentItemThumbnailUrl(content: ContentItemBase): null | string {
    switch (content.type) {
      case ContentItemType.Image:
        return BookerUtility.getFile((content as ContentItemImage).source)
      case ContentItemType.Video:
        if ((content as ContentItemVideo).sourceType === VideoContentSourceType.Youtube) {
          return BookerUtility.getYoutubeThumbnailByUrl((content as ContentItemVideo).source);
        }
        return BookerUtility.getFile((content as ContentItemImage).source)
      default:
        return null
    }
    return null;
  }

  static blobToJpg(blob: Blob): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(img, 0, 0);
            canvas.toBlob((jpegBlob) => {
              if (jpegBlob) resolve(jpegBlob);
            }, 'image/jpeg', 0.9);
          }
        };
        img.onerror = (error) => {
          reject(error);
        };
        if (typeof reader.result === "string") {
          img.src = reader.result;
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  }

  /**
   * ContentItemType 값으로 어떤 TimelineType 에 속하는지 조회
   */
  static getTimelineTypeFromContentItemType(contentItemType: ContentItemType): number {
    switch (contentItemType) {
      case ContentItemType.Image:
      case ContentItemType.Video:
      case ContentItemType.Relaxation:
        return 0
      case ContentItemType.Audio:
        return 1
      case ContentItemType.Scenario:
        return 2
      case ContentItemType.Quiz:
      case ContentItemType.Pronunciation:
      case ContentItemType.Book:
        return 3
      case ContentItemType.Text:
        return 4
      case ContentItemType.TextToSpeech:
        return 5
      case ContentItemType.Figure:
        return 6
    }
  }

  // 퀴즈 전체 받아오기
  static getQuizListInContent(userContent: UserClassContent | ClassContent) {
    const quizList: ContentItemQuiz[] = []
    const quizIdx = BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Quiz);
    const scenarioIdx = BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Scenario);

    const activityQuizList = userContent.timeline[quizIdx].contents.filter((c) => c.type === ContentItemType.Quiz)
    activityQuizList.forEach((activityQuiz) => {
      if (activityQuiz.type !== ContentItemType.Quiz) return
      const quiz = activityQuiz.content as ContentItemQuiz
      quizList.push(quiz)
    })

    const scenarioList = userContent.timeline[scenarioIdx].contents.filter((c) => {
      if (c.type === ContentItemType.Scenario) {
        const scenario = (c.content as ContentItemScenario);
        if (scenario.scenarioType !== ContentItemScenarioType.OpenContentItem) return false;
        if (!scenario.actionContent) return false;
        if (scenario.actionContent[0].type !== ContentItemType.Quiz) return false;
        return true;
      }
    })

    scenarioList.forEach((scenario) => {
      const scenarioItem = scenario.content as ContentItemScenario
      if (scenarioItem.actionContent) {
        const quiz = scenarioItem.actionContent[0] as ContentItemQuiz
        quizList.push(quiz)
      }
    })

    return quizList;

  }

  // 말하기 실습 전체 받아오기
  static getSpeakListInContent(userContent: UserClassContent | ClassContent) {
    const speakList: ContentItemPronunciation[] = []
    const speakIdx = BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Pronunciation);
    const scenarioIdx = BookerUtility.getTimelineTypeFromContentItemType(ContentItemType.Scenario);

    const activitySpeakList = userContent.timeline[speakIdx].contents.filter((c) => c.type === ContentItemType.Pronunciation)
    activitySpeakList.forEach((activitySpeak) => {
      if (activitySpeak.type !== ContentItemType.Pronunciation) return
      const speak = activitySpeak.content as ContentItemPronunciation
      speakList.push(speak)
    })

    const scenarioList = userContent.timeline[scenarioIdx].contents.filter((c) => {
      if (c.type === ContentItemType.Scenario) {
        const scenario = (c.content as ContentItemScenario);
        if (scenario.scenarioType !== ContentItemScenarioType.OpenContentItem) return false;
        if (!scenario.actionContent) return false;
        if (scenario.actionContent[0].type !== ContentItemType.Pronunciation) return false;
        return true;
      }
    })

    scenarioList.forEach((scenario) => {
      const scenarioItem = scenario.content as ContentItemScenario
      if (scenarioItem.actionContent) {
        if (scenarioItem.actionContent[0].type !== ContentItemType.Pronunciation) return false;
        const speak = scenarioItem.actionContent[0] as ContentItemPronunciation
        speakList.push(speak)
      }
    })

    return speakList;

  }

  static getScoreInQuizList(quizList: ContentItemQuiz[]) {
    const score = [0, 0, 0, 0]

    quizList.forEach((quizItem) => {
      quizItem.quizItems.forEach((quiz) => {
        if (quiz.userScore) {
          quiz.userScore.forEach((userScore, scoreIdx) => {
            score[scoreIdx] += userScore.value;
          })
        }
      })
    })

    return score
  }

  static async getClassEvaluation(evaluation: string, evaluationKey: string, evaluationLevel: string) {

    const reqData: EvaluationCriteriaRequest = {
      evaluation: evaluation,
      evaluationKey: evaluationKey,
      evaluationLevel: evaluationLevel,
    }

    if (BookerUtility.cacheMap.has(evaluation + evaluationKey + evaluationLevel)) {
      return BookerUtility.cacheMap.get(evaluation + evaluationKey + evaluationLevel);
    }

    const res = await ContentApi.evaluationCriteria(reqData)
    if (res && res.data) {

      let key;

      switch (res.data.evaluationCriteria.subjectCriteriaKey) {
        case 'Understanding' :
          key = '이해력'
          break
        case 'Expressiveness' :
          key = '표현력'
          break
        case 'Adaptability' :
          key = '응용력'
          break
        case 'ProblemSolving' :
          key = '문제해결력'
          break
      }


      BookerUtility.cacheMap.set(evaluation + evaluationKey + evaluationLevel, {
        key: key,
        level: res.data.evaluationCriteria.subjectCriteriaLevel,
        criteriaItem: res.data.evaluationCriteria.subjectCriteriaItem,
        evaluationCriteriaTitle: res.data.evaluationCriteria.evaluationCriteriaTitle,
        content: res.data.evaluationCriteria.subjectCriteriaContent
      })


      return {
        key: key,
        level: res.data.evaluationCriteria.subjectCriteriaLevel,
        criteriaItem: res.data.evaluationCriteria.subjectCriteriaItem,
        evaluationCriteriaTitle: res.data.evaluationCriteria.evaluationCriteriaTitle,
        content: res.data.evaluationCriteria.subjectCriteriaContent
      }
    }
  }

  static changeLevelToKorean(level: string) {
    if (level === 'low') return '부족'
    if (level === 'middle') return '보통'
    if (level === 'high') return '잘함'
  }

  static changeEvaluationKeyToKorean(evaluationKey: string) {
    if (evaluationKey === "Understanding") return '이해력'
    if (evaluationKey === "Expressiveness") return '표현력'
    if (evaluationKey === "Adaptability") return '응용력'
    if (evaluationKey === "ProblemSolving") return '문제해결력'
  }

  static classSubTitle(classStep: number) {
    let subTitle = '';
    switch (classStep) {
      case 1:
        subTitle = '대화 예절의 중요성 알기 (1/2)';
        break;
      case 2:
        subTitle = '대화 예절의 중요성 알기 (2/2)';
        break;
      case 3:
        subTitle = '대화 예절을 지키며 대화하는 방법 알기 (1/2)';
        break;
      case 4:
        subTitle = '대화 예절을 지키며 대화하는 방법 알기 (2/2)';
        break;
      case 5:
        subTitle = '회의에서 질켜야할 대회 예절 알기 (1/2)';
        break;
      case 6:
        subTitle = '회의에서 질켜야할 대회 예절 알기 (2/2)';
        break;
      case 7:
        subTitle = '온라인에서 질켜야할 대회 예절 알기 (1/2)';
        break;
      case 8:
        subTitle = '온라인에서 질켜야할 대회 예절 알기 (2/2)';
        break;
      case 9:
        subTitle = '대화 예절을 표어로 만들기';
        break;
      case 10:
        subTitle = '지켜야 할 대화 예절 알기';
        break;
    }
    return subTitle
  }

  static formatDate(timestamp: number): string {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  }

  static escapeHtml(str: string) {
    str = str.replace(/&/g, '&amp;');
    str = str.replace(/</g, '&lt;');
    str = str.replace(/>/g, '&gt;');
    str = str.replace(/ /g, '&nbsp;');
    return str.replace(/\n/g, '<br/>');
  }

}
