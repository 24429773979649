
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import {QuizItem} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";
import CommonPassageExplain from "@/Booker/views/components/common/CommonPassageExplain.vue";
import CommonPassageExplainBtnWrapper from "@/Booker/views/components/common/CommonPassageExplainBtnWrapper.vue";


@Component({
  name: "QuizStartInput",
  components: {
    CommonPassageExplainBtnWrapper,
    CommonPassageExplain, CommonTextArea, CommonInput
  }
})
export default class QuizStartInput extends Vue {
  @Prop() quiz!: QuizItem
  @Prop() index!: number
  @Prop() quizOnSubmit!: boolean
  @Prop() isSubmit !: any
  @Prop() imgItem!: string
  @Prop() subQuestionItem!: string
  @Prop() commonTextAreaReadOnly !: boolean

  BookerUtility = BookerUtility


  @Emit('zoomImg')
  zoomImg() {
    return this.imgItem
  }

  @Emit('checkSave')
  onInputAnswer(e: Event) {
    if (!this.quiz.options[0].userAnswer) this.quiz.options[0].userAnswer = '';
    this.quiz.options[0].userAnswer = (e.target as HTMLInputElement).value;
    //this.quiz.textAnswer = this.quiz.answer
  }

  subQuestionInput(e: Event) {
    this.quiz.subQuestion = (e.target as HTMLTextAreaElement).value;
  }

  explainInput(e: Event) {
    this.quiz.explain = (e.target as HTMLTextAreaElement).value;
  }

}
