
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import VideoText from "@/Booker/views/components/video/player/text/VideoText.vue";
import VideoShape from "@/Booker/views/components/video/player/shape/VideoShape.vue";
import ShapesMenu from "@/Booker/views/components/video/player/shape/ShapesMenu.vue";
import CreateSubtitle from "@/Booker/views/components/subtitle/CreateSubtitle.vue";
import {ContentItemFigure, ContentItemType, ShapeItemType, TimelineType} from "@/Booker/lib/BookerTypes";
import VideoTimelineContentPlayer from "@/Booker/views/components/video/player/VideoTimelineContentPlayer.vue";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import ElementSizeObserver from "@/assets/lib/dom/ElementSizeObserver";

@Component({
  name: 'BookerVideoPlayer',
  components: {VideoTimelineContentPlayer, VideoText, CreateSubtitle, ShapesMenu, VideoShape}
})
export default class BookerVideoPlayer extends Vue {
  @Prop() viewModel!: ClassContentPlayerViewModel
  @Prop() model!: ClassContentMediaListViewModel
  @Prop() isFull!: boolean
  @Ref() refVideoWrap!: HTMLDivElement
  @Ref() refPlayer!: HTMLDivElement

  videoWrapX = 0;
  videoWrapW = 0;
  videoWrapY = 0;
  videoWrapH = 0;


  TimelineType = TimelineType
  ContentItemType = ContentItemType
  BookerUtility = BookerUtility

  shapeType: ShapeItemType | null = null

  videoWrapperResizeObserver = new ElementSizeObserver()

  videoScale = 1


  // VideoShape 추가되게 빈배열 생성
  videoShapes: ContentItemFigure[] = [];

  // 도형 추가되게 하기
  addNewShape(type: ShapeItemType) {
    const shape = this.model.addFigureLibrary(type);
    const start = this.viewModel.seekbar.currentTime
    if (shape) this.viewModel.timeline.addNewShape(shape, start)
  }

  removeShape(key: string) {
    const contentItemFigure = this.viewModel.timeline.getTimelineContentByKey(key)
    if (contentItemFigure) {
      this.viewModel.timeline.removeItem(contentItemFigure);
    }
  }

  mounted() {
    // video-wrapper 사이즈 변경 모니터링
    this.videoWrapperResizeObserver.install(this.refPlayer, () => {
      this.updateVideoWrapSize()
    })


    const {x, y, width, height} = this.refVideoWrap.getBoundingClientRect();

    this.videoWrapX = x;
    this.videoWrapY = y;
    this.videoWrapW = width;
    this.videoWrapH = height;

    if (this.isFull) {
      this.cssMount()
      new ResizeObserver(this.cssMount).observe(this.$el)
    } else {
      return;
    }
    this.playPlayer()
  }

  beforeDestroy() {
    this.videoWrapperResizeObserver.uninstall()
  }

  onPlay() {
    this.viewModel.seekbar.play()
  }

  onPause() {
    this.viewModel.seekbar.pause()
  }

  onBuffering() {
    this.viewModel.seekbar.buffering()
  }

  deleteShape() {
    this.shapeType = null;
  }

  cssMount() {
    const defaultWidth = 1280;
    if (this.videoWrapW > defaultWidth) {
      const scaleRatio = this.$el.getBoundingClientRect().width / defaultWidth
      console.log('scaleRatio', scaleRatio)
      this.refVideoWrap.style.scale = `${scaleRatio}`
      return
    }
    const scaleRatio = this.$el.getBoundingClientRect().width / this.videoWrapW
    console.log('scaleRatio', scaleRatio)
    this.refVideoWrap.style.scale = `${scaleRatio}`
  }

  playPlayer() {
    this.viewModel.seekbar.play();
  }

  private updateVideoWrapSize() {
    const rootWidth = this.refPlayer.offsetWidth
    const rootHeight = this.refPlayer.offsetHeight

    const maxWrapWidth = rootWidth - 82 - 20
    const maxWrapHeight = rootHeight - 40

    let wrapWidth = maxWrapWidth
    let wrapHeight = maxWrapWidth * (9 / 16)

    if (wrapHeight > maxWrapHeight) {
      wrapHeight = maxWrapHeight
      wrapWidth = wrapHeight * (16 / 9)
    }

    // this.refVideoWrap.style.width = `${wrapWidth}px`
    // this.refVideoWrap.style.height = `${wrapHeight}px`
    this.videoScale = wrapWidth / 1280
    this.refVideoWrap.style.transform = `translate(${(maxWrapWidth - wrapWidth) / 2}px, ${(maxWrapHeight - wrapHeight) / 2}px) scale(${this.videoScale})`
    this.refVideoWrap.style.transformOrigin = 'left top'
  }


}
