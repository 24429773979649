
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";
import QuizScore from "@/components/ui/quiz/QuizScore.vue";
import {QuizItem, QuizItemType} from "@/Booker/lib/BookerTypes";

@Component({name: "QuestionHeader", components: {QuizScore}})
export default class QuestionHeader extends Vue {
  @Prop() isDifficulty!: boolean
  @Prop() difficulty!: number
  @Prop() difficultyIdx!: number
  @Prop() difficultyLastIdx!: number
  @Prop() quizModel!: ContentItemQuizEditorViewModel
  question: QuizItem | null = null;
  quizIdx = -1;

  difficultyList = ['공통', '상', '중', '하']

  get subQuestionStatus() {
    const quizIdx = this.quizModel?.findDifficultyIndexInAllList(this.difficultyIdx, this.difficulty)
    if (quizIdx !== undefined && quizIdx > -1) {
      const subQ = this.quizModel?.getQuiz().quizItems[quizIdx].subQuestion;
      if (typeof subQ === "string") {
        return true;
      }
    }
    return false
  }

  get numberText() {
    let numberText = '';

    if ((this.difficultyIdx + 1) < 10) {
      numberText = `0${this.difficultyIdx + 1}`
    } else {
      numberText = `${this.difficultyIdx + 1}`
    }

    return `${numberText}`
  }

  get typeText() {
    if (!this.quizModel.getQuiz().quizItems[this.quizIdx]) return '';
    switch (this.quizModel.getQuiz().quizItems[this.quizIdx].type) {
      case QuizItemType.Select: // 다지선다형
        return '다지선다형'
      case QuizItemType.Input: // 단답형
        return '단답형'
      case QuizItemType.Descriptive: // 설문형
        return '설문형'
      case QuizItemType.OX: // X: 퀴즈 형
        return 'OX'
      case QuizItemType.Dictation: // 구술형
        return '구술형'
      case QuizItemType.Blank: // 빈칸형
        return '빈칸형'
    }
    return '';
  }

  get difficultyText() {
    let difficultyText = ''

    if (this.isDifficulty) {
      switch (this.difficulty) {
        case 2:
          difficultyText = '상'
          break;
        case 1:
          difficultyText = '중'
          break;
        case 0:
          difficultyText = '하'
          break;
      }
    } else {
      difficultyText = '공통'
    }
    return difficultyText;
  }

  get numberScore() {
    if (this.question) return this.quizTotalScore(this.quizModel.getQuiz().quizItems[this.quizIdx])
    return 0
  }

  mounted() {
    this.quizIdx = this.quizModel.findDifficultyIndexInAllList(this.difficultyIdx, this.difficulty);
    this.question = this.quizModel.getQuiz().quizItems[this.quizIdx]
  }

  quizTotalScore(quiz: QuizItem) {
    return quiz.score.reduce((acc, cur) => acc + cur.value, 0)
  }

  changeDifficultyTextToNumber(difficultyText: string) {
    switch (difficultyText) {
      case this.difficultyList[1] :
        return 2
      case this.difficultyList[2] :
        return 1
      case this.difficultyList[3] :
        return 0
      default :
        return 1
    }
  }

  // 이미지 파일 업로드
  @Emit('onFileSelect')
  onFileSelect() {
    return {
      difficultyIdx: this.difficultyIdx,
      difficulty: this.difficulty
    }
  }

  /**
   * 난이도 선택 이벤트 및 난이도 텍스트 변경
   * @param newDifficulty
   */
  selectDifficulty(newDifficulty: number) {
    this.quizModel?.setDifficulty(this.difficultyIdx, this.difficulty, newDifficulty)
  }


  questionUp() {
    if (this.isDifficulty) this.quizModel!.moveUpQuestion(this.difficultyIdx, this.difficulty)
    else this.quizModel!.moveUpQuestion(this.difficultyIdx)
  }

  questionDown() {
    if (this.isDifficulty) this.quizModel!.moveDownQuestion(this.difficultyIdx, this.difficulty)
    else this.quizModel!.moveDownQuestion(this.difficultyIdx)
  }

  questionDel() {
    if (this.isDifficulty) this.quizModel!.deleteQuestion(this.difficultyIdx, this.difficulty)
    else this.quizModel!.deleteQuestion(this.difficultyIdx)
  }

  // 지문 추가하기
  changeSubQuestion() {
    const quizIdx = this.quizModel?.findDifficultyIndexInAllList(this.difficultyIdx, this.difficulty)
    if (quizIdx !== undefined && quizIdx > -1) {
      const subQ = this.quizModel?.getQuiz().quizItems[quizIdx].subQuestion;
      if (typeof subQ === "string") {
        this.quizModel?.delSubQuestion(this.difficultyIdx, this.difficulty)
      } else {
        this.quizModel?.setSubQuestion(this.difficultyIdx, this.difficulty, '')
      }
    }
  }
}
