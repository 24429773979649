
import {Component, Vue} from 'vue-property-decorator';
import MainHeader from '@/components/layouts/MainHeader.vue';
import CardView from '@/components/CardView.vue';
import FloatingWrapper from "@/components/FloatingWrapper.vue";
import LocalStorageUtil from "@/assets/lib/utility/LocalStorageUtil";
import ClassContentListViewModel from "@/Booker/lib/viewModel/ClassContentListViewModel";
import App from "@/assets/lib/controller/App";
import Chatbot from "@/assets/lib/utility/Chatbot"
import VoiceRecognition from "@/components/VoiceRecognition.vue";
import {ChatBotPostMsg, ChatBotPostMsgType} from "@/assets/lib/type/Types";
import CheckContinueClass from "@/views/ClassPage/component/CheckContinueClass.vue";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";

@Component({
  // eslint-disable-next-line no-undef
  components: {CheckContinueClass, FloatingWrapper, MainHeader, CardView, VoiceRecognition}
})
export default class MainPage extends Vue {

  viewModel = new ClassContentListViewModel()

  btnWrap: object[] = []
  isInit = false;
  isLogin = false;

  get visitCheckKey() {
    const date = new Date();
    return `isFirst-visit-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  mounted() {
    this.init()
    setTimeout(() => this.sendFirstMeetingMsg(), 500)
    this.isLogin = App.controller.auth.isLogin;
    window.removeEventListener('message', this.handleChatEvent)
    window.addEventListener('message', this.handleChatEvent)
  }

  sendChatBot(chatBotPostMsg: ChatBotPostMsg) {
    const chatBot = document.querySelector('iframe');
    if (chatBot && chatBot.contentWindow) chatBot.contentWindow.postMessage(chatBotPostMsg, '*')
  }

  handleChatEvent(message: MessageEvent) {
    switch (message.data.name) {
      case 'webchatReady':
        this.isInit = true;
        setTimeout(() => this.sendFirstMeetingMsg(), 500)
        break;
    }
  }

  login() {
    this.$router.push('/login')
  }

  logout() {
    App.controller.auth.logout()
    this.isLogin = false;
  }

  sendFirstMeetingMsg() {

    // if (!this.checkIsFirstTime) {
    //   const msgFirst = Chatbot.defaultMsgTemplate();
    //   msgFirst.text = "안녕하세요? 저는 여러분의 학습 도우미 AI(아이)슈타인입니다. 도움이 필요할 경우 언제든지 저를 클릭해 주세요."
    //   msgFirst.type = "slab:custom"
    //
    //   const chatBotPostMsgFist: ChatBotPostMsg = {
    //     chatBotPostMsgType: ChatBotPostMsgType.UI,
    //     customMsg: msgFirst,
    //     data: ''
    //   }
    //
    //   this.sendChatBot(chatBotPostMsgFist)
    //   this.setFirstTime();
    // }

    if (!App.controller.auth.isLogin) {
      setTimeout(() => {
        const msgSecond = Chatbot.defaultMsgTemplate();
        msgSecond.type = "slab:custom";
        msgSecond.text = `방문해 주셔서 감사합니다. 로그인 후 학습 도우미 AI(아이)슈타인과 함께 다양한 학습 정보를 확인해 보세요.`;

        const chatBotPostMsgSecond: ChatBotPostMsg = {
          chatBotPostMsgType: ChatBotPostMsgType.UI,
          customMsg: msgSecond,
          data: ''
        }

        this.sendChatBot(chatBotPostMsgSecond)
      }, 100)
    } else {
      const model = ClassContentManager.controller.model;

      const userClassList = model.userClassList.filter(c => c.isRelease && this.viewModel.releaseClassList.findIndex(r => r.content.key === c.key) !== -1);
      const length = userClassList.filter(c => c.isRelease).length;
      const msg = Chatbot.defaultMsgTemplate();
      msg.type = "slab:custom";
      msg.text = ``;


      if (length === 0) {
        //첫 수업 안내 메시지
        msg.text = "아직 강의를 듣지 않았네요. 학습 진도가 밀리지 않도록 학습 계획을 세우고 잘 실행해야겠지요? 자, 학습할 준비 되었나요? 그럼 강의카드를 누르고 AI 선생님과 재미있는 수업 함께 시작해 봅시다."
      } else {
        const lastContent = userClassList[length - 1];
        if (lastContent.isComplete && length >= 10) {
          //모든 수업 완료 메시지
          msg.text = "모든 강의를 끝까지 다 수강했네요. 이제우리 들었던 수업 내용을 복습해 볼까요? 아래의 '내퀴즈', '말하기 실습', '오답 노트', 'AI 리포트' 에서 수업내용을 천천히 생각해보고 복습해 본다면 성장이 2배!"
        } else {
          //이어서 수업하기 메시지
          msg.text = "강의를 잘 들으며 수업에 참여하고 있네요. AI 선생님과 함께 하는 강의가 귀에 쏙쏙 잘 들어오지요? 직접 말하며 참여하니 재미도 쑥쑥, 학습력도 쑥쑥! 지금처럼 꾸준히 강의에 참여한다면 앞으로의 성장이 더 기대가 됩니다."
        }

      }

      setTimeout(() => {
        const chatBotPostMsgSecond: ChatBotPostMsg = {
          chatBotPostMsgType: ChatBotPostMsgType.UI,
          customMsg: msg,
          data: ''
        }

        this.sendChatBot(chatBotPostMsgSecond)
      }, 100)

      // 50점 이하 수업 메시지
      if (model.userClassList.findIndex(c => c.isComplete && c.score < 50) > -1) {
        const msgSecond = Chatbot.defaultMsgTemplate();
        msgSecond.type = "slab:custom";
        msgSecond.text = `강의를 잘 듣고 있는데 평가기준에 미달된 강의가 있네요. 해당하는 강의를 다시 한번 들어보고 문제에 대해 천천히 생각하고 말해 본다면 좋은 결과가 있을거에요. 힘내서 다시 한번 도전해 봅시다.`;

        setTimeout(() => {
          const chatBotPostMsgSecond: ChatBotPostMsg = {
            chatBotPostMsgType: ChatBotPostMsgType.UI,
            customMsg: msgSecond,
            data: ''
          }

          this.sendChatBot(chatBotPostMsgSecond)
        }, 100);
      }
    }


  }

  setFirstTime() {
    LocalStorageUtil.setBoolean(this.visitCheckKey, true)
  }

  checkIsFirstTime() {
    return LocalStorageUtil.getBoolean(this.visitCheckKey, false)
  }

  init() {
    this.btnWrap = [
      {
        title: '내 퀴즈', desc: '제출한 퀴즈 결과를 확인하세요.', src: require('@/assets/images/main/icPortalQuiz.png'),
        page: '/my-quiz'
      },
      {
        title: '말하기 실습', desc: '말하기를 반복 훈련해 보세요.', src: require('@/assets/images/main/icPortalMic.png'),
        page: '/my-speaking'
      },
      {
        title: '오답노트', desc: '틀린 문제를 다시 풀어보세요.', src: require('@/assets/images/main/icPortalNote.png'),
        page: '/my-incorrect-note'
      },
      {
        title: 'AI 학습 리포트',
        desc: '학습 결과를 한눈에 확인하세요.',
        src: require('@/assets/images/main/icPortalReport.png'),
        page: '/report'
      },
    ]
  }

  goReport(page: string) {
    if (App.controller.auth.isLogin) this.$router.push(page);
    else this.$router.push('/login');
  }


}
