
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import TtsAudioType from "@/Booker/views/components/tts/TtsAudioType.vue"
import CommonInput from "@/Booker/views/components/common/CommonInput.vue"
import CommonButton from "@/Booker/views/components/common/CommonButton.vue"
import ContentItemTextToSpeechViewModel from "@/Booker/lib/viewModel/classContent/ContentItemTextToSpeechViewModel";
import {ContentToolStateType, TextToSpeechVoiceType} from "@/Booker/lib/BookerTypes";

@Component({name: "", components: {TtsAudioType, CommonInput, CommonButton}})
export default class TextToSpeech extends Vue {
  @Prop() model!: ClassContentMediaListViewModel
  textToSpeechViewModel: ContentItemTextToSpeechViewModel | null = null;
  ttsTitle = ''
  /**
   * TTS 저장
   */
  isSaveAble = false

  @Emit('setToolState')
  setToolState(state: ContentToolStateType) {
    return state;
  }

  init() {
    this.textToSpeechViewModel = new ContentItemTextToSpeechViewModel(this.model.createTextToSpeechTemplate(), this.model)
  }

  created() {
    this.init();
  }

  mounted() {
    if (!this.textToSpeechViewModel) this.init()
  }

  // 제목
  onInputTitle(e: Event) {
    //this.textToSpeechViewModel!.setTitle((e.target as HTMLInputElement).value)
    this.textToSpeechViewModel!.textToSpeech.title = (e.target as HTMLTextAreaElement).value;
    this.checkSave();
  }

  // 메시지
  onInputMessage(e: Event) {
    this.textToSpeechViewModel!.setMessage((e.target as HTMLTextAreaElement).value)
    this.checkSave();
  }

  // 목소리
  onInputVoiceType(voiceType: TextToSpeechVoiceType) {
    this.textToSpeechViewModel?.setVoiceType(voiceType);
    console.log('voiceType', voiceType);
    this.checkSave();
  }

  checkSave() {
    this.isSaveAble = this.isSaveTextToSpeech();
  }

  isSaveTextToSpeech() {
    if (!this.textToSpeechViewModel?.textToSpeech.title) return false;
    if (this.textToSpeechViewModel?.textToSpeech.title.length < 2 || this.textToSpeechViewModel?.textToSpeech.message.length < 2) return false;
    return true;
  }


  async saveTextToSpeechState() {

    const tts = await this.textToSpeechViewModel?.createTextToSpeechContentItem();

    if (tts && this.model.addTextToSpeechLibrary(tts)) {
      this.model.save();
      this.textToSpeechViewModel?.saveTextToSpeech();
      this.setToolState(ContentToolStateType.Default);
      return;
    }
    alert('저장에 실패하였습니다. 상태를 확인해주세요.')
  }

  // async uploadTts() {
  //   console.log('uploadTts')
  //   await this.model.addTextToSpeechLibrary(this.textToSpeechViewModel!.textToSpeech);
  //   this.textToSpeechViewModel?.saveTextToSpeech()
  // }


}
