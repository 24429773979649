
import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import ClassContentHeaderView from '@/views/ClassPage/ClassContentView/ClassContentHeaderView.vue'
import ClassContentVideoView from '@/views/ClassPage/ClassContentView/ClassContentVideoView.vue'
import CommonModal from "@/Booker/views/components/common/CommonModal.vue";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import LoadingModal from "@/components/LoadingModal.vue";
import {ClassStatus} from "@/Booker/lib/BookerTypes";
import ClassStartButton from "@/views/ClassPage/ClassStartButton.vue";
import ClassContentVideoTool from "@/views/ClassPage/ClassContentView/ClassContentVideoTool.vue";
import Chatbot from "@/assets/lib/utility/Chatbot";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";


@Component({
  name: 'ClassContentView',
  components: {
    ClassStartButton,
    LoadingModal,
    CommonModal,
    ClassContentVideoView,
    ClassContentHeaderView,
    ClassContentVideoTool
  }
})
export default class ClassContentView extends Vue {

  @Prop() isCloseBtn!: boolean;
  @Prop() moveButton!: boolean;
  @Prop() model!: ClassContentPlayerViewModel;
  modalStatus = false;
  classStatus: ClassStatus = 'BeFore'
  playStatus = true

  /**
   * 풀스크린
   */
  @Emit('screenFull')
  screenFull() {
    return;
  }

  updateStatus(classStatus: ClassStatus) {
    if (this.classStatus === 'BeFore') return;
    this.classStatus = classStatus
    console.log('classStatus', classStatus)
    switch (classStatus) {
      case "Play":
        this.model.seekbar.play();
        break;
      case "Pause":
      case "End":
      case "Quiz":
        this.model.seekbar.pause();
        break;
    }
  }

  playClass() {
    this.classStatus = 'Play'
    this.playStatus = !this.playStatus
    Chatbot.sendChatBotInit()
  }

  videoPlay() {
    this.playStatus = !this.playStatus
  }

  receiveResult(result: boolean) {
    this.modalStatus = false;
    if (!result) return;
    this.$router.push('/main');
    //TODO:수업종료
    if (ClassContentManager.controller.model.activeUserContent)
      ClassContentManager.controller.model.userClassUpdate(null)
  }

  receiveClassStartResult(result: boolean) {
    this.modalStatus = false;
    if (!result) return;
    this.$router.push('/main');
    //TODO:수업종료
  }

  openModal() {
    this.modalStatus = true;
  }

}
