import { render, staticRenderFns } from "./QuizDictationOption.vue?vue&type=template&id=000db6e8&scoped=true&"
import script from "./QuizDictationOption.vue?vue&type=script&lang=ts&"
export * from "./QuizDictationOption.vue?vue&type=script&lang=ts&"
import style0 from "./QuizDictationOption.vue?vue&type=style&index=0&id=000db6e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "000db6e8",
  null
  
)

export default component.exports