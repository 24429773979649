import {ClassContent, ContentItemTypes, TimelineContent, TimelineType} from "@/Booker/lib/BookerTypes";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import ContentSeekbarController from "@/Booker/lib/controller/ContentSeekbarController";
import ContentTimelineController from "@/Booker/lib/controller/ContentTimelineController";
import ClassContentAudioPlayer from "@/Booker/lib/controller/ClassContentAudioPlayer";
import ScenarioPlayController from "@/Booker/lib/controller/ScenarioPlayController";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

/**
 * Booker 의 컨텐츠 편집 또는 플레이용
 */
export default class ClassContentPlayerViewModel {
  seekbar: ContentSeekbarController
  timeline: ContentTimelineController
  scenarioPlayController: ScenarioPlayController
  defaultTimelineSize = 9 as const;
  timelineSize: 1 | 3 | 5 | 9 | 18 | 36 = this.defaultTimelineSize;
  volume = 50
  nowIdx = 0;
  time = 0;
  private content: ClassContent
  private audioPlayer = new ClassContentAudioPlayer()
  private ttsPlayer = new ClassContentAudioPlayer()
  private scenarioMap: Map<string, boolean> = new Map();


  constructor(contentKey: string) {
    const content = ClassContentManager.controller.model.getAllContent(contentKey)

    if (!content) {
      //@ts-ignore;
      // window.location = window.origin;
      alert('잘못된 접근입니다.');
      throw new Error('contentKey does not exists : ' + contentKey)
    }
    this.content = content

    this.seekbar = new ContentSeekbarController(this.content)
    this.timeline = new ContentTimelineController(this.content)
    this.scenarioPlayController = new ScenarioPlayController(this.content)
    this.scenarioPlayController.setUpdateStatus(() => {this.seekbar.pause()})

    this.seekbar.playingStateChangeEventListener.addListener(this, state => {
      // audio player 상태 업데이트
      this.audioPlayer.updatePlayingState(state, this.playingAudioContents)
      this.ttsPlayer.updatePlayingState(state, this.playingTTSContents)
      this.scenarioPlayController.updatePlayingState(state)
      if (Math.abs(this.seekbar.currentTime - this.time) > 1000) {
        this.time = this.seekbar.currentTime;
        const nowIdx = this.findTimelineIndexByStartTime(this.seekbar.currentTime)
        if (nowIdx > -1 && this.nowIdx !== nowIdx) this.nowIdx = nowIdx;
      }
      this.audioPlayer.audio.volume = this.volume / 100;
    })

    if (window.location.href.includes('localhost')) {
      //@ts-ignore
      window.seekbar = this.seekbar;
    }
  }

  get getVolume() {
    return this.volume;
  }

  /**
   * 오디오 출력용, 현재 플레이 되어야 하는 오디오 소스 목록 조회
   */
  get playingAudioContents(): TimelineContent[] {
    return this.timeline.getAudioContentsAtTime(this.seekbar.currentTime)
  }

  /**
   * 오디오 출력용, 현재 플레이 되어야 하는 오디오 소스 목록 조회
   */
  get playingTTSContents(): TimelineContent[] {
    return this.timeline.getTTSContentsAtTime(this.seekbar.currentTime)
  }

  get isAvatar() {
    if (this.content.isAvatar === undefined) return true;
    if (this.content.isAvatar === null) return true;
    return this.content.isAvatar
  }

  init() {
    this.seekbar = new ContentSeekbarController(this.content)
    this.timeline = new ContentTimelineController(this.content)
    this.scenarioPlayController = new ScenarioPlayController(this.content)
    this.scenarioPlayController.setUpdateStatus(() => {this.seekbar.pause()})

    this.seekbar.playingStateChangeEventListener.addListener(this, state => {
      // audio player 상태 업데이트
      this.audioPlayer.updatePlayingState(state, this.playingAudioContents)
      this.ttsPlayer.updatePlayingState(state, this.playingTTSContents)
      this.scenarioPlayController.updatePlayingState(state)
      if (Math.abs(this.seekbar.currentTime - this.time) > 1000) {
        this.time = this.seekbar.currentTime;
        const nowIdx = this.findTimelineIndexByStartTime(this.seekbar.currentTime)
        if (nowIdx > -1 && this.nowIdx !== nowIdx) this.nowIdx = nowIdx;
      }

    })

  }

  findTimelineIndexByStartTime(time: number) {
    const list = [...this.timeline.getTimelineContents(TimelineType.Video)]
    return list.sort((a, b) => a.start - b.start).findIndex(cont => cont.start <= time && cont.end! >= time)
  }

  setBooker() {
    this.scenarioPlayController.setUpdateStatus(() => {
      //
    })
  }

  clearTimeLine() {
    ClassContentManager.controller.model.clearTimeLine(this.content)
    const content = ClassContentManager.controller.model.getAllContent(this.content.key)
    if (content) {
      this.content = content
      this.init();
    }
  }

  setVolume(volume: number) {
    this.volume = volume
    this.audioPlayer.audio.volume = volume / 100;
    this.ttsPlayer.audio.volume = volume / 100;
    BookerUtility.sound.volume = volume / 100;
  }

  checkAlreadySend(key: string) {
    return this.scenarioMap.has(key)
  }

  saveSend(key: string) {
    this.scenarioMap.set(key, true);
  }

  deleteSend(key: string) {
    this.scenarioMap.delete(key)
  }

  /**
   * find content from content library by contentKey
   */
  findContent(contentKey: string): ContentItemTypes | null {
    return this.content.contentLibrary.find(cont => cont.key === contentKey) || null
  }

  /**
   * set timeline scale
   */
  setTimelineSize(size: 1 | 3 | 5 | 9 | 18 | 36) {
    this.timelineSize = size;
  }

  setCurrenTimeByTimelineIdx(timelineIdx: number) {
    if (timelineIdx < 0) return;
    const timelines = [...this.timeline.getTimelineContents(TimelineType.Video)]
    if (timelineIdx >= timelines.length) return;
    const timeline = timelines[timelineIdx];
    this.seekbar.setCurrenTime(timeline.start)
  }

  setCurrenTimeByTimelineStartTime(startTime: number) {
    this.seekbar.setCurrenTime(startTime)
  }

  destroy() {
    this.seekbar.pause()
    this.seekbar.playingStateChangeEventListener.clearListeners();
    this.seekbar.currentTime = 0;
  }

}
