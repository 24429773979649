
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import ScoreCardQuiz from "@/views/ClassPage/component/ScoreCardQuiz.vue";
import ScoreCardPractice from "@/views/ClassPage/component/ScoreCardPractice.vue";
import ScoreCardReal from "@/views/ClassPage/component/ScoreCardReal.vue";

@Component({name: "ScoreCard", components: {ScoreCardReal, ScoreCardPractice, ScoreCardQuiz}})
export default class ScoreCard extends Vue {
  @Prop() model!: AiReportViewModel
  @Ref() refScoreCard!: HTMLLIElement;

  get scoreList() {
    return this.model.quizScoreList;
  }

  scrollToCard(classStep: number) {
    if (this.model.classStepQuiz === classStep) {
      this.model.changeClassStepQuiz(-1);
    } else {
      this.model.changeClassStepQuiz(classStep);
      const scoreCardTop = this.refScoreCard.getBoundingClientRect().y

      window.scrollTo({
        top: (scoreCardTop + window.pageYOffset) - 55,
        behavior: 'smooth'
      })
    }

  }

}
