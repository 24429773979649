
import {Component, Vue} from 'vue-property-decorator';
import MainHeader from '@/components/layouts/MainHeader.vue';
import App from "@/assets/lib/controller/App";

@Component({
  components: {MainHeader}
})
export default class BookerPage extends Vue {

  get checkTeacher() {
    return App.controller.auth.isTeacherLogin
  }

}
