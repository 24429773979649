
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import PcmCreator from "@/assets/lib/media/PcmCreator";
import App from "@/assets/lib/controller/App";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";
import {QuizItem, QuizOptions} from "@/Booker/lib/BookerTypes";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import QuizStartDictationAnswer from "@/components/ui/quiz/quizTypes/QuizStartDictationAnswer.vue";
import CommonPassageExplain from "@/Booker/views/components/common/CommonPassageExplain.vue";
import CommonPassageExplainBtnWrapper from "@/Booker/views/components/common/CommonPassageExplainBtnWrapper.vue";
import STTService from "@/lib/STTService";
import WFPlayer from "wfplayer";
import FileApi from "@/assets/lib/api/FileApi";

export enum DictationStatusType {
  Before = "Before",
  Play = "Play",
  RePlay = "RePlay",
  After = "After"
}


@Component({
  name: "",
  computed: {
    BookerUtility() {
      return BookerUtility
    }
  },
  components: {
    CommonPassageExplainBtnWrapper,
    CommonPassageExplain, QuizStartDictationAnswer, CommonInput, CommonTextArea
  }
})
export default class QuizStartDictation extends Vue {
  @Prop() quiz!: QuizItem
  @Prop() uuid!: string;
  @Prop() isSubmit !: boolean
  @Ref() refCanvas!: HTMLCanvasElement
  @Ref() refWaveCanvas!: HTMLCanvasElement
  @Ref() audio!: HTMLAudioElement
  @Ref() refWaveBar!: HTMLDivElement
  @Ref() refWaveform!: HTMLDivElement
  @Prop() subQuestionItem!: string
  @Prop() imgItem!: string
  @Prop() commonTextAreaReadOnly !: boolean
  @Prop() index!: number

  DictationStatusType = DictationStatusType;
  dictationStatusType: DictationStatusType = DictationStatusType.Before;
  STTService = STTService

  timer: any
  timerMinute = 0
  timerSecond = 0
  playerTimer: any = null;
  playSecond = 0
  playMinute = 0

  recordStop = true
  recordPlay = false
  recordPause = false
  /**
   * PCM
   */
  pcmCreator: PcmCreator | null = null;
  myDictation = ''
  myDictationAnswer = ''
  isRecord = false;
  isForceStop = false;
  mediaRecorder?: MediaRecorder;
  disabledBtn = false
  speakingResult = ''
  // 녹음 종료
  firstDictation = false
  activeIndex = 0
  private audioStream: MediaStream | null = null;
  private recordedBlobs: Blob[] = [];
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private dataArray: Uint8Array | undefined;
  private canvasCtx: CanvasRenderingContext2D | null = null;
  private drawVisual: number | null = null;
  private wf: WFPlayer | null = null;

  @Emit('zoomImg')
  zoomImg() {
    return this.imgItem
  }

  mounted() {
    requestAnimationFrame(() => {
      this.init();
    })
  }

  init() {
    if (!this.pcmCreator) this.pcmCreator = new PcmCreator()
    App.controller.auth.setUuid();
  }

  async stopRecord() {
    await this.pcmCreator?.stop();
  }

  // Blob 배열을 ArrayBuffer로 변환하는 함수
  blobArrayToArrayBuffer(blobArray: Blob[]): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const arrayBuffer = fileReader.result as ArrayBuffer;
        if (arrayBuffer) {
          resolve(arrayBuffer);
        } else {
          reject(new Error('Failed to convert blob to array buffer'));
        }
      };
      fileReader.onerror = () => {
        reject(fileReader.error);
      };
      fileReader.readAsArrayBuffer(new Blob(blobArray));
    });
  }

  /**
   * audio wave
   */
  async createAudioWave() {
    console.log('createAudioWave', this.recordedBlobs)
    const audioContext = new AudioContext();
    const arrayBufferArray = await this.blobArrayToArrayBuffer(this.recordedBlobs)
      .then(arrayBufferArray => {
        return arrayBufferArray;
      })
      .catch(error => {
        console.error(error);
      });
    if (!arrayBufferArray) return;
    const audioBuffer = await audioContext.decodeAudioData(arrayBufferArray);
    const canvasCtx = this.refWaveCanvas.getContext('2d') as CanvasRenderingContext2D;
    const samples = audioBuffer.getChannelData(0);
    const bufferLength = samples.length;
    const canvasWidth = this.refWaveCanvas.width;
    const canvasHeight = this.refWaveCanvas.height;
    const sliceWidth = (canvasWidth / bufferLength);
    let x = 0;
    let y = 0;

    canvasCtx.clearRect(0, 0, canvasWidth, canvasHeight);
    canvasCtx.fillStyle = '#E8ECFF'
    canvasCtx.lineWidth = 4;
    canvasCtx.strokeStyle = '#7277E2';
    canvasCtx.beginPath();

    for (let i = 0; i < bufferLength; i++) {
      const sample = samples[i];
      y = (sample + 1) * canvasHeight / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.stroke();

    this.recordedBlobs = [];

  }

  @Emit('myDictation')
  endPcmSend(msg: string) {
    console.log('==================================================')
    console.log('endPcmSend', msg)
    if (msg === '[ERROR]') this.myDictation = '녹음에 실패했습니다. 다시 답변해 주세요.';
    else this.myDictation = msg;
    this.createAudioWave()
    console.log('==================================================')
    return {uuid: this.uuid, myDictation: this.myDictation}
  }

  @Emit('myDictationAnswer')
  endPcmSendAnswer(emitData: { uuid: string, myDictationAnswer: string, optionIndex: number }) {
    return emitData;
  }


  @Emit('dictationAnswerFile')
  dictationAnswerFile(fileUrl: string) {
    return {uuid: this.uuid, fileUrl: fileUrl}
  }

  /**
   * 타이머
   */
  restTimer() {
    return `${this.TimerPadZero(this.timerMinute)}:${this.TimerPadZero(this.timerSecond)}`
  }

  //타이머 시간 10이하일 때 문자열 0 추가
  TimerPadZero(func: number) {
    if (func < 10) {
      return `0${func}`
    } else {
      return func
    }
  }

  setRecordTimer() {
    this.timer = setInterval(() => {
      this.timerSecond++
      if (this.timerSecond === 60) {
        this.timerMinute++
        this.timerSecond = 0
      }
    }, 1000);
  }

  // 답변 시작 (녹음 시작)
  answerDictation() {
    this.initWFPlayer();
    this.dictationStatusType = DictationStatusType.Play
    clearInterval(this.timer)
    this.timerMinute = 0
    this.timerSecond = 0
    this.initMediaRecord();
  }

  startRecord() {
    STTService.startSTT((message) => message ? this.speakingResult = message : '')
    this.initMediaRecord();
  }

  stopAnswerDictation() {
    this.firstDictation = true
    this.dictationStatusType = DictationStatusType.After
    this.mediaRecorder?.stop();
    clearInterval(this.timer)
    //if (this.mediaRecorder) this.mediaRecorder.stop()
    this.stopRecord();
    this.playMinute = this.timerMinute;
    this.playSecond = this.timerSecond;
    if (this.drawVisual && this.drawVisual > -1) window.cancelAnimationFrame(this.drawVisual);
  }

  // 녹음 재생
  playDictation() {
    this.dictationStatusType = DictationStatusType.RePlay
    this.audioPlay();
  }

  audioPlay() {
    this.audio.play();
    this.audio.onplay = () => {
      this.playSecond = this.audio.currentTime % 60
      this.playMinute = (this.audio.currentTime - this.playSecond) / 60
      if (this.playerTimer) {
        clearInterval(this.playerTimer);
        //this.setWaveBarStyle(0);
        this.playerTimer = null;
      }
      this.playerTimer = setInterval(() => {
        this.timerTimeLine(this.playMinute, this.playSecond)
      }, 100)
    }
    this.audio.onpause = () => {
      clearInterval(this.playerTimer);
      //this.setWaveBarStyle(0);
      this.playerTimer = null;
    }
    this.audio.onended = () => {
      this.dictationStatusType = DictationStatusType.After
      this.audio.currentTime = 0;
      clearInterval(this.playerTimer)
      //this.setWaveBarStyle(0);
      this.playerTimer = null;
    }
  }

  async fileUploader(newFile: File) {
    const fileApiRes = await FileApi.uploadFile(newFile)
    const fileId = fileApiRes?.file_list[0].file_id
    if (fileId) {
      this.dictationAnswerFile(fileId)
    }
  }

  // setWaveBarStyle(left: number) {
  //   //this.refWaveBar.style.transform = `translateX(${left - 150}%)`
  //   //this.refWaveBar.style.left = `${left}%`
  //   //const refWaveBArStyle = this.refWaveBar.style
  //   console.log('left', left)
  //   console.log('this.refWaveCanvas.width', this.refWaveCanvas.width)
  //
  //   if(left >= this.refWaveCanvas.width) {
  //     //this.refWaveBar.style.left = 0 + 'px'
  //   }
  //
  // }

  timerTimeLine(min: number, sec: number) {
    if (sec > 59.9) {
      this.playMinute = this.playMinute + 1;
      this.playSecond = 0;
    } else this.playSecond = this.playSecond + 0.1;
    const nowPosition = (this.playSecond + (this.playMinute * 60)) / (this.timerMinute * 60 + this.timerSecond) * 100;
    //this.setWaveBarStyle((nowPosition))
    console.log('nowPosition', nowPosition)
  }

  beforeDestroy() {
    this.destroyMediaRecorder();
  }

  destroyMediaRecorder() {
    if (this.mediaRecorder) {
      if (this.isRecord) this.mediaRecorder.stop();
      this.mediaRecorder = undefined;
    }
    this.audioStream?.getTracks().forEach(t => t.stop())
  }

  async initMediaRecord() {
    this.audioStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        sampleRate: 16000
      }
    })

    this.pcmCreator?.createPcmForQuiz(this.endPcmSend, this.audioStream)

    // @ts-ignore
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext({sampleRate: 16000});
    this.analyser = this.audioContext?.createAnalyser();
    this.analyser.minDecibels = -90;
    this.analyser.maxDecibels = -10;
    this.analyser.smoothingTimeConstant = 0.85;
    this.analyser.fftSize = 2048;

    const distortion = this.audioContext.createWaveShaper();
    const gainNode = this.audioContext.createGain();
    const biquadFilter = this.audioContext.createBiquadFilter();
    const convolver = this.audioContext.createConvolver();
    const echoDelay = this.createEchoDelayEffect(this.audioContext);
    const source = this.audioContext.createMediaStreamSource(this.audioStream);
    source.connect(distortion);
    distortion.connect(biquadFilter);
    biquadFilter.connect(gainNode);
    convolver.connect(gainNode);
    echoDelay.placeBetween(gainNode, this.analyser);
    this.analyser.connect(this.audioContext.destination);

    const bufferLength = this.analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(bufferLength);
    this.analyser.getByteTimeDomainData(this.dataArray);
    this.analyser.connect(this.audioContext?.destination)
    this.canvasCtx = this.refCanvas.getContext("2d");

    this.mediaRecorder = new MediaRecorder(this.audioStream);
    if (this.mediaRecorder) this.mediaRecorder.start();
    if (this.mediaRecorder) this.mediaRecorder.onstart = () => {
      this.isRecord = true;
      this.visualize();
      this.setRecordTimer();
    }

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        this.recordedBlobs.push(event.data);
      }
    };

    this.mediaRecorder.onstop = async () => {
      this.audio.src = URL.createObjectURL(new Blob([...this.recordedBlobs]));
      this.isRecord = false;
      if (!this.isForceStop) {
        this.initWFPlayer();
        if (this.drawVisual) window.cancelAnimationFrame(this.drawVisual);
        const blob = new Blob(this.recordedBlobs, {type: "audio/mpeg"});
        const file = new File([blob], BookerUtility.createKey() + '-quizAnswer.mp3', {type: "audio/mpeg"});
        this.fileUploader(file);
        this.audio.src = URL.createObjectURL(blob);
        this.audio.onloadeddata = () => {
          this.wf?.load(this.audio);
          this.audio.play();
          setTimeout(() => {
            this.audio.pause();
          }, 100)
        }
      }
      gainNode.gain.value = 0;
      source.disconnect();
      distortion.disconnect();
      biquadFilter.disconnect();
      convolver.disconnect();
      this.analyser?.disconnect()
      this.recordedBlobs = [];
      if (this.drawVisual) window.cancelAnimationFrame(this.drawVisual);
      this.audioStream?.getTracks().forEach((t) => t.stop())
      STTService.stopSTT();
      this.analyser = null;
      this.audioStream = null;
      this.audioContext = null;
    }
  }


  createEchoDelayEffect(audioContext: AudioContext) {
    const delay = audioContext.createDelay(1);
    const dryNode = audioContext.createGain();
    const wetNode = audioContext.createGain();
    const mixer = audioContext.createGain();
    const filter = audioContext.createBiquadFilter();

    delay.delayTime.value = 0.75;
    dryNode.gain.value = 1;
    wetNode.gain.value = 0;
    filter.frequency.value = 1100;
    filter.type = "highpass";

    return {
      apply: function () {
        wetNode.gain.setValueAtTime(0.75, audioContext.currentTime);
      },
      discard: function () {
        wetNode.gain.setValueAtTime(0, audioContext.currentTime);
      },
      isApplied: function () {
        return wetNode.gain.value > 0;
      },
      placeBetween: function (inputNode: AudioNode, outputNode: AudioNode) {
        inputNode.connect(delay);
        delay.connect(wetNode);
        wetNode.connect(filter);
        filter.connect(delay);

        inputNode.connect(dryNode);
        dryNode.connect(mixer);
        wetNode.connect(mixer);
        mixer.connect(outputNode);
      },
    };
  }

  visualize() {
    if (!this.analyser) return;
    if (!this.canvasCtx) return;
    const WIDTH = this.refCanvas.width;
    const HEIGHT = this.refCanvas.height;
    this.analyser.fftSize = 1024 * 8;
    const bufferLength = this.analyser.fftSize;

    // We can use Float32Array instead of Uint8Array if we want higher precision
    // const dataArray = new Float32Array(bufferLength);
    const dataArray = new Uint8Array(bufferLength);

    this.canvasCtx.fillStyle = "rgb(240, 243, 255)";
    this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

    const draw = () => {
      if (!this.analyser) return;
      if (!this.canvasCtx) return;
      this.drawVisual = requestAnimationFrame(draw);

      this.analyser.getByteTimeDomainData(dataArray);

      this.canvasCtx.fillStyle = "rgb(240, 243, 255)";
      this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      this.canvasCtx.lineWidth = 4;
      this.canvasCtx.strokeStyle = "#888CE8";

      this.canvasCtx.beginPath();

      const sliceWidth = (WIDTH) / bufferLength;

      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        let v = dataArray[i] / 128.0;
        let y = (v * HEIGHT) / 2;

        if (i === 0) {
          this.canvasCtx.moveTo(x, y);
        } else {
          this.canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      this.canvasCtx.stroke();
    };

    draw();
  }

  subQuestionInput(e: Event) {
    this.quiz.subQuestion = (e.target as HTMLInputElement).value;
  }

  explainInput(e: Event) {
    this.quiz.explain = (e.target as HTMLTextAreaElement).value;
  }

  /******************/
  @Emit('dictationAnswerData')
  dictationAnswerData(emitData: { textAnswer: QuizOptions[], optionIndex: number, quizIndex: number }) {
    if (this.isSubmit) {
      return;
    }
    console.log('FROM-emitData', emitData)
    return emitData
  }


  @Emit('checkSave')
  onInputAnswer(e: Event) {
    const input = (e.target as HTMLInputElement);

    if (input && typeof input.dataset.index === "string") {
      const optionIdx = parseInt(input.dataset.index);

      this.quiz.options[optionIdx].userAnswer = (e.target as HTMLInputElement).value;
      this.dictationAnswerData({
        textAnswer: this.quiz.options,
        optionIndex: optionIdx,
        quizIndex: this.index
      });
    }
  }

  dictationStatusTypeUpdate(dictationStatusType: DictationStatusType) {
    this.dictationStatusType = dictationStatusType;
  }

  initWFPlayer() {

    if (this.wf === null) this.wf = new WFPlayer({
      container: this.refWaveform,

      // Media element like: video tag or audio tag
      mediaElement: this.audio,
      // Whether to use scroll mode
      waveColor: "#888CE8",

      // Background color
      backgroundColor: "#F0F3FF",

      // Whether to display cursor
      cursor: true,

      // Cursor color
      cursorColor: "#EC6D51",

      // Whether to display progress
      progress: true,

      // progress color
      progressColor: "#888CE8",

      // Whether to display grid
      grid: false,

      // Grid color

      // Whether to display ruler
      ruler: false,

      // Ruler color
      rulerColor: "rgba(255, 255, 255, 0.5)",

      // Whether to display ruler at the top
      wave: true,
      // Indicates whether to do http fetching with cookies
      waveSize: 1,
      // Indicates whether to enable CORS for http fetching

      // Initialize http headers

      // Pixel ratio
      pixelRatio: window.devicePixelRatio,
      // Duration of rendering
      duration: 10,
    });
    if (this.audio.src.length > 0) this.wf.load(this.audio)
  }

}
