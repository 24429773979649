
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {ContentItemType, DraggingObjectType, MediaContentItemBase, TimelineContent} from "@/Booker/lib/BookerTypes";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import ElementDragHandler from "@/assets/lib/dom/ElementDragHandler";
import {PT} from "@/assets/lib/dom/DomTypes";
import DraggingItemModel from "@/Booker/lib/model/DraggingItemModel";
import DomHelper from "@/assets/lib/dom/DomHelper";

@Component({name: "SubtitleTimelineItem", components: {}})
export default class SubtitleTimelineItem extends Vue {

  @Prop() model!: ClassContentMediaListViewModel
  @Prop() itemIndex!: number
  @Prop() timelineSize!: number
  @Prop() timelineContent!: TimelineContent
  @Prop() elementDragHandler!: ElementDragHandler

  @Ref() refView!: HTMLElement;

  @Ref('refSubtitleItem') refSubtitleItem!: HTMLDivElement

  mouseRelativeCoordinatePT: PT = {x: 0, y: 0}

  ContentItemType = ContentItemType

  // 대사 클릭시
  captionStatus = false

  deleteStatus = true
  exStatus = false
  isResizing = false
  startWidth = 0
  startX = 0
  // 자막
  captionArr: MediaContentItemBase[] | any[] = []

  get nowWidth() {
    return ((((this.timelineContent.end! - this.timelineContent.start) / 1000) * this.timelineSize)).toFixed(0);
  }

  /**
   * timelineContent 시간 변경
   */
  @Emit('timelineContentTimeChange')
  timelineContentTimeChange(contentItemType: ContentItemType, itemIndex: number, endTime: number) {
    return {contentItemType, itemIndex, endTime};
  }

  /**
   * timelineContent 삭제
   */
  @Emit('delTimelineItem')
  delTimelineItem() {
    return this.timelineContent;
  }

  /**
   * SeekBar
   */
  @Emit('setPreSeekBar')
  setPreSeekBar(isPreSeekBar: boolean) {
    return isPreSeekBar;
  }

  mounted() {
    addEventListener('keydown', this.deleteKeyInput)
  }

  beforeDestroy() {
    removeEventListener('keydown', this.deleteKeyInput)
  }

  deleteKeyInput(event: KeyboardEvent) {
    if (event.code === 'Delete' && this.$el.classList.contains('focus')) {
      if (confirm('삭제하시겠습니까?')) this.delTimelineItem();
    }
  }

  captionAddFocus(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.$el.classList.contains('focus')) {
      this.$el.classList.add('focus');
      this.captionStatus = true
    } else {
      this.$el.classList.remove('focus')
      this.captionStatus = false
    }

  }

  captionFunc() {
    this.captionArr = [
      {
        text: '상황 1을 살펴봅시다.',
        time: 0,
        explain: '상황 1을 살펴봅시다. 길에서 만난 친구에게 인사를 해보세요. 상황 1을 살펴봅시다. 길에서 만난 친구에게 인사를 해보세요.',
        id: 1
      },
    ]
  }

  /**
   * return time duration in korean
   */
  getDurationKorean(duration: number): string {
    const durationSec = Math.floor(duration / 1000);
    const durationMin = Math.floor(durationSec / 60);
    const durationHour = Math.floor(durationMin / 60);
    const durationDay = Math.floor(durationHour / 24);

    if (durationDay > 0) {
      return `${durationDay}일 ${durationHour % 24}시간 ${durationMin % 60}분 ${durationSec % 60}초`
    } else if (durationHour > 0) {
      return `${durationHour}시간 ${durationMin % 60}분 ${durationSec % 60}초`
    } else if (durationMin > 0) {
      return `${durationMin}분 ${durationSec % 60}초`
    } else {
      return `${durationSec}초`
    }
  }

  /**
   * 자막 리사이즈
   */

  subtitleEnter(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isResizing) return;
    this.exStatus = true
  }

  subtitleLeave(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    if (this.isResizing) return;
    this.exStatus = false
  }


  resizeSubtitleDown(event: MouseEvent) {
    if (!(event.target as HTMLElement).classList.contains('side-bar')) return;

    this.isResizing = true;
    this.deleteStatus = false;

    this.startX = event.clientX;
    this.startWidth = this.$el.clientWidth;
    this.exStatus = false

    this.setPreSeekBar(false);
    window.addEventListener('mousemove', this.resizeSubtitleMove);
    window.addEventListener('mouseup', this.resizeSubtitleUp);
  }

  resizeSubtitleMove(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const liWidth = this.refSubtitleItem.getBoundingClientRect().width;
    // if(liWidth <= 40) {
    //   this.refSubtitleItem.classList.add('overflow-hidden')
    // } else {
    //   this.refSubtitleItem.classList.remove('overflow-hidden')
    // }


    if (this.isResizing) {
      const deltaX = event.clientX - this.startX
      if (this.timelineContent.end) {
        let endTime = (1000 / this.timelineSize * (this.startWidth + deltaX)) + this.timelineContent.start
        const minTime = this.timelineContent.start + 1000
        if (endTime < minTime) endTime = minTime
        this.timelineContentTimeChange(this.timelineContent.type, this.itemIndex, endTime);
        this.setElStyle();
      }
    }
  }

  resizeSubtitleUp(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.isResizing = false;
    this.deleteStatus = true;
    this.setPreSeekBar(true);

    window.removeEventListener('mousemove', this.resizeSubtitleMove);
    window.removeEventListener('mouseup', this.resizeSubtitleUp);
  }


  /**
   * 자막 드래그 앤 드롭
   */
  dragTimelineItem(offset: null | PT, pt?: PT) {
    if (!offset) {
      this.repositionMouseUp()
      return;
    }

    if (offset.x === 0 && offset.y === 0) {
      this.repositionMouseDown();
      return;
    }

    if (pt) this.repositionMouseMove(offset, pt);
  }

  startDrag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.elementDragHandler.mouseDown(event, this.dragTimelineItem)
    const rect = this.elementDragHandler.usePtConverter(this.$el.getBoundingClientRect())
    const pt = this.elementDragHandler.usePtConverter(DomHelper.getMouseDownPt(event))
    this.mouseRelativeCoordinatePT = {
      x: rect.x - pt.x,
      y: rect.y - pt.y
    }
  }

  @Emit('endRepositionTimeline')
  repositionMouseUp() {
    this.$el.classList.remove('drag');
    this.setElStyle();
    return {timelineContent: this.timelineContent, timelineIndex: this.itemIndex}
  }

  repositionMouseDown() {
    DraggingItemModel.setItem(DraggingObjectType.TimelineContent, this.timelineContent);
  }

  repositionMouseMove(offset: null | PT, pt: PT) {
    if (!offset) return;
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');
    this.setElStyle();
  }

  setElStyle() {
    this.$el.setAttribute('style',
      `width : ${this.nowWidth}px; left : ${this.timelineContent.start * this.timelineSize / 1000}px `
    );
  }

}
