
import {Component, Emit, Vue} from 'vue-property-decorator'

@Component({
  name: 'BackButton'
})
export default class BackButton extends Vue {
  @Emit('openModal')
  openModal() {
    return true;
  }
}
