import {ClassInfo, FileInfo, FileInfoDetail} from "@/assets/lib/type/Types";

export interface ResponseData<T> {
  code: number;
  message: string;
  data: T;
}

export interface EmptyResponse {

}

export interface OAuthLoginResponse {
  code: string;
}

export interface OAuthTokenResponse {
  access_token: string,
  refresh_token: string,
  access_token_expire_at: number,
  refresh_token_expire_at: number,
}

export interface UserInfoResponse {
  user_info: {
    user_idx: string // user.user_idx,
    uid: string // user.uid,
    name: string // user.name,
  }
}

export interface PcmTextIntentionResponse {
  finalResult: string;
}

export interface CreateUUIDResponse {
  uuid: string;
}

export interface YoutubeResponse {
  id: string,
  start: number,
  duration: number,
  thumbnail: string,
  title: string,
  items: [
    duration: ""
  ],

// {
//   "kind": "youtube#video",
//   "etag": "qdTc_kRMH7Iti7Ui9oQSLYUf1RI",
//   "id": "YXfzapAgLSo",
//   "contentDetails":
//   ,
//
//   "contentRating": {},
//   "projection": "rectangular"
// }
}

export interface YoutubeEmptyResponse {
  items: [
    duration: "",
  ],
}

export enum UserType {
  Student = 'student',
  Teacher = 'teacher'
}

export interface LoginUserInfo {
  user_id: string
  user_name: string
  user_level: number
  user_type: UserType
}

export interface LoginResponse {
  token_info: {
    token: string
    expire_at: number
  },
  user_info: LoginUserInfo
}

export interface FileInfoResponse {
  file_list: FileInfo[]
}

export interface FileInfoDetailResponse {
  file_info: FileInfoDetail
}


export interface ResBase {
  code: number;
  message?: string
  data: any;
}

export interface PrepareResponse {
  uploadUrl: string
  checkUrl: string
  downloadUrl: string
}

export interface BookCheckResponse {
  result: {
    totalPage: string
    donePage: string
  }
}

export interface BookFilesDownloadResponse {
  uploadUrl: string
  checkUrl: string
  downloadUrl: string
}

export interface classInfoResponse extends ResBase {
  contents: ClassInfo[];
}

export interface UserClassInfoResponse extends ResBase {
  contents: UserClassInfoResponseData[];
}

export interface UserClassInfoResponseData {
  userClassInfoIdx: number;
  content: string;
  contentKey: string;
  playTime: number;
  totalTime: number;
  isComplete: boolean;
  score: number;
  classStep: number;
  createdAt: number;
  updatedAt: number;
}

export interface UserContentInfoResponse extends ResBase {
  userContentList: UserContentInfoResponseData[];
}

export interface UserContentInfoResponseData {
  userContentInfoIdx: number;
  contentKey: string;
  classStep: number;
  isComplete: boolean;
  userContent: string;
  createdAt: number;
  updatedAt: number;
  deletedAt: number;
}

export interface EvaluationCriteriaResponseData {
  evaluationCriteria: {
    evaluationCriteriaGrade: number;
    evaluationCriteriaTitle: string;
    subject: string;
    subjectDomain: number;
    subjectSort: number;
    subjectCriteriaItem: string;
    subjectCriteriaLevel: string;
    subjectCriteriaKey: string;
    subjectCriteriaContent: string;
    created_at: number;
    updated_at: number;
    deleted_at: number | null;
  }
}
