import { render, staticRenderFns } from "./QuizAddItem.vue?vue&type=template&id=379c1a12&scoped=true&"
import script from "./QuizAddItem.vue?vue&type=script&lang=ts&"
export * from "./QuizAddItem.vue?vue&type=script&lang=ts&"
import style0 from "./QuizAddItem.vue?vue&type=style&index=0&id=379c1a12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379c1a12",
  null
  
)

export default component.exports