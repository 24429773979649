
import {Component, Emit, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import TestText from "@/Booker/views/components/video/player/text/VideoText.vue"

import {ContentItemType, MediaContentItemBase, TimelineContent, TimelineType} from "@/Booker/lib/BookerTypes";

import BookerVideoPlayer from "@/Booker/views/components/video/BookerVideoPlayer.vue";
import VideoTimelineItem from "@/Booker/views/components/video/timeline/VideoTimelineItem.vue";
import SubtitleTimelineItem from "@/Booker/views/components/subtitle/SubtitleTimelineItem.vue";
import QuizTimelineItem from "@/Booker/views/components/quiz/timeline/QuizTimelineItem.vue";
import DraggingItemModel from "@/Booker/lib/model/DraggingItemModel";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import TimelineTools from "@/Booker/views/components/timeline/TimelineTools.vue";
import ElementDragHandler from "@/assets/lib/dom/ElementDragHandler";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import AudioTimelineItem from "@/Booker/views/components/audio/AudioTimelineItem.vue";
import ScenarioTimelineItem from "@/Booker/views/components/scenario/ScenarioTimelineItem.vue";
import TextToSpeechTimelineItem from "@/Booker/views/components/tts/TextToSpeechTimelineItem.vue";
import FigureTimelineItem from "@/Booker/views/components/figure/FigureTimelineItem.vue";
import CallRegulator from "@/Booker/lib/util/CallRegulator";
import VideoEditModal from "@/Booker/views/components/video/VideoEditModal.vue";
import CommonBookerModal from "@/Booker/views/components/common/CommonBookerModal.vue";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import BookMarkVideoEditModal from "@/Booker/views/components/BookMarkVideoEditModal.vue";


@Component({
  name: "BookMarkToolEdit",
  computed: {
    BookerUtility() {
      return BookerUtility
    }
  },
  components: {
    BookMarkVideoEditModal,
    CommonBookerModal,
    VideoEditModal,
    ScenarioTimelineItem,
    AudioTimelineItem,
    TimelineTools,
    CommonButton,
    VideoTimelineItem,
    QuizTimelineItem,
    BookerVideoPlayer,
    TestText,
    SubtitleTimelineItem,
    TextToSpeechTimelineItem,
    FigureTimelineItem
  }
})
export default class BookerVideoTimelineEditor extends Vue {
  @Prop() viewModel!: ClassContentPlayerViewModel
  @Prop() model!: ClassContentMediaListViewModel;
  @Prop() pauseStatus!: boolean
  @Ref() seekbar!: HTMLDivElement;
  @Ref() previewSeekbar!: HTMLDivElement;
  @Ref() refEditBottom!: HTMLDivElement;
  @Ref() previewSeekbarTime!: HTMLSpanElement;
  timelineSize = 9

  modalStatus = false


  // @Prop() timelineSize!: number;
  elementDragHandler = new ElementDragHandler()
  rePositionCallRegulator = new CallRegulator(100);
  reSizeCallRegulator = new CallRegulator(50);
  ContentItemType = ContentItemType
  seekBarSize = 15;
  seekBarCorrectionSize = 52 - this.seekBarSize;
  // 툴 종류별 이미지
  btnTool = [
    {imgUrl: require('@/assets/images/bookmark/bookMarkTool/toolEdit/icGuideScenario.png')},
    {imgUrl: require('@/assets/images/bookmark/bookMarkTool/toolEdit/icGuideQuiz.png')},
    {imgUrl: require('@/assets/images/bookmark/bookMarkTool/toolEdit/icGuideMedia.png')},
    {imgUrl: require('@/assets/images/bookmark/bookMarkTool/toolEdit/icGuideCaption.png')},
    {imgUrl: require('@/assets/images/bookmark/bookMarkTool/toolEdit/icGuideTts.png')},
    {imgUrl: require('@/assets/images/bookmark/bookMarkTool/toolEdit/icGuideAudio.png')},
  ]
  isPreSeekBar = true;
  seekBarTimer = 0;
  timeLine = ["00:00"]
  /**
   * 우측 하단 미디어
   */
    // 시나리오
  scenarioArr: MediaContentItemBase[] | any[] = []

  // 미디어 클릭시
  /**
   *  Seekbar 마우스 이벤트
   */
  isDragging = false
  // 퀴즈
  /**
   * 클릭 이벤트
   */
  emptyTimeLine = true
  // 스크린 버튼 클릭시 화면 더 크게
  videoEdit = false
  isSetTimelineWheel = false;
  // 비디오 설정
  modalTimelineContent: TimelineContent | null = null

  scrollRightTimer: any = null;
  scrollLeftTimer: any = null;

  // 미디어 (이미지, 동영상, 휴식)
  get videoTimelineContentList() {
    return this.viewModel.timeline.getTimelineContents(TimelineType.Video);
  }

  // 퀴즈,말하기,교재
  get quizTimelineContentList() {
    return this.viewModel.timeline.getTimelineContents(TimelineType.Activity);
  }

  // 자막
  get subtitleTimelineContentList() {
    return this.viewModel.timeline.getTimelineContents(TimelineType.TextOverlay);
  }

  // 오디오
  get audioTimelineContentList() {
    return this.viewModel.timeline.getTimelineContents(TimelineType.Audio);
  }

  // 시나리오
  get scenarioContentList() {
    return this.viewModel.timeline.getTimelineContents(TimelineType.Scenario);
  }

  // 도형
  get figureContentList() {
    return this.viewModel.timeline.getTimelineContents(TimelineType.Figure);
  }

  get figureLineNumberList() {
    return this.viewModel.timeline.figureLineNumberList.reverse()
  }

  // 텍스트 투 스피치
  get textToSpeechContentList() {
    return this.viewModel.timeline.getTimelineContents(TimelineType.TextToSpeech);
  }

  @Emit('togglePauseStatus')
  togglePauseStatus() {
    return;
  }

  timeLineRenderBySize(timeViewIndex: number) {
    if (this.timelineSize === 1) return timeViewIndex % 6 === 0
    if (this.timelineSize === 3) return timeViewIndex % 3 === 0
    if (this.timelineSize === 5) return timeViewIndex % 2 === 0
    return true
  }

  /**
   * 생명 주기
   */

  created() {
    // TODO test
    // @ts-ignore
    window.editor = this
  }

  mounted() {
    this.createTime()
    this.initEventListeners()
    this.setPtConverter();

    this.timelineSize = this.viewModel.timelineSize
  }

  beforeDestroy() {
    if (this.seekBarTimer) clearInterval(this.seekBarTimer);
  }

  /**
   * 하단 seekBar 플레이위치 랜더링
   * 시간표기 한칸의 값을 비율에 맞춰 적용
   */
  setSeekbarPosition() {
    this.seekBarTimer = setInterval(() => {
      const seekbarLeft = this.viewModel?.seekbar.currentTime / 1000 * this.timelineSize;
      if (this.seekbar) this.seekbar.style.setProperty('left', `${this.seekBarCorrectionSize + seekbarLeft}px`)
    }, 30)
  }

  setSeekbarPlay() {
    this.setSeekbarPosition();
  }

  setSeekbarPause() {
    clearInterval(this.seekBarTimer);
  }

  // seekbar 위치 다음 섹션으로 옮기기
  movePlayNextSection() {
    let currentIndex = this.getCurrentSectionIndex(1);
    const nextIndex = currentIndex + 1

    if (nextIndex < this.videoTimelineContentList.length) {
      const nextSection = this.videoTimelineContentList[nextIndex]
      this.viewModel.seekbar.setCurrenTime(nextSection.start);
      this.setSeekbarPosition()
    }

  }

  // seekbar 위치 이전 섹션으로 옮기기
  movePlayPrevSection() {
    let index = this.getCurrentSectionIndex(-600);

    if (index >= 0) {
      const prevSection = this.videoTimelineContentList[index]
      this.viewModel.seekbar.setCurrenTime(prevSection.start);
      this.setSeekbarPosition()
    }

  }

  // 현재 섹션
  getCurrentSectionIndex(timeTick = 0) {
    const currentTime = this.viewModel.seekbar.currentTime + timeTick;
    for (let i = 0; i < this.videoTimelineContentList.length; i++) {
      const section = this.videoTimelineContentList[i];
      if (section && section.end !== undefined && currentTime >= section.start && currentTime <= section.end) {
        return i;
      }
    }
    return -1;
  }

  @Watch('viewModel.seekbar.length')
  createTime() {
    const times = [];
    for (let i = 0; i < (this.viewModel.seekbar.length / 1000) + 70; i += 10) {
      const min = Math.floor(i / 60);
      const sec = Math.floor(i % 60);
      const time = `${min < 10 ? "0" : ""}${min}:${sec < 10 ? "0" : ""}${sec}`;
      times.push(time);
    }
    this.timeLine = times;
  }

  wheelEvent(event: WheelEvent) {
    event.stopPropagation();
    if (!this.isSetTimelineWheel) return;
    if (event.deltaY < 0) {
      switch (this.timelineSize) {
        case 1:
          this.setTimelineSize(3);
          break;
        case 3:
          this.setTimelineSize(5);
          break;
        case 5:
          this.setTimelineSize(9);
          break;
        case 9:
          this.setTimelineSize(18);
          break;
        case 18:
          this.setTimelineSize(36);
          break;
      }
      return;
    }
    switch (this.timelineSize) {
      case 36:
        this.setTimelineSize(18);
        break;
      case 18:
        this.setTimelineSize(9);
        break;
      case 9:
        this.setTimelineSize(5);
        break;
      case 5:
        this.setTimelineSize(3);
        break;
      case 3:
        this.setTimelineSize(1);
        break;
    }
  }

  /**
   * TimelineTool이랑 연동
   */
  TimelineToolZoomIn() {
    switch (this.timelineSize) {
      case 1:
        this.setTimelineSize(3);
        break;
      case 3:
        this.setTimelineSize(5);
        break;
      case 5:
        this.setTimelineSize(9);
        break;
      case 9:
        this.setTimelineSize(18);
        break;
      case 18:
        this.setTimelineSize(36);
        break;
    }
  }

  TimelineToolZoomOut() {
    switch (this.timelineSize) {
      case 36:
        this.setTimelineSize(18);
        break;
      case 18:
        this.setTimelineSize(9);
        break;
      case 9:
        this.setTimelineSize(5);
        break;
      case 5:
        this.setTimelineSize(3);
        break;
      case 3:
        this.setTimelineSize(1);
        break;
    }
  }

  initEventListeners() {
    // addEventListener('keydown', (event: KeyboardEvent) => {
    //   if (event.ctrlKey || event.metaKey) this.isSetTimelineWheel = true;
    //   else this.isSetTimelineWheel = false;
    //   if (event.ctrlKey || event.metaKey) addEventListener('wheel', this.wheelEvent)
    // })
    // addEventListener('keyup', () => {
    //   this.isSetTimelineWheel = false;
    //   removeEventListener('wheel', this.wheelEvent)
    // })
  }

  setPtConverter() {
    this.elementDragHandler.setPtConverter(pt => {
      pt.x += this.refEditBottom.scrollLeft
      pt.y += this.refEditBottom.scrollHeight
      return pt
    })
  }

  setTimelineSize(size: 1 | 3 | 5 | 9 | 18 | 36) {
    this.viewModel.setTimelineSize(size);
    this.timelineSize = size

    const time = this.viewModel.seekbar.currentTime / 1000
    const seekBarTime = (this.seekBarCorrectionSize / this.timelineSize)

    this.changeSeekBarPosition(time, seekBarTime)
  }

  // Seekbar MouseDown
  onSlideMouseDown() {
    this.isDragging = true
    window.addEventListener('mouseup', this.onSlideMouseUp);
    window.addEventListener('mousemove', this.onSlideMouseMove);
  }

  onSlideMouseMove(e: MouseEvent) {
    const positionX = e.clientX;
    if (positionX > this.refEditBottom.getBoundingClientRect().right - 100) {
      if (this.scrollRightTimer === null) {
        this.scrollRightTimer = setInterval(() => {
          if (this.refEditBottom) {
            this.refEditBottom.scrollLeft += 10;
          } else this.clearRightTimer()
          if (!this.isDragging) this.clearRightTimer()
        }, 30);
      }
    } else if (positionX < this.refEditBottom.getBoundingClientRect().left + 100) {
      if (this.scrollLeftTimer === null) {
        this.scrollLeftTimer = setInterval(() => {
          if (this.refEditBottom) this.refEditBottom.scrollLeft -= 10;
          else this.clearLeftTimer()
          if (!this.isDragging) this.clearLeftTimer();
        }, 30);
      }
    } else {
      this.clearRightTimer()
      this.clearLeftTimer()
    }

    const time = this.getTimeByPositionX(positionX)
    const seekBarTime = (this.seekBarCorrectionSize / this.timelineSize)

    this.changeSeekBarPosition(time, seekBarTime)
  }

  clearRightTimer() {
    clearInterval(this.scrollRightTimer)
    this.scrollRightTimer = null;
  }

  clearLeftTimer() {
    clearInterval(this.scrollLeftTimer)
    this.scrollLeftTimer = null;
  }

  changeSeekBarPosition(time: number, seekBarTime: number) {
    if (!this.seekbar) return;
    if (time <= 0) {
      this.seekbar.style.setProperty('left', `${this.seekBarCorrectionSize}px`);
    } else {
      const leftPosition = (time + seekBarTime) * this.timelineSize
      this.seekbar.style.setProperty('left', `${leftPosition}px`)
    }

    this.viewModel.seekbar.currentTime = time * 1000;
  }

  onSlideMouseUp() {
    this.isDragging = false;
    this.clearRightTimer();
    this.clearLeftTimer();

    window.removeEventListener('mouseup', this.onSlideMouseUp);
    window.removeEventListener('mousemove', this.onSlideMouseMove);
  }

  /**
   * 풀스크린
   */
  @Emit('setIsFullVideo')
  fullScreen() {
    this.videoEdit = !this.videoEdit
    return this.videoEdit
  }

  /**
   * 이미지 및 동영상 Preview mode 조회 / drag and drop
   */

  // Drag Over
  dragOverHandler(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const startAt = this.getTimeByPositionX(event.clientX) * 1000;
    const content = DraggingItemModel.getDraggingContentItem();
    if (!content) return;
    this.rePositionCallRegulator.call(() => this.viewModel.timeline.setPreviewContent(content, startAt, DraggingItemModel.getContentLength()))
    console.log('DRAG OVER!');
  }

  // Drop
  dropHandler(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.viewModel.timeline.applyPreviewContent();
    this.createTime();

    console.log('DROP!')
  }

  previewSeekBar(event: MouseEvent) {
    if (this.previewSeekbar.style.display === 'none' && this.isPreSeekBar)
      this.viewPreviewSeekBar();
    const time = this.getTimeByPositionX(event.clientX)
    const seekBarTime = (this.seekBarCorrectionSize / this.timelineSize)

    let timeMin = Math.floor(time / 60);
    let timeSec = Math.floor(time % 60);

    if (time <= 0) {
      this.previewSeekbar.style.setProperty('left', `${this.seekBarCorrectionSize}px`);
      timeMin = 0;
      timeSec = 0;
    } else {
      const leftPosition = (time + seekBarTime) * this.timelineSize
      this.previewSeekbar.style.setProperty('left', `${leftPosition}px`)
    }

    this.previewSeekbarTime.innerText = `${timeMin < 10 ? '0' + timeMin : timeMin}:${timeSec < 10 ? '0' + timeSec : timeSec}`
  }

  getTimeByPositionX(positionX: number) {
    const min = this.$el.getBoundingClientRect().x + 52;
    const position = positionX - min + this.refEditBottom.scrollLeft;
    const time = position / this.timelineSize
    if (time < 0) return 0;
    return time;
  }

  setPreSeekBar(isPreSeekBar: boolean) {
    this.isPreSeekBar = isPreSeekBar;
    if (isPreSeekBar) this.viewPreviewSeekBar()
    else this.removePreviewSeekBar()
  }

  timelineContentTimeChange(eventData: { contentItemType: ContentItemType, itemIndex: number, endTime: number }) {
    this.reSizeCallRegulator.call(() => {
      const {itemIndex, endTime, contentItemType} = eventData;
      this.viewModel.timeline.setTimelineContentEndTime(contentItemType, itemIndex, endTime)
      this.createTime();
    })
  }

  timelineContentStartAtChange(eventData: { contentItemType: ContentItemType, itemIndex: number, startAt: number }) {
    this.reSizeCallRegulator.call(() => {
      const {itemIndex, startAt, contentItemType} = eventData;
      this.viewModel.timeline.setTimelineVideoStartAt(contentItemType, itemIndex, startAt)
      this.createTime();
    })
  }

  delTimelineItem(timelineContent: TimelineContent) {
    console.log('timelineContent', timelineContent)
    if (timelineContent.type !== ContentItemType.Figure)
      this.viewModel.timeline.removeTimelineContents(timelineContent);
    else {
      this.viewModel.timeline.removeItem(timelineContent)
    }
    this.createTime();
  }

  removePreviewSeekBar() {
    this.previewSeekbar.style.setProperty('display', 'none');
  }

  viewPreviewSeekBar() {
    this.previewSeekbar.style.setProperty('display', 'block');
  }

  setSeekbarPreviewPosition() {
    this.seekbar.style.setProperty('left', this.previewSeekbar.style.left)
    this.viewModel.seekbar.currentTime = (parseInt(this.previewSeekbar.style.left) - this.seekBarCorrectionSize) / this.timelineSize * 1000
  }

  /**
   * 마우스 위치에따라 예상위치 판별
   * @param event MouseEvent
   * @param type TimelineType
   */
  repositionHandler(event: MouseEvent) {
    const timelineContent = DraggingItemModel.getDraggingTimelineContent();
    if (!timelineContent) return;
    const startAt = this.getTimeByPositionX(event.clientX) * 1000;
    this.viewModel.timeline.setPreviewTimelineContent(timelineContent, startAt);
  }

  /**
   * drop 시 emit 으로 전달받은 컨텐츠와 예상위치로 이동
   * @param eventData 컨텐츠와 위치
   */
  endRepositionTimeline() {
    this.viewModel.timeline.applyPreviewContent();

    DraggingItemModel.reset();
    this.createTime();
  }

  /**
   * YouTube Duration 설정
   */
  youtubeDuration() {
    console.log('123')
  }

  showEditModal(timelineContent: TimelineContent) {
    console.log('dkdkf')
    this.modalStatus = !this.modalStatus
    this.modalTimelineContent = timelineContent;

  }

  closeModal() {
    this.modalStatus = false;
    this.modalTimelineContent = null;
  }


}
