import ClassContentController from "@/Booker/lib/controller/ClassContentController";
import BookerConf from "@/Booker/BookerConf";

class ClassContentManager {
  controller = new ClassContentController()


  constructor() {
    if (BookerConf.isLocalDev) {
      // @ts-ignore
      window.ClassContentManager = this
    }
  }
}

export default new ClassContentManager()
