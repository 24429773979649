
import '@/assets/style/font.css'
import {Component, Ref, Vue} from "vue-property-decorator";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";


@Component({
  name: 'TestPage',
  components: {CommonInput}
})
export default class TestPage extends Vue {

  @Ref() userId!: HTMLInputElement;
  @Ref() userType!: HTMLInputElement;
  @Ref() serviceToken!: HTMLInputElement;
  chatbot: any;

  clickInit() {
    // 스크립트 불러오기
    const faqChatBotSDK = document.createElement('script');
    const now = Date.now();
    // faqChatBotSDK.src = 'http://121.134.39.203:10001/api/v1/faq/inject.js?version=1&ts=' + now;
    faqChatBotSDK.src = 'http://localhost:8083/api/v1/faq/inject.js?version=1&ts=' + now;
    //@ts-ignore
    document.querySelector('head').append(faqChatBotSDK)
    faqChatBotSDK.onload = () => {
      console.log('FaqChatBotSDK loaded')

      //tb url http://121.134.39.203:10001

      this.userId.value = 'test-user2'
      this.userType.value = 'teacher'
      this.serviceToken.value = '5b2eaa3058f22842963ef6908d8bb17bc3a16e339cace3550b2d1b6eb9c4cd4d'
      // this.serviceToken.value = '7c808ad1-fa1b-443b-958a-952b9a69765b'

      const config = {
        elSelector: '#chat-bot', // 챗봇이 그려질 element selector
        userId: this.userId.value, // 사용자 아이디
        userType: this.userType.value, // all: 로그인안된상태, student: 학생, teacher: 선생님, class_teacher: 담임선생님
        serviceKey: this.serviceToken.value, // 별도 발급된 서비스 키
        initCallBack: () => {
          console.log('FaqChatBotSDK init completed');
        },
        closeBtnCallback: () => {
          console.log('FaqChatBotSDK close');
        }
      };
      //@ts-ignore
      //eslint-disable-next-line
      this.chatbot = new FaqChatBotSDK();
      // 챗봇 초기화
      // 선택된 div 에 iframe 을 생성
      this.chatbot.init(config);
    }

  }

  clickChangeUserInfo() {
    // 사용자 변경이 필요한 경우
    this.chatbot.changeUserInfo({
      userId: this.userId.value, // (Optional)
      userType: this.userType.value, // (Optional)
    });
  }

  clickClear() {
    this.chatbot.clearMessage();
  }

  clickInfo() {
    // 사용자 정보 조회
    /** @type {{ userId: string, userType: ('all'|'student'|'teacher'|'class_teacher'), serviceKey: string }} */
    const config = this.chatbot.getUserInfo();
    console.log('config', config)

    // 챗봇이 생성된 ifrmae element 가져오기
    /** @type {null | HTMLIFrameElement} */
    const iframeElement = this.chatbot.getChatBotIframe()
    console.log('iframeElement', iframeElement)
  }

}
