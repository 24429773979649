type EnvKind = 'local' | 'stg' | 'prod';

export default class Config {
  private static config = {
    GENIE_HOST: "https://openapi-de.gigagenie.ai:9080",
    YOUTUBE_HOST: "https://www.googleapis.com",
    YOUTUBE_EMBED: "https://www.youtube.com/embed/",
    API_HOST: "",
    PROD_HOST: "https://aitutor.sketchlab.co.kr",
    KT_EDU_ON_HOST: "https://dev.user.kteduon.co.kr",
    YOUTUBE_IMG_HOST: "https://i.ytimg.com/vi", // sddefault.jpg
    YOUTUBE_KEY: "AIzaSyC7FO-hEH-tKM5hn3V1v59lW9NFk7lCkdw",
    UUID: "17fa9095-e06d-5598-9857-1b808ac12067",
    CLIENT_ID: "STgwMDI3NDg6MTY2NzM0OTIxNTYwOA==",
    CLIENT_KEY: "Y2xpZW50X2tleToxNjY3MzQ5MjE1NjA4",
    CLIENT_SECRET: "Y2xpZW50X3NlY3JldDoxNjY3MzQ5MjE1NjA4",
    PIN_SECRET: "202303",
    CERT_URL: "https://api.gigagenie.ai/api/v1/aiportal/cert",
    RASA_URL: "https://aitutor-chatframe.sketchlab.co.kr"
  };

  static get CONFIG() {
    return this.config;
  }
}
