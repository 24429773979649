
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {QuizItemType} from "@/Booker/lib/BookerTypes";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import QuizDifficulty from "@/Booker/views/components/quiz/QuizDifficulty.vue"

@Component({name: "QuizAddItem", components: {CommonButton, QuizDifficulty}})
export default class QuizAddItem extends Vue {

  @Prop() isSaveAble!: boolean
  @Prop() btnText!: string
  @Prop() isDifficulty !: boolean;
  @Prop() selectedDifficulty !: number;
  @Ref() refInput!: HTMLInputElement;

  mounted() {
    console.log('isDifficulty', this.isDifficulty)
    this.refInput.checked = this.isDifficulty
  }

  @Emit('addQuiz')
  addQuiz(type: QuizItemType) {
    return type
  }

  @Emit('saveQuiz')
  saveQuiz() {
    return true
  }

  addInput() {
    this.addQuiz(QuizItemType.Input)
  }

  addDescriptive() {
    this.addQuiz(QuizItemType.Descriptive)
  }

  addSelect() {
    this.addQuiz(QuizItemType.Select)
  }

  addOX() {
    this.addQuiz(QuizItemType.OX)
  }

  addDictation() {
    this.addQuiz(QuizItemType.Dictation)
  }

  addBlank() {
    this.addQuiz(QuizItemType.Blank)
  }

  @Emit('sendOnChangeDifficulty')
  onChangeDifficulty(difficulty: number) {
    console.log('onChangeDifficulty', difficulty)
    return difficulty
  }

  @Emit('onChangeIsDifficulty')
  onChangeIsDifficulty() {
    return !this.isDifficulty
  }

}
