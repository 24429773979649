
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import {ContentItemPronunciation, ContentLibraryType, ContentToolStateType} from "@/Booker/lib/BookerTypes";
import ContentItemPronunciationEditorViewModel
  from "@/Booker/lib/viewModel/classContent/ContentItemPronunciationEditorViewModel";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import PronunciationItem from "@/Booker/views/components/pronunciation/PronunciationItem.vue";
import FileApi from "@/assets/lib/api/FileApi";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

@Component({name: "EditPronunciation", components: {PronunciationItem, CommonButton, CommonInput}})
export default class EditPronunciation extends Vue {

  @Prop() model!: ClassContentMediaListViewModel
  @Prop() pronunciationKey!: string
  @Prop() refTimeMin!: CommonInput
  @Prop() refTimeSec!: CommonInput
  pronunciationModel: ContentItemPronunciationEditorViewModel | null = null;
  isSave = false;
  sec = 0;
  min = 0;
  BookerUtility = BookerUtility;

  get image() {
    if (this.pronunciationModel?.image)
      return BookerUtility.getFile(this.pronunciationModel?.image)
    return ''
  }

  init() {

    const pronunciation = this.model.getContentLibrary(ContentLibraryType.Pronunciation).find(content => content.key === this.pronunciationKey) as ContentItemPronunciation
    if (!pronunciation) {
      alert('알수없는 데이터 입니다.');
      this.$router.back();
    }
    this.pronunciationModel = new ContentItemPronunciationEditorViewModel(JSON.parse(JSON.stringify(pronunciation)))
    this.getTime()
  }

  mounted() {
    if (!this.pronunciationModel) this.init()
  }

  @Emit('setToolState')
  setToolState(state: ContentToolStateType) {
    return state;
  }

  @Watch('pronunciationKey')
  onChangePronunciationKey() {
    this.init();
  }

  selectItem(selectIdx: number) {
    this.pronunciationModel?.selectSentence(selectIdx);
    this.getTime();
  }

  /**
   * 저장 확인
   * 하위 컴포넌트들의 Emit으로 호출되는 함수
   * 하위 컴포넌트들의 데이터를 조회해서 저장 가능상태인지 판단.
   */
  checkSave() {
    this.isSave = !this.isSave
    this.isSave = this.isSaveAble();
  }

  /**
   * 저장 가능 상태인지 확인
   * 제목, 갯수, 질문이 비었는지 여부 확인
   */
  isSaveAble() {
    return this.pronunciationModel!.checkSaveItemState();
  }

  onInputTitle(e: Event) {
    this.pronunciationModel!.item.title = (e.target as HTMLInputElement).value;
    this.checkSave();
  }

  setTime() {
    if (this.pronunciationModel!.item.sentences.length < 1) return
    this.pronunciationModel!.item.sentences[this.pronunciationModel!.selectedSentenceIndex].duration
      = (this.min * 60 + this.sec) * 1000;
  }

  getTime() {
    if (this.pronunciationModel!.item.sentences.length < 1) return
    const time = this.pronunciationModel!.item.sentences[this.pronunciationModel!.selectedSentenceIndex].duration / 1000;
    this.min = Math.floor(time / 60);
    this.sec = time % 60;
    this.checkSave();
  }

  onInputSentence(e: Event) {
    if (!this.pronunciationModel) return;
    this.pronunciationModel!.item.sentences[this.pronunciationModel!.selectedSentenceIndex].sentence
      = (e.target as HTMLInputElement).value;
    this.checkSave();
  }

  savePronunciation() {
    if (this.model.addPronunciationLibrary(this.pronunciationModel!.item)) {
      this.model.save();
      this.setToolState(ContentToolStateType.Default);
      return;
    }
    alert('저장에 실패하였습니다. 상태를 확인해주세요.');
  }

  async pronunciationImg(e: Event) {
    const target = e.target as HTMLInputElement
    if (target.files && target.files.length > 0) {

      const fileApiRes = await FileApi.uploadFile(target.files[0])
      const fileId = fileApiRes?.file_list[0].file_id
      if (this.pronunciationModel && fileId) this.pronunciationModel.setImage(fileId)

    }
  }
}
