/**
 * 강의 컨텐츠 하나
 */
export interface ClassContent {
  key: string
  title: string
  createTime: number
  thumbnailKey?: string
  thumbnailUrl?: string
  class?: string
  classStep: number
  isAvatar?: boolean
  isRelease?: boolean
  contentLibrary: ContentItemTypes[]
  timeline: ClassContentTimeline[] // 각 유형별로 하나씩 timeline 이 포함됨
  filter?: number
  standardList?: StandardList []
}

/**
 * 강의 컨텐츠 하나
 */
export interface UserClassContent extends ClassContent {
  userClassInfoIdx: number;
  playTime: number;
  totalTime: number;
  isComplete: boolean;
  score: number;
  classStep: number;
  createdAt?: number;
  updatedAt?: number;
}

export interface StandardList {
  text: string,
  code: string,
}

export interface ClassContentTimeline {
  type: TimelineType
  contents: TimelineContent[]
}

/**
 * timeline 에 들어가는 컨텐츠 하나
 */
export interface TimelineContent {
  key: string //타임라인 아이템의 고유 key 값
  isPreview?: boolean // timeline 에 추가가 확정되지 않은 상태에서 임시로 추가된 컨텐츠인지 여부

  start: number // time in milliseconds
  end?: number // time in milliseconds

  startAt?: number
  endAt?: number

  type: ContentItemType // 해당되는 컨텐츠 유형
  content: ContentItemTypes // 컨텐츠
}

/**
 * Timeline 의 Layer 유형
 */
export enum TimelineType {
  Video = 'Video',
  Audio = 'Audio',
  Scenario = 'Scenario',
  Activity = 'Activity',
  TextToSpeech = 'TextToSpeech',
  TextOverlay = 'TextOverlay',
  Figure = 'Figure',
}


/**
 * 컨텐츠 유형
 */
export enum ContentItemType {
  Image = 'Image',
  Video = 'Video',
  Audio = 'Audio',
  Book = 'Book',
  Text = 'Text',
  Quiz = 'Quiz',
  Figure = 'Figure',
  Pronunciation = 'Pronunciation',
  Relaxation = 'Relaxation',
  Scenario = 'Scenario',
  TextToSpeech = 'TextToSpeech',

}

export enum ContentLibraryType {
  Media = 'Media', // 미디어
  Audio = 'Audio', // 오디오
  Book = 'Book', // 교재
  Subtitle = 'Subtitle', // 자막
  Quiz = 'Quiz', // 퀴즈
  Pronunciation = 'Pronunciation', // 말하기 실습
  Relaxation = 'Relaxation', // 휴식
  Scenario = 'Scenario', // 시나리오 -> 인터랙티브
  TextToSpeech = 'TextToSpeech', // Text To Speech
  Figure = 'Figure',
  Avatar = 'Avatar' // 아바타
}


export type ContentItemTypes =
  ContentItemImage
  | ContentItemVideo
  | ContentItemAudio
  | ContentItemBook
  | ContentItemText
  | ContentItemQuiz
  | ContentItemPronunciation
  | ContentItemRelaxation
  | ContentItemScenario
  | ContentItemTextToSpeech
  | ContentItemFigure

/**
 * 컨텐츠 base interface
 */
export interface ContentItemBase {
  key: string
  type: ContentItemType
  createTime: number
  title?: string
  bound?: ContentItemBound
  zIndex?: number
}

export interface MediaContentItemBase extends ContentItemBase {
  source: string // image url
  file?: File // 서버에 저장되지 않은 상태일때 임시로 file 정보를 보관하고 있음
}

/**
 * 사진 컨텐츠
 */
export interface ContentItemImage extends MediaContentItemBase {
  type: ContentItemType.Image
  width: number
  height: number
}

/**
 * 동영상 컨텐츠
 */
export interface ContentItemVideo extends MediaContentItemBase {
  type: ContentItemType.Video
  isLoading?: boolean
  sourceType: VideoContentSourceType
  length: number // video length in milliseconds
}


/**
 * 오디오 컨텐츠
 */
export interface ContentItemAudio extends MediaContentItemBase {
  type: ContentItemType.Audio
  length: number // audio length in milliseconds
}

/**
 * TTS 컨텐츠
 */
export interface ContentItemTextToSpeech extends MediaContentItemBase {
  type: ContentItemType.TextToSpeech
  voiceType: TextToSpeechVoiceType
  length: number // audio length in milliseconds
  message: string // tts 메시지
  title: string // tts 제목
  actionContent?: ContentItemBase[]
}

/**
 * 교재 컨텐츠
 */
export interface ContentItemBook extends ContentItemBase {
  type: ContentItemType.Book
  fileName: string
  apiInfo: { checkUrl: string; downloadUrl: string; uploadUrl: string }
  uploadState: { totalPage: number; donePage: number }
  pages: ContentItemBase[] // ContentItemBase 로 선언, 기본적으로 ContentItemImage 임
}


/**
 * 텍스트 오버레이 컨텐츠
 */
export interface ContentItemText extends ContentItemBase {
  type: ContentItemType.Text
  text: string
  font: string
  size: number // fontSize in px
  color: string
  background: string
  align: string // textAlign: left, center, right
  angle: number // 회전각
  isSubtitle: boolean // 자막여부
}

/**
 * 퀴즈 컨텐츠
 */
export interface ContentItemQuiz extends ContentItemBase {
  type: ContentItemType.Quiz
  quizItems: QuizItem[]
  /** 포함된 퀴즈 문항 목록 */
  timeLimit?: number
  quizType: number //0:실전문제, 1:연습문제, 2:퀴즈
  useRepeat: boolean // 반복학습 사용 여부
  repeatMinScore: number // N 점 이하일때 반복
  repeatSection: number // 반복 학습할 구간 index
}


/**
 * 말하기 실습 컨텐츠
 */
export interface ContentItemPronunciation extends ContentItemBase {
  type: ContentItemType.Pronunciation
  sentences: PronunciationSentence[]
  img?: string
  fileUrl?: string
}

/**
 * 휴식 컨텐츠
 */
export interface ContentItemRelaxation extends MediaContentItemBase {
  type: ContentItemType.Relaxation
  sourceType: VideoContentSourceType.Upload
  length: number // video length in milliseconds
}


/**
 * 시나리오 컨텐츠
 */
export interface ContentItemScenario extends ContentItemBase {
  type: ContentItemType.Scenario
  scenarioType: ContentItemScenarioType
  message: string // 시나리오 메시지
  keyword?: string // 낱말사전, 지니 인사이드 인 경우 키워드
  actionContent?: ContentItemBase[] // 챗봇 메시지 유형인 경우 표시할 컨텐츠 목록
}

export enum ActionContentType {
  None = 'None', // 없음
  RecommendBook = 'RecommendBook', // 교재 추천
  Quiz = 'Quiz', // 퀴즈
  Pronunciation = 'Pronunciation', // 말하기 실습
  Relaxation = 'Relaxation', // 휴식
}


export interface ContentItemBound {
  // absolute 기준 bound 설정
  // null : unset
  left?: string
  top?: string
  right?: string
  bottom?: string
  transform?: string
  width?: string
  height?: string
  background?: string
}

export interface BoundTransform {
  currentX: number,
  currentY: number,
  angle: number,
  width: string,
  height: string,
}

export enum QuizItemType {
  Select = 0, // 다지선다형
  Input = 1, // 단답형
  // Drawing = 2,
  // Capture = 3,
  Descriptive = 4, // 설문형
  OX = 5, // OX 퀴즈 형
  Dictation = 6, // 구술형
  Blank = 7, // 빈칸형
}

/**
 * QuizSet 내 하나의 문항
 */
export interface QuizItem {
  quizUuid: string,
  /** 해당 퀴즈의 컨텐트 key 값 */
  contentKey: string;
  /** 질문 */
  question: string;
  /** 보조 지문 */
  subQuestion?: string
  /** 질문 유형 */
  type: QuizItemType;
  /** 퀴즈 요소 */
  options: QuizOptions[];
  /** 정답시 챗봇의 메시지 */
  correctAnswerMsg?: string
  /** 오답시 챗봇의 메시지 */
  inCorrectAnswerMsg?: string
  /** 해설 */
  explain?: string
  /** 퀴즈의 난이도 0 : 하 , 1 : 중 | 공통 , 2 : 상 */
  difficulty: number,
  /** 유저가 푼 퀴즈의 난이도 */
  userDifficulty?: number,
  /** 지문 이미지 */
  img?: string
  /** 퀴즈 점수 */
  score: QuizScore[],
  userScore?: QuizScore[],
}

export interface QuizOptions {
  /** 고유 값 */
  uuid: string,
  /** 답 여부 */
  isCorrect: boolean,
  /** 질문 */
  text: string,
  /** 답 타입 */
  answerType: 'number' | 'string' | 'voice',
  /** 답 */
  answer: number | string,
  /** 유저가 제출한 답 */
  userAnswer?: number | string,
  /** 보조 답 : 여기에 있어도 답변으로 처리 */
  subAnswers?: number[] | string[],
  /** 유저가 제출한 보조답 */
  userSubAnswers?: number[] | string[],
  /** 유저가 답변한 파일의 주소 */
  answerFileUrl?: string,
}

export interface QuizScore {
  key: QuizScoreType,
  krText: QuizScoreKrType,
  value: number
}

export enum QuizScoreType {
  Understanding = 1,
  Expressiveness = 2,
  Adaptability = 3,
  ProblemSolving = 4,
}

export enum QuizScoreKrType {
  Understanding = '이해력',
  Expressiveness = '표현력',
  Adaptability = '응용력',
  ProblemSolving = '문제해결력',
}

/**
 * 말하기 실습 문장
 */
export interface PronunciationSentence {
  sentence: string
  duration: number // in milliseconds
}

/**
 * 시나리오 유형
 */
export enum ContentItemScenarioType {
  OpenContentItem = 'OpenContentItem', // 챗봇 메시지
  WordDictionary = 'WordDictionary', // 낱말사전
  GenieInside = 'GenieInside', // 지니 인사이드
}

/**
 * 음성 유형
 */
export enum TextToSpeechVoiceType {
  Man = 'man1',
  Woman = 'woman1',
  Kid = 'kid',
}

/**
 * 동영상 컨텐츠 소스 유형
 */
export enum VideoContentSourceType {
  Upload = 'Upload', // 업로드된 동영상
  Youtube = 'Youtube' // 유튜브 영상
}

export type ProgressCallback = (current: number, total: number) => void


export enum DraggingObjectType {
  ContentItemBase,
  TimelineContent
}

/**
 * 컨텐츠 에디터 상태
 */
export enum ContentToolStateType {
  Create = 'Create', // 생성
  View = 'View', // 상세 보기
  Edit = 'Edit', // 수정
  Default = 'Default', // 기본 미리보기
}

export interface ContentSideMenu {
  key: ContentLibraryType,
  text: string,
  imgOff: string,
  imgOn: string,
}

export interface YoutubeItem {
  id: string,
  thumbnail: string,
  title: string,
  url: string,
  duration: number
}

export interface CreatorTextToSpeech {
  title: string,
  message: string,
  voiceType: TextToSpeechVoiceType
}

/**
 * 도형 타입 구분
 */
export enum ShapeItemType {
  Text = 'Text',
  Circle = 'Circle',
  Square = 'Square',
  Polygon = 'Polygon',
  Star = 'Star'
}

/**
 * 도형 기본 설정
 */
export interface ContentItemFigure extends ContentItemBase {
  figureType: ShapeItemType;
  text: string;
  size: number;
  bgColor: string;
  textColor: string;
  show: boolean;
  lineNumber: number;
  bound: ContentItemBound;
}

export type ClassStatus = 'BeFore' | 'Play' | 'Quiz' | 'Pause' | 'End';


export interface TotalUserContent {
  // 전체 점수
  totalScore: number;
  // 총평
  totalResultText: string;
  // 단원 성취기준 평가 목록
  standardEvaluations: StandardEvaluation[];
  // 평가 영역별 분석 목록
  classStepEvaluation: Map<number, ClassStepEvaluation[]>;
  // 득점표 목룍
  classStepScoreList: ClassStepScore[];
}

export interface StandardEvaluation {
  key: string;
  level: string;
  criteriaItem: string;
  evaluationCriteriaTitle: string;
  content: string;
}

export interface ClassStepEvaluation {
  classStep: number;
  evaluation: string;
  level: string;
  text: string;
  score: number;
  averageScore: number;
}

export interface ClassStepScore {
  classStep: number,
  totalQuiz: number,
  correctQuiz: number,
}

export interface EvaluationForView {
  key?: string,
  level?: string,
  criteriaItem: string,
  evaluationCriteriaTitle: string,
  content: string
}

export interface UserContent {
  contentKey: string;
  key: string;
  isComplete: boolean;
  classStep?: number;
  type: ContentItemType.Quiz | ContentItemType.Pronunciation;
  userContentList: ContentItemQuiz[];
}
