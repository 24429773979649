
import {Component, Prop, Vue} from "vue-property-decorator";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";

@Component({name: "QuizTimeLimit", components: {CommonInput}})
export default class QuizTimeLimit extends Vue {
  @Prop() quizModel !: ContentItemQuizEditorViewModel

  isInputDisabled = false

  mounted() {
    this.isInputDisabled = this.quizModel.getTimeLimit() === undefined
  }

  toggleDisabled() {
    this.quizModel.quiz.timeLimit = this.isInputDisabled ? 10 : undefined
    this.isInputDisabled = !this.isInputDisabled
  }

  onInputTimeLimit(e: Event) {
    const inputValue = (e.target as HTMLInputElement).value;
    const numericValue = parseInt(inputValue, 10);
    if (!isNaN(numericValue)) {
      this.quizModel.setTimeLimit(numericValue);
    }
  }
}
