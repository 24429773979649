
import {Component, Emit, Vue} from "vue-property-decorator";

@Component({ name: "VideoEditModal",  components: {}})
export default class VideoEditModal extends Vue {




  @Emit('closeModal')
  closeModal() {
    console.log('123')
    return
  }
}
