
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {QuizItem} from "@/Booker/lib/BookerTypes";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import QuizSelectOption from "@/Booker/views/components/quiz/QuizSelectOption.vue";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";
import QuizBlankOption from "@/Booker/views/components/quiz/QuizBlankOption.vue";

@Component({
  name: "QuizBlank",
  components: {QuizBlankOption, CommonTextArea, QuizSelectOption, CommonInput, CommonButton}
})
export default class QuizBlank extends Vue {
  @Prop() question!: QuizItem
  @Prop() quizModel!: ContentItemQuizEditorViewModel
  @Prop() difficultyIdx!: number
  @Prop() difficulty!: number
  @Prop() questionIdx!: number

  explainStatus = false
  answerTextStatus = false
  BookerUtility = BookerUtility;


  explainTextStatus = true
  correctTextStatus = true


  @Ref() refQuizImg!: HTMLImageElement

  get options() {
    return this.question.options
  }

  @Emit('checkSave')
  checkSend() {
    return true;
  }

  @Emit('checkSave')
  checkSaveSend() {
    this.checkSend();
  }

  @Emit('checkSave')
  onInputQuestion(e: Event) {
    this.question.question = (e.target as HTMLInputElement).value;
  }

  delOptionEmit(optionIdx: number) {
    this.delOption(optionIdx)
  }

  @Emit('checkSave')
  addOption() {
    //this.quizModel.addOption(this.question)
    console.log('this.questionIdx, this.difficulty', this.questionIdx, this.difficulty)
    this.quizModel.addAnswerArray(this.difficultyIdx, this.difficulty)
  }

  @Emit('checkSave')
  delOption(optionIdx: number) {
    this.quizModel.delAnswerArray(this.difficultyIdx, this.difficulty, optionIdx)
  }

  upOption(optionIdx: number) {
    this.quizModel.moveUpAnswerArray(this.difficultyIdx, this.difficulty, optionIdx)
  }

  downOption(optionIdx: number) {
    this.quizModel.moveDownAnswerArray(this.difficultyIdx, this.difficulty, optionIdx)
  }

  showCorrectAnswer() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.correctAnswerMsg;
      if (typeof subQ === "string") {
        this.quizModel?.delCorrectMsg(this.difficultyIdx, this.difficulty)
        this.correctTextStatus = true
      } else {
        this.quizModel?.setCorrectMsg(this.difficultyIdx, this.difficulty, '')
        this.correctTextStatus = false
      }
    }
  }

  showExplain() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.explain;
      if (typeof subQ === "string") {
        this.quizModel?.delExplain(this.difficultyIdx, this.difficulty)
        this.explainTextStatus = true
      } else {
        this.quizModel?.setExplain(this.difficultyIdx, this.difficulty, '')
        this.explainTextStatus = false
      }
    }
  }

  onInputCorrectAnswer(e: Event) {
    this.question.correctAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setCorrectMsg(this.difficultyIdx, this.difficulty, this.question.correctAnswerMsg);
  }

  onInputInCorrectAnswer(e: Event) {
    this.question.inCorrectAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setInCorrectMsg(this.difficultyIdx, this.difficulty, this.question.inCorrectAnswerMsg);
  }

  explainInput(e: Event) {
    this.question.explain = (e.target as HTMLTextAreaElement).value;
    this.quizModel.setExplain(this.difficultyIdx, this.difficulty, this.question.explain);
  }

  subQuestionInput(e: Event) {
    this.question.subQuestion = (e.target as HTMLTextAreaElement).value;
    this.quizModel.setSubQuestion(this.difficultyIdx, this.difficulty, this.question.subQuestion);
  }

  setText(emitData: { text: string, optionIdx: number }) {
    const {text, optionIdx} = emitData;
    this.quizModel.setAnswerArrayText(this.difficultyIdx, this.difficulty, optionIdx, text);
  }

  setSubAnswer(emitData: { text: string, optionIdx: number, subAnswersIndex: number }) {
    const {text, optionIdx, subAnswersIndex} = emitData;
    this.quizModel.setSubAnswer(this.difficultyIdx, this.difficulty, optionIdx, subAnswersIndex, text);
  }

  addSubAnswer(answerIndex: number) {
    this.quizModel.addSubAnswer(this.difficultyIdx, this.difficulty, answerIndex);
  }

  delSubAnswer(emitData: { answerIndex: number, subAnswersIndex: number }) {
    const {answerIndex, subAnswersIndex} = emitData;
    this.quizModel.delSubAnswer(this.difficultyIdx, this.difficulty, answerIndex, subAnswersIndex);
  }


}
