
import {Component, Emit, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import ContentItemScenarioViewModel from "@/Booker/lib/viewModel/classContent/ContentItemScenarioViewModel"
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import ScenarioActivity from "@/Booker/views/components/scenario/ScenarioActivity.vue";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import {
  ActionContentType,
  ContentItemPronunciation,
  ContentItemScenarioType,
  ContentItemType,
  ContentLibraryType,
  ContentToolStateType
} from "@/Booker/lib/BookerTypes";

@Component({
  name: "",
  components: {CommonButton, ScenarioActivity, CommonInput}
})
export default class EditScenario extends Vue {

  scenarioViewModel: ContentItemScenarioViewModel | null = null

  @Prop() model!: ClassContentMediaListViewModel
  @Prop() libraryKey!:string
  @Ref() refOption!: HTMLLIElement

  isSaveAble = false
  editIsSaveAble = false
  actionIndex = 0

  isSave = false
  selectStatus = false
  activityOption = false
  selectedOption = ''
  msg = ''
  option = '테스트'
  ContentItemType = ContentItemType;
  ContentItemScenarioType = ContentItemScenarioType;
  ActionContentType = ActionContentType;

  get scenarioType() {
    return this.scenarioViewModel?.scenario.scenarioType
  }

  get actionContent() {
    return this.scenarioViewModel?.scenario.actionContent
  }

  @Watch('libraryKey')
  libraryKeyWatch(){
    this.init();
  }

  init() {

    console.log('pronunciationKey', this.libraryKey)

    const scenario = this.model.getContentLibrary(ContentLibraryType.Scenario).find(content => content.key === this.libraryKey) as ContentItemPronunciation
    console.log('pronunciation', scenario)
    if (!scenario) {
      alert('알수없는 데이터 입니다.');
      this.$router.back();
    }
      this.scenarioViewModel = new ContentItemScenarioViewModel(JSON.parse(JSON.stringify(scenario)), this.model)
    //this.getTime()
  }

  created() {
    this.init()
  }

  mounted() {
    if (!this.scenarioViewModel) this.init()
  }

  onInputTitle(e: Event) {
    this.scenarioViewModel!.scenario.title = (e.target as HTMLInputElement).value;
    //this.scenarioViewModel?.saveScenario();
    this.checkSave();
  }


  addChatBot() {
    this.scenarioViewModel?.changeScenarioType(ContentItemScenarioType.OpenContentItem);
  }

  addDictionary() {
    this.scenarioViewModel?.changeScenarioType(ContentItemScenarioType.WordDictionary)
  }

  addGenieInside() {
    this.scenarioViewModel?.changeScenarioType(ContentItemScenarioType.GenieInside)
  }

  @Emit('setToolState')
  setToolState(state: ContentToolStateType) {
    return state;
  }

  onInputMessage(e: Event) {
    this.scenarioViewModel!.setMessage((e.target as HTMLTextAreaElement).value);
    this.checkSave();
  }

  deleteActionContent() {
    this.scenarioViewModel?.deleteActionContent();
  }


  /**
   * 저장 가능상태인지 확인
   */

  isSaveScenario() {
    if (!this.scenarioViewModel?.scenario.title) return false;
    if (this.scenarioViewModel?.scenario.title.length < 2 || this.scenarioViewModel?.scenario.message.length < 2) return false;
    return true;
  }

  checkSave() {
    //this.isSaveAble = false
    this.isSaveScenario()
  }

  saveScenario() {
    if (this.model.addScenarioLibrary(this.scenarioViewModel!.scenario)) {
      this.model.save();
      this.scenarioViewModel?.saveScenario();
      this.setToolState(ContentToolStateType.Default);
      return;
    }
    alert('저장에 실패하였습니다. 상태를 확인해주세요.');
  }

}
