import axios, {AxiosResponse} from "axios";
import Api from "@/assets/lib/api/Api";
import {PrepareRequest} from "@/assets/lib/type/ApiRequestTypes";
import {PrepareResponse, ResponseData} from "@/assets/lib/type/ApiResponseTypes";
import {AxiosError} from "axios/index";
import Config from "@/config";
import App from "@/assets/lib/controller/App";

export default class DocumentApi extends Api {

  private static docAxios = axios.create({
    baseURL: Config.CONFIG.API_HOST + '/api/v1/document'
  })

  private static ktEduOnAxios = axios.create({
    baseURL: '/v2/meetings'
  })

  static get headerWithToken() {
    return {headers: {Authorization: `Bearer ${App.controller.auth.token}`}}
  }


  /**
   * 컨텐츠 파일 정보 조회
   * @param contentId : 콘텐츠 Key
   * @param data
   * @param onSuc
   * @param onErr
   */
  static prepare<Q = PrepareRequest, R = ResponseData<PrepareResponse>>(contentId: string, data: Q, onSuc?: (res: R) => void, onErr?: (err: AxiosError<R, Q>) => void) {
    return this.postRequest<Q, R>(this.docAxios, `/${contentId}/prepare`, data, this.headerWithToken, onSuc, onErr);
  }

  /**
   * prepare 에서 받은 upload url로 파일 업로드
   * @param data
   * @param uploadUrl
   */
  static async uploadFile(data: File, uploadUrl: string): Promise<AxiosResponse<any, any> | null> {
    const form = new FormData()
    form.append("files", data)
    try {
      const ret = await this.ktEduOnAxios.post('/' + uploadUrl, form, this.headerWithToken)
      return ret.data.data
    } catch (err) {
      console.error(err)
    }
    return null;
  }

  /**
   * prepare 에서 받은 upload url로 파일 업로드
   * @param checkUrl
   * @param data
   * @param onSuc
   * @param onErr
   */
  static checkUploadStatus(checkUrl: string) {
    return this.ktEduOnAxios.get('/' + checkUrl);
  }

  /**
   * prepare 에서 받은 upload url로 파일 업로드
   * @param downloadUrl
   * @param data
   * @param onSuc
   * @param onErr
   */
  static downloadFiles(downloadUrl: string) {
    return this.ktEduOnAxios.get(downloadUrl, {
      responseType: 'blob'
    });
  }


}

//
// meetingId : 0tKdiathK
// prepare 요청
// POST https://dev.user.kteduon.co.kr/dx/meetings/0tKdiathK/documents/prepare
//   payload : {"fileFormat":"jpg","fileName":"images.jpeg","requestId":"slab_user1"}
// fileName 은 임의의값
// 응답 예시
// {
//   "code": 200,
//   "message": "success",
//   "result": {
//   "uploadUrl": "/v2/meetings/0tKdiathK/documents/192e9/location/1/50080",
//     "checkUrl": "/v2/meetings/0tKdiathK/documents/192e9/location/1/50080",
//     "downloadUrl": "/v2/meetings/0tKdiathK/documents/192e9/location/1/50080/pages"
// }
// }
// 파일 업로드(uploadUrl)
// POST https://dev.user.kteduon.co.kr/v2/meetings/0tKdiathK/documents/192e9/location/1/50080
//   curl example : curl -X POST -F "files=@test.ppt" https://dev.user.kteduon.co.kr/v2/meetings/0tKdiathK/documents/192e9/location/1/50080
//   변환 상태 조회(checkUrl)
// GET https://dev.user.kteduon.co.kr/v2/meetings/0tKdiathK/documents/192e9/location/1/50080?ts=1682905710766
//   응답 예시 : {"code":200,"message":"OK","result":{"totalPage":"1","donePage":"1"}}
// 변환된 이미지 불러오기(downloadUrl)
// GET https://dev.user.kteduon.co.kr/v2/meetings/0tKdiathK/documents/192e9/location/1/50080/pages/0001.jpg
//   0001.jpg <- 파일 이름은 각 페이지 순서대로 0001, 0002, … 형식임
