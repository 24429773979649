import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import ClassPage from '@/views/ClassPage/ClassPage.vue'
import MainPage from "@/views/ClassPage/MainPage.vue";
import LoginPage from "@/views/ClassPage/LoginPage.vue";
import JoinPage from "@/views/ClassPage/JoinPage.vue";
import AiSetting from "@/views/ClassPage/AiSetting.vue";
import BookMarkMainPage from "@/Booker/views/pages/BookMarkMainPage.vue";
import BookMarkToolPage from "@/Booker/views/pages/BookMarkToolPage.vue";
import PinAuth from "@/router/PinAuth.vue";
import BookerPage from "@/Booker/views/pages/BookerPage.vue";
import TestPage from "@/views/ClassPage/TestPage.vue";
import AiReport from "@/views/ClassPage/AiReport.vue";
import MyQuizPage from "@/views/ClassPage/MyQuizPage.vue";
import MySpeakingPage from "@/views/ClassPage/MySpeakingPage.vue";
import MyIncorrectNote from "@/views/ClassPage/MyIncorrectNote.vue";


Vue.use(VueRouter)


// pinAuth는 그대로
// 미 로그인시 접속 가능 : 로그인, 메인
// 로그인 해야 : 설정, 북커, 수업 등에 입장 가능하게

const routes: Array<RouteConfig> = [
  {
    path: '/', component: PinAuth,
    children: [
      //PinAuth
      {path: '/main', name: 'main', component: MainPage},
      {path: '/', name: 'main', component: MainPage},
      {path: '/test', name: 'test', component: TestPage},
      {path: '/join', name: 'join', component: JoinPage},


      //Login
      {
        path: '/login', name: 'Login', component: LoginPage, children: [
          {path: '/class/:contentKey', name: 'class', component: ClassPage, props: true},
          {path: '/setting', name: 'Setting', component: AiSetting},
          {path: '/report', name: 'Report', component: AiReport},
          {path: '/my-quiz', name: 'MyQuizPage', component: MyQuizPage},
          {path: '/my-speaking', name: 'MySpeakingPage', component: MySpeakingPage},
          {path: '/my-incorrect-note', name: 'myIncorrectNote', component: MyIncorrectNote},

          //Login Teacher
          {
            path: '/bookmark', name: 'Booker', component: BookerPage, children: [
              {path: '/bookmark/main', name: 'BookMarkMainPage', component: BookMarkMainPage},
              {path: '/bookmark/tool/:contentKey', name: 'BookMarkToolPage', component: BookMarkToolPage, props: true},
            ]
          }
        ]
      },
    ],
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
