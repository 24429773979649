
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {ContentItemType, DraggingObjectType, MediaContentItemBase, TimelineContent} from "@/Booker/lib/BookerTypes";
import {PT} from "@/assets/lib/dom/DomTypes";
import ElementDragHandler from "@/assets/lib/dom/ElementDragHandler";
import DraggingItemModel from "@/Booker/lib/model/DraggingItemModel";

@Component({
  name: "",
  components: {}
})
export default class QuizTimelineItem extends Vue {
  @Prop() itemIndex!: number
  @Prop() timelineContent!: TimelineContent
  @Prop() quizTitle!: string
  @Prop() elementDragHandler!: ElementDragHandler
  @Prop() timelineSize!: number
  @Ref() refDragView!: HTMLDivElement;
  testText = '테스트'
  // 퀴즈 클릭시
  quizStatus = false
  quizIndex = 0
  // 퀴즈
  quizArr: MediaContentItemBase[] | any[] = []
  deleteStatus = true
  ContentItemType = ContentItemType

  /**
   * PreviewSeekBar 상태 변경
   */
  @Emit('setPreSeekBar')
  setPreSeekBar(isPreSeekBar: boolean) {
    return isPreSeekBar;
  }

  /**
   * timelineContent 시간 변경
   */
  @Emit('timelineContentTimeChange')
  timelineContentTimeChange(contentItemType: ContentItemType, itemIndex: number, endTime: number) {
    return {contentItemType, itemIndex, endTime};
  }

  // 포지션시 필요한것
  // 해당 마우스 위치에따라 start 타임을 확인하고
  // 같은 start 타임이 있다면 이전 비디오 index의 start 타임으로
  // index값은 어떻게 판별하는가?
  // 기존에 있던거 그대로 써보고 판단하자.

  mounted() {
    this.quizFunc()
  }

  quizFunc() {
    this.quizArr = [
      {status: '퀴즈', text: '의인법의 이해', id: 1},
      {status: '말하기', text: '긴 문장 매끄럽게', id: 2},
      {status: '교재', text: '3학년 국어 말하기', id: 3},
    ]
  }


  /**
   * quizItem 클릭시 focus
   * @param e
   */
  quizAddFocus(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.$el.classList.contains('focus')) {
      this.$el.classList.add('focus')
    } else {
      this.$el.classList.remove('focus')
    }

  }

  /**
   * 퀴즈 말하기 교재 Drag Reposition
   */

  dragTimelineItem(offset: null | PT, pt?: PT) {
    if (!offset) {
      this.repositionMouseUp()
      return;
    }

    if (offset.x === 0 && offset.y === 0) {
      this.repositionMouseDown();
      return;
    }

    this.repositionMouseMove();
  }

  startDrag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.elementDragHandler.mouseDown(event, this.dragTimelineItem)
  }

  @Emit('endRepositionTimeline')
  repositionMouseUp() {
    this.$el.classList.remove('drag');
    this.deleteStatus = true
  }

  repositionMouseDown() {
    DraggingItemModel.setItem(DraggingObjectType.TimelineContent, this.timelineContent);
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');
    this.deleteStatus = false
  }

  repositionMouseMove() {
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');

  }

  formatCreatTime() {
    const seconds = Math.floor(this.timelineContent.start / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const scenarioTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds.toString()}`;

    return scenarioTime
  }

  /**
   * timelineContent 삭제
   */
  @Emit('delTimelineItem')
  delTimelineItem() {
    return this.timelineContent;
  }

  // quizEnter(e: MouseEvent) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.exStatus = true
  // }


}
