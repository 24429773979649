import {OAuthTokenResponse} from "@/assets/lib/type/ApiResponseTypes";

export interface UserSession {
  user_idx: number
  uid: string
  name: string
  token: null | OAuthTokenResponse
}

export interface UserUuid {
  uid: string
  uuid: null | string
}

export interface Device {
  title: string,
  idx: string,
}

export interface DeviceDetail {
  id: string,
  label: string,
  info: MediaDeviceInfo | null,
}

export enum CustomMsgUi {
  Text = 'Text',
  Card = 'Card',
  Quiz = 'Quiz',
  Time = 'Time',
  SingleImg = 'SingleImg',
  SingleButton = 'SingleButton'
}

export enum MsgSenderType {
  bot = 'bot',
  notice = 'notice',
  user = 'user'
}


export enum ChatBotPostMsgType {
  Init = 0,
  Event = 1,
  UI = 2,
  Status = 3,
  Style = 4,
  Clear = 5,
  Speech = 6,
  KeyWord = 7
}


export interface ChatBotPostMsg {
  chatBotPostMsgType: ChatBotPostMsgType,
  customMsg?: CustomMsg,
  isVoice?: boolean,
  customButtonEvent?: CustomButtonEvent,
  data: string
}


export interface CustomMsg {
  uuid: string
  type?: string,
  ui: CustomMsgUi,
  createdAt: number,
  sender: MsgSenderType,
  text: string,
  subText?: string,
  highLightText?: string,
  img?: string,
  isRead: boolean
  buttons?: CustomButtonCreate[]
}

export interface CustomButtonCreate {
  text: string,
  code: CustomButtonEvent
}

export interface CustomButtonEvent {
  eventType: EventType,
  value: {
    contentTitle: string,
    contentKey: string,
    data: string | object
  }
}

export interface ClassInfo {
  class_content_key: string
  title: string
  content: string
  isRelease: boolean
  created_at: number
  updated_at: number
  deleted_at: number | null
}

export interface FileInfo {
  file_id: string,
  fieldname: string,
  filename: string,
  mimetype: string,
  size: number,
  path: string,
}

export interface FileInfoDetail {
  file_id: string,
  user_idx: number,
  fieldname: string,
  filename: string,
  mimetype: string,
  size: number,
  path: string,
  hls_convert_status: number,
  hls_url: string,
  created_at: number
}

export enum EventType {
  Init = 'Init',
  Voice = 'Voice',
  Quiz = 'Quiz',
  Faq = 'Faq',
  Book = 'Book',
  Pronunciation = 'Pronunciation',
  Speech = 'Speech',
  giene = 'giene',
  shortCut = 'shortCut',
  Quick = 'Quick',
  Common = 'Common',
  URL = 'URL'
}
