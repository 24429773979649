import {
  ClassContent,
  ContentItemBase,
  ContentItemFigure,
  ContentItemType,
  ContentItemTypes,
  ContentItemVideo,
  TimelineContent,
  TimelineType
} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

export default class ContentTimelineController {
  private readonly content: ClassContent
  private figureTotalList: Map<number, TimelineContent[]> = new Map();

  constructor(content: ClassContent) {
    this.content = content;
    this.initFigureMap()
  }

  private _previewTimelineContent: TimelineContent | null = null

  /**
   * preview 컨텐츠 조회
   */
  get previewTimelineContent(): TimelineContent | null {
    return this._previewTimelineContent
  }

  get figureLineNumberList() {
    const list: number[] = [];
    this.figureTotalList.forEach((_list, lineNumber) => {
      if (list.indexOf(lineNumber) === -1 && _list.length) list.push(lineNumber)
    })
    return list.sort();
  }

  /**
   * preview 사용중인지
   */
  get isPreviewMode(): boolean {
    return this._previewTimelineContent !== null
  }

  /**
   * 내부의 가장 큰 라인값 가져오기
   */
  get maxLineNumber() {
    let max = 0

    this.figureTotalList.forEach((list, key) => {
      if (key > max) max = key;
    })

    return max;
  }

  initFigureMap() {
    this.figureTotalList = new Map();
    this.getTimelineContents(TimelineType.Figure).forEach((timelineFigure) => {
      const figure = timelineFigure.content as ContentItemFigure
      this.addItem(figure.lineNumber, 0, timelineFigure)
    })
  }

  /**
   * 1,2,3,4 순서에 맞게 리스트 생성 해준다.
   */
  fromOneList(length: number): number[] {
    return Array.from({length}, (_, i) => i + 1);
  }

  /**
   * 전체 라인 넘버를 1,2,3,4 순서에 맞게 변경해준다.
   * 기존에 데이터를 가지고 1,2,3,4 순으로 새로 데이터를 만들고
   * 기존 데이터중에 새로운 데이터의 시간이 들어갈수 있는 지점이 있으면 그 지점에 추가
   * 기존 데이터에 대입시킨다.
   */
  adjustLineNumber() {
    const numberList = [...this.figureLineNumberList];

    if (!this.isSequential(numberList)) {

      const newMap: Map<number, TimelineContent[]> = new Map();
      const numberFromOneList = this.fromOneList(numberList.length);
      numberList.forEach((number, index) => {
        const values = this.figureTotalList.get(numberList[index])
        if (values) newMap.set(numberFromOneList[index], values)
      })

      this.figureTotalList = new Map();
      newMap.forEach((value, key) => this.figureTotalList.set(key, value))
      numberList.forEach((number, index) => {
        const newIdx = numberFromOneList[index];
        const values = newMap.get(newIdx);
        if (values)
          values.forEach(timelineContent => {
            const figure = timelineContent.content as ContentItemFigure
            figure.lineNumber = newIdx;
          })
      })

      this.initFigureMap();
    }

  }

  checkInsertAbleLineTime(timelines: TimelineContent[], cont: TimelineContent) {
    type AbleTime = {
      start: number;
      end: number;
    }

    const ableList: AbleTime[] = [];

    timelines.forEach((time, index) => {
      if (index === 0) ableList.push({start: 0, end: time.start})
      else ableList.push({start: time.end!, end: time.start})
    })

    for (let index = 0; index < timelines.length; index++) {
      const time = timelines[index];
      if (index === 0) ableList.push({start: 0, end: time.start})
      else if (index === timelines.length - 1) ableList.push({start: time.end!, end: -1})
      else {
        const nextTime = timelines[index + 1];
        ableList.push({start: time.end!, end: nextTime.start})
      }
    }

    ableList.forEach(ableTime => {
      if (ableTime.end > cont.end! && ableTime.start < cont.start) return true;
    })

    return false;
  }

  /**
   * 1,2,3,4 순서가 맞는지 확인 해준다.
   */
  isSequential(numbers: number[]): boolean {
    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i] !== i + 1) {
        return false;
      }
    }
    return true;
  }

  /**
   * 특정 시간대에 재생중인 타임라인의 인덱스값 반환
   * @param time
   */
  getVideoContentsAtTime(time: number): number {
    const list = [...this.getTimelineContents(TimelineType.Video)];
    return list.findIndex((tl) => (tl.start <= time) && (tl.end! >= time))
  }

  /**
   * 특정 시간대에 플레이할 오디오 컨텐츠 목록 조회
   * @param time
   */
  getAudioContentsAtTime(time: number): TimelineContent[] {
    const contents: TimelineContent[] = []
    const audioContents = this.getTimelineContents(TimelineType.Audio).sort((a, b) => a.start - b.start);
    for (const cont of audioContents) {
      if (cont.end === undefined) continue
      if (cont.end < time) continue
      if (cont.start > time) break
      contents.push(cont)
    }
    return contents
  }

  /**
   * 특정 시간대에 플레이할 오디오 컨텐츠 목록 조회
   * @param time
   */
  getTTSContentsAtTime(time: number): TimelineContent[] {
    const contents: TimelineContent[] = []
    const audioContents = this.getTimelineContents(TimelineType.TextToSpeech).sort((a, b) => a.start - b.start);
    for (const cont of audioContents) {
      if (cont.end === undefined) continue
      if (cont.end < time) continue
      if (cont.start > time) break
      contents.push(cont)
    }
    return contents
  }

  /**
   * 타임라인별 컨텐츠 목록 조회
   * @param timelineType
   */
  getTimelineContents(timelineType: TimelineType): TimelineContent[] {
    return this.content.timeline.find(tl => tl.type === timelineType)?.contents || []
  }

  /**
   * 모든 컨텐츠 목록 조회
   */
  getAllTimelineContents(): TimelineContent[] {
    // return all TimelineContent list
    const contents: TimelineContent[] = []
    for (const tl of this.content.timeline) {
      contents.push(...tl.contents)
    }
    return contents
  }

  /**
   * 특정 컨텐츠 조회
   */
  getTimelineContentByKey(key: string): TimelineContent | undefined {
    return this.getAllTimelineContents().find(timelineContent => timelineContent.key === key)
  }

  /**
   * 플레이어에 필요한 컨텐츠 목록 조회
   */
  getAllTimelineContentsForPlayer(): TimelineContent[] {
    // return all TimelineContent list
    const contents: TimelineContent[] = []
    contents.push(...this.getTimelineContents(TimelineType.Video))
    contents.push(...this.getTimelineContents(TimelineType.TextOverlay))
    contents.push(...this.getTimelineContents(TimelineType.Figure))
    return contents
  }

  /**
   * 오디오에 필요한 컨텐츠 목록 조회
   */
  getAllTimelineContentsForAudio(): TimelineContent[] {
    // return all TimelineContent list
    const contents: TimelineContent[] = []
    contents.push(...this.getTimelineContents(TimelineType.Audio))
    contents.push(...this.getTimelineContents(TimelineType.TextToSpeech))
    return contents
  }

  /**
   * 수업 플레이어에 필요한 컨텐츠 목록 조회 서버에서 처리해줘야함
   */
  getActivityTimelineContentsForClass() {
    const contents: TimelineContent[] = []
    this.getTimelineContents(TimelineType.Activity)
      .forEach((timeline => {
        contents.push(timeline)
      }))

    return contents
  }

  getLengthTimelineContentsByType(contentItemType: ContentItemType, positionInTimeline = 0): number {
    const timesByType = this.getTimelineContents(this.getTimelineTypeFromContentItemType(contentItemType))
    if (timesByType.length < 1) return 0;
    if (timesByType.length < positionInTimeline) return 0;
    const position = timesByType.length - positionInTimeline - 1
    return timesByType[position].end ? Math.floor((timesByType[position].end!)) : 0;
  }

  getMaxTimelineLength() {

    let max = 0

    this.getAllTimelineContents().forEach((current) => {
      if (current.end && current.end > max) max = current.end;
      if (current.start + 10 * 1000 > max) max = current.start + 10 * 1000;
    })

    return max;
  }

  addNewShape(figure: ContentItemFigure, start = 0, end = 0): ContentItemFigure {
    //this.videoShapes.push(new VideoShape());

    const timelineFigure: TimelineContent = {
      key: BookerUtility.createKey(),
      type: ContentItemType.Figure,
      content: figure,
      start: start,
      end: end ? end : start + 10 * 1000
    }

    this.changeEmptyLine(timelineFigure);

    this.initFigureMap();

    return figure
  }

  /**
   * 시간 목록에 새로운 시간이 추가 가능한지 확인하는 함수
   * @ : 시간 목록에 있는 시간
   * [v] : 해당 지점 확인
   * [v] @ [v] @@@@ [v] @ [v]
   * [v]의 4가지 지점을 확인해서 추가 가능한지 확인
   */

  canBeInserted(timeList: TimelineContent[], timelineFigure: TimelineContent): boolean {
    const list = timeList.sort((a, b) => a.start > b.start ? 1 : -1)

    // 새로운 시간과 이전 시간을 비교
    for (let i = 0; i < list.length; i++) {
      const schedule = list[i];
      if (!schedule.end) continue;
      if (!timelineFigure.end) continue;
      // 새로운 시간이 처음 요소와 비교될경우
      if (i === 0) {
        if (timelineFigure.end < schedule.start) return true;
        if (list.length === 1 && timelineFigure.start > schedule.end) return true;
        if (list.length > 1 && timelineFigure.end < list[i + 1].start && timelineFigure.start > schedule.end) return true;
      }
      // 새로운 시간이 i-1 번째 스케줄과 i 번째 스케쥴 사이에 았는 경우
      else if (i > 0 && i < list.length - 1) {
        if (timelineFigure.start > list[i - 1].end! && timelineFigure.end < schedule.start) {
          return true;
        }
        // 새로운 시간이 i번째 스케줄과 i+1 번째 스케쥴 사이에 았는 경우
        else if (timelineFigure.start > schedule.end && timelineFigure.end < list[i + 1].start) {
          return true;
        }
        // 새로운 시간이 마지막 요소와 비교될경우
      } else if (i === list.length - 1 && timelineFigure.start > schedule.end) {
        return true;
      }
    }
    return false;
  }

  changeEmptyLine(timelineFigure: TimelineContent) {
    (timelineFigure.content as ContentItemFigure).lineNumber = this.findEmptyLineNumber(timelineFigure)
    const timelineFigureContents = this.getTimelineContents(TimelineType.Figure)
    timelineFigureContents.splice(0, 0, timelineFigure);
    this.adjustLineNumber()
  }

  findEmptyLineNumber(timelineFigure: TimelineContent) {
    const timelineFigureContents = this.getTimelineContents(TimelineType.Figure)
    let findEmptyLineNumber = 0;

    // 작은숫자부터
    this.figureLineNumberList.forEach(lineNumber => {
      const lineList = timelineFigureContents.filter(cont => (cont.content as ContentItemFigure).lineNumber === lineNumber)
      if (findEmptyLineNumber > 0) return findEmptyLineNumber;
      if (this.canBeInserted(lineList, timelineFigure)) findEmptyLineNumber = lineNumber;
      // if (this.canBeInserted(lineList, timelineFigure)) findEmptyLineNumber = lineNumber;
    })

    if (findEmptyLineNumber === 0) return this.maxLineNumber + 1
    return findEmptyLineNumber;
  }

  /**
   * preview content 를 설정
   * getTimelineContents 에서는 preview 설정한 컨텐츠를 포함해서 리턴함
   * return 되는 TimelineContent 의 start 값은 요청한 값과 가까운 보정된 값으로 설정되고, end 값은 start + duration 으로 설정됨
   * 추가한 컨텐츠를 timeline 에 추가하는것을 확정할때 applyPreviewContent 를 호출해야 함
   * 추가한 컨텐츠를 timeline 에 추가하지 않고 삭제할때 removePreviewContent 를 호출해야 함
   * @param {ContentItemTypes} content 추가하고자 하는 컨텐츠
   * @param {number} start 원하는 컨텐츠 시작 시간 (밀리초)
   * @param {number} duration 원하는 컨텐츠 재생 시간 (밀리초), Video Timeline 또는 Audio Timeline 에 추가할때만 사용됨
   */
  setPreviewContent(content: ContentItemTypes, start: number, duration?: number) {

    // remove preview content already added
    if (this.isPreviewMode) this.removePreviewContent()

    // verify duration by timeline type
    if (duration === undefined) {
      throw new Error('duration is required for timeline contents')
    }


    // create preview content
    const timelineContent = this.createTimelineContent(content, start, duration)
    timelineContent.isPreview = true;

    this._previewTimelineContent = timelineContent

    const timelineType = this.getTimelineTypeFromContentItemType(timelineContent.type);
    const timelineContents = this.getTimelineContents(timelineType)


    let {indexOfPreviewContentToBeInserted} =
      this.timelineContentsAndInsertPositionIndex(start, timelineType)

    switch (timelineType) {

      case TimelineType.Activity :
      case TimelineType.Scenario :
        indexOfPreviewContentToBeInserted = 0
        this._previewTimelineContent.start = start
        break;

      case TimelineType.Audio :
      case TimelineType.TextOverlay :
      case TimelineType.TextToSpeech :
        this._previewTimelineContent.start = start
        break;


    }

    timelineContents.splice(indexOfPreviewContentToBeInserted, 0, timelineContent)

    // adjust start, end time of other contents in timeline
    this.adjustStartEndTimeInVideoTimelineContents(timelineType);

  }

  setPreviewTimelineContent(timelineContent: TimelineContent, start: number): TimelineContent {

    // remove preview content already preview
    if (this._previewTimelineContent) this._previewTimelineContent.isPreview = false;

    timelineContent.isPreview = true;
    this._previewTimelineContent = timelineContent;
    const timelineType = this.getTimelineTypeFromContentItemType(timelineContent.type);

    const {indexOfPreviewContentToBeInserted} =
      this.timelineContentsAndInsertPositionIndex(start, timelineType)

    const time = start - this._previewTimelineContent.start;

    switch (timelineType) {

      case TimelineType.Activity :
      case TimelineType.Scenario:

        this._previewTimelineContent.start = start
        break;

      case TimelineType.Figure :
      case TimelineType.Audio :
      case TimelineType.TextOverlay :
      case TimelineType.TextToSpeech :
        this._previewTimelineContent.start = start
        this._previewTimelineContent.end = this._previewTimelineContent.end! + time
        break;

      case TimelineType.Video :
        this.changeOrderTimelineContents(this._previewTimelineContent, indexOfPreviewContentToBeInserted)

    }

    this.adjustStartEndTimeInVideoTimelineContents(timelineType);
    return timelineContent
  }

  timelineContentsAndInsertPositionIndex(start: number, timelineType: TimelineType) {

    // add preview content
    const timelineContents = this.getTimelineContents(timelineType)

    // find proper location to insert preview content
    let indexOfPreviewContentToBeInserted = 0;
    if (start !== 0) indexOfPreviewContentToBeInserted = timelineContents.findIndex(cont => cont.start > start)
    if (indexOfPreviewContentToBeInserted < 0) indexOfPreviewContentToBeInserted = timelineContents.length

    return {timelineContents, indexOfPreviewContentToBeInserted}
  }

  /**
   * apply preview timeline content to timeline
   */
  applyPreviewContent() {
    if (!this._previewTimelineContent) return

    if (this._previewTimelineContent.type === ContentItemType.Figure) {
      const {content, start, end} = this._previewTimelineContent
      this.removeTimelineContents(this._previewTimelineContent)
      this.addNewShape(content as ContentItemFigure, start, end)
    }

    this._previewTimelineContent.isPreview = false
    this._previewTimelineContent = null
  }

  /**
   * previewContent 를 취소하고 timeline 에서 제거
   */
  cancelPreviewContent() {
    this.removePreviewContent()
  }

  removeTimelineContents(timelineContent: TimelineContent) {
    const timelineType = this.getTimelineTypeFromContentItemType(timelineContent.type)
    const timelineContentsList = this.getTimelineContents(timelineType);
    if (!timelineContentsList) return;
    const delIndex = timelineContentsList.indexOf(timelineContent);
    if (delIndex === -1) return;
    timelineContentsList.splice(delIndex, 1);
  }

  /**
   * timeline의 컨텐트 end 타임 증감
   */
  setTimelineContentEndTime(contentItemType: ContentItemType, itemIndex: number, endTime: number) {
    const timelineType = this.getTimelineTypeFromContentItemType(contentItemType);
    const content = this.getTimelineContents(timelineType)[itemIndex];
    if (!content) return;
    if (content.type === ContentItemType.Video) {
      content.endAt = Math.round(endTime)
    } else {
      content.end = endTime;
    }

    this.adjustStartEndTimeInVideoTimelineContents(timelineType);
  }

  /**
   * timeline의 컨텐트 end 타임 증감
   */
  setTimelineVideoStartAt(contentItemType: ContentItemType, itemIndex: number, startAt: number) {
    const timelineType = this.getTimelineTypeFromContentItemType(contentItemType);
    const content = this.getTimelineContents(timelineType)[itemIndex];
    if (!content) return;
    if (startAt < 0 && content.startAt) content.startAt += startAt;
    else content.startAt = startAt;
    this.adjustStartEndTimeInVideoTimelineContents(timelineType);
  }

  changeOrderTimelineContents(timelineContent: TimelineContent, index: number) {
    const type = this.getTimelineTypeFromContentItemType(timelineContent.type)
    const timelineContentsList = this.content.timeline.find(tl => tl.type === type)?.contents;
    if (!timelineContentsList) return;
    timelineContentsList.splice(timelineContentsList.indexOf(timelineContent), 1);
    timelineContentsList.splice(index, 0, timelineContent);
  }

  /**
   * ContentItemType 값으로 어떤 TimelineType 에 속하는지 조회
   */
  getTimelineTypeFromContentItemType(contentItemType: ContentItemType): TimelineType {
    switch (contentItemType) {
      case ContentItemType.Image:
      case ContentItemType.Video:
      case ContentItemType.Relaxation:
        return TimelineType.Video
      case ContentItemType.Audio:
        return TimelineType.Audio
      case ContentItemType.Text:
        return TimelineType.TextOverlay
      case ContentItemType.Quiz:
      case ContentItemType.Pronunciation:
      case ContentItemType.Book:
        return TimelineType.Activity
      case ContentItemType.Scenario:
        return TimelineType.Scenario
      case ContentItemType.TextToSpeech:
        return TimelineType.TextToSpeech
      case ContentItemType.Figure:
        return TimelineType.Figure
    }
  }

  isNotAdjust(timelineType: TimelineType) {
    if (timelineType === TimelineType.Video) return false
    return true
  }

  /**
   * 라인에 아이템 제거
   * lineNumber : 제거할 라인
   * itemKey : 제거할 아이템
   */
  removeItem(timelineContent: TimelineContent) {
    this.removeTimelineContents(timelineContent)
    this.adjustLineNumber();
    this.initFigureMap();
  }

  /**
   * 라인에 아이템 추가
   * lineNumber : 추가할 라인
   * addPositionIndex : 추가할 위치 / 기본 0
   * item : 추가할 아이템
   */
  addItem(lineNumber: number, addPositionIndex = 0, item: TimelineContent) {
    const figureList = this.figureTotalList.get(lineNumber);
    if (figureList) {
      figureList.push(item)
      this.figureTotalList.set(lineNumber, [...figureList]);
    } else {
      const list = [];
      list.push(item)
      this.figureTotalList.set(lineNumber, list);
    }
  }

  addTimelineContent(content: ContentItemTypes, start = 0, duration?: number) {
    const timelineType = this.getTimelineTypeFromContentItemType(content.type);
    const timelineContent = this.createTimelineContent(content, start, duration);
    const timelineContents = this.getTimelineContents(timelineType)
    timelineContents.splice(timelineContents.length, 0, timelineContent)
    this.adjustStartEndTimeInVideoTimelineContents(timelineType)
  }

  createTimelineContent(content: ContentItemTypes, start: number, duration?: number) {
    const timelineContent: TimelineContent = {
      key: BookerUtility.createKey(),
      isPreview: false,
      start,
      end: duration === undefined ? undefined : start + duration,
      type: content.type,
      content
    }

    return timelineContent;
  }

  private findContentByKeyFromLibrary(contentKey: string): null | ContentItemBase {
    return this.content.contentLibrary.find(lib => lib.key === contentKey) || null
  }

  /**
   * remove preview content from timeline
   */
  private removePreviewContent() {
    if (!this._previewTimelineContent) return

    const timelineType = this.getTimelineTypeFromContentItemType(this._previewTimelineContent.type)
    const timelineContents = this.getTimelineContents(timelineType)
    const index = timelineContents.findIndex(cont => cont === this._previewTimelineContent)
    if (index >= 0) {
      timelineContents.splice(index, 1)
      this.adjustStartEndTimeInVideoTimelineContents(timelineType)
    }
  }

  /**
   * timeline 에 있는 content list 의 start, end 시간을 조정
   */
  private adjustStartEndTimeInVideoTimelineContents(timelineType: TimelineType) {

    if (this.isNotAdjust(timelineType)) return;

    // get timeline
    const timeline = this.getTimelineContents(timelineType)

    // timeline 에 컨텐츠가 없는 경우는 skip
    if (timeline.length <= 0) return

    // 두번째 컨텐츠부터는 start 를 이전 컨텐츠의 end 값으로 설정
    for (let i = 0; i < timeline.length; i++) {

      const timelineItem = timeline[i];
      let duration = timelineItem.end! - timelineItem.start
      if (timelineItem.content.type === ContentItemType.Video) {
        const length = (timelineItem.content as ContentItemVideo).length
        duration = length

        if (timelineItem.startAt) duration = duration - timelineItem.startAt;
        if (timelineItem.endAt) duration = duration - timelineItem.endAt;
        if (duration > (timelineItem.content as ContentItemVideo).length) {
          duration = length
          timelineItem.endAt = 0
        }
      }

      if (i === 0) {
        // 첫번째 컨텐츠는 start 를 0 으로 설정
        timelineItem.start = 0
        timelineItem.end = duration
      } else {
        timelineItem.start = timeline[i - 1].end!
        timelineItem.end = timelineItem.start + duration
      }

    }

  }


}
