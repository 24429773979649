
import {Component, Prop, Vue} from "vue-property-decorator";
import {ContentItemText, TimelineContent} from "@/Booker/lib/BookerTypes";
import ContentItemSubtitleEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemSubtitleEditorViewModel";
import SubtitleOption from "@/Booker/views/components/subtitle/SubtitleOption.vue"
import VideoShapeOption from "@/Booker/views/components/video/player/shape/VideoShapeOption.vue";


@Component({
  name: "ViewSubtitle",
  components: {VideoShapeOption, SubtitleOption}
})
export default class ViewSubtitle extends Vue {
  textModel: ContentItemSubtitleEditorViewModel | null = null;
  @Prop() timelineContent!: TimelineContent

  init() {
    this.textModel = new ContentItemSubtitleEditorViewModel(this.timelineContent.content as ContentItemText);
  }

  mounted() {
    if (!this.textModel) this.init()

  }

}
