import 'vue-class-component/hooks' // import hooks type to enable auto-complete
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Api from "@/assets/lib/api/Api";

Vue.config.productionTip = false
Api.init()

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

