
import {Component, Emit, Vue} from "vue-property-decorator";
import {ShapeItemType} from "@/Booker/lib/BookerTypes";

@Component({name: "ShapesMenu", components: {}})
export default class ShapesMenu extends Vue {

  shapesMenu = [
    {
      imgSrc: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_square.png"),
      imgHover: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_square_hover.png"),
      hover: false,
      type: ShapeItemType.Square
    },
    {
      imgSrc: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_circle.png"),
      imgHover: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_circle_hover.png"),
      hover: false,
      type: ShapeItemType.Circle
    },
    {
      imgSrc: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_triangle.png"),
      imgHover: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_triangle_hover.png"),
      hover: false,
      type: ShapeItemType.Polygon
    },
    {
      imgSrc: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_star.png"),
      imgHover: require("@/assets/images/bookmark/bookMarkTool/shapeToolBar/ic_shape_star_hover.png"),
      hover: false,
      type: ShapeItemType.Star
    },
  ]

  ShapeItemType = ShapeItemType;

  /**
   * 클릭 도형 타입 구분
   * @param type
   */

  menuShapeClick(type: string) {
    this.shapesMenu.forEach((shape) => {
      if (shape.type === type) {
        console.log('shape', shape.type)
      }
    })
  }

  onHover(index: number, isHover: boolean) {
    this.shapesMenu[index].hover = isHover;
  }

  @Emit('addShape')
  addShape(type: ShapeItemType) { /**/
  }

  clickShape(type: ShapeItemType) {
    this.addShape(type)
  }


}
