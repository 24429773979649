
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import {ContentItemQuiz, TimelineType} from "@/Booker/lib/BookerTypes";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";
import BookerUtility from "@/Booker/lib/util/BookerUtility";

@Component({
  name: "QuizRepeat",
  components: {CommonButton, CommonInput}
})
export default class QuizRepeat extends Vue {
  @Prop() quizModel!: ContentItemQuizEditorViewModel;
  @Prop() quiz!: ContentItemQuiz
  number = 0;
  isRepeat = false;
  BookerUtility = BookerUtility;
  TimelineType = TimelineType;
  timeLineModel: null | ClassContentPlayerViewModel = null

  get contentKey() {
    return this.$route.params.contentKey
  }

  get timelines() {
    if (this.timeLineModel) return this.timeLineModel.timeline.getTimelineContents(TimelineType.Video)
    return [];
  }

  @Watch('quiz')
  setQuiz() {
    this.init();
  }

  init() {
    this.number = this.quiz.repeatMinScore;
    this.timeLineModel = new ClassContentPlayerViewModel(this.contentKey)
    this.isRepeat = this.quizModel.quiz.useRepeat
  }

  mounted() {
    this.init();
  }

  onInputQuizRepeat(e: Event) {
    const inputValue = (e.target as HTMLInputElement).value;
    const numericValue = parseInt(inputValue, 10);
    if (!isNaN(numericValue)) {
      this.quizModel.setRepeatMinScore(numericValue)
    }

  }

  updateRepeat() {
    this.quizModel.toggleRepeat()
  }

  setRepeatPosition(e: Event) {
    const select = e.target as HTMLSelectElement
    this.quizModel.setRepeatSection(select.selectedIndex)
  }

}
