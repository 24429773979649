
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import {QuizItem} from "@/Booker/lib/BookerTypes";

@Component({name: "QuizSelectOption", components: {CommonInput}})
export default class QuizSelectOption extends Vue {
  @Prop() optionIdx!: number;
  @Prop() question!: QuizItem;

  get answer() {
    return this.question.options[this.optionIdx].answer
  }

  get text() {
    return this.question.options[this.optionIdx].text
  }

  @Emit('delOptionEmit')
  delOptionEmit() {
    return this.optionIdx
  }

  @Emit('upOption')
  upOption() {
    return this.optionIdx
  }

  @Emit('downOption')
  downOption() {
    return this.optionIdx
  }

  @Emit('checkSaveSend')
  onInputQuestion(e: Event) {
    this.question.options[this.optionIdx].text = (e.target as HTMLInputElement).value;
  }

  @Emit('checkSaveSend')
  changeAnswer() {
    this.question.options.forEach((option) => {
      option.answer = 0
      option.isCorrect = false
    })
    this.question.options[this.optionIdx].answer = 1
  }

}
