
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import CommonBookerModal from "@/Booker/views/components/common/CommonBookerModal.vue";
import Card from "@/components/ui/Card.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import {ClassContent} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import FileApi from "@/assets/lib/api/FileApi";
import ClassContentListViewModel from "@/Booker/lib/viewModel/ClassContentListViewModel";

@Component({
  name: "BookMarkAddClassModal",
  computed: {
    BookerUtility() {
      return BookerUtility
    }
  },
  components: {CommonInput, Card, CommonBookerModal}
})
export default class BookMarkAddClassModal extends Vue {
  @Prop() newContent!: ClassContent
  @Prop() modalTitle!: string
  @Prop() model!: ClassContentListViewModel
  thumbnail = ''

  choiceSubmit = false
  choiceStep = false
  submitList = false
  standardList = false
  stepList = false
  thumbnailStatus = false
  optionIndex = 0

  // 추후 삭제
  exClassStandard = ['4국01-02', '4국01-06']
  selectedStandardIndex: number | null = null
  subject = [
    '국어 말하기',
    '국어 쓰기'
  ]

  get classStepContentList() {
    return `${this.newContent.classStep} 차시`
  }

  get classStandard() {
    return this.newContent.standardList
  }

  mounted() {
    console.log('this.classStandard', this.classStandard)
  }

  @Emit('closeModal')
  closeModal() {
    return
  }

  titleSave(e: Event) {
    if (this.newContent) {
      this.newContent.title = (e.target as HTMLInputElement).value;
    }
  }

  addStandard() {
    this.classStandard?.push({
      text: '',
      code: ''
    })
  }

  async onFile(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target && target.files && target.files.length > 0) {

      const fileApiRes = await FileApi.uploadFile(target.files[0])
      const fileId = fileApiRes?.file_list[0].file_id
      if (fileId) {
        if (this.newContent) this.newContent.thumbnailUrl = fileId
        this.thumbnail = fileId
        this.thumbnailStatus = true
      }

      target.files = null;
    }

    console.log('this.newContent.content.thumbnailUrl', this.newContent.thumbnailUrl)
  }

  addFocusSelectSubmit() {
    this.submitList = !this.submitList
    this.choiceSubmit = true
  }

  addFocusSelectStep() {
    if (this.choiceSubmit) {
      this.stepList = !this.stepList
    }
    this.choiceStep = true
  }

  addFocusSelectStandard(index: number) {
    this.selectedStandardIndex = index
    if (this.choiceStep && this.selectedStandardIndex === index) {
      this.standardList = !this.standardList
    } else {
      return;
    }

  }

  choiceClassStep(index: number) {
    console.log('index', index)
    return this.newContent.classStep = index;
  }

  choiceClass(subject: string) {
    this.newContent.class = subject
  }

  choiceStandard(standardIdx: number, exClassStandardIdx: number) {
    if (!this.newContent.standardList) {
      this.newContent.standardList = [{
        text: this.exClassStandard[exClassStandardIdx],
        code: this.exClassStandard[exClassStandardIdx]
      }];
      return;
    }
    this.newContent.standardList[standardIdx] = {
      text: this.exClassStandard[exClassStandardIdx],
      code: this.exClassStandard[exClassStandardIdx]
    };
  }

  addNewClass() {
    console.log('123')
    this.closeModal();
    this.model.addNewContentInModal(this.newContent);
  }
}
