import {ContentItemPronunciation, PronunciationSentence} from "@/Booker/lib/BookerTypes";

export default class ContentItemPronunciationEditorViewModel {
  item: ContentItemPronunciation
  selectedSentenceIndex = -1

  constructor(item: ContentItemPronunciation) {
    this.item = item;

    if(this.item.sentences.length > 0) this.selectedSentenceIndex = 0
    else this.selectedSentenceIndex = -1
  }


  setImage(image:string){
    this.item.img = image;
    this.item = JSON.parse(JSON.stringify(this.item))
  }

  get image(){
    return this.item.img
  }

  /**
   * 선택된 문장
   */
  get selectedSentence(): null | PronunciationSentence {
    if(this.selectedSentenceIndex < 0 || this.selectedSentenceIndex >= this.item.sentences.length) return null
    return this.item.sentences[this.selectedSentenceIndex]
  }

  /**
   * 선택된 문장의 길이
   * ex) 1분 32초인 경우 01:32 를 리턴
   */
  get selectedSentenceDurationText(): string {
    if(! this.selectedSentence) return ''
    return this.getSentenceDurationText(this.selectedSentence)
  }

  /**
   * 문장 duration 길이 값을 시간 표현 문자열로 반환
   * @param sentence
   */
  getSentenceDurationText(sentence: PronunciationSentence) {
    let duration = sentence.duration
    if(duration < 0) duration = 0
    const inSec = Math.round(duration / 1000)
    let min = Math.floor(inSec / 60) + ''
    let sec = (inSec % 60) + ''
    while(min.length < 2) {
      min = '0' + min
    }
    while(sec.length < 2) {
      sec = '0' + sec
    }

    return `${min}:${sec}`
  }

  /**
   * 문장을 선택
   * @param sentenceIndex
   */
  selectSentence(sentenceIndex: number) {
    if(sentenceIndex < 0) sentenceIndex = 0
    if(sentenceIndex >= this.item.sentences.length) sentenceIndex = this.item.sentences.length - 1
    this.selectedSentenceIndex = sentenceIndex
  }

  /**
   * 문장을 삭제
   * @param sentenceIndex
   */
  deleteSentence(sentenceIndex: number) {
    if(sentenceIndex < 0 || sentenceIndex >= this.item.sentences.length) return
    this.item.sentences.splice(sentenceIndex, 1)

    this.selectSentence(this.selectedSentenceIndex)
  }

  /**
   * 문장을 추가하고 추가한 문장을 선택
   * @param sentence
   * @param duration
   */
  addSentence(sentence = '', duration = 10000) {
    this.item.sentences.push({sentence, duration})
    this.selectedSentenceIndex = this.item.sentences.length - 1
  }

  /**
   * 저장가능 상태 전체 확인
   */
  checkSaveItemState() : boolean{
    let result = true;

    if(!this.item.title) return false;
    if(this.item.title.length < 2) return false;
    if(this.item.sentences.length < 1) return false;
    this.item.sentences.forEach((sentence)=>{
      if(!this.checkSentence(sentence)) result = false
    })

    return result;
  }

  checkSentence(sentence : PronunciationSentence){
    if(sentence.sentence.length < 1) return false
    if(sentence.duration < 2000) return false
    return true;
  }

}
