
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import {QuizItem} from "@/Booker/lib/BookerTypes";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import QuizSelectOption from "@/Booker/views/components/quiz/QuizSelectOption.vue";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";

@Component({
  name: "QuizOX",
  computed: {
    BookerUtility() {
      return BookerUtility
    }
  },
  components: {CommonTextArea, QuizSelectOption, CommonInput, CommonButton}
})
export default class QuizOX extends Vue {
  @Prop() question!: QuizItem
  @Prop() quizModel!: ContentItemQuizEditorViewModel
  @Prop() difficultyIdx!: number
  @Prop() difficulty!: number
  @Prop() questionIdx!: number
  @Prop() commonTextAreaReadOnly !: boolean

  explainStatus = false
  answerTextStatus = false

  explainTextStatus = true
  correctTextStatus = true

  @Ref() refQuizImg!: HTMLImageElement


  @Emit('checkSave')
  onInputQuestion(e: Event) {
    this.question.question = (e.target as HTMLInputElement).value;
  }

  @Emit('checkSave')
  changeAnswer(answerIdx: number) {
    this.question.options.forEach((option) => {
      option.answer = 0
    })
    this.question.options[answerIdx].answer = 1;
  }

  showCorrectAnswer() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.correctAnswerMsg;
      if (typeof subQ === "string") {
        this.quizModel?.delCorrectMsg(this.difficultyIdx, this.difficulty)
        this.correctTextStatus = true
      } else {
        this.quizModel?.setCorrectMsg(this.difficultyIdx, this.difficulty, '')
        this.correctTextStatus = false
      }
    }
  }

  showExplain() {
    if (this.difficultyIdx !== undefined && this.difficultyIdx > -1) {
      const subQ = this.question.explain;
      if (typeof subQ === "string") {
        this.quizModel?.delExplain(this.difficultyIdx, this.difficulty)
        this.explainTextStatus = true
      } else {
        this.quizModel?.setExplain(this.difficultyIdx, this.difficulty, '')
        this.explainTextStatus = false
      }
    }
  }

  explainInput(e: Event) {
    this.question.explain = (e.target as HTMLTextAreaElement).value;
    this.quizModel.setExplain(this.difficultyIdx, this.difficulty, this.question.explain);
  }

  onInputCorrectAnswer(e: Event) {
    this.question.correctAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setCorrectMsg(this.difficultyIdx, this.difficulty, this.question.correctAnswerMsg);
  }

  onInputInCorrectAnswer(e: Event) {
    this.question.inCorrectAnswerMsg = (e.target as HTMLInputElement).value;
    this.quizModel.setInCorrectMsg(this.difficultyIdx, this.difficulty, this.question.inCorrectAnswerMsg);
  }

  subQuestionInput(e: Event) {
    this.question.subQuestion = (e.target as HTMLTextAreaElement).value;
    this.quizModel.setSubQuestion(this.difficultyIdx, this.difficulty, this.question.subQuestion);
  }
}
