export enum MouseButtonType {
  Left = 0,
  Middle = 1,
  Right = 2,
  Back = 3, // X1
  Forward = 4, // X2
}

export interface PT {
  x: number;
  y: number;
}
export interface LINE {
  p1: PT
  p2: PT
}
