
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import {QuizItem} from "@/Booker/lib/BookerTypes";
import App from "@/assets/lib/controller/App";
import STTService from "@/lib/STTService";
import WFPlayer from 'wfplayer';
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import FileApi from "@/assets/lib/api/FileApi";

export enum DictationStatusType {
  Before = "Before",
  Play = "Play",
  RePlay = "RePlay",
  After = "After"
}

@Component({name: "QuizStartDictationAnswer", components: {CommonInput}})
export default class QuizStartDictationAnswer extends Vue {
  @Prop() quiz!: QuizItem
  @Prop() isSubmit!: boolean
  @Prop() optionIndex!: number
  @Prop() uuid!: string;
  @Prop() dictationStatusType!: DictationStatusType
  @Prop() optionIdx!: number

  @Ref() audio!: HTMLAudioElement
  @Ref() refWaveBar!: HTMLDivElement
  @Ref() refCanvas!: HTMLCanvasElement
  @Ref() refWaveCanvas!: HTMLCanvasElement
  @Ref() refBtnRecord!: HTMLButtonElement
  @Ref() refWaveform!: HTMLDivElement;

  DictationStatusType = DictationStatusType;
  myStatusType?: DictationStatusType
  BookerUtility = BookerUtility
  timer: any
  timerMinute = 0
  timerSecond = 0
  playerTimer: any = null;
  playSecond = 0
  playMinute = 0

  myDictationAnswer = ''

  isPlaying = false

  myDictation = ''
  isRecord = false;
  isForceStop = false;
  mediaRecorder?: MediaRecorder;
  activeIndex = 0
  private audioStream: MediaStream | null = null;
  private recordedBlobs: Blob[] = [];
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private dataArray: Uint8Array | undefined;
  private canvasCtx: CanvasRenderingContext2D | null = null;
  private drawVisual: number | null = null;
  private wf: WFPlayer | null = null;

  get disabledBtnStatus() {

    let isDisable = false;

    if (this.dictationStatusType === DictationStatusType.Play || this.dictationStatusType === DictationStatusType.RePlay) isDisable = true

    return isDisable
  }

  created() {
    this.myStatusType = this.dictationStatusType;
  }

  mounted() {
    requestAnimationFrame(() => {
      this.init();
    })

  }

  init() {
    App.controller.auth.setUuid();
  }

  @Emit('dictationStatusTypeUpdate')
  updateStatus(dictationStatusType: DictationStatusType) {
    this.myStatusType = dictationStatusType;
    return dictationStatusType;
  }

  stopRecord() {
    if (this.myDictationAnswer === '') {
      this.endPcmSendAnswer('답변 내용이 없습니다.');
    }
    STTService.stopSTT();
  }

  setRecordTimer() {
    this.timer = setInterval(() => {
      this.timerSecond++
      if (this.timerSecond === 60) {
        this.timerMinute++
        this.timerSecond = 0
      }
    }, 1000);
  }

  // 답변 시작 (녹음 시작)

  @Emit('emitData')
  emitData(emitData: { index: number, }) {
    return emitData
  }

  async answerDictation(index: number) {
    if (this.myStatusType === DictationStatusType.Before || this.myStatusType === DictationStatusType.After) {
      this.activeIndex = index
      if (this.activeIndex === index) {
        clearInterval(this.timer)
        this.timerMinute = 0
        this.timerSecond = 0
        try {
          await this.initMediaRecord();
        } catch (e) {
          console.log('e', e)
        }
        this.updateStatus(DictationStatusType.Play)
      } else {
        return;
      }
    }
  }

  stopAnswerDictation(index: number) {
    //this.activeIndex = index
    if (this.optionIndex === index) {
      this.mediaRecorder?.stop();
      clearInterval(this.timer)
      this.updateStatus(DictationStatusType.After)
      if (this.drawVisual && this.drawVisual > -1) window.cancelAnimationFrame(this.drawVisual);
      this.stopRecord();
    } else {
      return;
    }
  }

  // 녹음 재생
  playDictation(index: number) {
    // this.activeIndex = index
    if (this.optionIndex === index) {
      this.updateStatus(DictationStatusType.RePlay)
      this.audioPlay();
    } else {
      return;
    }
  }

  audioPlay() {
    this.audio.play();
    this.audio.onplay = () => {
      this.playSecond = this.audio.currentTime % 60
      this.playMinute = (this.audio.currentTime - this.playSecond) / 60
      if (this.playerTimer) {
        clearInterval(this.playerTimer);
        // this.setWaveBarStyle(0);
        this.playerTimer = null;
      }
      this.playerTimer = setInterval(() => {
        this.timerTimeLine(this.playMinute, this.playSecond)
      }, 100)
    }
    this.audio.onpause = () => {
      clearInterval(this.playerTimer);
      //this.setWaveBarStyle(0);
      this.playerTimer = null;
    }
    this.audio.onended = () => {
      this.updateStatus(DictationStatusType.After)
      //this.dictationStatusType = DictationStatusType.After
      this.audio.currentTime = 0;
      clearInterval(this.playerTimer)
      this.playerTimer = null;
    }
  }

  timerTimeLine(min: number, sec: number) {
    if (sec > 59.9) {
      this.playMinute = this.playMinute + 1;
      this.playSecond = 0;
    } else this.playSecond = this.playSecond + 0.1;
  }

  beforeDestroy() {
    this.destroyMediaRecorder();
    if (STTService.isStarted) STTService.stopSTT();
  }

  destroyMediaRecorder() {
    if (this.mediaRecorder) {
      if (this.isRecord) this.mediaRecorder.stop();
      this.mediaRecorder = undefined;
      this.audioStream?.getTracks().forEach(t => t.stop())
    }
    this.recordedBlobs = [];
    URL.revokeObjectURL(this.audio.src);
  }


  async initMediaRecord() {
    this.recordedBlobs = [];
    try {
      this.audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          sampleRate: 16000
        }
      })
    } catch (e) {
      alert('마이크를 사용할 수 없습니다.');
    }
    if (!this.audioStream) return;
    console.log('audioStream')

    // @ts-ignore
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    console.log('AudioContext')
    this.audioContext = new AudioContext({sampleRate: 16000});
    this.analyser = this.audioContext?.createAnalyser();
    this.analyser.minDecibels = -90;
    this.analyser.maxDecibels = -10;
    this.analyser.smoothingTimeConstant = 0.85;
    this.analyser.fftSize = 2048;

    const distortion = this.audioContext.createWaveShaper();
    const gainNode = this.audioContext.createGain();
    const biquadFilter = this.audioContext.createBiquadFilter();
    const convolver = this.audioContext.createConvolver();
    const echoDelay = this.createEchoDelayEffect(this.audioContext);
    const source = this.audioContext.createMediaStreamSource(this.audioStream);
    source.connect(distortion);
    distortion.connect(biquadFilter);
    biquadFilter.connect(gainNode);
    convolver.connect(gainNode);
    echoDelay.placeBetween(gainNode, this.analyser);
    this.analyser.connect(this.audioContext.destination);

    const bufferLength = this.analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(bufferLength);
    this.analyser.getByteTimeDomainData(this.dataArray);
    this.analyser.connect(this.audioContext?.destination)
    this.canvasCtx = this.refCanvas.getContext("2d");

    this.mediaRecorder = new MediaRecorder(this.audioStream);
    if (this.mediaRecorder) this.mediaRecorder.start();
    if (this.mediaRecorder) this.mediaRecorder.onstart = () => {
      STTService.startSTT((message) => {
        console.log('message', message)
        message ? this.endPcmSendAnswer(message) : ''
      })
      this.isRecord = true;
      this.visualize();
      this.setRecordTimer();
    }

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        this.recordedBlobs.push(event.data);
      }
    };

    this.mediaRecorder.onstop = async () => {
      this.isRecord = false;
      const blob = new Blob(this.recordedBlobs, {type: "audio/mpeg"});
      this.audio.src = URL.createObjectURL(blob);
      if (this.drawVisual) window.cancelAnimationFrame(this.drawVisual);

      gainNode.gain.value = 0;
      source.disconnect();
      distortion.disconnect();
      biquadFilter.disconnect();
      convolver.disconnect();
      this.analyser?.disconnect()
      this.audioStream?.getTracks().forEach((t) => t.stop())
      this.analyser = null;
      this.audioStream = null;
      this.audioContext = null;
    }


  }


  createEchoDelayEffect(audioContext: AudioContext) {
    const delay = audioContext.createDelay(1);
    const dryNode = audioContext.createGain();
    const wetNode = audioContext.createGain();
    const mixer = audioContext.createGain();
    const filter = audioContext.createBiquadFilter();

    delay.delayTime.value = 0.75;
    dryNode.gain.value = 1;
    wetNode.gain.value = 0;
    filter.frequency.value = 1100;
    filter.type = "highpass";

    return {
      apply: function () {
        wetNode.gain.setValueAtTime(0.75, audioContext.currentTime);
      },
      discard: function () {
        wetNode.gain.setValueAtTime(0, audioContext.currentTime);
      },
      isApplied: function () {
        return wetNode.gain.value > 0;
      },
      placeBetween: function (inputNode: AudioNode, outputNode: AudioNode) {
        inputNode.connect(delay);
        delay.connect(wetNode);
        wetNode.connect(filter);
        filter.connect(delay);

        inputNode.connect(dryNode);
        dryNode.connect(mixer);
        wetNode.connect(mixer);
        mixer.connect(outputNode);
      },
    };
  }

  async fileUploader(newFile: File) {
    const fileApiRes = await FileApi.uploadFile(newFile)
    const fileId = fileApiRes?.file_list[0].file_id
    if (fileId) {
      this.dictationAnswerFile(fileId)
    }
  }

  @Emit('dictationAnswerFile')
  dictationAnswerFile(fileUrl: string) {
    return {uuid: this.uuid, fileUrl: fileUrl}
  }

  visualize() {
    if (!this.analyser) return;
    if (!this.canvasCtx) return;
    const WIDTH = this.refCanvas.width;
    const HEIGHT = this.refCanvas.height;
    this.analyser.fftSize = 1024 * 8;
    const bufferLength = this.analyser.fftSize;

    // We can use Float32Array instead of Uint8Array if we want higher precision
    // const dataArray = new Float32Array(bufferLength);
    const dataArray = new Uint8Array(bufferLength);

    this.canvasCtx.fillStyle = "rgb(240, 243, 255)";
    this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

    const draw = () => {
      if (!this.analyser) return;
      if (!this.canvasCtx) return;
      this.drawVisual = requestAnimationFrame(draw);

      this.analyser.getByteTimeDomainData(dataArray);

      this.canvasCtx.fillStyle = "rgb(240, 243, 255)";
      this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      this.canvasCtx.lineWidth = 4;
      this.canvasCtx.strokeStyle = "#888CE8";

      this.canvasCtx.beginPath();

      const sliceWidth = (WIDTH) / bufferLength;

      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        let v = dataArray[i] / 128.0;
        let y = (v * HEIGHT) / 2;

        if (i === 0) {
          this.canvasCtx.moveTo(x, y);
        } else {
          this.canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      this.canvasCtx.stroke();
    };

    draw();
  }

  subQuestionInput(e: Event) {
    this.quiz.subQuestion = (e.target as HTMLInputElement).value;
  }

  explainInput(e: Event) {
    this.quiz.explain = (e.target as HTMLTextAreaElement).value;
  }

  @Emit('myDictationAnswer')
  endPcmSendAnswer(msg: string) {
    console.log('==================================================')
    console.log('endPcmSend', msg)

    this.myDictationAnswer = msg;

    console.log('==================================================')
    return {uuid: this.uuid, myDictationAnswer: this.myDictationAnswer, optionIndex: this.optionIndex}
  }


  initWFPlayer() {

    if (this.wf === null) this.wf = new WFPlayer({
      container: this.refWaveform,

      // Media element like: video tag or audio tag
      mediaElement: this.audio,
      // Whether to use scroll mode
      waveColor: "#888CE8",

      // Background color
      backgroundColor: "#F0F3FF",

      // Whether to display cursor
      cursor: true,

      // Cursor color
      cursorColor: "#EC6D51",

      // Whether to display progress
      progress: true,

      // progress color
      progressColor: "#888CE8",

      // Whether to display grid
      grid: false,

      // Grid color

      // Whether to display ruler
      ruler: false,

      // Ruler color
      rulerColor: "rgba(255, 255, 255, 0.5)",

      // Whether to display ruler at the top
      wave: true,
      // Indicates whether to do http fetching with cookies
      waveSize: 1,
      // Waveform height scale ratio
      waveScale: 0.8,
      // Indicates whether to enable CORS for http fetching

      // Initialize http headers

      // Pixel ratio
      pixelRatio: window.devicePixelRatio,
      // Duration of rendering
      duration: 10,

    });
    if (this.audio.src.length > 0) this.wf.load(this.audio)
  }
}
