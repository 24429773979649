
import {Component, Emit, Vue} from 'vue-property-decorator'
import BackButton from '@/components/BackButton.vue'
import ClassTitle from '@/components/ClassTitle.vue'
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";

@Component({
  name: 'ClassContentHeaderView',
  components: {ClassTitle, BackButton}
})
export default class ClassContentHeaderView extends Vue {
  viewModel: null | ClassContentMediaListViewModel = null
  clickSettingArea() {
    this.openModal();
  }
  get contentKey() {
    return this.$route.params.contentKey
  }

  mounted() {
    this.viewModel = new ClassContentMediaListViewModel(this.contentKey);
  }

  @Emit('openModal')
  openModal() {
    return true
  }
}
