
import {Component, Ref, Vue, Watch} from 'vue-property-decorator'
import ClassContentView from '@/views/ClassPage/ClassContentView/ClassContentView.vue'
import ChatRoom from '@/views/ClassPage/ChatRoom/ChatRoom.vue'
import SpeakingPractice from '@/components/ui/speaking/SpeakingPractice.vue'
import '@/assets/style/font.css'
import Floating from "@/components/Floating.vue";
import Avatar from "@/components/ui/avatar/Avatar.vue";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import {
  ContentItemPronunciation,
  ContentItemScenario,
  ContentItemType,
  TimelineContent,
  TimelineType,
  UserClassContent
} from '@/Booker/lib/BookerTypes'
import CommonLoading from "@/components/ui/loading/CommonLoading.vue";
import {ChatBotPostMsg, ChatBotPostMsgType, CustomButtonEvent, DeviceDetail, EventType} from "@/assets/lib/type/Types";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import Chatbot from "@/assets/lib/utility/Chatbot";
import BookerUtility from '@/Booker/lib/util/BookerUtility'
import VirtualBackground from "@/assets/lib/media/VirtualBackground";
import VirtualBackgroundFactory from "@/assets/lib/media/VirtualBackgroundFactory";
import ObjectStorage from "@/assets/lib/utility/ObjectStorage";
import AiReportModal from "@/views/ClassPage/component/AiReportModal.vue";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import CheckContinueClass from "@/views/ClassPage/component/CheckContinueClass.vue";
import App from "@/assets/lib/controller/App";
import DeviceUtil from "@/assets/lib/utility/DeviceUtil";

declare global {
  interface Window {
    botpressWebChat: any;
  }
}

@Component({
  name: 'ClassPage',
  components: {
    CheckContinueClass,
    AiReportModal, CommonLoading, Avatar, ClassContentView, ChatRoom, SpeakingPractice, Floating
  },
})
export default class ClassPage extends Vue {

  @Ref() readonly chatRoom!: ChatRoom;
  @Ref() refVideo!: HTMLVideoElement;

  classContentPlayerViewModel: ClassContentPlayerViewModel | null = null
  ContentItemType = ContentItemType
  ClassContentManager = ClassContentManager

  isSmallTabletSize = window.innerWidth <= 768;
  isMiddleTabletSize = window.innerWidth >= 768 && window.innerWidth <= 1280;

  chatRoomModal = false
  chatRoomToggle = this.isSmallTabletSize;
  defaultFloat = false
  isLoadedAvartar = false
  //임시 말하기 실습 클릭 (추후 지울것)
  showSpeaking = false

  timelinePronunciation: ContentItemPronunciation | null = null;
  pronunciationMap: Map<string, ContentItemPronunciation> | null = null;
  BookerUtility = BookerUtility
  TimelineType = TimelineType

  camStatus = true
  camLoading = false
  videoMap: Map<string, MediaDeviceInfo> = new Map();
  selectedCam: DeviceDetail = {id: '0', label: '카메라 미 선택', info: null};
  selectedMic: DeviceDetail = {id: '0', label: '마이크 미 선택', info: null};
  stream: null | MediaStream = null;
  oldStream: MediaStream[] = [];
  selectedFilter = 0;
  virtualBackground: VirtualBackground = VirtualBackgroundFactory.virtualBackground

  modalStatus = false;
  aiReportModel: AiReportViewModel | null = null;

  get nowTimelineIndex() {
    if (this.classContentPlayerViewModel)
      return this.classContentPlayerViewModel.nowIdx
    return -1
  }

  get isPlaying() {
    if (this.classContentPlayerViewModel)
      return this.classContentPlayerViewModel.seekbar.isPlaying
    return false
  }

  get activeContent() {
    return ClassContentManager.controller.model.activeUserContent
  }

  get contentKey() {
    return this.$route.params.contentKey
  }

  get isEnd() {
    return this.classContentPlayerViewModel?.seekbar.isEnd
  }

  get pronunciationList() {
    if (this.classContentPlayerViewModel)
      return this.classContentPlayerViewModel.timeline.getAllTimelineContents().filter(content => content.type === ContentItemType.Pronunciation)
    return [];
  }

  get isPronunciation() {
    if (!this.pronunciationMap) return false
    if (this.timelinePronunciation) return true;
    const findProCondition = (tl: TimelineContent) => {
      return this.classContentPlayerViewModel
        && tl.start < this.classContentPlayerViewModel.seekbar.currentTime
        && this.pronunciationMap?.has(tl.content.key)
    }

    const pro = this.pronunciationList.find(tl => findProCondition(tl))
    if (pro) {
      BookerUtility.playSound();
      this.timelinePronunciation = pro.content as ContentItemPronunciation
      this.classContentPlayerViewModel?.seekbar.pause()
      return true;
    }
    return false
  }

  @Watch('isEnd')
  onChangeIsEnd() {
    if (this.isEnd && this.aiReportModel === null) {
      this.initAiReportViewModel();
    }
  }

  async initAiReportViewModel() {

    if (!this.activeContent) return;
    if (!this.classContentPlayerViewModel) return;

    this.activeContent.isComplete = true;
    this.activeContent.playTime = this.classContentPlayerViewModel.seekbar.currentTime;
    await ClassContentManager.controller.userClassUpdate();
    await ClassContentManager.controller.model.userInit();

    this.aiReportModel = new AiReportViewModel();
    this.aiReportModel.createTotalUserContent().then(() => {
      if (this.activeContent && this.activeContent.classStep && this.aiReportModel) {
        this.aiReportModel.selectedEvaluationClassStep = this.activeContent.classStep
        this.aiReportModel.selectedQuizClassStep = this.activeContent.classStep
        this.aiReportModel.nowClassStep = this.activeContent.classStep
        this.aiReportModel.userContentSet(this.activeContent);
      }
      this.modalStatus = true;
    })
  }

  @Watch('nowTimelineIndex')
  onChangeTimelineIndex() {
    if (this.nowTimelineIndex > -1) {
      ClassContentManager.controller.userClassUpdate()
    }
  }

  async mounted() {

    this.selectedMic = DeviceUtil.selectedMic;
    this.selectedCam = DeviceUtil.selectedCam;
    this.selectedFilter = DeviceUtil.selectedFilter;

    this.classContentPlayerViewModel = new ClassContentPlayerViewModel(this.contentKey);
    let isFirst = false;
    ClassContentManager.controller.model.activeUserContent = null;
    this.classContentPlayerViewModel?.seekbar.setCurrenTime(0);
    setTimeout(() => {
      this.clearMsg();
    }, 1000);
    const userContent = ClassContentManager.controller.model.getUserContent(this.contentKey);
    if (userContent) ClassContentManager.controller.setActiveUserContent(userContent)
    if (!userContent) {
      isFirst = true;
      await ClassContentManager.controller.userClassAdd(this.contentKey)
      await ClassContentManager.controller.model.userInit();
      const userContent = ClassContentManager.controller.model.getUserContent(this.contentKey);
      if (userContent && userContent.isRelease) {
        ClassContentManager.controller.setActiveUserContent(userContent)
      } else if (App.controller.auth.isTeacherLogin) {
        const teacherContent = ClassContentManager.controller.model.getAllContent(this.contentKey)
        if (teacherContent) {
          const userContentTmp: UserClassContent = {
            ...teacherContent,
            isComplete: false,
            isRelease: false,
            userClassInfoIdx: 0,
            playTime: 0,
            totalTime: 0,
            score: 0,
            classStep: teacherContent?.classStep || 1,
            createdAt: Math.floor(new Date().getTime() / 1000),
            updatedAt: Math.floor(new Date().getTime() / 1000),
          }
          ClassContentManager.controller.setActiveUserContent(userContentTmp);
          this.classContentPlayerViewModel?.seekbar.setCurrenTime(0);
        }
      }
    }

    if (this.activeContent && !isFirst) {
      if (this.activeContent.isComplete) {
        const check = confirm('수업을 다시 들으시겠습니까?')
        if (check) {
          this.activeContent.playTime = 0;
          this.classContentPlayerViewModel?.seekbar.setCurrenTime(0)
        } else this.classContentPlayerViewModel?.seekbar.setCurrenTime(this.activeContent.totalTime)
      } else {
        if (this.activeContent.playTime > 0) {
          const doubleCheck = confirm('수업을 이어서 들으시겠습니까?')
          if (!doubleCheck) {
            this.activeContent.playTime = 0;
            this.classContentPlayerViewModel?.seekbar.setCurrenTime(0)
          } else {
            this.classContentPlayerViewModel?.seekbar.setCurrenTime(this.activeContent.playTime)
          }
        }
      }
    }

    Chatbot.addChatBotEventListener(this.handleChatEvent)
    this.pronunciationMap = new Map<string, ContentItemPronunciation>()
    this.pronunciationList.forEach((pro) => {
      if (this.pronunciationMap)
        this.pronunciationMap.set(pro.content.key, pro.content as ContentItemPronunciation)
    })

    this.initMedea();

  }

  initMedea() {
    this.updateDeviceList();

    this.getMedia();
    navigator.mediaDevices.ondevicechange = () => this.updateDeviceList()

    const selectedFilterLocalStorage = new ObjectStorage<number>('selectedFilter', this.selectedFilter)
    this.selectedFilter = selectedFilterLocalStorage.value;
  }

  beforeDestroy() {
    console.log('beforeDestroy')

    if (this.classContentPlayerViewModel) {
      this.classContentPlayerViewModel.destroy();
    }
    Chatbot.removeChatBotEventListener(this.handleChatEvent)
    this.removeTracks();
  }

  removeTracks() {
    navigator.mediaDevices.ondevicechange = () => null;
    this.stream?.getTracks().forEach((t) => t.stop());
    this.stream = null;
    this.refVideo.srcObject = null;
    this.virtualBackground.destroyed()

    if (this.oldStream.length > 0) {
      for (const stream of this.oldStream) {
        stream.getTracks().forEach(t => t.stop())
      }
    }
  }

  handleChatEvent(message: MessageEvent) {

    const customButtonEvent: CustomButtonEvent = message.data
    const model = this.classContentPlayerViewModel;
    switch (message.data.eventType) {
      case EventType.Pronunciation:
        if (model) {
          if (!model.checkAlreadySend(customButtonEvent.value.contentKey)) {
            const pro = model.timeline.getTimelineContents(TimelineType.Scenario).find(tl => {
              const actionContent = (tl.content as ContentItemScenario).actionContent
              if (actionContent) {
                return actionContent[0].key === customButtonEvent.value.contentKey
              }
              return false
            });
            if (pro) {
              const proItem = (pro.content as ContentItemScenario).actionContent
              if (proItem && proItem[0].type === ContentItemType.Pronunciation) {
                BookerUtility.playSound();
                this.timelinePronunciation = proItem[0] as ContentItemPronunciation
                model.saveSend(customButtonEvent.value.contentKey);
              }
            }
            this.classContentPlayerViewModel?.seekbar.pause()
          }
        }
        break;
    }
  }

  playerPause() {
    this.classContentPlayerViewModel?.seekbar.pause()
  }

  playerStart() {
    // this.classContentPlayerViewModel?.seekbar.play()
  }

  clearMsg() {
    const chatBotPostMsg: ChatBotPostMsg = {
      chatBotPostMsgType: ChatBotPostMsgType.Clear,
      data: ''
    }
    Chatbot.sendChatBot(chatBotPostMsg)
  }

  endPronunciation() {
    if (this.timelinePronunciation) this.pronunciationMap?.delete(this.timelinePronunciation.key)
    this.timelinePronunciation = null;
    this.classContentPlayerViewModel?.seekbar.play();
  }

  speakingResultFile(EmitData: { key: string, fileUrl: string }) {
    const {key, fileUrl} = EmitData
    if (this.pronunciationMap && this.timelinePronunciation && this.timelinePronunciation.key === key) {
      this.timelinePronunciation.fileUrl = fileUrl;
      const findIdxInTimeline = this.pronunciationList.findIndex((pro) => pro.content.key === key)
      if (findIdxInTimeline > -1) {
        this.pronunciationList[findIdxInTimeline].content = this.timelinePronunciation
        ClassContentManager.controller.changeTimelineContent(this.contentKey, this.pronunciationList[findIdxInTimeline])
        ClassContentManager.controller.userClassUpdate()
        return;
      }

      const model = this.classContentPlayerViewModel;
      if (!model) return;

      const pro = model.timeline.getTimelineContents(TimelineType.Scenario).find(tl => {
        const actionContent = (tl.content as ContentItemScenario).actionContent
        if (actionContent) {
          return actionContent[0].key === key
        }
        return false
      });
      if (pro) {
        ClassContentManager.controller.changeTimelineContent(key, pro)
        ClassContentManager.controller.userClassUpdate()
      }

    }
  }

  loadingAvatar() {
    this.isLoadedAvartar = true;
  }

  foldToggle() {
    if (this.isSmallTabletSize) return;
    this.chatRoomToggle = !this.chatRoomToggle
    if (!this.chatRoomToggle) {
      this.defaultFloat = false
      this.closeModal();

    } else {
      this.defaultFloat = true

    }

    // this.$nextTick(() => {
    //   const rightToolButton = document.querySelector('.right-tool button');
    //   if (rightToolButton) {
    //     rightToolButton.classList.add('move');
    //   }
    // });


  }

  showModal() {
    this.chatRoomModal = true;
  }

  closeModal() {
    this.chatRoomModal = false;
  }

  viewSpeaking() {
    this.showSpeaking = true;
  }

  screenFull() {
    this.foldToggle();
  }

  updateDeviceList() {
    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        //this.audioMap = new Map();
        this.videoMap = new Map();

        devices.forEach((device) => {
          if (device.kind === "videoinput") {
            this.videoMap.set(device.deviceId, device);
          }
        });
      });
  }

  async getMedia() {
    await this.getStream()
    if (this.camStatus) await this.adaptStream();
  }

  async getStream() {
    const video: true | { deviceId: string } = this.selectedCam.id == "0" ? true : {deviceId: this.selectedCam.id};
    const audio: true | { deviceId: string } = this.selectedMic.id == "0" ? true : {deviceId: this.selectedMic.id};

    const stream = await navigator.mediaDevices
      .getUserMedia({video: video, audio: audio});
    if (stream.id == this.stream?.id) return;
    this.stream = stream;
    this.oldStream.push(this.stream)

    this.updateDeviceList();
  }

  adaptStream() {

    if (!this.refVideo) return;
    if (this.refVideo.srcObject && this.refVideo.srcObject === this.stream && this.selectedFilter === 0) return

    try {
      this.camLoading = true
      if (this.selectedFilter != 0) {
        this.virtualBackground.stream = this.stream;
        this.virtualBackground.setBackground(this.selectedFilter)
        this.refVideo.srcObject = this.virtualBackground.virtualStream;
      } else {
        this.virtualBackground.destroyed();
        this.refVideo.srcObject = this.stream;
      }
      if (this.refVideo?.paused) this.refVideo.onloadeddata = () => {
        this.refVideo?.play();
        this.refVideo.muted = true;
        this.camLoading = false;
      }
    } catch (e) {
      alert("카메라를 실행할 수 없습니다.")
      console.error(e)
      this.camLoading = false;
    }
  }


  receiveResult() {
    this.modalStatus = false;
    this.$router.push('/main');
    return this.modalStatus
  }

}
