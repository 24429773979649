
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import ElementDragHandler from "@/assets/lib/dom/ElementDragHandler";
import {ContentItemType, DraggingObjectType, TimelineContent} from "@/Booker/lib/BookerTypes";
import DraggingItemModel from "@/Booker/lib/model/DraggingItemModel";
import {PT} from "@/assets/lib/dom/DomTypes";

@Component({name: "", components: {}})
export default class ScenarioTimelineItem extends Vue {
  @Ref() refDragView!: HTMLDivElement;
  @Prop() scenarioTitle!: string;
  @Prop() scenarioTime!: number;
  @Prop() scenarioText!: string
  @Prop() elementDragHandler!: ElementDragHandler;
  @Prop() timelineSize!: number;
  @Prop() itemIndex!: number;
  @Prop() timelineContent!: TimelineContent;

  deleteStatus = true
  scenarioIndex = 0
  scenarioStatus = false
  ContentItemType = ContentItemType

  /**
   * PreviewSeekBar 상태 변경
   */
  @Emit('setPreSeekBar')
  setPreSeekBar(isPreSeekBar: boolean) {
    return isPreSeekBar;
  }

  /**
   * timelineContent 시간 변경
   */
  @Emit('timelineContentTimeChange')
  timelineContentTimeChange(contentItemType: ContentItemType, itemIndex: number, endTime: number) {
    return {contentItemType, itemIndex, endTime};
  }


  /**
   * 시나리오 Drag Reposition
   */

  dragTimelineItem(offset: null | PT, pt?: PT) {
    if (!offset) {
      this.repositionMouseUp()
      return;
    }

    if (offset.x === 0 && offset.y === 0) {
      this.repositionMouseDown();
      return;
    }

    this.repositionMouseMove();
  }

  startDrag(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.elementDragHandler.mouseDown(event, this.dragTimelineItem)
  }


  @Emit('endRepositionTimeline')
  repositionMouseUp() {
    this.$el.classList.remove('drag');
    this.deleteStatus = true
  }

  repositionMouseDown() {
    DraggingItemModel.setItem(DraggingObjectType.TimelineContent, this.timelineContent);
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');
    this.deleteStatus = false
  }

  repositionMouseMove() {
    if (!this.$el.classList.contains('drag')) this.$el.classList.add('drag');
  }

  /**
   * scenario 클릭시 focus
   * @param e
   */

  scenarioAddFocus(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.$el.classList.contains('focus')) {
      this.$el.classList.add('focus');
    } else {
      this.$el.classList.remove('focus');
    }
  }

  formatCreatTime() {
    const seconds = Math.floor(this.timelineContent.start / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const scenarioTime = `${minutes.toString().padStart(2, '0')}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds.toString()}`;

    return scenarioTime
  }

  /**
   * timelineContent 삭제
   */
  @Emit('delTimelineItem')
  delTimelineItem() {
    return this.timelineContent;
  }


}
