import {ContentItemBound, ContentItemText} from "@/Booker/lib/BookerTypes";

export type TextOverlayAlign = 'left' | 'center' | 'right'

export default class ContentItemSubtitleEditorViewModel {

  textItem: ContentItemText
  textSize = [20, 40, 60, 80, 100]
  textColor = ['#FFA62C', '#2E3542', '#FF508B', '#F9A825', '#29B9B6', '#0A99FF', '#8873F3']
  backgroundColor = ['gray', '#ddd', '#fff', 'rgba(0,0,0,0)', 'red', 'blue']
  fontFamily = ['NanumSquareRoundOTF', 'monospace', 'sans-serif', 'cursive']

  constructor(textItem: ContentItemText) {
    this.textItem = textItem;
  }

  increaseSize() {
    if (this.textItem.size >= 100) return;
    this.textItem.size += 20;
  }

  decreaseSize() {
    if (this.textItem.size <= 20) return
    this.textItem.size -= 20;
  }


  changeColor(index: number) {
    this.textItem.color = this.textColor[index];
  }

  changeSize(index: number) {
    this.textItem.size = this.textSize[index];
  }

  changeBackgroundColor(index: number) {
    this.textItem.background = this.backgroundColor[index];
  }

  changeFont(index: number) {
    this.textItem.font = this.fontFamily[index];
  }

  changeAlign(align: TextOverlayAlign) {
    this.textItem.align = align;
  }

  changeToTop() {
    const topBound: ContentItemBound = {
      left: 50 + '%',
      top: 10 + '%',
      width: 400 + 'px',
      height: 100 + 'px',
      transform: `translateX(-50%)`,
    }
    this.textItem.bound = topBound;
  }

  changeToMiddle() {
    const middleBound: ContentItemBound = {
      left: 50 + '%',
      top: 50 + '%',
      transform: `translate(-50%, -50%)`,
      width: 400 + 'px',
      height: 100 + 'px',
    }
    this.textItem.bound = middleBound;
  }

  changeToBottom() {
    const bottomBound: ContentItemBound = {
      left: 50 + '%',
      bottom: 10 + '%',
      transform: `translateX(-50%)`,
      width: 400 + 'px',
      height: 100 + 'px',
    }
    this.textItem.bound = bottomBound;
  }


}
