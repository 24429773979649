
import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from '@/components/ui/Card.vue'
import ClassContentListViewModel from "@/Booker/lib/viewModel/ClassContentListViewModel";
import ClassContentManager from "@/Booker/lib/manager/ClassContentManager";
import {UserClassContent} from "@/Booker/lib/BookerTypes";
import {ChatBotPostMsg, ChatBotPostMsgType} from "@/assets/lib/type/Types";
import Chatbot from "@/assets/lib/utility/Chatbot";

@Component({
  components: {Card}
})
export default class CardView extends Vue {
  @Prop() title !: string
  @Prop() viewModel !: ClassContentListViewModel
  selectedIdx = 0;

  timer = 0;
  AICardTitleData = [
    {title: '인공지능이란 무엇일까요?', img: require(`@/assets/images/cards/what_is_ai.png`), key: "", classStep: 1, percent: 0},
    {
      title: '인공지능은 어떻게 학습할까요?',
      img: require(`@/assets/images/cards/how_learn_ai.png`),
      key: "",
      classStep: 2,
      percent: 0
    },
    {
      title: '처음 만나는 AI Codiny',
      img: require(`@/assets/images/cards/first_meet_ai.png`),
      key: "",
      classStep: 3,
      percent: 0
    },
  ]

  get userContentList() {
    return ClassContentManager.controller.model.userClassList
  }

  get cardLists() {
    if (this.title === '국어 말하기') return this.releaseClassList
    return this.AICardTitleData
  }

  get releaseClassList() {
    const list: { title: string; img: string | undefined; key: string; classStep: number; percent: number; }[] = [];
    this.viewModel.releaseClassList.sort((a, b) => a.content.classStep! > b.content.classStep! ? 1 : -1).forEach((content, idx) => {
      const userContent = this.userContentList.find(u => u.classStep === content.content.classStep && u.key === content.content.key);
      let percent = 0;
      if (userContent) {
        percent = this.contentPercent(userContent)
      }
      list.push({
        title: content.content.title,
        img: content.content.thumbnailUrl,
        classStep: content.content.classStep!,
        key: content.content.key,
        percent: percent
      })
    })

    return list
  }

  contentPercent(userClassContent?: UserClassContent) {
    if (!userClassContent) return 0;
    if (userClassContent.isComplete) return 100;
    const percent = Math.ceil(((userClassContent.playTime + 1) / userClassContent.totalTime) * 100)
    return percent > 100 ? 100 : percent
  }

  isViewCard(cardIdx: number) {
    if (this.selectedIdx === 0) {
      return cardIdx <= 2 && cardIdx >= 0
    } else if (this.selectedIdx === this.cardLists.length - 1) {
      return cardIdx <= this.selectedIdx && cardIdx >= this.selectedIdx - 2
    }
    return cardIdx <= this.selectedIdx + 1 && cardIdx >= this.selectedIdx - 1
  }

  clearMsg() {
    const chatBotPostMsg: ChatBotPostMsg = {
      chatBotPostMsgType: ChatBotPostMsgType.Clear,
      data: ''
    }
    console.log('chatBotPostMsg', chatBotPostMsg)
    Chatbot.sendChatBot(chatBotPostMsg)
  }

  async setSelectIdx(EmitData: { selectIdx: number, title: string }) {
    const {selectIdx, title} = EmitData;
    this.selectedIdx = selectIdx;
    this.clearTimer();
    this.clearMsg();
    const findIdx = this.cardLists.findIndex(c => c.classStep === selectIdx)
    switch (title) {
      case '인공지능이란 무엇일까요?':
        window.open('https://aicodiny.com/tut/1', '_blank')
        break;
      case '인공지능은 어떻게 학습할까요?':
        window.open('https://aicodiny.com/tut/2', '_blank')
        break;
      case '처음 만나는 AI Codiny':
        window.open('https://aicodiny.com/tut/3', '_blank')
        break;
      default:

        await this.$router.push('/class/' + this.cardLists[findIdx].key)
        break;
    }
  }

  increaseSelectIdx() {
    this.clearTimer()
    this.selectedIdx++;
  }

  decreaseSelectIdx() {
    this.clearTimer()
    this.selectedIdx--;
  }

  mounted() {
    this.timer = this.setTimer();
  }

  setTimer() {
    return setInterval(() => {
      this.selectedIdx < this.cardLists.length - 1
        ? this.selectedIdx++
        : this.selectedIdx = 0
    }, 10 * 1000)
  }

  clearTimer() {
    if (this.timer) clearInterval(this.timer);
    this.timer = this.setTimer();
  }

  setSelectCard = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
  }


}

