import AppController from "@/assets/lib/controller/AppController";

class App {
  readonly OAuthClientId = 'e8500c4bf38b876fd38a0da1eb9ad4e4393fb7904cd3a3b6338c95ffa9b94c47'
  readonly OAuthClientSecret = '1b6c9121ee173be1d8da0477bd202f3c95be5c2c4df87e4159a881ace6fe4104'

  controller = new AppController()
}

export default new App()
