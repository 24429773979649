import {PT} from "./DomTypes";

export default class DomHelper {
  static getMouseDownPtOfElement(evt: MouseEvent | TouchEvent, elem: HTMLElement) {
    const pt = this.getMouseDownPt(evt)
    const rect = elem.getBoundingClientRect()
    pt.x -= rect.x
    pt.y -= rect.y
    return pt
  }
  static getMouseDownPtOfElement2(pt: PT, elem: HTMLElement) {
    const rect = elem.getBoundingClientRect()
    pt.x -= rect.x
    pt.y -= rect.y
    return pt
  }

  static getMouseDownPt(evt: MouseEvent | TouchEvent) {
    // if(evt instanceof TouchEvent) { // safari 에서 지원되지 않음
    // @ts-ignore
    if(evt.touches) {
      // @ts-ignore
      return {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
    } else if(evt instanceof MouseEvent) {
      return {x: evt.clientX, y: evt.clientY};
    } else {
      return {x: 0, y: 0}
    }
  }
}
