
import {Component, Emit, Prop, Ref, Vue} from "vue-property-decorator";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import ContentItemScenarioViewModel from "@/Booker/lib/viewModel/classContent/ContentItemScenarioViewModel"
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import ScenarioActivity from "@/Booker/views/components/scenario/ScenarioActivity.vue";
import CommonButton from "@/Booker/views/components/common/CommonButton.vue";
import {
  ActionContentType,
  ContentItemScenarioType,
  ContentItemType,
  ContentToolStateType
} from "@/Booker/lib/BookerTypes";
import ScenarioGuideModal from "@/Booker/views/components/scenario/ScenarioGuideModal.vue";

@Component({
  name: "",
  components: {ScenarioGuideModal, CommonButton, ScenarioActivity, CommonInput}
})
export default class CreateScenario extends Vue {

  scenarioViewModel: ContentItemScenarioViewModel | null = null

  @Prop() model!: ClassContentMediaListViewModel
  @Prop() contentKey!: string
  @Ref() refOption!: HTMLLIElement

  isSaveAble = false
  actionIndex = 0

  isSave = false
  selectStatus = false
  activityOption = false
  selectedOption = ''
  msg = ''
  option = '테스트'
  ContentItemType = ContentItemType;
  ContentItemScenarioType = ContentItemScenarioType;
  ActionContentType = ActionContentType;
  guideTooltip = true

  get scenarioType() {
    return this.scenarioViewModel?.scenario.scenarioType
  }

  get actionContent() {
    return this.scenarioViewModel?.scenario.actionContent
  }


  init() {
    this.scenarioViewModel = new ContentItemScenarioViewModel(this.model.createScenarioTemplate(), this.model)
  }

  created() {
    this.init()
  }

  mounted() {
    if (!this.scenarioViewModel) this.init()

    setTimeout(()=> {
      this.guideTooltip = false
    }, 3000)

  }

  onInputTitle(e: Event) {
    this.scenarioViewModel!.scenario.title = (e.target as HTMLInputElement).value;
    //this.scenarioViewModel?.saveScenario();
    this.checkSave();
  }


  addChatBot() {
    this.scenarioViewModel?.changeScenarioType(ContentItemScenarioType.OpenContentItem);
  }

  addDictionary() {
    this.scenarioViewModel?.changeScenarioType(ContentItemScenarioType.WordDictionary)
  }

  addGenieInside() {
    this.scenarioViewModel?.changeScenarioType(ContentItemScenarioType.GenieInside)
  }

  @Emit('setToolState')
  setToolState(state: ContentToolStateType) {
    return state;
  }

  onInputMessage(e: Event) {
    this.scenarioViewModel!.setMessage((e.target as HTMLTextAreaElement).value);
    this.checkSave();
  }

  deleteActionContent() {
    this.scenarioViewModel?.deleteActionContent();
  }


  /**
   * 저장 가능상태인지 확인
   */

  isSaveScenario() {
    if (!this.scenarioViewModel?.scenario.title) return false;
    if (this.scenarioViewModel?.scenario.title.length < 2 || this.scenarioViewModel?.scenario.message.length < 2) return false;
    return true;
  }

  checkSave() {
    this.isSaveAble = this.isSaveScenario()
  }

  saveScenario() {
    if (this.model.addScenarioLibrary(this.scenarioViewModel!.scenario)) {
      this.model.save();
      this.scenarioViewModel?.saveScenario();
      this.setToolState(ContentToolStateType.Default);
      return;
    }
    alert('저장에 실패하였습니다. 상태를 확인해주세요.');
  }


  // 인터랙티브 가이드 모달
  modalStatus = false
  showGuide() {
    this.modalStatus = true
  }
  closeModal() {
    this.modalStatus = false
  }
 showTooltip() {
   this.guideTooltip = true
 }
  hideTooltip() {
    this.guideTooltip = false
  }



}
