
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {TimelineContent, TimelineType} from "@/Booker/lib/BookerTypes";
import ClassContentPlayerViewModel from "@/Booker/lib/viewModel/ClassContentPlayerViewModel";
import DomHelper from "@/assets/lib/dom/DomHelper";

@Component({name: "ClassContentVideoTool", components: {}})
export default class ClassContentVideoTool extends Vue {
  @Prop() playStatus!: boolean;
  @Prop() model!: ClassContentPlayerViewModel;
  @Prop() moveButton !: boolean;
  TimelineType = TimelineType
  timeline: TimelineContent[] = [];

  timelineStatus = false
  fullStatus = true
  volume = 50;
  isLoading = false;
  btnFullStatus = false

  get InputBackgroundColor() {
    return {
      backgroundSize: `${this.model.seekbar.currentTime / this.model.seekbar.length * 100}% 100%`,
      backgroundColor: this.model.seekbar.currentTime === 0 ? `rgba(231, 243, 255, 0.1)` : 'rgba(82, 172, 237, 0.2)'
    }
  }

  get volumeInputBackgroundColor() {
    return {
      backgroundSize: `${this.model.volume}% 100%`,
      backgroundColor: this.model.volume === 0 ? `rgba(231, 243, 255, 0.1)` : 'rgba(82, 172, 237, 0.2)'
    }
  }

  created() {
    this.isLoading = false;
  }

  mounted() {
    console.log('mounted')
    this.volume = this.model.getVolume;
    this.timeline = [...this.model.timeline.getTimelineContents(TimelineType.Video).sort((a, b) => a.start - b.start)];
  }

  getDurationHHMMSS(time: number) {
    let sec = Math.floor(time / 1000)
    let min = Math.floor(sec / 60)
    let hour = Math.floor(min / 60)

    if (min >= 60) {
      min = min % 60
    }

    if (sec >= 60) {
      sec = sec % 60
    }

    const timeWithZero = (time: number) => {
      if (time >= 10) return `${time}`
      return `0${time}`
    }

    if (timeWithZero(hour) === '00') {
      return `${timeWithZero(min)}:${timeWithZero(sec)}`
    }

    return `${timeWithZero(hour)}:${timeWithZero(min)}:${timeWithZero(sec)}`
  }

  volumeMouseDown() {

    window.addEventListener('mousemove', this.volumeMouseMove);
    window.addEventListener('mouseup', this.volumeMouseUp);
    window.addEventListener('touchmove', this.volumeMouseMove);
    window.addEventListener('touchend', this.volumeMouseUp);
    window.addEventListener('touchcancel', this.volumeMouseUp);
  }

  volumeMouseUp() {

    window.removeEventListener('mousemove', this.volumeMouseMove);
    window.removeEventListener('mouseup', this.volumeMouseUp);
    window.removeEventListener('touchmove', this.volumeMouseMove);
    window.removeEventListener('touchend', this.volumeMouseUp);
    window.removeEventListener('touchcancel', this.volumeMouseUp);
  }

  volumeMouseMove(evt: MouseEvent | TouchEvent) {
    const target = evt.target as HTMLInputElement;
    const pointX = DomHelper.getMouseDownPt(evt).x;
    const value = (pointX - target.getBoundingClientRect().x) / target.getBoundingClientRect().width;
    const percent = Math.floor(value * 100);
    if (percent < 1) this.model.setVolume(0)
    else if (percent > 99) this.model.setVolume(100)
    else this.model.setVolume(percent)
  }


  playerSeekBarMouseDown() {
    this.model.seekbar.pause()
    window.addEventListener('mousemove', this.playerSeekBarMouseMove);
    window.addEventListener('mouseup', this.playerSeekBarMouseUp);
    window.addEventListener('touchmove', this.playerSeekBarMouseMove);
    window.addEventListener('touchend', this.playerSeekBarMouseUp);
    window.addEventListener('touchcancel', this.playerSeekBarMouseUp);
  }

  playerSeekBarMouseMove(evt: MouseEvent | TouchEvent) {
    const target = evt.target as HTMLInputElement;
    const pointX = DomHelper.getMouseDownPt(evt).x;
    const value = (pointX - target.getBoundingClientRect().x) / target.getBoundingClientRect().width;
    if (value < 0) this.model.seekbar.setCurrenTime(0)
    else if (value > this.model.seekbar.length) this.model.seekbar.setCurrenTime(this.model.seekbar.length)
    else this.model.seekbar.setCurrenTime(value * this.model.seekbar.length)
  }

  playerSeekBarMouseUp() {
    this.model.seekbar.play();
    window.removeEventListener('mousemove', this.playerSeekBarMouseMove);
    window.removeEventListener('mouseup', this.playerSeekBarMouseUp);
    window.removeEventListener('touchmove', this.playerSeekBarMouseMove);
    window.removeEventListener('touchend', this.playerSeekBarMouseUp);
    window.removeEventListener('touchcancel', this.playerSeekBarMouseUp);
  }


  /**
   * 풀스크린
   */
  @Emit('changeFull')
  changeFull() {
    this.changeScreenFull();
    return;
  }

  @Emit('moveBtnFull')
  moveBtnFull() {
    return this.btnFullStatus;
  }

  /**
   * 타임라인 텍스트 클릭
   * @param startTime
   */
  @Emit('goTimelineStartTime')
  focusList(startTime: number) {
    return startTime
  }

  /**
   * 재생 or 멈춤
   */
  @Emit('videoPlay')
  videoPlay() {
    //
  }

  goPreTimelineIdx() {
    const index = this.model.findTimelineIndexByStartTime(this.model.seekbar.currentTime - 600)
    if (index < 0) return;
    this.model.seekbar.setCurrenTime(this.timeline[index].start)
  }

  goNextTimelineIdx() {
    const index = this.model.findTimelineIndexByStartTime(this.model.seekbar.currentTime + 1)
    if (index === this.timeline.length - 1) return;
    this.model.seekbar.setCurrenTime(this.timeline[index + 1].start)
  }

  changeVolume() {
    if (this.model.volume >= 1) {
      this.model.setVolume(0)
    } else {
      this.model.setVolume(100)
    }
  }

  showTimeline() {
    this.timelineStatus = !this.timelineStatus
  }


  changeScreenFull() {
    if (this.fullStatus) {
      document.documentElement.requestFullscreen()
    } else {
      document.exitFullscreen()
    }
    this.fullStatus = !this.fullStatus
  }


}
