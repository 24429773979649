
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {QuizItem} from "@/Booker/lib/BookerTypes";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import CommonTextArea from "@/Booker/views/components/common/CommonTextArea.vue";
import CommonPassageExplain from "@/Booker/views/components/common/CommonPassageExplain.vue";
import CommonPassageExplainBtnWrapper from "@/Booker/views/components/common/CommonPassageExplainBtnWrapper.vue";

@Component({
  name: "QuizStartSelect",
  components: {
    CommonPassageExplainBtnWrapper,
    CommonPassageExplain, CommonTextArea
  }
})
export default class QuizStartSelect extends Vue {
  @Prop() quiz!: QuizItem
  @Prop() index!: number
  @Prop() imgItem!: string
  @Prop() quizOnSubmit!: boolean
  @Prop() isSubmit !: boolean
  @Prop() subQuestionItem!: string
  @Prop() commonTextAreaReadOnly !: boolean

  BookerUtility = BookerUtility;

  @Emit('zoomImg')
  zoomImg() {
    return this.imgItem
  }

  selectClassObject(optionIndex: number) {
    const options = this.quiz.options;
    if (!options || options.length < optionIndex + 1) return;
    const option = options[optionIndex];
    console.log('option', option)
    return {
      'check': option.userAnswer === 1,
      'submit': this.quizOnSubmit,
      'correct': this.quizOnSubmit && option.answer === 1,
      'incorrect': this.quizOnSubmit && !option.isCorrect
    }
  }


  submitSelect(optionIndex: number) {
    if (this.quizOnSubmit) return;
    const options = this.quiz.options;
    if (!options || options.length < optionIndex + 1) return;
    options.forEach((option) => {
      option.userAnswer = 0;
    });
    options[optionIndex].userAnswer = 1;
    this.quiz.options = [...options];
    this.updateInputAnswer();
  }

  @Emit('updateInputAnswer')
  updateInputAnswer() {
    return this.quiz;
  }

  subQuestionInput(e: Event) {
    this.quiz.subQuestion = (e.target as HTMLInputElement).value;
  }

  explainInput(e: Event) {
    this.quiz.explain = (e.target as HTMLTextAreaElement).value;
  }
}
