import {MouseButtonType, PT} from "./DomTypes";
import DomHelper from "./DomHelper";

export default class ElementDragHandler {
  private readonly mouseMoveHandler: ((evt: MouseEvent | TouchEvent) => void);
  private readonly mouseUpHandler: ((evt: MouseEvent | TouchEvent) => void);

  mouseDownPt: null | PT = null;
  offset: PT = {x: 0, y: 0};
  savedPT: PT = {x: 0, y: 0};

  dragging = false;

  private dragMoveEventHandler: null | ((offset: null | PT, pt?: PT) => void) = null
  private ptConverter: null | ((pt: PT) => PT) = null

  constructor() {
    this.mouseMoveHandler = this.onMouseMove.bind(this);
    this.mouseUpHandler = this.onMouseUp.bind(this);
  }

  savePT(pt: PT) {
    this.savedPT = pt;
  }

  setPtConverter(ptConverter : null | ((pt: PT) => PT)){
    this.ptConverter = ptConverter
  }

  usePtConverter (pt: PT) : PT{
    return this.ptConverter!(pt)
  }


  mouseDown(evt: MouseEvent | TouchEvent, dragMoveEventHandler: null | ((offset: null | PT, pt?: PT) => void) = null, ptConverter: null | ((pt: PT) => PT) = null) {
    if(ptConverter) this.ptConverter = ptConverter
    this.dragMoveEventHandler = dragMoveEventHandler
    this.mouseDownPt = DomHelper.getMouseDownPt(evt)
    if(this.ptConverter) this.mouseDownPt = this.ptConverter(this.mouseDownPt)

    this.offset = {x: 0, y: 0}
    this.dragMoveEventHandler?.(this.offset, this.mouseDownPt)

    // if(evt instanceof TouchEvent) { // safari 에서 지원되지 않음
    // @ts-ignore
    if(evt.touches) {
      evt.stopPropagation();
      evt.preventDefault();
      window.addEventListener('touchmove', this.mouseMoveHandler!);
      window.addEventListener('touchend', this.mouseUpHandler!);
      window.addEventListener('touchcancel', this.mouseUpHandler!);

      // console.error('down', evt.clientX, evt.clientY);
      this.dragging = true;
    } else if(evt instanceof MouseEvent) {
      const button: MouseButtonType = evt.button;
      if(button !== MouseButtonType.Left) return;
      evt.stopPropagation();
      evt.preventDefault();
      window.addEventListener('mousemove', this.mouseMoveHandler!);
      window.addEventListener('mouseup', this.mouseUpHandler!);

      // console.error('down', evt.clientX, evt.clientY);
      this.dragging = true;
    }
  }

  private onMouseMove(evt: MouseEvent | TouchEvent) {
    let pt = DomHelper.getMouseDownPt(evt)
    if(this.ptConverter) pt = this.ptConverter(pt)

    this.offset = {x: pt.x - this.mouseDownPt!.x, y: pt.y - this.mouseDownPt!.y};

    this.dragMoveEventHandler?.(this.offset, pt)
    // console.error('move', this.offset.x, this.offset.y);
  }
  private onMouseUp(evt: MouseEvent | TouchEvent) {
    window.removeEventListener('mousemove', this.mouseMoveHandler!);
    window.removeEventListener('mouseup', this.mouseUpHandler!);
    window.removeEventListener('touchmove', this.mouseMoveHandler!);
    window.removeEventListener('touchend', this.mouseUpHandler!);
    window.removeEventListener('touchcancel', this.mouseUpHandler!);
    // console.error('up', evt.clientX, evt.clientY);
    this.dragging = false;

    this.dragMoveEventHandler?.(null)
  }
}
