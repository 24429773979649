
import {Component, Emit, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import ContentItemQuizEditorViewModel from "@/Booker/lib/viewModel/classContent/ContentItemQuizEditorViewModel";

@Component({name: "QuizSelectType", components: {}})
export default class QuizSelectType extends Vue {
  @Prop() selectedQuizType !: number;
  @Prop() isQuizType!: boolean;
  @Prop() quizModel!: ContentItemQuizEditorViewModel;
  @Ref() refPracticeQuiz !: HTMLInputElement;


  @Emit('onChangeQuizType')
  onChangeQuizType(type: number) {
    this.quizModel.quiz.quizType = type
    return type;
  }

  @Emit('onChangeIsQuizType')
  onChangeIsQuizType() {
    return !this.isQuizType
  }

  @Watch('isQuizType')
  watchChangeIsDifficulty() {
    if (!this.isQuizType) {
      requestAnimationFrame(() => this.refPracticeQuiz.click())
    }
  }


}
