
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import ReportScore from "@/views/ClassPage/component/ReportScore.vue";
import CourseStatus from "@/views/ClassPage/component/CourseStatus.vue";
import AiReportViewModel from "@/assets/lib/viewModel/AiReportViewModel";
import AnalysisByEvaluation from "@/views/ClassPage/component/AnalysisByEvaluation.vue";
import AchievementEvaluation from "@/views/ClassPage/component/AchievementEvaluation.vue";
import CommonLoading from "@/components/ui/loading/CommonLoading.vue";

@Component({
  name: "AiReportModal",
  components: {CommonLoading, AchievementEvaluation, AnalysisByEvaluation, CourseStatus, ReportScore}
})
export default class AiReportModal extends Vue {
  @Prop() title!: string
  @Prop() model!: AiReportViewModel
  modalClass = 'reportModal'

  @Emit('sendResult')
  sendResult(result: boolean) {
    return result
  }


}
