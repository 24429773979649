
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import YoutubeApi from "@/assets/lib/api/YoutubeApi";
import CommonInput from "@/Booker/views/components/common/CommonInput.vue";
import ClassContentMediaListViewModel from "@/Booker/lib/viewModel/ClassContentMediaListViewModel";
import BookerUtility from "@/Booker/lib/util/BookerUtility";
import Config from "@/config";


@Component({
  name: "YoutubeUrlModal",
  computed: {},
  components: {CommonInput}
})
export default class YoutubeUrlModal extends Vue {

  YoutubeApi = YoutubeApi
  youtubeSrc = ''
  youtubePlaceholder = "URL 입력"
  emptySrc = true

  @Prop() modalStatus!: boolean
  @Prop() model!: ClassContentMediaListViewModel;
  @Prop() youtubeThumbnail!: string


  @Emit('closeModal')
  closeModal() {
    return this.modalStatus
  }

  uploadYoutube(e: Event) {
    const youtubeId = BookerUtility.getYoutubeDataByYoutubeAPI((e.target as HTMLInputElement).value)
    this.youtubeSrc = Config.CONFIG.YOUTUBE_EMBED + youtubeId;
    this.emptySrc = false
  }

  @Emit('uploadYoutubeThumbnail')
  async uploadYoutubeThumbnail() {
    return await this.YoutubeApi.setYoutubeAPI(this.youtubeSrc);
  }


}
